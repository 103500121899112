import React, { useState, useEffect, useCallback, useRef } from 'react'
import { GiCheckMark } from 'react-icons/gi'
import { HiSelector } from 'react-icons/hi'
import { createPortal } from "react-dom"
import { AnimatePresence, motion } from 'framer-motion'

const SelectOptionAnim = {
    hidden: {
        height: 0,
        opacity: 0
    },
    visible: {
        height: 'auto',
        opacity: 1,
        zIndex: 10000,
    }
}

const InlineSelect = ({ label, items, value, setvalue, injectClass = '', loading }) => {

    const wrapRef = useRef(null)
    const optionRef = useRef(null)
    const [focussed, setFocussed] = useState(false)
    const [pos, setPos] = useState({ width: 0, top: 0, left: 0 })

    const isValue = () => value !== null && value !== ''
    const getName = () => items.filter(c => c.value === value)[0].text
    const selectItem = val => {
        setvalue(val)
        focusOut()
    }
    const toggleFocus = useCallback(() => {
        setFocussed(!focussed)
        window.removeEventListener('click', toggleFocus)
    }, [focussed])

    useEffect(() => {
        if (focussed) {
            document.body.addEventListener('click', toggleFocus)
            let e = wrapRef.current.getBoundingClientRect()
            let o = { width: `${e.width}px`, left: `${e.x}px` }
            if ((e.top + e.height + 270) > window.innerHeight) {
                o.bottom = `${window.innerHeight - e.top}px`
            } else {
                o.top = `${(e.top + e.height)}px`
            }
            setPos(o)
            setTimeout(() => {
                if (optionRef.current !== null) {
                    optionRef.current.querySelector('div>div>div>div')?.focus()
                }
            }, 200)
            // setTimeout(() => optionRef.current && optionRef.current.focus(), 100)
        }

        return () => document.body.removeEventListener('click', toggleFocus)
    }, [focussed]) // eslint-disable-line

    const focusOut = () => {
        setFocussed(false)
        if (wrapRef.current) {
            wrapRef.current.focus()
        }
    }

    const OptionElem = () => (
        createPortal(
            <motion.div ref={optionRef} role='list' variants={SelectOptionAnim} initial="hidden" animate="visible" exit="hidden" className="fixed overflow-y-auto max-h-[270px] bg-bluegray-200 dark:bg-mdark ring-1 ring-bluegray-300 dark:ring-ldark p-2 rounded" style={pos}>
                <div>
                    <span id="select-option-label" className="inline-block mb-2 text-xs text-gray-600 dark:text-gray-400">{label}</span>
                    <div>
                        {items.map((c, i) => (
                            <div role="button" key={c.text} tabIndex={0} aria-label={value === c.value ? `${c.text} selected` : `${c.text} not selected`} className="py-2 px-3 hover:bg-tcolor hover:text-white rounded-lg text-sm flex justify-between items-center text-bluegray-600 focus:outline-none dark:text-gray-300 dark:hover:text-white cursor-pointer" onClick={() => selectItem(c.value)}>
                                <span>{c.text}</span>
                                {value === c.value && <GiCheckMark className="text-green-500 w-4 flex-shrink-0" />}
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>, document.body)
    )

    return (
        <div ref={wrapRef} tabIndex={0} className={`relative ${focussed ? 'ring-tcolor ring-2' : 'ring-gray-600 ring-1'} px-2 py-1 rounded-lg ${loading ? 'hover:cursor-wait' : 'hover:cursor-pointer'} flex justify-between items-center ${injectClass} ${loading ? 'animate-pulse' : ''}`} onClick={() => toggleFocus()}>
            <div>
                <span className="text-gray-600 dark:text-gray-400 text-sm mr-3">{label}</span>
                <span className={`ml-3 font-bold rounded-lg px-2`}>{isValue() ? getName() : 'Select Item'}</span>
            </div>
            <span className="text-lg flex">
                <HiSelector className="text-gray-400 ml-2" />
            </span>
            <AnimatePresence>
                {focussed ? <OptionElem /> : null}
            </AnimatePresence>
        </div>
    )
}

export default InlineSelect
