
const FormCard = ({injectClass, children, title,onClick}) => {

    return (
        <div className={`form-wrap ${injectClass}`}>
            {title ? <div tabIndex={0} className={`form-wrap-heading text-gray-600 dark:text-tcolor ${onClick ? 'cursor-pointer' : ''}`} onClick={ () => onClick ? onClick() : null }>{title}</div>:''}
            {children}
        </div>
    )
}

export default FormCard