import { GiCheckMark } from 'react-icons/gi'
import { FaExclamationTriangle } from 'react-icons/fa'
import { HiSelector } from 'react-icons/hi'
import { AnimatePresence, motion } from 'framer-motion'
import { TextAnimateX } from '../../commons/anims'
import { useEffect, useMemo, useRef, useState } from 'react'
import FlowText from './FlowText'
import { createPortal } from "react-dom"
// import { useTranslation } from 'react-i18next'

const SelectOptionAnim = {
    hidden: {
        height: 0,
        opacity: 0
    },
    visible: {
        height: 'auto',
        opacity: 1,
        zIndex: 10000,
    }
}

const ProviderSelect = ({ fieldProps, fieldMeta, label, edit, options, fieldHelper, position='bottom', loading=false, dataLoaded=false, onChange ,disabled=false,ariaLabel=''}) => {

    const input = useRef(null)
    const wrapRef = useRef(null)
    const [showError, setShowError] = useState(false)
    const [filter, setFilter] = useState('')
    const [focussed, setFocussed] = useState(false)
    const [pos, setPos] = useState({width: 0, top: 0, left:0})
    const [enterPress, setEnterPress] = useState()
    const [opt,setOpt] = useState([])
    // const { t } = useTranslation()
    const [index,setIndex] = useState(-1);
    const resultContainer = useRef(null)
    const optionsRef = useRef(options); // Ref to store the options
    optionsRef.current = options; // Update on each render
    const [ariaLab,setAriaLab] = useState(ariaLabel)
    const [os, setOs] = useState('');


    useEffect(() => {
        setOpt(options)
    }, [options])
    const makeFocus = () => {
        if(input.current !== null)
            input.current.focus()
    }
    const toggleFocus = () => {
        if(focussed) {
            setIndex(-1)
            setFilter('')
            if(input.current !== null) input.current.blur()
        }
        setFocussed(!focussed)
        document.body.removeEventListener('click', toggleFocus)
    }
    const handleBlur = () => {
        setTimeout(() => {
            if(focussed) toggleFocus()
        }, 200)
    }

    const setEntervalue =(value)=>{
        if (value && opt.find(c => c.text === value) === undefined) {
            fieldHelper.setValue(value)
            setOpt([...opt,{text:value,value}])
            if(typeof onChange === 'function') onChange(value)
        }
    }

    useEffect(() => {
        if(focussed) {
            document.body.addEventListener('click', toggleFocus)
            let e = wrapRef.current.getBoundingClientRect()
            let o = {width: `${e.width}px`, left: `${e.x}px`}
            if((e.top + e.height + 270) > window.innerHeight) {
                o.bottom = `${window.innerHeight - e.top}px`
            } else {
                o.top = `${(e.top + e.height)}px`
            }
            setPos(o)
        }

        return () => document.body.removeEventListener('click', toggleFocus)
    }, [focussed]) // eslint-disable-line

    useEffect(() => {
        if (!resultContainer.current) return;
        resultContainer.current.scrollIntoView({
            block: "center"
        })
    }, [focussed, index])

    const toggleError = (val) => setShowError(val)
    const setValue = (value) => {
        if(opt.find(c => c.value === value) === undefined) {
            setOpt([{text: value, value}, ...opt])
        }
        fieldHelper.setValue(value)
        if(typeof onChange === 'function') onChange(value)
    }
    const filteredOptions = useMemo(() => {
        let filt = opt.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim())))
        if(filter.trim() !== '' && filt.find(c => c.text.toString().toLowerCase().trim() === filter.toLowerCase().trim()) === undefined) {
            filt = [{text: `Create "${filter}"`, value: filter}, ...filt]
        }
        return filt
    }, [filter,opt,enterPress]) // eslint-disable-line
    const displayText = useMemo(() => {
        let a = opt.filter(c => c.value === fieldProps.value)
        setAriaLab(a.length > 0 ? `${ariaLabel} ${a[0].text} is selected` : ariaLabel)
        return a.length > 0 ? a[0].text : false
        }, [fieldProps.value,opt])// eslint-disable-line
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown') {
                setIndex(prevIndex => (prevIndex + 1) % filteredOptions.length);
                event.preventDefault(); // Prevent page scrolling
            } else if (event.key === 'ArrowUp') {
                setIndex(prevIndex => (prevIndex - 1 + filteredOptions.length) % filteredOptions.length);
                event.preventDefault(); // Prevent page scrolling
            } else if (event.key === 'Enter' && index >= 0) {
                const selectedOption = filteredOptions[index];
                setValue(selectedOption.value); // Assuming onChange updates the value
                setFocussed(false); // Close the dropdown
                setIndex(-1); // Reset index
                event.preventDefault(); // Prevent form submission
            }
        };
        const handleSelection=(index)=>{
            const selectedItem = filteredOptions[index];
            setIndex(-1)
        }
        const setSelectedValue = (e) => {
            setValue(e);
            setFocussed(!focussed)
            document.body.removeEventListener('click', toggleFocus)
        }

        const listboxId = 'referrer-select-options'; // Unique ID for the listbox
        const inputId = 'referrer-select-input';

        useEffect(() => {
            const platform = navigator.platform.toLowerCase();
            const macOSPlatforms = ['macintosh', 'macintel', 'macppc', 'mac68k'];
            if (macOSPlatforms.some(platformType => platform.includes(platformType))) {
              setOs('mac');
            } else {
              setOs('other');
            }
          }, []);
    return (
        <div className="relative">
            <div ref={wrapRef} className={`group relative flex flex-col ${edit ? 'ring-1 ring-gray-300 dark:ring-gray-600' : ''} px-2 pt-1 rounded-lg ${focussed ? ' focus-within:ring-tcolor dark:focus-within:ring-tcolor' : ''} transition-all duration-100 cursor-pointer ${loading ? 'animate-pulse' : ''}`} onClick={makeFocus}>
                <span className={`${edit ? 'ml-1' : ''} text-xs text-gray-500 dark:text-gray-400`}><FlowText text={label} direction="x" /></span>
                <div className="relative">
                    {/* <input ref={input} type="text" className="h-0 w-0 absolute" {...fieldProps} onFocus={toggleFocus} /> */}
                    {!edit && dataLoaded &&
                        <div className={`pb-2 h-8 ${fieldProps.value ? '' : 'text-gray-500 line-through'}`}>{displayText || ' '}</div>
                    }
                    {
                        !dataLoaded &&
                        <div className="w-3/4 h-2 my-2 rounded-lg animate-pulse bg-gray-300"></div>
                    }
                    {
                        edit && dataLoaded &&
                        <>
                            <div className={`pb-1.5 pl-3 h-8 ${fieldProps.value ? '' : 'text-zinc-700 dark:text-white'} ${focussed ? 'hidden' : ''}`}>{displayText || `${label}`}</div>
                            <input id={inputId}
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-expanded={focussed}
                        aria-controls={listboxId}
                        aria-activedescendant={focussed && index >= 0 ? `option-${index}` : undefined}
                        aria-label={ariaLab}
                        ref={input}
                        type="text"
                        className={`pb-1.5 pl-3 bg-transparent pr-10 w-full outline-none ${!focussed ? 'h-0 w-0 absolute' : ''}`}
                        autoComplete="off"
                        value={filter}
                        onFocus={toggleFocus}
                        onBlur={handleBlur}
                        tabIndex={0}
                        onChange={ev => setFilter(ev.target.value)}
                        onKeyDown={handleKeyDown} onKeyPress={ev => { if (ev.code === "Enter") { setEntervalue(ev.target.value) } else { setEnterPress(0) } }} />
                        </>
                    }
                    {edit &&
                        <span className="absolute right-2 -top-1 text-xl flex">
                            {fieldMeta.error && fieldMeta.touched && <FaExclamationTriangle className="text-red-500 cursor-pointer" onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} />}
                            <HiSelector className="text-gray-400 ml-2" />
                        </span>
                    }
                    <AnimatePresence>
                        {fieldMeta.error && fieldMeta.touched && showError &&
                            <motion.span variants={TextAnimateX} initial="hidden" animate="visible" exit="hidden" className="absolute -top-1 right-16 inline-block bg-gray-300 dark:bg-ldark py-0.5 px-2 rounded text-sm text-red-500">
                                {fieldMeta.error}
                            </motion.span>
                        }
                    </AnimatePresence>
                </div>
            </div>
            {
            createPortal(
                <AnimatePresence>
                    {
                        focussed &&
                        <div className="fixed left-0 right-0 bottom-0 top-0">
                             <motion.div role="listbox"
                            id={listboxId}
                            tabIndex={0} variants={SelectOptionAnim} initial="hidden" animate="visible" exit="hidden" className={`fixed overflow-y-auto max-h-[270px] bg-bluegray-200 dark:bg-mdark ring-1 ring-bluegray-300 dark:ring-ldark p-2 rounded`} style={pos}>
                            <span className="inline-block mb-2 text-xs text-tcolor dark:text-gray-400">{label}</span>
                            {
                                filteredOptions.map((c, i) => (
                                    <div key={i}
                                    id={`option-${i}`}
                                    role="option"
                                    {...(os === 'mac' ? {'aria-selected': c.value === fieldProps.value ? 'true' : 'false'} : {})}
                                    // aria-selected={c.value === fieldProps.value ? true : false}
                                    onMouseDown={() => handleSelection(i)}
                                    {...(os === 'other' ? {'aria-label': c.value === fieldProps.value ? `${c.text} selected` : `${c.text} not selected`} : {})}
                                    // aria-label={c.value === fieldProps.value ? `${c.text} selected` : `${c.text} not selected`}
                                    ref={i == index ? resultContainer : null}
                                    style={{ backgroundColor: i == index ? "rgba(3, 153, 216)" : "" }}
                                    onClick={() => setValue( c.value)} className="py-2 px-3 hover:bg-tcolor hover:text-white rounded-lg text-sm flex justify-between items-center text-bluegray-600 dark:text-gray-300 dark:hover:text-white cursor-pointer">
                                        {c.text}
                                        { c.value === fieldProps.value && <GiCheckMark className="text-green-500" /> }
                                    </div>
                                ))
                            }
                            { filteredOptions.length === 0 && <span className="block px-5 pb-2 text-gray-500"> </span>}
                        </motion.div>
                        </div>
                       
                    }
                </AnimatePresence>, document.body)
            }
        </div>
    )
}

export default ProviderSelect