import { GiCheckMark } from 'react-icons/gi'
import { FaExclamationTriangle } from 'react-icons/fa'
import { HiSelector } from 'react-icons/hi'
import { AnimatePresence, motion } from 'framer-motion'
import { TextAnimateX } from '../../commons/anims'
import { useEffect, useMemo, useRef, useState } from 'react'
import FlowText from './FlowText'
import { useTranslation } from 'react-i18next'
import { createPortal } from 'react-dom'
import { AiFillCloseCircle } from 'react-icons/ai'

const SelectOptionAnim = {
    hidden: {
        height: 0,
        opacity: 0
    },
    visible: {
        height: 'auto',
        opacity: 1,
        zIndex: 10000,
    }
}

let multiSelectTime;
const FieldMultiSelect = ({ fieldProps, fieldMeta, label, edit, options,asians,native, fieldHelper, position='bottom', loading=false, dataLoaded=false,disabled=false, onChange,ariaLabel,setAsian,setNative }) => {

    const input = useRef(null)
    const wrapRef = useRef(null)
    const [showError, setShowError] = useState(false)
    const [filter, setFilter] = useState('')
    const [focussed, setFocussed] = useState(false)
    const [pos, setPos] = useState({width: 0, top: 0, left:0})
    const { t } = useTranslation()

    const [asianCurrent,setAsianCurrent] = useState([])
    const [nativeCurrent,setNativeCurrent] = useState([])
    const [index,setIndex] = useState(-1);
    const [allOption,setAllOption]= useState([])
const optionsRef = useRef(options); 
    optionsRef.current = options;  
    const [ariaLab,setAriaLab] = useState(ariaLabel)
    const [os, setOs] = useState('');

    useEffect(() => {
        // Ensure the currently focused option is visible
        const currentOption = document.getElementById(`option-${index}`);
        if (currentOption) {
            currentOption.scrollIntoView({ block: 'nearest' });
        }
    }, [index]);
    
    const makeFocus = () => {
        if(input.current !== null)
            input.current.focus()
    }
    const toggleFocus = (ev) => {
        if(focussed) {
            setIndex(-1)
            setFilter('')
            if(input.current !== null) input.current.blur()
        }
        setFocussed(!focussed)
        window.removeEventListener('click', toggleFocus)
    }
    const handleBlur = () => {
        multiSelectTime = setTimeout(() => {
            if(focussed) toggleFocus()
        }, 200)
    }

    useEffect(() => {
        // setDropdown([...dropdown])
        if(focussed) {
            setTimeout(() => {
                window.addEventListener('click', toggleFocus)
            }, 100)
            let e = wrapRef.current.getBoundingClientRect()
            let o = {width: `${e.width}px`, left: `${e.x}px`}
            if((e.top + e.height + 270) > window.innerHeight) {
                o.bottom = `${window.innerHeight - e.top}px`
            } else {    
                o.top = `${(e.top + e.height)}px`
            }
            setPos(o)
        }

        return () => window.removeEventListener('click', toggleFocus)
    }, [focussed]) // eslint-disable-line

    const toggleError = (val) => setShowError(val)
    const setValue = (ev, value) => {
        ev.stopPropagation()
        clearTimeout(multiSelectTime)
        let data;
        if(fieldProps.value.includes(value)) {
            data = fieldProps.value.filter(c => c !== value)
            fieldHelper.setValue(fieldProps.value.filter(c => c !== value))
        }else {
            data = [...fieldProps.value, value]
            fieldHelper.setValue([...fieldProps.value, value])
        }
        if(value===9){
            fieldHelper.setValue([value])
        }else if (value!==9 && fieldProps.value.includes(9)){
            fieldHelper.setValue([...fieldProps.value.filter(c=>c!==9),value])
        }
        if(typeof onChange === 'function') onChange(value)
    }
    const setAsianValue = (ev,value) =>{
        ev.stopPropagation()
        clearTimeout(multiSelectTime)
        let data = [...fieldProps.value, value]
       
        if(fieldProps.value.includes(value)) fieldHelper.setValue(fieldProps.value.filter(c => c !== value))
        else  if (value!==9 && fieldProps.value.includes(9)){
            fieldHelper.setValue([...fieldProps.value.filter(c=>c!==9),value])
        }
        else fieldHelper.setValue([...fieldProps.value,value])
        if(asianCurrent.length > 0 && fieldProps.value.includes(asianCurrent)){
            fieldHelper.setValue(data.filter(c => c !== !asianCurrent.includes(value)))
        } 
        if(asianCurrent.includes(value)){
            let values = asianCurrent.filter((x)=>x!==value)
            setAsianCurrent(values)
        }else{
            setAsianCurrent((prevValue)=>[...prevValue,value])
        }
        if(typeof setAsian === 'function') setAsian(value)

    }
    const setNativeValue = (ev,value) =>{
        ev.stopPropagation()
        clearTimeout(multiSelectTime)
        let data = [...fieldProps.value, value]
        
        if(fieldProps.value.includes(value)) fieldHelper.setValue(fieldProps.value.filter(c => c !== value))
        else  if (value!==9 && fieldProps.value.includes(9)){
            fieldHelper.setValue([...fieldProps.value.filter(c=>c!==9),value])
        }
        else fieldHelper.setValue([...fieldProps.value,value])
        if(nativeCurrent.length > 0 && fieldProps.value.includes(nativeCurrent)){
            fieldHelper.setValue(data.filter(c => c !== !nativeCurrent.includes(value)))
        } 
        if(nativeCurrent.includes(value)){
            let values = asianCurrent.filter((x)=>x!==value)
            setNativeCurrent(values)
        }else{
            setNativeCurrent((prevValue)=>[...prevValue,value])
        }
        if(typeof setNative === 'function') setNative(value)
    }
    const removeItem = (ev, val) => {
        ev.stopPropagation()
        fieldHelper.setValue(fieldProps.value.filter(c => c !== val))
        if(typeof setAsian === 'function' && val >= 10 && val <=29) setAsian(val)
        if(typeof setNative === 'function' && val >=30 && val <=35) setNative(val)
    }
    const filteredOptions = useMemo(() => {
        return options.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim())))
    }, [filter,options]) // eslint-disable-line
    const asianOption = useMemo(()=>{
        return asians.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim())))
    }, [filter,asians]) // eslint-disable-line
    const nativeOption = useMemo(()=>{
        return native.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim())))
    }, [filter,native]) // eslint-disable-line

    useEffect(()=>{
        setAllOption([])
         options.map((c)=>{
            setAllOption((prevOption)=>[...prevOption,c])
            if(c.value === 4){
                setAllOption((prevOption)=>[...prevOption,...asians])
            }else if(c.value === 6){
                setAllOption((prevOption)=>[...prevOption,...native])
            }
        })
    },[options,asians,native])//eslint-disable-line

    const allOptions = useMemo(()=>{
        return allOption.length === options.length+asians.length+native.length ? allOption.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim()))) : []
    },[filter,allOption])//eslint-disable-line

    const displayText = useMemo(() => {
        if(fieldProps.value === undefined) return false
        let a = fieldProps.value.map(c => {
            let o = [...options,...asians,...native].find(e => e.value.toString() === c.toString())
            return o ? o : null
        }).filter(c => c !== null)
        setAriaLab(a.length > 0 ? `${ariaLabel} ${a.map((c)=>{return `${c.text} is selected`})}` : ariaLabel)
        if(a.length > 0) {
            return (
                <div className={`flex items-center flex-wrap ${focussed ? 'justify-end' : ''}`}>
                    {
                        !focussed ?
                        a.map(c => (
                            <span key={c.value} className={`flex items-center gap-1 ${focussed ? 'text-[6px]' : 'text-[10px]'} transition-all duration-200 ${c.value !== 29 && c.value !== 35 &&'bg-bluegray-400'} text-white dark:bg-gray-500 px-2 py-0.5 rounded-xl mx-0.5 my-0.5 font-medium`}>
                                {c.value !== 29 && c.value !== 35 && c.text}
                                { c.value !== 29 && c.value !== 35 && edit && <AiFillCloseCircle title={t("Remove")} className="-mr-1" size="12px" onClick={(ev) => removeItem(ev, c.value)} /> }
                            </span>
                        ))
                        :
                            <span className={`${focussed ? 'text-[10px]' : 'text-xs'} transition-all duration-200 bg-bluegray-400 text-white dark:bg-gray-500 px-2 py-0.5 rounded-xl mx-0.5 my-0.5 whitespace-nowrap`}>{a.length} {t('Selected')}</span>
                    }
                </div>
            )
        } else return false
    }, [fieldProps.value, options,asians,native, focussed])// eslint-disable-line

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            setIndex(prevIndex => (prevIndex + 1) % allOptions.length);
            event.preventDefault(); // Prevent page scrolling
        } else if (event.key === 'ArrowUp') {
            setIndex(prevIndex => (prevIndex - 1 + allOptions.length) % allOptions.length);
            event.preventDefault(); // Prevent page scrolling
        } else if (event.key === 'Enter' && index >= 0) {
            const selectedOption = allOptions[index];
            if(selectedOption.value !== 4 && selectedOption.value !==6){
                setValue(event,selectedOption.value); // Assuming onChange updates the value
            }
            // setFocussed(false); // Close the dropdown
            setIndex(index); // Reset index
            event.preventDefault(); // Prevent form submission
        }
    };

    const listboxId = 'referrer-select-options'; // Unique ID for the listbox
    const inputId = 'referrer-select-input';

    useEffect(() => {
        const platform = navigator.platform.toLowerCase();
        const macOSPlatforms = ['macintosh', 'macintel', 'macppc', 'mac68k'];
        if (macOSPlatforms.some(platformType => platform.includes(platformType))) {
          setOs('mac');
        } else {
          setOs('other');
        }
      }, []);

    return (
        <div className="relative">
            <div ref={wrapRef} className={`group relative flex flex-col ${disabled ? 'bg-gray-300 dark:bg-gray-600' : edit ? 'ring-1 ring-gray-300 dark:ring-gray-600' : ''} px-2 pt-1 rounded-lg ${focussed ? ' focus-within:ring-tcolor dark:focus-within:ring-tcolor' : ''} transition-all duration-100 cursor-pointer ${loading ? 'animate-pulse' : ''}`} onClick={makeFocus}>
                <span className={`${edit ? 'ml-1' : ''} text-xs text-gray-500 dark:text-gray-400`}><FlowText text={label} direction="x" /></span>
                <div className="relative">
                    {/* <input ref={input} type="text" className="h-0 w-0 absolute" {...fieldProps} onFocus={toggleFocus} /> */}
                    {!edit && dataLoaded &&
                        <div className={`pb-2 ${fieldProps.value.length > 0 ? '' : 'text-gray-500 line-through'}`}>{displayText || ''}</div>
                    }
                    {
                        !dataLoaded &&
                        <div className="w-3/4 h-2 my-2 rounded-lg animate-pulse bg-gray-300"></div>
                    }
                    {
                        edit && dataLoaded &&
                        <>
                            <div className={`pb-1.5 pl-3 min-h-8 w-[96%]  ${fieldProps.value?.length > 0 ? '' : 'text-zinc-700'} ${focussed ? 'absolute -top-3 right-10 w-full' : ''}`}>{displayText || (focussed ? '' : `${label}`)}</div>
                            <input id={inputId}
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-expanded={focussed}
                        aria-controls={listboxId}
                        aria-activedescendant={focussed && index >= 0 ? `option-${index}` : undefined}
                        aria-label={ariaLab}
                        ref={input}
                        type="text"
                        className={`pb-1.5 pl-3 bg-transparent pr-10 w-full outline-none ${!focussed ? 'h-0 w-0 absolute' : ''}`}
                        autoComplete="off"
                        value={filter}
                        onFocus={toggleFocus}
                        onBlur={handleBlur}
                        onChange={ev => setFilter(ev.target.value)}
                        onKeyDown={handleKeyDown}/>
                        </>
                    }
                    {edit &&
                        <span className="absolute right-2 -top-1 text-xl flex">
                            {fieldMeta.error && fieldMeta.touched && <FaExclamationTriangle className="text-red-500 cursor-pointer" onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} />}
                            <HiSelector className="text-gray-400 ml-2" />
                        </span>
                    }
                    <AnimatePresence>
                        {fieldMeta.error && fieldMeta.touched && showError &&
                            <motion.span variants={TextAnimateX} initial="hidden" animate="visible" exit="hidden" className="absolute -top-1 right-16 inline-block bg-gray-300 dark:bg-ldark py-0.5 px-2 rounded text-sm text-red-500">
                                {fieldMeta.error}
                            </motion.span>
                        }
                    </AnimatePresence>
                </div>
            </div>
            { createPortal(
                <AnimatePresence>
                    {
                        focussed &&
                        <div className="fixed left-0 right-0 bottom-0 top-0">
                            <motion.div tabIndex={0}  role="listbox" id={listboxId}  variants={SelectOptionAnim} initial="hidden" animate="visible" exit="hidden" className={`fixed overflow-y-auto max-h-[270px] bg-bluegray-200 dark:bg-mdark ring-1 ring-bluegray-300 dark:ring-ldark p-2 rounded`} style={pos}>
                            <span className="inline-block mb-2 text-xs text-tcolor dark:text-gray-400">{label}</span>
                            {
                                allOptions.map((c, i) => {
                                    return(
                                    <>
                                       <div key={i}
                                            id={`option-${i}`}
                                            {...(os === 'mac' ? {'aria-selected': fieldProps.value?.includes(c.value) ? 'true' : 'false'} : {})}
                                            // aria-selected={fieldProps.value?.includes(c.value) ? 'true' : 'false'}
                                            {...(os === 'other' ? {'aria-label': fieldProps.value?.includes(c.value) ? `${c.text} selected` : `${c.text} not selected`} : {})}
                                            // aria-label={fieldProps.value?.includes(c.value) ? `${c.text} selected` : `${c.text} not selected`}
                                            role="option"  style={{ backgroundColor: i == index ? "rgba(3, 153, 216)" : "" }} onClick={(ev) => ((c.value !== 4 && c.value !== 6) && (c.value >= 10 && c.value <=29) && (c.value >=30 && c.value <=35)) ? setValue(ev, c.value) : (c.value !== 4 && c.value !== 6 && c.value >=10 && c.value <=29) ? setAsianValue(ev,c.value) : (c.value !== 4 && c.value !== 6 && c.value >=30 && c.value <=35) ?  setNativeValue(ev,c.value) : (c.value !== 4 && c.value !== 6) && setValue(ev,c.value)} className={`py-2 ${c.value > 9 ? 'px-8' : 'px-3'} hover:bg-tcolor hover:text-white rounded-lg text-sm flex justify-between items-center  dark:text-gray-300 dark:hover:text-white ${c.value !== 4 && c.value !== 6 ? 'cursor-pointer text-bluegray-600' : 'font-semibold'}`}>
                                          {c.text}
                                          { fieldProps.value?.includes(c.value) && <GiCheckMark className="text-green-500 w-4 flex-shrink-0" /> }
                                       </div>
                                    </>
                                )}
                                )
                            }
                            { allOptions.length === 0 && <span className="block px-5 pb-2 text-gray-500">{t("No Data")}</span>}
                        </motion.div>
                        </div>
                    }
                </AnimatePresence>, document.body)
            }
        </div>
    )
}

export default FieldMultiSelect