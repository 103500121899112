import { useEffect, useState } from "react"
import Tab from "../../components/snippets/Tabs/Tab"
import TabGroup from "../../components/snippets/Tabs/TabGroup"
import TabButtonGroup from "../../components/snippets/Tabs/TabButtonGroup"
// import { motion } from 'framer-motion'
import TabButton from "../../components/snippets/Tabs/TabButton"
import API from '../../axios/API'
// import Casedetails from "./Casedetails"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import AssociatedCases from './AssociatedCases'
import UploadsFile from './Uploads'
import CaseNotes from "./CaseNotes"
import Communications from "./Communications"
// import RelationshipSelector from "./RelationshipSelector"
import { useSelector } from "react-redux"
// import AssessmentLoader from "../Assessment/AssessmentLoader"
import PageLoader from "./PageLoader"
// import FlowText from "../../components/snippets/FlowText"
import NewCaseDetails from "./NewCaseDetails"
import Reminders from "./Reminders"
import 'moment-timezone';
import moment from 'moment';
import {FaClock} from 'react-icons/fa'
import AddServiceUsage from "../Case/AddServiceUsage"



const CaseData = ({ onClose, match,history }) => {

    const [loading, setLoading] = useState(true)
    const [casedata, setcasedata] = useState('')
   const relationShipCount = useSelector(state => state.auth.relationShipCount)
   const changerelationShipCount = useSelector(state =>state.auth.changerelationShipCount)
   const userDetails = useSelector(state=>state.auth.userDetails)
   const organization = useSelector(state=>state.auth.organization)
   let orgCaseNotesDisable = localStorage.getItem('orgCaseNotesDisable')
   const { t } = useTranslation()
   var [timeZone,setTimeZone] = useState('')
   let lang = localStorage.getItem('language')
   let serviceAuthorization = localStorage.getItem('serviceAuthorization')

   console.log(serviceAuthorization)
    const [tab, setTab] = useState(0)
    useEffect(() => {
        setLoading(true);
        const queryParam = new URLSearchParams(window.location.search);
        if(queryParam && queryParam.get('reminder')) {
            setTab(5);
        }

        if(history.location.state !== undefined && history.location.state) {
            setTab(history.location.state.tabIndex)
        }
        API.get(`organizations/${organization}/cases/${match?.params?.caselist_id}?lang=${lang}`).then(res => {
            if (res.status === 200) {
                setcasedata(res.data);
              
                setLoading(false)
            }
        })
    }, [relationShipCount,changerelationShipCount,match.params.caselist_id])// eslint-disable-line
    
    const OnmouseEnter = () =>{
        var date = moment(new Date())
        var zone = casedata.cgInfo.timeZoneOffset
        setTimeZone(date.clone().tz(zone).format('MMM DD,YYYY hh:mm:ss A z'))
    }
     
    return (
        <>
            {
                loading ? <PageLoader/> : <div>
                    {/* <div style={{ fontSize: "100%", fontFamily: "inherit" }}>
                        <TabButtonGroup>
                            <TabButton index={0}>
                                <motion.span className="flex my-2 justify-center cursor-pointer group relative text-white">
                                    {((userDetails.role[0]==="admin" && userDetails.role.length === 1)||(userDetails.caremanagerId !== casedata.careManagerId) || (casedata.careManagerId === 0)) ? <FlowText text={(casedata.caregiverName)}/>:<Link dataLoaded={loading} style={{ color: "rgba(3, 153, 216, var(--tw-text-opacity))" }} to={`/caregiver/${casedata.caregiverId}`} title="Caregiver">{(casedata.caregiverName !== 'null null' ? casedata.caregiverName :'')}</Link>}
                                </motion.span>
                            </TabButton>
                            taking care of
                            <TabButton index={1}>
                                <motion.span className="flex my-2 justify-center cursor-pointer group relative text-white">
                                    {((userDetails.role[0]==="admin" && userDetails.role.length === 1)||(userDetails.caremanagerId !== casedata.careManagerId) || (casedata.careManagerId === 0)) ? <FlowText text={casedata.careReceiverName}/>:<Link style={{ color: "rgba(3, 153, 216, var(--tw-text-opacity))" }} to={`/carereceiver/${casedata.careReceiverId}`} title="Carereceiver">{(casedata.careReceiverName !== 'null null' ? casedata.careReceiverName :'')}</Link>}
                                </motion.span>
                            </TabButton>
                            <TabButton index={2}>
                                {((userDetails.role[0]==="admin" && userDetails.role.length === 1)||(userDetails.caremanagerId !== casedata.careManagerId) || (casedata.careManagerId === 0)) ? <FlowText text={casedata.relationship}/> :<RelationshipSelector props={casedata.relationship} caseId={match?.params?.caselist_id} />}
                            </TabButton>
                        </TabButtonGroup>

                    </div>&nbsp; */}
                      <div className="flex" style={{ marginTop:'-18px'}}>&nbsp;&nbsp;&nbsp;
                             <span className="text-gray-600 dark:text-white" tabIndex={0}>{t("Case #")}&nbsp;</span><span className="text-gray-600 dark:text-white"><Link>{match?.params?.caselist_id}</Link></span>
                             &nbsp;<span className="flex text-gray-600 dark:text-white ml-4 mt-0" tabIndex={0}>{t("Caregiver TimeStamp")}<span className="cursor-pointer text-gray-600 dark:text-white"><FaClock size="30px" className="flex ml-3 bg-tcolor p-2 rounded-full items-center shadow-lg text-white" onMouseEnter={() => OnmouseEnter()} title={timeZone} /></span> </span>
                             {/* &nbsp;&nbsp;&nbsp;<span className="text-tcolor dark:text-white underline">{t("Referred by")}&nbsp;</span><span className="text-tcolor dark:text-white"><Link>{'Akilan'}</Link></span><br></br>
                             &nbsp;&nbsp;&nbsp;<span className="text-tcolor dark:text-white underline">{t("Phone")}&nbsp;</span><span className="text-tcolor dark:text-white"><Link>{'99-888-7777'}</Link></span><br></br>
                             &nbsp;&nbsp;&nbsp;<span className="text-tcolor dark:text-white underline">{t("Email")}&nbsp;</span><span className="text-tcolor dark:text-white"><Link>{'Akilano2@gmail.com'}</Link></span> */}
                            </div>
                    <div className="text-center">
                        <TabButtonGroup>
                            <TabButton index={0} value={tab} setValue={setTab} id={'newCase'}>
                               {t("Case Details")}
                            </TabButton>
                           {(userDetails.orgId === parseInt(organization) && ((userDetails.parentRole.length === 1 && userDetails.parentRole.includes('referrer')) || (userDetails.parentRole.length === 1 && userDetails.parentRole.includes('screener')))) || (userDetails.orgId !== parseInt(organization) && ((userDetails.childRole.length === 1 && userDetails.childRole.includes('dynamic_ref')) || (userDetails.childRole.length === 1 && userDetails.childRole.includes('dynamic_scr')))) ? '' : <TabButton index={1} value={tab} setValue={setTab}>
                                {t("Communications")}
                            </TabButton>}
                            {(userDetails.orgId === parseInt(organization) && ((userDetails.parentRole.length === 1 && userDetails.parentRole.includes('referrer')) || (userDetails.parentRole.length === 1 && userDetails.parentRole.includes('screener')))) || (userDetails.orgId !== parseInt(organization) && ((userDetails.childRole.length === 1 && userDetails.childRole.includes('dynamic_ref')) || (userDetails.childRole.length === 1 && userDetails.childRole.includes('dynamic_scr')))) || casedata.status === 'CLOSED' ? '' : <TabButton index={2} value={tab} setValue={setTab}>
                                {t("Uploads")}
                            </TabButton>}
                            <TabButton index={3} value={tab} setValue={setTab}>
                                {t("Associated Cases")}
                            </TabButton>
                           {((userDetails.orgId !== parseInt(organization) && userDetails.childRole.length === 1 &&  userDetails.childRole.includes("dynamic_scr")) || (userDetails.orgId === parseInt(organization) && userDetails.parentRole.length === 1 &&  userDetails.parentRole.includes("screener"))) && orgCaseNotesDisable === 'true' ? '' : <TabButton index={4} value={tab} setValue={setTab}>
                                {t("Notes")}
                            </TabButton>}
                            <TabButton index={5} value={tab} setValue={setTab} id={'reminder'}>
                                {t("Reminders")}
                            </TabButton>
                            {serviceAuthorization === 'true' ?<TabButton index={6} value={tab} setValue={setTab}>
                                {t("Service Authorization")}
                            </TabButton>:''}
                        </TabButtonGroup>
                    </div>
                    <TabGroup value={tab}>
                        <Tab index={0} value={tab}>
                            {/* <Casedetails initialData={match?.params?.caselist_id} /> */}
                            <NewCaseDetails initialData={match?.params?.caselist_id} />
                            {/* <NewCaseRaj initialData={match?.params?.caselist_id} /> */}
                        </Tab>
                        <Tab index={1} value={tab}>
                            <Communications props = {match?.params?.caselist_id}/>
                        </Tab>
                        <Tab index={2} value={tab}>
                            <UploadsFile props = {match?.params?.caselist_id} CmId={casedata.cgInfo.careManagerId} />
                        </Tab>
                        <Tab index={3} value={tab}>
                            <AssociatedCases props={match?.params?.caselist_id} CmId={casedata.cgInfo.careManagerId} />
                        </Tab>
                        <Tab index={4} value={tab}>
                            <CaseNotes props={match?.params?.caselist_id} />
                        </Tab>
                        <Tab index={5} value={tab}>
                             <Reminders props={match?.params?.caselist_id} CmId={casedata.cgInfo.careManagerId} />
                        </Tab>
                        <Tab index={6} value={tab}>
                             <AddServiceUsage props={match?.params?.caselist_id} type={'Authorization'} />
                        </Tab>
                    </TabGroup>
                </div>
            }</>
    )
}

export default CaseData