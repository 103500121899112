import { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
// import axios from 'axios';
import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch, useSelector } from 'react-redux'
import { setAlert } from '../../store/theme/actions'
import { FaUserEdit, FaEnvelope, FaCheck, FaTimesCircle, FaUserMinus, FaInfoCircle,FaPhoneAlt} from 'react-icons/fa'
import InlineTextField from '../../components/snippets/InlineTextField'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import AddButton from '../../components/snippets/AddButton'
import API from '../../axios/API'
import { Link, useHistory } from "react-router-dom"
import Dialog from '../../components/snippets/Dialog'
import FlowText from '../../components/snippets/FlowText'
import { useTranslation } from 'react-i18next'
import { createPortal } from 'react-dom'


createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const Userlist = () => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [usersList, setUsersList] = useState([]);
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(false);
    const userDetails = useSelector(state=>state.auth.userDetails)
    const [removeuser,setRemoveUser] = useState([])
    const [deactivateuser,setDeactivateUser] = useState([])
    const [activateuser,setActivateUser] = useState([])
    const dispatch = useDispatch()
    const [deactivatePopup, setDeactivatePopup] = useState(false)
    const [activatePopup, setActivatePopup] = useState(false)
    const [removePopup, setRemovePopup] = useState(false)
    const [pplId, setPplId] = useState()
    const organization = useSelector(state => state.auth.organization)
    const { t } = useTranslation()
    const history = useHistory()
    let orgId = localStorage.getItem('orgid')
    const [perPage, setPerPage] = useState(10);
    const [sizePage] = useState([10]);
    const [totalRows, setTotalRows] = useState(0);
    const [column,setColumn] = useState()
    const [sortDirection,setSortDirection] = useState()
    const prevPage = useRef('');
    const [statusFilter, setStatusFilter] = useState()
    const [triggerAll, setTriggerAll] = useState(false)
    const [records,setRecords] = useState(false)
    const dialogRef = useRef(null);
    const activeRef = useRef(null)
    const removeRef = useRef(null)
    const [previousDialogFocus, setPreviousDialogFocus] = useState(null);
    const [previousActiveFocus, setPreviousActiveFocus] = useState(null);
    const [previousRemoveFocus, setPreviousRemoveFocus] = useState(null);
    const tableRef = useRef(null)

    useEffect(() => {
       var pageView = sessionStorage.getItem('pageView');
       setLoading(true);
       if(pageView){
        prevPage.current = pageView
        setPage(parseInt(pageView));
        sessionStorage.removeItem('pageView');
        if (prevPage.current === 1 || page === 1) {
            let apiPath = `page=${prevPage.current || page}&size=${perPage}`
            API.get(`organizations/${organization}/users?status=active&${apiPath}`).then((res) => {
                setTotalRows(res.data.page.totalElements)
                setUsersList(res.data._embedded.immutableUserResponseList)
            }).catch(e => {
                console.log(e)
            }).finally(() => setLoading(false))
        // API.get(`organizations/${organization}/users?status=active`).then((res) => {
        //     setUsersList(res.data.body);
        // }).catch(e => {
        //     console.log(e)
        // }).finally(() => setLoading(false))
        }
       }else{
        prevPage.current = page
        let apiPath = `page=${prevPage.current}&size=${perPage}`
        API.get(`organizations/${organization}/users?status=active&${apiPath}`).then((res) => {
            setTotalRows(res.data.page.totalElements)
            setUsersList(res.data._embedded.immutableUserResponseList)
        }).catch(e => {
            console.log(e)
        }).finally(() => setLoading(false))

    } 
    previousActiveFocus && previousActiveFocus.focus();  
    previousDialogFocus && previousDialogFocus.focus();
    previousRemoveFocus && previousRemoveFocus.focus();
    }, [removeuser,deactivateuser,activateuser,organization,page,triggerAll])// eslint-disable-line

    const removeUser = ()=>{
        API.delete(`users/${pplId}?orgId=${orgId}`).then(res =>{
            setRemoveUser(res);
            if(res.status === 200 && res.data.params === true){
                dispatch(setAlert({title:t('Success'),subtitle:t('User Removed Successfully'),type:'success',active:true}))
            }else if(res.status === 200 && res.data.status === false){
                dispatch(setAlert({title:t('Warning'),subtitle:res.data.errorMessage,type:'warning',active:true}))
            }
            else if(res.status === 400){
                dispatch(setAlert({title:t('Error'),subtitle:t('Invalid Id'),type:'error',active:true}))
            }else if(res.status === 404){
                dispatch(setAlert({title:t('Error'),subtitle:t('User Not Found'),type:'error',active:true}))
            }else{}
        }).catch(e =>{
            console.log(e)
        }).finally(()=>setRemovePopup(false))
    }

    const deactivateUser = ()=>{
        API.patch(`users/${pplId}:deactivate?orgId=${orgId}`).then(res=>{
            setDeactivateUser(res)
            if(res.status === 200 && res.data.params === true){
                dispatch(setAlert({title:t('Success'),subtitle:t('User Deactivated Successfully'),type:'success',active:true}))
            }else if(res.status === 200 && res.data.status === false){
                dispatch(setAlert({title:t('Warning'),subtitle:res.data.errorMessage,type:'warning',active:true}))
            }
            else if(res.status === 400){
                dispatch(setAlert({title:t('Error'),subtitle:t('Invalid Id'),type:'error',active:true}))
            }else if(res.status === 404){
                dispatch(setAlert({title:t('Error'),subtitle:t('User Not Found'),type:'error',active:true}))
            }else{}
        }).catch(e=>{
            console.log(e);
        }).finally(()=>setDeactivatePopup(false))
    }

    const activateUser = ()=>{
        API.patch(`users/${pplId}:reactivate?orgId=${orgId}`).then(res=>{
            setActivateUser(res)
            if(res.status === 200 && res.data.params === true){
                dispatch(setAlert({title:t('Success'),subtitle:t('User Activated Successfully'),type:'success',active:true}))
            }else if(res.status === 200 && res.data.status === false){
                dispatch(setAlert({title:t('Warning'),subtitle:res.data.errorMessage,type:'warning',active:true}))
            }
            else if(res.status === 400){
                dispatch(setAlert({title:t('Error'),subtitle:t('Invalid Id'),type:'error',active:true}))
            }else if(res.status === 400){
                dispatch(setAlert({title:t('Error'),subtitle:t('User Not Found'), type:'error',active:true}))
            }else{}
        }).catch(e=>{
            console.log(e)
        }).finally(()=>setActivatePopup(false))
    }

    // const changePassword =(userName)=>{
    //     axios.post(`${process.env.REACT_APP_API_BASE_URL}users/${userName}:forgot-password`).then(res=>{
    //         if (res.status === 200){
    //             dispatch(setAlert({ title: ('Success'), subtitle: 'Mail Sent Successfully', type: 'success', active: true }))
    //         }
    //     })
    // }

    const openDeactivatePopup = (value) => {
        dialogRef.current?.focus();
        setPplId(value)
        setDeactivatePopup(true)
    }

    const openActivatePopup = (value) => {
        activeRef.current?.focus();
        setPplId(value)
        setActivatePopup(true)
    }

    const openRemovePopup = (value) => {
        removeRef.current?.focus();
        setPplId(value)
        setRemovePopup(true)
    }

    const caseNavigation = () => {
        sessionStorage.setItem('pageView', prevPage.current.toString());
    }

    useEffect(() => {
        if (deactivatePopup) {
          setPreviousDialogFocus(document.activeElement);
          dialogRef.current?.focus();
        } else {
          previousDialogFocus && previousDialogFocus.focus();
        }
      }, [deactivatePopup]);
    
      useEffect(()=>{
        if (activatePopup) {
            setPreviousActiveFocus(document.activeElement);
            activeRef.current?.focus();
          } else {
            previousActiveFocus && previousActiveFocus.focus();
          }
      },[activatePopup])

      useEffect(()=>{
        if (removePopup) {
            setPreviousRemoveFocus(document.activeElement);
            removeRef.current?.focus();
          } else {
            previousRemoveFocus && previousRemoveFocus.focus();
          }
      },[removePopup])

    const colums = useMemo(() => {
        return [
            { name: t('Name'), selector:'firstName', cell: row => <div aria-label={`Name ${row.firstName} ${row.lastName}`} tabIndex={0} data-private="lipsum">{row.firstName} {row.lastName}</div>, grow: 1, sortable: true,sortField:'SORT_NAME' },
            // { name: t('User Name'), selector: 'userName', grow: 1, sortable: true },
            // { name: t('Phone'), selector: 'phone',grow:1, sortable: true },
            {name:t('Phone'),selector:'phone', cell: row=>{return <div data-private="lipsum" style={{display:'flex',fontSize:'13px',marginTop:'15px'}}>{row.phone === "" || row.phone === null ? '':<a href={`tel: +1${row.phone.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor p-2 rounded-lg text-white" title={t('Call')}>
            <FaPhoneAlt size="10px" style={{margin:'auto'}}/>
            </a>}&nbsp;&nbsp;<span tabIndex={0} aria-label={`phone ${row.phone}`}>{row.phone}</span></div>},grow:1,sortable: true, sortField:'SORT_PHONE'},
            // { name: t('E-Mail'), selector: 'email', grow: 1, sortable: true },
            {name:t('Email'),cell:row=>{return <div data-private="lipsum" style={{display:'flex',fontSize:'13px',marginTop:'15px'}}>{row.email === "" || row.email === null ? '' :<a href={`mailto: ${row.email}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Mail')}>
            <FaEnvelope size="10px"  style={{margin:'auto'}} />
        </a>}&nbsp;&nbsp;<span tabIndex={0} aria-label={`Email ${row.email}`} data-tag="allowRowEvents">{row.email}</span></div>},grow:1,sortable: true,wrap: true, sortField: 'SORT_EMAIL'},
            { name: t('Role'), grow:2,  cell: row => <>
           { row.roles.map((c ,i) => <div tabIndex={0} aria-label={`Role ${row.roles}`}>{`${c} ${(row.roles.length === i+1) ? '':','}`}</div>) }
            </>, sortable: true,sortField: 'SORT_ROLES' },
            {
                name: t('Active'),
                button: true, grow: 1, cell: row => <>
                    {(row.active === false && row.status === "PENDING_CERTIFICATION") ? <button aria-label='Pending Certification' ><FaInfoCircle size="20px" title={t('Pending Certification')} className="text-blue-600 dark:text-blue-300" /></button> :
                        (row.active === true && row.status === "ACTIVE") ?<button aria-label='Click to deactivate' onClick={()=>((organization && userDetails && userDetails.orgId === parseInt(organization))||( organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin'))) ? openDeactivatePopup(row.pplId) : ''}> <FaCheck size="20px" title={t('Click to deactivate')} className="text-green-600 dark:text-green-300" /></button>:
                            (row.active === false && row.status === "DEACTIVATED") ? <button aria-label='Click to activate' onClick={()=>((organization && userDetails && userDetails.orgId === parseInt(organization))||( organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin'))) ? openActivatePopup(row.pplId) : ''}> <FaTimesCircle title={t('Click to activate')} size="20px" className="text-yellow-600 dark:text-yellow-300" /></button> : ''}
                </>,
            },
            {
                name: t('Action'), button: true, grow: 1, cell: row => <>
                    {(row.active === false && row.status === "PENDING_CERTIFICATION") ? <>{((organization && userDetails && userDetails.orgId === parseInt(organization))||( organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin')))?<Link to={`editUser/${row.pplId}`} onClick={() => caseNavigation()}><FaUserEdit size="20px" title={t('Edit')} className="text-gray-600 dark:text-gray-300" /></Link>:<FaUserEdit size="20px" title={t('Edit')} className="text-gray-600 dark:text-gray-300" /> }&nbsp;&nbsp;
                    <button aria-label='Remove' onClick={()=>((organization && userDetails && userDetails.orgId === parseInt(organization))||( organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin'))) ? openRemovePopup(row.pplId) : ''}><FaUserMinus size="20px" title={t('Remove')} className="text-red-600 dark:text-red-300" /></button></> : (row.active === false && row.status === "DEACTIVATED") ?   <button aria-label='Remove'  onClick={()=>(organization && userDetails ) ? openRemovePopup(row.pplId) : ''}><FaUserMinus size="20px" title={t('Remove')} className="text-red-600 dark:text-red-300" /></button> :
                    (row.active === true && row.status === "ACTIVE") ?<>{((organization && userDetails && userDetails.orgId === parseInt(organization))||( organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin'))) ? <Link to={`editUser/${row.pplId}`} onClick={() => caseNavigation()}><FaUserEdit size="20px" title={t('Edit')} className="text-gray-600 dark:text-blue-300" /></Link>:<FaUserEdit size="20px" title={t('Edit')} className="text-gray-600 dark:text-blue-300" />}</>: ''}
                </>,
            },
            // { name: t('Change Password'), button: true, grow: 1, cell: row => <button onClick={()=>changePassword(row.userName)}><FaEnvelope size="20px" title={'Email'} className="text-gray-600 dark:text-gray-300" /></button> },
        ]
    }, [])// eslint-disable-line
    const addNewUser=()=>{
        history.push({pathname:`/addnewuser`})
    }
    const SubHeader = useMemo(() => ( 
        <div className="flex items-center">
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} onEnter={() => searchRecords()} onClick={() => searchRecords()} onClose={() => closeFilter()} />
            {((organization && userDetails && userDetails.orgId === parseInt(organization))||(organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin')))?
            <span tabIndex={0} aria-label='Add New User' onClick={()=>addNewUser()}><AddButton title={t("Add New User")} /></span> : ''}
            </div>
    ), [filter]) // eslint-disable-line
    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return usersList.filter(c => filter === '' || filter === null || filterSplit)
    }, [usersList, filter])// eslint-disable-line
    const ExpandComponent = useCallback(row => {
        return (

            <div className="bg-bluegray-300 dark:bg-ldark" key={row.data.caseId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>

        )
    }, []) //eslint-disable-line
    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    })
    useEffect(() => {
        const element = document.querySelectorAll('[id="column-undefined"]');
        if(element!=null){
            if (element) {
                element.forEach((e,i) => {
                    e.setAttribute('id','header_'+i)
                })
            }
        }
    })

    const closeFilter = () => {
        setPage(1)
        setStatusFilter()
        setColumn()
        setSortDirection()
        // changeStatus('All Status')
        setTriggerAll(!triggerAll)
    }

    const closeFilterStatus = () => {
        setRecords(false)
        setPage(1)
        setColumn()
        setSortDirection()
        setFilter('')
        setTriggerAll(!triggerAll)
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setUsersList([])
        setPerPage(sizePage);
        let apiPath = `page=${page}&size=${newPerPage}`
        API.get(`organizations/${organization}/users?status=active&${apiPath}`).then((res) => {
            if (res.status === 200) {
                setTotalRows(res.data.page.totalElements)
                setPerPage(res.data.page.size)
                setUsersList(res.data._embedded.immutableUserResponseList)
            }
        })
        setLoading(false);
        // sizeHandling(newPerPage)
    };

    if ((!loading && history.location.state === undefined && (filteredcasedetails.length >= 10 || usersList.length >= 10)) || (!loading && (filteredcasedetails.length >= 10 || usersList.length >= 10))) {
        if (page > 1) {
            // document.getElementById('pagination-next-page')?.removeAttribute('disabled')
            document.getElementById('pagination-first-page')?.removeAttribute('disabled')
            document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
                setPage(1)
            })
        }
        document.getElementById('pagination-next-page')?.addEventListener(('click'), () => {
            setPage(page + 1)
        })
        if (page > 1) {
            document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
            document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
                setPage(page - 1)
            })
        }
    }  else if (!loading && page > 1 && filteredcasedetails.length < 10 && usersList.length < 10) {
        document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
        document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
            setPage(page - 1)
        })
        document.getElementById('pagination-first-page')?.removeAttribute('disabled')
        document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
            setPage(1)
        })
    } else if (filteredcasedetails.length < 10 && usersList.length < 10 && !loading && history.location.state === undefined) {
        document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    }else {
        document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    }

    const searchRecords = () => {
        if (filter.length > 0) {
            if (statusFilter !== 'All Status' && statusFilter !== undefined) {
                API.get(`organizations/${organization}/users?status=active&page=1&size=${perPage}&status=${statusFilter}&search=${filter}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                        setUsersList([])
                        setUsersList(res.data._embedded.immutableUserResponseList)
                        setTotalRows(res.data.page.totalElements)
                    }else{
                        setRecords(true)
                        setUsersList([])
                    }
                })
            } else{
                API.get(`organizations/${organization}/users?status=active&page=1&size=${perPage}&search=${filter}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        // setPage(1)
                        setUsersList([])
                        setUsersList(res.data._embedded.immutableUserResponseList)
                        setTotalRows(res.data.page.totalElements)
                    }else{
                        setRecords(true)
                        setUsersList([])
                    }
                })
            }
        } 
        else  {
            if(statusFilter && statusFilter !== 'All Status'){
                let Apipath = statusFilter ? `page=1&size=${perPage}&status=${statusFilter}` : `page=${page}&size=${perPage}`
                API.get(`organizations/${organization}/users?status=active&${Apipath}`).then((res) => {
                    if (res.status === 200) {
                        setPage(1)
                        setUsersList(res.data._embedded.immutableUserResponseList)
                        setTotalRows(res.data.page.totalElements)
                    }
                })
            }
          
        }
        if (res.data._embedded) {
            setRecords(false)
        }
        else {
            setRecords(true)
            setUsersList([])
        }
    }
 
    const handleSort = async (column, sortDirection) => {
        setColumn(column.sortField)
        setSortDirection(sortDirection.toUpperCase())
        let apiPath = `page=1&size=${perPage}&sort=${column.sortField}`;        
        API.get(`organizations/${organization}/users?status=active&${apiPath},${sortDirection.toUpperCase()}`).then((res) => {
            if (res.status === 200) {
                setUsersList(res.data._embedded.immutableUserResponseList)
                setTotalRows(res.data.page.totalElements)
            }
        })
        //   setData(remoteData);
    };
    
    return (
        <div>
            {
                loading ?
                    <DatatableLoader /> :
                    <div ref={tableRef} className=" mt-4 p-2 bg-bluegray-200 dark:bg-ddark rounded-lg">
                        <DataTable title="casedetails" columns={colums} data={filteredcasedetails} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination paginationServer={true} noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader onSort={handleSort} 
                        sortServer paginationRowsPerPageOptions={sizePage} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} noDataComponent={t('There are no records to display')} tabIndex='0' />
                        {/* <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div> */}
                        {records ?<div className='flex justify-center'> <span onClick={() => closeFilterStatus()}><button aria-label="Back to All Cases" className='bg-tcolor flex px-6 py-0.5 mr-3 mt-3 items-center text-sm'>{t("Click here to User List")}</button> </span></div> : ''}
                    </div>
            }
            {/* Deactivate user */}
           {deactivatePopup && createPortal(<div role="dialog"><div ref={dialogRef} role="dialog" tabIndex={-1} aria-modal="true"></div>
            <Dialog title={t("Deactivate User")} showDialog={deactivatePopup} onClose={() => setDeactivatePopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2" tabIndex={0}>
                        {t("Are you sure you want to deactivate this user")}
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <span tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setDeactivatePopup(false)}>{t("No")}</span>
                        <span tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => deactivateUser()}>{t("Yes")}</span>
                    </div>
                </div>
            </Dialog></div>,document.body)}

            {/* Activate user */}
            {activatePopup && createPortal(<div role="dialog"><div ref={activeRef} role="dialog" tabIndex={-1} aria-modal="true"></div>
            <Dialog title={t("Activate User")} showDialog={activatePopup} onClose={() => setActivatePopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2" tabIndex={0}>
                        {t("Are you sure you want to activate this user")}
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <span tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setActivatePopup(false)}>{t("No")}</span>
                        <span tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => activateUser()}>{t("Yes")}</span>
                    </div>
                </div>
            </Dialog></div>,document.body)}

            {/* remove user */}
            {removePopup && createPortal(<div role="dialog"><div ref={removeRef} role="dialog" tabIndex={-1} aria-modal="true"></div>
            <Dialog title={t("Remove User")} showDialog={removePopup} onClose={() => setRemovePopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2" tabIndex={0}>
                        {t("Are you sure you want to remove this user")}
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <span tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setRemovePopup(false)}>{t("No")}</span>
                        <span tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => removeUser()}>{t("Yes")}</span>
                    </div>
                </div>
            </Dialog></div>,document.body)}
        </div>
    )
}

export default Userlist