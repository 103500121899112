import { GiCheckMark } from 'react-icons/gi'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FaFilter } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

const SelectOptionAnim = {
    hidden: {
        height: 0,
        opacity: 0
    },
    visible: {
        height: 'auto',
        opacity: 1,
        zIndex: 10,
    }
}

let blurEvent;

const TableSelectFilter = ({ label, options, defaultValue, value, setValue, injectClass }) => {

    const wrapRef = useRef(null)
    const [filter, setFilter] = useState('')
    const [focussed, setFocussed] = useState(false)
    const [pos, setPos] = useState({width: 0, top: 0, left:0})
    const { t } = useTranslation()

    const makeFocus = (ev) => {
        ev.stopPropagation()
        setFocussed(!focussed)
    }
    const toggleFocus = () => {
        if(focussed) {
            setFilter('')
        }
        setFocussed(!focussed)
        document.body.removeEventListener('click', toggleFocus)
    }
    const changeValue = (ev, value) => {
        ev.stopPropagation()
        if(typeof setValue === 'function') setValue(value)
    }

    useEffect(() => {
        if(focussed) {
            document.body.addEventListener('click', toggleFocus)
            let e = wrapRef.current.getBoundingClientRect()
            let o = {left: `${e.x}px`}
            if(e.x > window.innerWidth / 2) o.left = `${(e.x - 200) + e.width}px`
            if((e.top + e.height + 270) > window.innerHeight) {
                o.bottom = `${window.innerHeight - e.top}px`
            } else {
                o.top = `${(e.top + e.height)}px`
            }
            setPos(o)
        }

        return () => document.body.removeEventListener('click', toggleFocus)
    }, [focussed]) // eslint-disable-line
    useEffect(() => {
        return () => clearTimeout(blurEvent)
    }, [])

    const filteredOptions = useMemo(() => {
        return options.filter(c => (filter === null || filter.trim() === '' || c.value.toLowerCase().trim().includes(filter.toLowerCase().trim())))
    }, [filter, options]) // eslint-disable-line

    const accessibilityFixs = (value)=>{
        if(typeof setValue === 'function') setValue(value)
    }

    const selectStatus=()=>{
        setFocussed(!focussed)
    }
    return (
        <div className={`w-full text-left ${injectClass}`}>
            <div ref={wrapRef} className="flex items-center gap-1.5">
                {/* <div>{label}</div> */}
                <div className="absolute -right-3.5" >
                   <button aria-label='Status' tabIndex={0} onClick={()=>selectStatus()}> <FaFilter size="16px" className="filterIcon transition-all duration-200 flex-shrink-0 px-1" onClick={makeFocus} /></button>
                    { value!==undefined &&  value !== defaultValue &&
                        <div className="relative h-full"><div className="w-1 h-1 bg-green-400 rounded-full absolute -top-4 -right-0.5"></div></div>
                    }
                </div>
            </div>
            <AnimatePresence>
                {
                    focussed &&
                    <div className="fixed left-0 right-0 bottom-0 top-0 z-[2]">
                        <motion.div variants={SelectOptionAnim} initial="hidden" animate="visible" exit="hidden" className={`fixed overflow-y-auto max-h-72 bg-bluegray-300 dark:bg-mdark ring-1 ring-bluegray-300 dark:ring-ldark p-2 rounded w-full top-full max-w-[200px]`} style={pos}>
                        <span className="inline-block mb-2 text-[9px] text-gray-600 dark:text-gray-400">{label}</span>
                        {
                            filteredOptions.map((c, i) => (
                                <div key={i} id={`option-${i}`}
                                role="option" tabIndex="0" onKeyDown={(event) => {event.key === 'Enter' && accessibilityFixs(c.value)}} onClick={(ev) => changeValue(ev, c.value)} className="py-2 px-1 hover:bg-tcolor hover:text-white rounded-lg text-[10px] flex justify-between items-center cursor-pointer text-bluegray-600 dark:text-gray-300 dark:hover:text-white overflow-hidden"
                                aria-selected= {c.value === value ? 'true' : 'false'}
                                aria-label={c.value === value ? `${c.text} Selected` :  ''}>
                                    <span className="overflow-hidden overflow-ellipsis"
                                   >{c.text}</span>
                                    { c.value === value && <GiCheckMark className="text-green-500 flex-shrink-0" /> }
                                </div>
                            ))
                        }
                        { filteredOptions.length === 0 && <span className="block px-5 pb-2 text-gray-500">{t("No Data")}</span>}
                    </motion.div>
                    </div>
                    
                }
            </AnimatePresence>
        </div>
    )
}

export default TableSelectFilter