import { AnimatePresence } from 'framer-motion'
import { useMemo,useEffect,useState,useRef} from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { MdKeyboardArrowDown } from 'react-icons/md'

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

const FieldSupportDatePicker = ({value, setValue, showTime=false, maxDate, minDate,showYear=false,disabled=false ,ariaLabel='',currentPicker}) => {

    const [os,setOs] = useState()
    const [yearOpen,setYearOpen] = useState(false)
    const [monthOpen,setMonthOpen] = useState(false)
    const [tempYear,setTempYear] = useState(parseInt(value?.split('/')[0]))
    const [tempMonth,setTempMonth] = useState('')
    const yearDropdownRef = useRef(null)
    const monthDropdownRef = useRef(null)

    const startDate = useMemo(() => {
        let t = new Date(value)
        if(isNaN(t.getDate())) return new Date()
        return new Date(value)
    }, [value])
    const years = useMemo(() => {
        let y = []
        let d = new Date()
        let year = d.getFullYear() + 50
        for(let j = 1900; j <= year; j++) {
            y.push(j)
        }
        return y
    }, [])

    const handleKeyDown = (event)=>{
        if((event.key === 'Tab' || event.key === 'TAB') && !showTime && !event.shiftKey){
            if(typeof currentPicker === 'function') {
                currentPicker(event)
            }
        }else if((event.key === 'Tab' || event.key === 'TAB') && (event.target.textContent === '11:30 PM' && showTime && !event.shiftKey)){
            if(typeof currentPicker === 'function') {
                currentPicker(event)
            }
        }else if(event.key === 'Enter' && !showTime){
            if(value === '' && value.toString().length === 0){
                setValue(new Date())
            }
            if(typeof currentPicker === 'function') {
                currentPicker(event)
            }
        }
    } 

    useEffect(() => {
        const platform = navigator.platform.toLowerCase();
        const macOSPlatforms = ['macintosh', 'macintel', 'macppc', 'mac68k'];
        if (macOSPlatforms.some(platformType => platform.includes(platformType))) {
          setOs('mac');
        } else {
          setOs('other');
        }
      }, []);

      useEffect(()=>{
        setTimeout(()=>{
            setMonthOpen(false)
        },100)
        setTimeout(()=>{
            setYearOpen(false)
        },100)
    },[tempMonth,tempYear])

    useEffect(()=>{
        if(yearOpen){
            document.getElementById(parseInt(value?.split('/')[0]))?.focus()
        }
        if(monthOpen){
            document.getElementById(new Date(value).getMonth())?.focus()
        }
    },[yearOpen,monthOpen])

    return (
        <div className='daref'>
    
        {
            showYear ? <DatePicker inline
            selected={startDate}
            onChange={(date) => setValue(date)}
            showYearPicker
            dateFormat="yyyy"
            readOnly={false}
          /> : <DatePicker inline showTimeSelect={showTime} minDate={minDate} maxDate={maxDate}
          renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
          }) => (
              <div className="flex justify-between mx-4">
                  <button onKeyDown={(ev)=>{
                    if(ev.shiftKey){
                        currentPicker(ev)
                    }
                  }} onClick={!prevMonthButtonDisabled ? decreaseMonth : function(){}}>
                      {"<"}
                  </button>
                  {os === 'other' ? <select aria-label='Year'
                      value={date.getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                  >
                      {years.map((option,i) => (
                          <option key={i} value={option} tabIndex={0} aria-label={option}>
                              {option}
                          </option>
                      ))}
                  </select> : <div className='relative'>
                    <div ref={yearDropdownRef} tabIndex={0} onKeyDown={(ev)=>{if(ev.key === 'Enter') setYearOpen(!yearOpen)}} className='w-fit z-[10] bg-white' role="button" onClick={()=>setYearOpen(!yearOpen)}>
                        <div className='flex flex-row justify-between'>
                            <span className='px-2'>{date.getFullYear() ? date.getFullYear() : 'Year'}</span>
                            <MdKeyboardArrowDown className='my-0.5 mx-2'/>
                        </div>
                        </div>
                        {
                            yearOpen && (
                                <AnimatePresence>
                                    <div className='absolute bg-white h-48 overflow-auto w-20 my-2 flex flex-col items-start rounded-lg px-2 py-1 z-[2]'>
                                        {
                                            years.map((option)=>{
                                                return <div key={option} role="option" id={option} className={`my-[0.5] hover:bg-tcolor hover:text-white px-2 rounded-lg cursor-pointer ${option === tempYear ? 'bg-tcolor text-white' : ''}`}
                                                onKeyDown={(ev)=>{if(ev.key.length === 1 || ev.key === 'Enter'){setTempYear(option);changeYear(option)}}}
                                                onClick={()=>{setTempYear(option);changeYear(option)}} tabIndex={0}>
                                                    {option}
                                                    </div>
                                            })
                                        }
                                    </div>
                                </AnimatePresence>
                            )
                        }
                    </div>}

                {
                    os === 'other' ? <select aria-label='Month'
                    value={months[date.getMonth()]}
                    onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                    }
                >
                    {months.map((option,i) => (
                        <option key={i} value={option} tabIndex={0} aria-label={option}>
                            {option}
                        </option>
                    ))}
                </select> : <div className='relative'>
                    <div ref={monthDropdownRef} tabIndex={0} onKeyDown={(ev)=>{if(ev.key === 'Enter') setMonthOpen(!monthOpen)}} className='w-fit z-[10] bg-white' role="button" onClick={()=>setMonthOpen(!monthOpen)}>
                        <div className='flex flex-row justify-between'>
                            <span className='px-2'>{months[date.getMonth()] ? months[date.getMonth()] : 'Month'}</span>
                            <MdKeyboardArrowDown className='my-0.5 mx-2'/>
                        </div>
                        </div>
                        {
                            monthOpen && (
                                <AnimatePresence>
                                    <div className='absolute bg-white w-24 my-2 flex flex-col items-start rounded-lg px-2 py-1 z-[2]'>
                                        {
                                            months.map((option)=>{
                                                return <div key={option} role="option" id={option} className={`my-[0.5] hover:bg-tcolor hover:text-white px-2 rounded-lg cursor-pointer ${option === tempMonth ? 'bg-tcolor text-white' : ''}`}
                                                onKeyDown={(ev)=>{if(ev.key.length ===1 || ev.key === 'Enter'){setTempMonth(option);changeMonth(months.indexOf(option))}}}
                                                onClick={()=>{setTempMonth(option);changeMonth(months.indexOf(option))}} tabIndex={0}>
                                                    {option}
                                                    </div>
                                            })
                                        }
                                    </div>
                                </AnimatePresence>
                            )
                        }
                    </div>
                }

                  <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {">"}
                  </button>
              </div>
          )}
          selected={startDate}
          showYearPicker ={showYear}
          onChange={date => setValue(date)}
          onKeyDown={(ev)=>handleKeyDown(ev)}
          onClickOutside={(ev)=>currentPicker(ev)}
          strictParsing={true}
      />
        }
        </div>
    )
}

export default FieldSupportDatePicker