import { useState, useMemo, useRef, useLayoutEffect, useEffect } from 'react'
import { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import FormCard from '../../components/snippets/FormCard'
import { useFormik } from 'formik'
import { motion } from "framer-motion"
import * as Yup from 'yup'
import FieldInput from "../../components/snippets/FieldInput"
import DataTable from 'react-data-table-component'
import { useDispatch } from 'react-redux'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import FieldSelect from "../../components/snippets/FieldSelect"
import DatatableLoader from '../../components/snippets/DatatableLoader'

import { setAlert } from '../../store/theme/actions'
import { useSelector } from 'react-redux'
import { createPortal } from "react-dom"
import API from '../../axios/API'
import axios from 'axios'
import FlowText from '../../components/snippets/FlowText'
import Dialog from '../../components/snippets/Dialog'
import EditServiceUsage from '../Dialogs/EditServiceUsage'
// import ServiceUsageAdd from '../../components/snippets/ServiceUsageAdd'
import { useTranslation } from 'react-i18next'
import ProviderSelect from '../../components/snippets/ProviderSelect'

const initialFormValues = {
    day: 0,
    year: 0,
    month: 0,
    date: '',
    serviceUsageId: '',
    unitCost: '',
    vendorName: '',
    fundingId: '',
    details: '',
    unitId: '',
    units: ''
}



createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const ServiceUsage = ({ carereceiverId,caseId }) => {
    const [filter] = useState('')
    const dispatch = useDispatch()
    const [loading] = useState(false)
    // const [formData,setFormData] = useState()
    const serviceTable = useRef(null)
    const [activeQuickAction] = useState({ position: 'bottom', active: null })
    const [dataLoaded, setDataLoaded] = useState(false)
    const [editForm] = useState(true)
    const [serviceUsageDetails, setServiceUsageDetails] = useState([])
    const [columnsSelected, setColumnsSelected] = useState([])
    // const [services, setServices] = useState([])
    const [totalCost, setTotalCost] = useState(0)
    const [pertotal,setPerTotal] = useState(0)
    const [totalUnits, setTotalUnits] = useState(0)
    const [editServiceUsage, setEditServiceUsage] = useState(false)
    const [editDetails, setEditDetails] = useState('')
    const [render, setRender] = useState(0)
    const [deletePopup, setDeletePopup] = useState(false)
    const [deleteData, setDeleteData] = useState()
    const [vendorDetails, setVendorDetails] = useState([])
    const [fundingDetails, setFundingDetails] = useState([])
    const relationshipCount = useSelector(state => state.auth.relationShipCount)
    const [serviceUnnitDetails, setServiceUnitDetails] = useState([])
    const [serviceDetails,setServiceDetails] = useState([])
    const [serviceCategories, setServiceCategories] = useState([])
    const { t } = useTranslation()
    let orgId = localStorage.getItem('orgid')
    const userDetails = useSelector(state => state.auth.userDetails)
    const [hideColumn,setHideColumn] = useState(false)
    let lang= localStorage.getItem('language')
    const editRef = useRef(null)
    const deleteRef = useRef(null)
    const [previousDeleteFocus,setPreviousDeleteFocus] = useState(null)
    const [previousEditFocus,setPreviousEditFocus]= useState(null)
    let serviceUsage = localStorage.getItem('serviceUsage')


    Yup.addMethod(Yup.string, 'onlyNumbers', function () {
        return this.test(t('onlynubmers'), t("Only Numbers Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[0-9]*$/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })

    const profileFormSchema = Yup.object().shape({
        date: Yup.date().required(t("Date is a required field")),
        serviceUsageId: Yup.string().required(t("Service Category is required field")),
        units: Yup.string().validateDescription().min(1, t("Atlest one digit is required")).matches(/^[0-9.]*$/, t("Only Numbers Allowed")).required(t("Units Consumed is required")),
        unitCost: Yup.string().max(8,t("max limit 8 digits")).required(t('Unit Cost is required')),
        vendorName: Yup.string().validateDescription().required(t("Provider is required")).nullable(),
        fundingId: Yup.string().required(t("Funding Source is required")),
        unitId: Yup.string().required(t("Service Units is required")),
        details: Yup.string().validateDescription()
    })

    useEffect(() => {
        if(userDetails.role.length === 1 && userDetails.role.includes('referrer')){
            setHideColumn(true)
        }
        profileForm.values.serviceUsageId = ''
        if(serviceUsage === 'true'){
            profileForm.values.serviceUsageId = ''
            axios.all([API.get(`organizations/${orgId}/menus/service-usage?lang=${lang}&orgService=0`), API.get(`caregivers/${carereceiverId}/service-usage?lang=${lang}&caseId=${caseId}`)]).then(axios.spread((usageResp, listResp) => {
                // setVendorDetails(vendorResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setServiceUsageDetails(usageResp.data.body.map(c=>({text:c.serviceName,value:c.serviceId})))
                setServiceCategories(usageResp.data.body)
                setServiceDetails(listResp.data.body)
                let cost = 0;
                let tot  = 0;
                let unit = 0;
                listResp.data.body.map((c, i) => { 
                    unit = unit + c.units
                    tot  = tot + c.cost
                    cost = cost + c.totalCost
                    setTotalUnits(unit)
                    setPerTotal(tot)
                    setTotalCost(cost)
                    return null;
                })
            }))  
        }else{
            profileForm.values.serviceUsageId = ''
           axios.all([API.get(`organizations/${orgId}/menus/service-usage?lang=${lang}&orgService=0`), API.get(`caregivers/${carereceiverId}/service-usage?lang=${lang}&caseId=${caseId}`), API.get(`organizations/${orgId}/menus/funding?lang=${lang}`), API.get(`/options/organizations/${orgId}/service-units?lang=${lang}`)]).then(axios.spread((usageResp, listResp, fundingResp, unitResp) => {
            // setVendorDetails(vendorResp.data.body.map(c => ({ text: c.name, value: c.value })))
            setFundingDetails(fundingResp.data.body.map(c => ({ text: c.name, value: c.value })))
            setServiceUnitDetails(unitResp.data.body.map(c => ({ text: c.name, value: c.value })))
            setServiceUsageDetails(usageResp.data.body.map(c=>({text:c.serviceName,value:c.serviceId})))
            setServiceCategories(usageResp.data.body)
            setServiceDetails(listResp.data.body)
            let cost = 0;
            let tot  = 0;
            let unit = 0;
            listResp.data.body.map((c, i) => { 
                unit = unit + c.units
                tot  = tot + c.cost
                cost = cost + c.totalCost
                setTotalUnits(unit)
                setPerTotal(tot)
                setTotalCost(cost)
                return null;
            })
           }))
        }
        profileForm.resetForm()
    }, [relationshipCount,render]) // eslint-disable-line

    const formData = useMemo(() => {
        setDataLoaded(true)
        return initialFormValues
    }, [])

    
    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            addToService()
        }
    })
    
    const servicecate =(val) =>{
       let providerValidation = false;
       if(serviceUsage === 'true'){
       profileForm.values.serviceUsageId = val 
        serviceCategories.map((c,i)=> {
            if(c.serviceId === val){
                setFundingDetails(c.fundingOption.map(e => ({ text: e.fundingName, value: e.fundingId })))
                setServiceUnitDetails(c.unitOptions.map(e => ({ text: e.unitName, value: e.unitId })))
                setVendorDetails(c.options.map(e => ({text:e.vendorName,value:e.vendorId})))
                c.options.forEach(element => {
                    if(element.vendorId === profileForm.values.vendorId) {
                        providerValidation=true;
                        profileForm.setFieldValue('vendorName', profileForm.values.vendorId);
                    }else{
                        profileForm.setFieldValue('vendorName', null);
                    }
                });
                c.fundingOption.forEach(element => {
                    if(element.fundingId === profileForm.values.fundingId) {
                        providerValidation=true;
                        profileForm.setFieldValue('fundingId', profileForm.values.fundingId);
                    }else{
                        profileForm.setFieldValue('fundingId', null);
                    }
                });
                c.unitOptions.forEach(element => {
                    if(element.unitId === profileForm.values.unitId) {
                        providerValidation=true;
                        profileForm.setFieldValue('unitId', profileForm.values.unitId);
                    }else{
                        profileForm.setFieldValue('unitId', null);
                    }
                });
            } return null;
        });
       }else{
       profileForm.values.serviceUsageId = val 
          serviceCategories.map((c,i)=> {
            if(c.serviceId === val){
                setVendorDetails(c.options.map(e => ({text:e.vendorName,value:e.vendorId})))
                c.options.forEach(element => {
                    if(element.vendorId === profileForm.values.vendorId) {
                        providerValidation=true;
                        profileForm.setFieldValue('vendorName', profileForm.values.vendorId);
                    }else{
                        profileForm.setFieldValue('vendorName', null);
                    }
                });
            } return null;
          });
      }
         // console.log(val)
        
        // profileForm.setValues({...profileForm.values,vendorName:val})
       }
    const provide =(val)=>{
     if(typeof val === 'string'){
      profileForm.setValues({...profileForm.values,vendorName:val,vendorId:null})
     }else if(typeof val === 'number'){
        profileForm.setValues({...profileForm.values,vendorId:val,vendorName:val})
     }
    }
    // const toggleFormEdit = () => {
    //     if (editForm) profileForm.submitForm()
    //     if (profileForm.isValid || !editForm) setEditForm(!editForm)
    // }

    const addToService = () => {
        if (profileForm.values.date) {
            profileForm.values.date = profileForm.values.date?.split('/')
            profileForm.values.day = profileForm.values.date[2]
            profileForm.values.month = profileForm.values.date[1]
            profileForm.values.year = profileForm.values.date[0]
        }
        let details = {
            pplId: carereceiverId,
            serviceUsageId: profileForm.values.serviceUsageId,
            units: profileForm.values.units,
            cost: profileForm.values.unitCost,
            day: profileForm.values.day,
            month: profileForm.values.month,
            year: profileForm.values.year,
            vendorId : profileForm.values.vendorId,
            vendorName : profileForm.values.vendorName,
            fundingId : profileForm.values.fundingId,
            unitId : profileForm.values.unitId,
            details : profileForm.values.details,
            caseId : caseId
        }
        API.post(`caregivers/${carereceiverId}/service-usage?orgId=${orgId}`, details).then(res => {
            if (res.status === 200 && res?.data?.errorCode !== '422') {
                dispatch(setAlert({ title: t('Success'), subtitle: t('Service added successfully'), type: 'success', active: true }))
                setRender(render + 1)
                profileForm.resetForm()
                profileForm.values.serviceUsageId = ''
            }else if(res?.data?.errorCode === '422'){
                profileForm.values.date = profileForm.values.year + "/"+profileForm.values.month +"/"+ profileForm.values.day
                dispatch(setAlert({ title: t('Error'), subtitle: t(res?.data?.errorMessage), active: true, type: 'error' }))
            }
        }).catch(err => {
            console.log(err)
        })
    }
    // const QuickActionComp = useCallback((row) => {
    //     let options = [
    //         { icon: MdDelete, title: 'Delete Service Usage', link: '', onClick: () => dispatch(setAlert({ title: 'Warning', subtitle: 'Working in progress', active: true, type: 'warning' })) },
    //         { icon: MdEdit, title: "Edit Service Usage", link: '', onClick: () => dispatch(setAlert({ title: 'Warning', subtitle: 'Working in progress', active: true, type: 'warning' })) }
    //     ]
    //     return (
    //         <QuickAction id={row.services} active={activeQuickAction} setActive={id => setActiveQuickAction(id)} table={serviceTable} options={options} />
    //     )
    // }, [activeQuickAction, serviceUsageDetails])

    const caseInsensitiveUnit = (rowA,rowB) =>{
        const a = rowA.cost;
        const b = rowB.cost;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    }
    const caseInsensitiveTotal = (rowA,rowB) =>{
        const a = rowA.totalCost;
        const b = rowB.totalCost;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    }

    const colums = useMemo(() => {
        return [
            // {
            //     cell: row => QuickActionComp(row),
            //     allowOverflow: true,
            //     button: true,
            //     width: '56px', // custom width for icon button
            // },
            {
                name: t('Service Date'),
                selector: 'serviceDate', 
                cell:row=>{return (<div tabIndex={0} aria-label={`Service Date ${row.month}/${row.day}/${row.year}`}>{row.month}/{row.day}/{row.year}</div>)},
                sortable: true,
                sortField: 'Service_Date',
                sortFunction: (a, b) => {
                    // Custom sort function for date column
                    const dateA = new Date(a.serviceDate);
                    const dateB = new Date(b.serviceDate);
                    return dateA - dateB;
                  },
            },
            {
                name: t('Service Category'),
                selector: 'display',
                cell:row=>{return (<div tabIndex={0} aria-label={`Service Category ${row.display}`}>{row.display}</div>)},
                sortable: true,
		        sortField: 'Service_category',
            },
            {
                name: t('Provider'),
                selector: 'vendorName',
                cell:row=>{return (<div tabIndex={0} aria-label={`Provider ${row.vendorName}`}>{row.vendorName}</div>)},
                sortable: true,
		        sortField: 'Provider',
            },
            {
                name: t('Units Consumed'),
                selector: row => { return (`${row.units} ${row.unitName}`) },
                cell:row=>{return (<div tabIndex={0} aria-label={`Unit Consumed ${row.units} ${row.unitName}`}>{row.units} {row.unitName}</div>)},
                sortable: true,
		        sortField: 'Units_Consume',
            },
            {
                name:t('Unit Cost'),
                selector:row =>{return (<div className="flex justify-end" style={{direction: 'rtl',flexDirection: 'row-reverse',justifyContent: 'end',textAlign: 'end',float: 'right'}}><p>$</p>&nbsp;<div>{(row.cost).toFixed(2)}</div></div>)},
                cell:row=>{return (<div tabIndex={0} aria-label={`Unit Cost $ ${(row.cost).toFixed(2)}`} className="flex justify-end" style={{direction: 'rtl',flexDirection: 'row-reverse',justifyContent: 'end',textAlign: 'end',float: 'right'}}><p>$</p>&nbsp;<div>{(row.cost).toFixed(2)}</div></div>)},
                sortable: true,
		        sortField: 'Units_Cost',
                sortFunction: caseInsensitiveUnit
                
            },
            {
                name: t('Total Cost'),
                selector: row => { return (`$ ${(row.totalCost).toFixed(2)}`) },
                cell:row=>{return (<div tabIndex={0} aria-label={`Total Cost $ ${(row.totalCost).toFixed(2)}`}>${(row.totalCost).toFixed(2)}</div>)},
                sortable: true,
		        sortField: 'Total_Cos',
                sortFunction: caseInsensitiveTotal
            },
            {
                name: t('Action'),
                selector: 'actions',button: true, grow: 1,omit:hideColumn,
                cell: row => <><div>{((userDetails.role.length === 1 && userDetails.role.includes('referrer')) || (userDetails.role.length === 2 && userDetails.role.includes('referrer') && userDetails.role.includes('dynamic_ref'))) ? '':<button onClick={() => editService(row)}><FaEdit title={t("Edit")} size="20px" className="text-gray-600 dark:text-gray-300" /></button>}</div>&nbsp;&nbsp;&nbsp;&nbsp;
                    <div>{((userDetails.role.length === 1 && userDetails.role.includes('referrer')) || (userDetails.role.length === 2 && userDetails.role.includes('referrer') && userDetails.role.includes('dynamic_ref'))) ? '' :<button onClick={() => deleteService(row)}><FaTrashAlt title={t("Delete")} size="20px" className="text-red-600 dark:text-gray-300" /></button>}</div>
                </>,
            }
        ]
            // .map(c => {
            //     if (c.name !== undefined && columnsSelected.find(e => e.name === c.name)) c.omit = columnsSelected.find(e => e.name === c.name).active
            //     return c
            // })
    }, [activeQuickAction, columnsSelected, serviceDetails]) // eslint-disable-line
    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return serviceDetails.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [filter, serviceDetails])
    useLayoutEffect(() => {
        setColumnsSelected(colums.filter(c => c.name !== undefined && c.optionalColumn !== false).map(c => ({ name: c.name, active: c.initialActive || false })))
    }, []) // eslint-disable-line  
    // const data = [{casedetails}]

    const editService = (value) => {
        setEditServiceUsage(true)
        setEditDetails(value)
    }
    const deleteService = (value) => {
        setDeletePopup(true)
        setDeleteData(value)
    }

    const removeServiceUsage = () => {
        API.delete(`caregivers/${deleteData.pplId}/service-usage/${deleteData.pplServiceUsageId}?orgId=${orgId}`).then(res => {
            if (res.status === 200) {
                setTotalUnits(totalUnits - totalUnits)
                setPerTotal(pertotal - pertotal)
                setTotalCost(totalCost - totalCost)
                dispatch(setAlert({ title: t('Success'), subtitle: t('Service usage deleted successfully'), active: true, type: 'success' }))
                setRender(render + 1)
                setDeletePopup(false)
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
                setRender(render + 1)
                setDeletePopup(false)
            }
        }).catch(err => {
            dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
            setRender(render + 1)
            setDeletePopup(false)
        })
    }
    useEffect(() => {
        if (deletePopup) {
          setPreviousDeleteFocus(document.activeElement);
          deleteRef.current?.focus();
        } else {
          previousDeleteFocus && previousDeleteFocus.focus();
        }
      }, [deletePopup]);

      useEffect(() => {
        if (editServiceUsage) {
          setPreviousEditFocus(document.activeElement);
          editRef.current?.focus();
        } else {
          previousEditFocus && previousEditFocus.focus();
        }
      }, [editServiceUsage]);
      
    return (
        <>
            <div>
               { ((userDetails.role.length === 1 && userDetails.role.includes('referrer')) || (userDetails.role.length === 2 && userDetails.role.includes('referrer') && userDetails.role.includes('dynamic_ref'))) ? '': <FormCard injectClass="mt-8" >
                    {/* <ServiceUsageAdd injectClass="absolute -top-5 right-5" edit={editForm} onClick={() => profileForm.submitForm()} /> */}
                    <div className={'mt-2 mb-2'}>
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                            <FieldInput ariaLabel={t("Service Date")} type="date" maxDate={new Date()} label={t("Service Date")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('date')} fieldProps={profileForm.getFieldProps('date')} fieldHelper={profileForm.getFieldHelpers('date')} edit={editForm} dataLoaded={dataLoaded}/>
                            <FieldSelect ariaLabel={t("Service Category")} label={t("Service Category")} fieldMeta={profileForm.getFieldMeta('serviceUsageId')} fieldHelper={profileForm.getFieldHelpers('serviceUsageId')} fieldProps={profileForm.getFieldProps('serviceUsageId')} edit={editForm} options={serviceUsageDetails} position="top" dataLoaded={dataLoaded} onChange={(val)=>servicecate(val)}/>
                            {/* <FieldSelect label={t("Provider")} fieldMeta={profileForm.getFieldMeta('vendorId')} fieldHelper={profileForm.getFieldHelpers('vendorId')} fieldProps={profileForm.getFieldProps('vendorId')} edit={editForm} options={vendorDetails} position="top" dataLoaded={dataLoaded} /> */}
                            {/* <FieldInput label={t("Provider")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('vendorName')} fieldProps={profileForm.getFieldProps('vendorName')} edit={editForm} dataLoaded={dataLoaded} /> */}
                            <ProviderSelect ariaLabel={t("Provider")} label={t("Provider")} fieldMeta={profileForm.getFieldMeta('vendorName')} fieldHelper={profileForm.getFieldHelpers('vendorName')} fieldProps={profileForm.getFieldProps('vendorName')} edit={editForm} options={vendorDetails} position="top" dataLoaded={dataLoaded} onChange={(val)=>provide(val)} />
                         </div><br></br>
                         <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                         <FieldSelect ariaLabel={t("Service Units")} label={t("Service Units")} fieldMeta={profileForm.getFieldMeta('unitId')} fieldHelper={profileForm.getFieldHelpers('unitId')} fieldProps={profileForm.getFieldProps('unitId')} edit={editForm} options={serviceUnnitDetails} position="top" dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={t("Units Consumed")} label={t("Units Consumed")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('units')} fieldProps={profileForm.getFieldProps('units')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={t("Unit Cost $")} type="price" label={t("Unit Cost $")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('unitCost')} fieldProps={profileForm.getFieldProps('unitCost')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={t("Funding Source")} label={t("Funding Source")} fieldMeta={profileForm.getFieldMeta('fundingId')} fieldHelper={profileForm.getFieldHelpers('fundingId')} fieldProps={profileForm.getFieldProps('fundingId')} edit={editForm} options={fundingDetails} position="top" dataLoaded={dataLoaded} />
                         </div>
                         <div><br></br>
                         <FieldInput ariaLabel={t("Details")} type="text" label={t("Details")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('details')} fieldProps={profileForm.getFieldProps('details')} edit={editForm} dataLoaded={dataLoaded} />
                         </div>
                    </div>
                   <div className="flex justify-center mt-7 mx-5">
                   <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Add")}</button>
                   </div>
                </FormCard>}
            </div>
            &nbsp;
            <div className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg relative" ref={serviceTable}>
                {
                    loading ? 
                    <DatatableLoader /> :(serviceDetails.length>0)?
                     <DataTable injectClass="mt-12"
                        columns={colums}
                        data={filteredcasedetails} noHeader
                        theme={localStorage.getItem('theme') === 'dark' ? "defaultDark" : "defaultLight"}
                    />:
                    <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>
                       
                }
            </div>
            <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-1 form-wrap py-4`}>
               { ((userDetails.role.length === 1 && userDetails.role.includes('referrer')) || (userDetails.role.length === 2 && userDetails.role.includes('referrer') && userDetails.role.includes('dynamic_ref'))) ? '':<div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-7">
                    <h1 tabIndex={0}>{t("Grand total")}</h1>
                    <p></p>
                    <p></p>
                    <div tabIndex={0}  aria-label={`Total Unit Consumed ${totalUnits} Units`}><span aria-hidden={true}>{totalUnits} {t("Units")}</span></div>
                    <div tabIndex={0}  aria-label={`Total Unit Cost $ ${pertotal}`}><span aria-hidden={true}>$ {pertotal}</span></div>
                    <div tabIndex={0}  aria-label={`Total Cost $ ${totalCost}`}><span aria-hidden={true}>$ {totalCost}</span></div>
                </div>}
            
              
            </motion.div>

            {
                editServiceUsage && 
                createPortal(<div role="dialog">
                    <div ref={editRef} role="dialog" tabIndex={-1} aria-modal="true"></div>
                    <Dialog title={t("Edit Service Usage")} showDialog={editServiceUsage} onClose={() => setEditServiceUsage(false)}>
                    <EditServiceUsage details={editDetails} crId={carereceiverId} caseID={caseId} onClose={() => setEditServiceUsage(false)} />
                </Dialog>
                </div>
                    , document.body)
            }
            {
                deletePopup && createPortal(
                    <div role="dialog">
                    <div ref={deleteRef} role="dialog" tabIndex={-1} aria-modal="true"></div>
                    <Dialog title={t("Delete Service Usage")} showDialog={deletePopup} onClose={() => setDeletePopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2" tabIndex={0}>
                        {t(`Are you sure you want to delete this service usage`)}
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setDeletePopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => removeServiceUsage()}>{t("Yes")}</button>
                    </div>
                </div>
            </Dialog>
                    </div>, document.body)
            }
        </>
    )
}


export default ServiceUsage