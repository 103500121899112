import { useFormik } from "formik"
import API from '../../axios/API'
import {setAlert} from '../../store/theme/actions'
import FieldInput from "../../components/snippets/FieldInput"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from 'yup'
import { useState, useMemo } from "react"
import { setNotesAction } from "../../store/auth/actions"
import { useTranslation } from "react-i18next"
import FieldDate from "../../components/snippets/FieldDate"

const initialFormValues = {
    notes : '',
    reminderDate : ''
}


const RemindersDialog = ({onClose,props,type}) =>{

    const dispatch = useDispatch()
    const [dataLoaded] = useState(true)
    const [formLoading] = useState(false)
    const [savenote, setSaveNote] = useState(false)
    const notesAction= useSelector(state =>state.auth.notesAction)
    const [initialData,setInitialData] = useState(props.notes)
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')
  
    const formData = useMemo(()=>{
        if(type === 'update'){
            setInitialData(props.notes)
            return ({...initialData})
        }else{
            return ({...initialFormValues})
        }
    },[type]) // eslint-disable-line

    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    const notesFormSchema = Yup.object().shape({
        reminderDate: Yup.date().required(t("Date is a required field")),
        notes : Yup.string().validateDescription().required(t("Reminder is a required field"))
    })

    const notesForm = useFormik({
        initialValues : formData,
        validationSchema : notesFormSchema,
        enableReinitialize : true,
        validateOnMount : true,
        onSubmit : values=>{
            (type === 'create') ? 
            submitForm() : updateForm()
        }
    })

    const submitForm = () => {
        setSaveNote(true)
        let details =
        {
            "organizationId":orgId,
            "caseId":props,
            "reminderDate":notesForm.values.reminderDate,
            "activeIndicator":true,
            "notes":notesForm.values.notes
            
        }
        API.post(`createCaseReminder`, details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title:t('Success'), subtitle: t("Reminder Created successfully"), type: "success", active: true }))
                dispatch(setNotesAction(notesAction+1))
                onClose()
            }
        }).catch(e => {
            console.log(e)
        })
    }

    const updateForm = () =>{
        setSaveNote(true)
       let details = {
        "organizationId":orgId,
        "caseId":props.notes.caseId,
        "reminderDate":notesForm.values.reminderDate,
        "activeIndicator":props.notes.activeIndicator,
        "notes":notesForm.values.notes,
        "reminderId":props.notes.reminderId
          }
          API.patch(`/updateCaseReminder`, details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t("Reminder Update successfully"), type: "success", active: true }))
                onClose();
                dispatch(setNotesAction(notesAction+1))
            }
        }).catch(e => {
            console.log(e)
        })
    }
    return (
        <div className="py-2 w-full">
            <div className="px-4 mt-2">
            <div style={{width:'200px'}}>
                <FieldDate loading={formLoading} type="date" minDate={new Date()} ariaLabel={notesForm.getFieldProps('reminderDate')} label={t("Reminder Date")} autoComplete='off' fieldMeta={notesForm.getFieldMeta('reminderDate')} fieldProps={notesForm.getFieldProps('reminderDate')} fieldHelper={notesForm.getFieldHelpers('reminderDate')} edit={true} dataLoaded={dataLoaded} />
                    </div><br></br>
            <FieldInput type="textarea" ariaLabel={notesForm.getFieldProps('notes')} loading={formLoading} label={t("Type a reminder")} autoComplete='off' fieldMeta={notesForm.getFieldMeta('notes')} fieldProps={notesForm.getFieldProps('notes')} edit={true} dataLoaded={dataLoaded} />
            </div>
            <div className="flex justify-between mt-8 mx-2">
                <button tabIndex={0} aria-live="polite" className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={onClose}>{t("Cancel")}</button>
                <button tabIndex={0} aria-live="polite" disabled={savenote ? true : false} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => notesForm.submitForm()}>{t("Save")}</button>
            </div>
        </div>
    )
}

export default RemindersDialog