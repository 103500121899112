
import { useFormik } from "formik"
import { useState, useMemo, useEffect } from "react"
import * as Yup from 'yup'
import { useTranslation } from "react-i18next"
import FieldInput from "../../components/snippets/FieldInput"
import ReferrerCheckbox from "../../components/snippets/ReferrerCheckbox"
import ReferrerInput from "../../components/snippets/ReferrerInput"
import ReferrerSelect from "../../components/snippets/ReferrerSelect"
import FieldSelect from "../../components/snippets/FieldSelect"
import { useHistory } from 'react-router-dom';
import API from '../../axios/API';
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux"
import DemographicLoader from "./DemographicLoader"
import Button from "../../components/snippets/Button"

import { setAlert } from "../../store/theme/actions"
import PcspInput from "../../components/snippets/PcspInput"

// const initalFollowupValue = {
//     followupDate: '',
//     notes: '',
//     reason: '',
// }

const initialFormValues =
{
    "careGiver":
    {
        "dateOfBirth": '',
        "address": { "state": "", "addressLine1": "", "addressLine2": "", "city": "", "postalCode": "" },
        "language": "",
        "personId": 0,
        "firstName": "",
        "lastName": "",
        "emailAddress": "",
        "phoneNumber": "",
        "areaCode": "",
        "subscriberId": "",
        "preferredName": "",
        "hispanic": false,
        "nativeAmerican": false,
        "dobDay": 1,
        "phoneType": "",
        "pacificIslander": false,
        "otherRace": false,
        "refused": false,
        "medicaid": false,
        "medicare": false,
        "tricare": false,
        "otherInsurer": false,
        "multiRacial": false,
        "dobYear": 0,
        "dobMonth": 1,
        "uninsured": false,
        "maritalStat": "",
        "phoneAuthorized": false,
        "pronouns": null,
        "samsId": "",
        "ssn": "",
        "homePhone": "",
        "gender": "",
        "employmentId": 0,
        "educationId": 0,
        "incomeId": 0,
        "white": true,
        "black": false,
        "asian": false,
        "middleEastern": false,
        "middleName": "",
        "pgName": "",
        "pcspDate": "",
    },
    "careReceiver":
    {
        "address": { "state": "", "addressLine1": "", "addressLine2": "", "city": "", "postalCode": "" },
        "pcspDate": "",
        "race": '',
        "dateOfBirth": '',
        "language": "",
        "personId": 0,
        "firstName": "",
        "lastName": "",
        "relationshipId": '',
        "emailAddress": "",
        "phone": "",
        "areaCode": "",
        "subscriberId": "",
        "preferredName": "",
        "hispanic": false,
        "nativeAmerican": false,
        "dobDay": 1,
        "phoneType": "",
        "pacificIslander": false,
        "otherRace": false,
        "refused": false,
        "medicaid": false,
        "medicare": false,
        "tricare": false,
        "otherInsurer": false,
        "multiRacial": false,
        "dobYear": 0,
        "dobMonth": 1,
        "uninsured": false,
        "maritalStat": "",
        "phoneAuthorized": false,
        "pronouns": null,
        "samsId": "",
        "ssn": "",
        "homePhone": "",
        "gender": "",
        "employmentId": 0,
        "educationId": 0,
        "incomeId": 0,
        "white": false,
        "black": false,
        "asian": false,
        "middleEastern": false,
        "middleName": "",
        "relationship": "",
        "assessType": "",
        "check": "",
        "tcareProtocol": ""
    },
    referrerCaseFollowup: {
        followupdate: '',
        notes: '',
        reason: '',
    },

    "organizationId": '',
    "caseId": ''

}

const AddCaseDemographic = ({ match }) => {


    const { t } = useTranslation()
    const [formLoading, setFormLoading] = useState(false)
    const dispatch = useDispatch()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [states, setStates] = useState([])
    // const [gender, setGender] = useState([])
    // const [raceEthnicity, setRaceEthnicity] = useState([])
    const [both, setBoth] = useState(false)
    // const [relationShip, setRelationship] = useState([])
    const [loading] = useState(false)
    // const [followupReason, setFollowupReason] = useState([])
    const organization = useSelector(state => state.auth.organization)
    const history = useHistory()
    const [cgLength, setCgLength] = useState()
    const [crLength, setCrLength] = useState()
    const userDetails = useSelector(state => state.auth.userDetails)
    const [crRefused, setCrRefused] = useState(false)
    const [cgRefused, setCgRefused] = useState(false)
    const [years, setYears] = useState([])
    const [language, setLanguage] = useState([])
    const [zipEnable, setZipEnable] = useState(false)
    const [crEnable, setCrEnable] = useState(false)
    let refdyad = localStorage.getItem('refdyad')
    let subId = localStorage.getItem('subId')
    const [subreq, setSubReq] = useState(false)
    let birthyear = localStorage.getItem('birthyear')
    const [birthyear1, setBirthYear1] = useState(false)
    // const [birthyear2,setBirthYear2] = useState(false)
    let prgName = localStorage.getItem('prgname')
    let assessType1 = localStorage.getItem('assessType')
    let pcsp = localStorage.getItem('pcsp')
    const [programName1, setProgramName1] = useState(false)
    const [datePscp, setDatePscp] = useState(false)
    const [assessmentTypeId1, setAssessmentTypeId1] = useState(false)
    const [programName, setProgramName] = useState([])
    const [assessmentType, setAssessmentType] = useState([])
    const [errWarning, setErrWarning] = useState(false)
    const [errWarning1, setErrWarning1] = useState(false)
    const [errWarning2, setErrWarning2] = useState(false)
    const [customsubWarn, setCustomSubWarn] = useState()
    const [customsubWarn1, setCustomSubWarn1] = useState()
    const [customsubWarn2, setCustomSubWarn2] = useState()
    const [setNextButtonDisable] = useState(false)
    const [setNextButtonDisable1] = useState(false)
    const [setNextButtonDisable2] = useState(false)
    const [option, setoption] = useState(false)
    const [buttonDisable, setButtonDisable] = useState(false)
    let protocolType1 = localStorage.getItem('tcareProtocolEnabled')
    const [protocolTypeId1, setProtocolTypeId1] = useState(false)
    const [protocol, setProtocol] = useState([])
    let lang = localStorage.getItem('language')
    // const [editForm, setEditForm] = useState(false)
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))



    const [tempCrSub,setTempCrSub] = useState()

    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test(t('validPhone'), t("Not a Valid Phone"), (value) => {
            if (value === undefined || value === '') return true
            // return (value.replace(/ /g, '').length === 13 || value.replace(/ /g, '').length === 12)
            return (value.replace(/[- ]/g, '').length === 10)
        })
    })
    Yup.addMethod(Yup.string, 'otherRelation', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[a-zA-Z'_^-\s]*$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validateName', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'otherRelation', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[a-zA-Z'_^-\s]*$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'EmptySpace', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'LengthSubId', function(){
        return this.test(t('SubscriberId must be the length of either 9 or 14'),t('SubscriberId must be the length of either 9 or 14'),(value)=>{
            if(value === undefined || value == '' || value.length === 0 || value === null || value.length === 9 || value.length === 14) return true
            if(value.length >= 10 && value.length < 14) return false  
        })
    })

    // const followupData = useMemo(() => {
    //     setDataLoaded(true)
    //     setFormLoading(false)
    //     return { ...initalFollowupValue }
    // }, [])

    const formData = useMemo(() => {
        setDataLoaded(true)
        setFormLoading(false)
        return { ...initialFormValues }
    }, [])

    useEffect(() => {
        for (let i = 1900; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
    }, []) //eslint-disable-line

    useEffect(() => {
        if (birthyear === 'true') {
            setBirthYear1(true)
            // setBirthYear2(true)
        }
        if (prgName === 'true') {
            setProgramName1(true)
        }
        if (pcsp === 'true') {
            setDatePscp(true)
        }
        if (assessType1 === 'true') {
            setAssessmentTypeId1(true)
        }
        if (refdyad === 'true') {
            setBoth(true)
        }
        if (protocolType1 === 'true') {
            setProtocolTypeId1(true)
        }
        if (menuItems && menuItems !== null && menuItems !== '' && menuItems !== undefined) {
            if (menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined) {
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/states?lang=${lang}`).then((statesResp) => {
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.language && menuItems.language.length > 0 && menuItems.language !== undefined) {
                setLanguage(menuItems.language.map((c) => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/languages?lang=${lang}`).then((languageResp) => {
                    setLanguage(languageResp.data.body.map((c) => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.programName && menuItems.programName.length > 0 && menuItems.programName !== undefined) {
                setProgramName(menuItems.programName.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/case-programName?lang=${lang}`).then((programNameId) => {
                    setProgramName(programNameId.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.assessmentType && menuItems.assessmentType.length > 0 && menuItems.assessmentType !== undefined) {
                setAssessmentType(menuItems.assessmentType.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/case-assessmentType?lang=${lang}`).then((assessmentTypeId) => {
                    setAssessmentType(assessmentTypeId.data.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.protocol && menuItems.protocol.length > 0 && menuItems.protocol !== undefined) {
                setProtocol(menuItems.protocol.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/protocol?lang=${lang}`).then((protocolResp) => {
                    setProtocol(protocolResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
        } else {
            axios.all([API.get(`menus/states?lang=${lang}`), API.get(`menus/genders?lang=${lang}`),
            API.get(`menus/races?lang=${lang}`), API.get(`menus/relationships?lang=${lang}`), API.get(`options/follow-up-reasons?lang=${lang}`), API.get(`menus/languages?lang=${lang}`), API.get(`menus/case-programName?lang=${lang}`), API.get(`menus/case-assessmentType?lang=${lang}`), API.get(`menus/protocol?lang=${lang}`)]).then(axios.spread((statesResp, gendersResp,
                raceResp, relationshipResp, followupResp, languageResp, programNameId, assessmentTypeId, protocolResp) => {
                setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                // setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
                // setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
                // setRelationship(relationshipResp.data.body.map(c => ({ text: c.name, value: c.value })))
                // setFollowupReason(followupResp.data.map(c => ({ text: c.display, value: c.optionId })))
                setLanguage(languageResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setProgramName(programNameId.data.body.map(c => ({ text: c.name, value: c.value })))
                setAssessmentType(assessmentTypeId.data.body.map(c => ({ text: c.name, value: c.value })))
                setProtocol(protocolResp.data.body.map(c => ({ text: c.name, value: c.value })))
            }))
        }
        setYears(years.map(c => ({ text: c, value: c })))

        if (subId === 'true') {
            setSubReq(true)
        }
    }, [assessType1, birthyear, pcsp, prgName, refdyad, subId, protocolType1]) // eslint-disable-line

    const profileFormSchema =  Yup.object().shape({
        careReceiver: Yup.object({
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace(),
                state: Yup.string(),
                city: Yup.string(),
                county: Yup.string(),
                postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")),
            }),
            firstName: Yup.string().nullable().max(50).validateName(),
            lastName: Yup.string().nullable().max(50).validateName(),
            emailAddress: Yup.string().validateEmail(),
            dateOfBirth: Yup.date(),
            phone: Yup.string().validatePhoneLength(),
            subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should numeric value")): !subreq && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should numeric value")): subreq && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
            assessType: Yup.string(),
            tcareProtocol: Yup.string()
        }),
        careGiver:
            Yup.object({
                address: Yup.object({
                    addressLine1: Yup.string().EmptySpace(),
                    state: Yup.string(),
                    city: Yup.string(),
                    postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")),
                }),
                firstName: Yup.string().nullable().max(50).validateName(),
                lastName: Yup.string().nullable().max(50).validateName(),
                phoneNumber: Yup.string().validatePhoneLength(),
                emailAddress: Yup.string().validateEmail(),
                dateOfBirth: Yup.date(),
                pgName: Yup.string(),
                pcspDate: Yup.date()

            }),
        referrerCaseFollowup: Yup.object({
            followupdate: Yup.date(),
            notes: Yup.string().EmptySpace(),
            reason: Yup.string(),
        })
    }) 
    const careReceiverFormSchema = (crRefused) ? Yup.object().shape({
        careReceiver: Yup.object({
            pcspDate: pcsp === 'true' && option ? Yup.date().required(t("Date Of Associated PCSP is a required field")) : Yup.date(),
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace(),
                state: Yup.string(),
                city: Yup.string(),
                county: Yup.string(),
                postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")),
            }),
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            emailAddress: Yup.string().validateEmail(),
            dateOfBirth: Yup.date(),
            phone: Yup.string().validatePhoneLength(),
            subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should numeric value")): !subreq  && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should numeric value")): subreq && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
            assessType: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")) : Yup.string(),
            tcareProtocol: protocolTypeId1 ? Yup.string().required(t("Protocol Type is a required field")) : Yup.string()
        }),
    }) : Yup.object().shape({
        careReceiver: Yup.object({
            pcspDate: pcsp === 'true' && option ? Yup.date().required(t("Date Of Associated PCSP is a required field")) : Yup.date(),
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace(),
                state: Yup.string(),
                city: Yup.string(),
                county: Yup.string(),
                postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")),
            }),
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            emailAddress: Yup.string().validateEmail(),
            dateOfBirth: Yup.date().required(t("Please provide the required details")),
            phone: Yup.string().validatePhoneLength(),
            subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should numeric value")): !subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should numeric value")): subreq && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().LengthSubId().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
            assessType: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")) : Yup.string(),
            tcareProtocol: protocolTypeId1 ? Yup.string().required(t("Protocol Type is a required field")) : Yup.string()
        }),
    })

    const careGiverFormSchema = (cgRefused) ? Yup.object().shape({
        careGiver:
            Yup.object({
                address: Yup.object({
                    addressLine1: Yup.string().EmptySpace(),
                    state: Yup.string(),
                    city: Yup.string(),
                    postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")),
                }),
                firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is a required field")),
                lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
                phoneNumber: Yup.string().validatePhoneLength().required(t("Phone is a required field")),
                emailAddress: Yup.string().validateEmail(),
                dateOfBirth: Yup.date(),
                pgName: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string(),
                pcspDate: pcsp === 'true' ? Yup.date().required(t("Date Of Associated PCSP is a required field")) : Yup.date()
            }),
    }) : Yup.object().shape({
        careGiver:
            Yup.object({
                address: Yup.object({
                    addressLine1: Yup.string().EmptySpace(),
                    state: Yup.string(),
                    city: Yup.string(),
                    postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")),
                }),
                firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is a required field")),
                lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
                phoneNumber: Yup.string().validatePhoneLength().required(t("Phone is a required field")),
                emailAddress: Yup.string().validateEmail(),
                dateOfBirth: Yup.date().required(t("Please provide the required details")),
                pgName: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string(),
                pcspDate: pcsp === 'true' ? Yup.date().required(t("Date Of Associated PCSP is a required field")) : Yup.date()
            }),
    })

    const careReceiverForm = useFormik({
        initialValues: formData,
        validationSchema: careReceiverFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            if (!errWarning2 && !errWarning) {
                setButtonDisable(true)
                setFormLoading(true)
                profileForm.values.careReceiver = values.careReceiver
                submitForm()
                setFormLoading(false)
            }

        }
    })

    const careGiverForm = useFormik({
        initialValues: formData,
        validationSchema: careGiverFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            if (!errWarning1) {
                setButtonDisable(true)
                setFormLoading(true)
                profileForm.values.careGiver = values.careGiver
                submitForm()
                setFormLoading(false)
            }

        }
    })


    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            if (!errWarning && !errWarning1 && !errWarning2)
                setFormLoading(true)
            // profileForm.setValues({careGiver:values.careGiver,careReceiver:values.careReceiver,caseId:'',referrerCaseFollowup:values.referrerCaseFollowup})
            submitForm()
            setFormLoading(false)
        }
    })

    const setRefused = (side, value) => {
        if (side === 'cr') {
            setCrRefused(value)
        } else if (side === 'cg') {
            setCgRefused(value)
        }
    }

    const getEmailAddress = (value, field) => {
        var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
        if (regex.test(value)) {
            API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${0}/emailValidate?email=${value}`).then((res) => {
                if (res.status === 200) {
                    setErrWarning(false)
                    setErrWarning1(false)
                    setNextButtonDisable(false)
                    setNextButtonDisable1(false)
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    if (field === 'cr') {
                        setErrWarning(true)
                        setCustomSubWarn(err.response.data.errorMessage)
                        setNextButtonDisable(true)
                        // careReceiverForm.setValues({...careReceiverForm.values,careReceiver:{...careReceiverForm.values.careReceiver,emailAddress:""}})
                    } else if (field === 'cg') {
                        setErrWarning1(true)
                        setCustomSubWarn1(err.response.data.errorMessage)
                        setNextButtonDisable(true)
                        // careGiverForm.setValues({...careGiverForm.values,careGiver:{...careGiverForm.values.careGiver,emailAddress:""}})
                    } else if (field === 'pcrr') {
                        setErrWarning(true)
                        setCustomSubWarn(err.response.data.errorMessage)
                        setNextButtonDisable(true)
                        // profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,emailAddress:""}})
                    } else if (field === 'pcgr') {
                        setErrWarning1(true)
                        setCustomSubWarn1(err.response.data.errorMessage)
                        setNextButtonDisable(true)
                        //  profileForm.setValues({... profileForm.values,careGiver:{... profileForm.values.careGiver,emailAddress:""}})
                    }
                }
            })
        } else {
            setErrWarning(false)
            setErrWarning1(false)
            setNextButtonDisable(false)
            setNextButtonDisable1(false)
        }
    }

    const common = (value) => {
        if (value === true) {
            setoption(true)
            setCustomSubWarn1(false)
            setErrWarning1(false)
            setZipEnable(false)
            careGiverForm.resetForm();
            careReceiverForm.setValues({ ...careReceiverForm.values, careReceiver: { ...careReceiverForm.values.careReceiver, pcspDate: careGiverForm.values.careGiver.pcspDate, check: true } })
        }
        else if (value === false) {
            setoption(false)
            setRefused('cg', false)
            careGiverForm.setValues({ ...careGiverForm.values, careGiver: { ...careGiverForm.values.careGiver, pcspDate: careReceiverForm.values.careReceiver.pcspDate } })
            //  careGiverForm.resetForm();
        }
    }
    const common1 = (value) => {
        if (value === true) {
            setoption(true)
            setCustomSubWarn1(false)
            setErrWarning1(false)
            careGiverForm.resetForm();
            setBoth(false)
        }
        else if (value === false) {
            setoption(false)
            //  careGiverForm.resetForm();
        }
    }

    const getSubscriberId= (value,field) => {
        var regex = /^[a-zA-Z0-9_]*$/;
        if(value && regex.test(value) && tempCrSub !== value){
            API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${0}/SubIdValidate?subId=${value}`).then((res)=>{
                if(res.status === 200){
                    setErrWarning2(false)
                    careReceiverForm.setValues({ ...careReceiverForm.values, careReceiver: { ...careReceiverForm.values.careReceiver, subscriberId: value } })
                    profileForm.setValues({ ...profileForm.values, careReceiver: { ...profileForm.values.careReceiver, subscriberId: value } })
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    if (field === 'cr') {
                        setErrWarning2(true)
                        setCustomSubWarn2(err.response.data.errorMessage)
                        setNextButtonDisable(true)
                        // careReceiverForm.setValues({...careReceiverForm.values,careReceiver:{...careReceiverForm.values.careReceiver,subscriberId:""}})
                    } else if (field === 'pcrr') {
                        setErrWarning2(true)
                        setCustomSubWarn2(err.response.data.errorMessage)
                        setNextButtonDisable(true)
                        // profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,subscriberId:""}})
                    }

                }
            })
        }else{
            setErrWarning2(false)
            setNextButtonDisable2(false)
        }
        setTempCrSub(value)
    }

    const getAddress = (value, field) => {
        if (value.length === 5) {
            API.get(`/intake/zones?zipCode=${value}`).then((res) => {
                if (res.status === 200) {
                    if (field === 'cr') {
                        setCrEnable(true)
                        careReceiverForm.setValues({ ...careReceiverForm.values, careReceiver: { ...careReceiverForm.values.careReceiver, address: { ...careReceiverForm.values.careReceiver.address, state: res.data.body.state, city: res.data.body.city, country: res.data.body.country, county: res.data.body.county } } })
                    } else if (field === 'cg') {
                        setZipEnable(true)
                        careGiverForm.setValues({ ...careGiverForm.values, careGiver: { ...careGiverForm.values.careGiver, address: { ...careGiverForm.values.careGiver.address, state: res.data.body.state, city: res.data.body.city, country: res.data.body.country } } })
                    } else if (field === 'pcrr') {
                        setCrEnable(true)
                        profileForm.setValues({ ...profileForm.values, careReceiver: { ...profileForm.values.careReceiver, address: { ...profileForm.values.careReceiver.address, state: res.data.body.state, city: res.data.body.city, country: res.data.body.country, county: res.data.body.county } } })
                        // profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,address:{...profileForm.values.careReceiver.address,state:res.data.body.state}}})
                        // profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,address:{...profileForm.values.careReceiver.address,city:res.data.body.city}}})
                        // profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,address:{...profileForm.values.careReceiver.address,country:res.data.body.country}}})
                    } else if (field === 'pcgr') {
                        setZipEnable(true)
                        profileForm.setValues({ ...profileForm.values, careGiver: { ...profileForm.values.careGiver, address: { ...profileForm.values.careGiver.address, state: res.data.body.state, city: res.data.body.city, country: res.data.body.country } } })
                        // profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,address:{...profileForm.values.careGiver.address,state:res.data.body.state}}})
                        // profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,address:{...profileForm.values.careGiver.address,city:res.data.body.city}}})
                        // profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,address:{...profileForm.values.careGiver.address,country:res.data.body.country}}})
                    }
                }
            })
        }
        else if (value.length === 0) {
            if (field === 'cr') {
                setCrEnable(false)
                careReceiverForm.setValues({ ...careReceiverForm.values, careReceiver: { ...careReceiverForm.values.careReceiver, address: { ...careReceiverForm.values.careReceiver.address, state: "", city: "", country: "" } } })
            } else if (field === 'cg') {
                setZipEnable(false)
                careGiverForm.setValues({ ...careGiverForm.values, careGiver: { ...careGiverForm.values.careGiver, address: { ...careGiverForm.values.careGiver.address, state: "", city: "", country: "" } } })
            } else if (field === 'pcrr') {
                setCrEnable(false)
                profileForm.setValues({ ...profileForm.values, careReceiver: { ...profileForm.values.careReceiver, address: { ...profileForm.values.careReceiver.address, state: "" } } })
                profileForm.setValues({ ...profileForm.values, careReceiver: { ...profileForm.values.careReceiver, address: { ...profileForm.values.careReceiver.address, city: "" } } })
                profileForm.setValues({ ...profileForm.values, careReceiver: { ...profileForm.values.careReceiver, address: { ...profileForm.values.careReceiver.address, country: "" } } })
            } else if (field === 'pcgr') {
                setZipEnable(false)
                profileForm.setValues({ ...profileForm.values, careGiver: { ...profileForm.values.careGiver, address: { ...profileForm.values.careGiver.address, state: "", city: "", country: "" } } })
                // profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,address:{...profileForm.values.careGiver.address,state:res.data.body.state}}})
                // profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,address:{...profileForm.values.careGiver.address,city:res.data.body.city}}})
                // profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,address:{...profileForm.values.careGiver.address,country:res.data.body.country}}})
            }
        }
    }


    // const validateForm = () => {
    //     if (refdyad === 'true') {
    //         setBoth(true)
    //         // profileForm.setValues({careGiver:careGiverForm.values.careGiver,careReceiver:careReceiverForm.values.careReceiver,caseId:'',referrerCaseFollowup:profileForm.values.referrerCaseFollowup})
    //         setButtonDisable(true)
    //         setTimeout(function () {
    //             profileForm.submitForm()
    //             if (!profileForm.isValid) {
    //                 setButtonDisable(false)
    //             }
    //         }, 500)
    //     } else {
    //         if ((profileForm.values.careReceiver.firstName && profileForm.values.careReceiver.firstName !== "") && (profileForm.values.careReceiver.lastName && profileForm.values.careReceiver.lastName !== "") && (profileForm.values.careGiver.firstName && profileForm.values.careGiver.firstName !== "") && (profileForm.values.careGiver.lastName && profileForm.values.careGiver.lastName !== "") && (profileForm.values.careGiver.phoneNumber && profileForm.values.careGiver.phoneNumber !== "")) {
    //         } else {
    //             profileForm.setValues({ careGiver: careGiverForm.values.careGiver, careReceiver: careReceiverForm.values.careReceiver, caseId: '', referrerCaseFollowup: profileForm.values.referrerCaseFollowup })
    //         }
    //         if ((careReceiverForm.values.careReceiver.firstName === '' && careReceiverForm.values.careReceiver.lastName === '' && careReceiverForm.values.careReceiver.emailAddress === '' && careReceiverForm.values.careReceiver.dateOfBirth === '' && careReceiverForm.values.careReceiver.address.postalCode === "" && careReceiverForm.values.careReceiver.address.addressLine1 === "" && careReceiverForm.values.careReceiver.address.city === "" && careReceiverForm.values.careReceiver.address.state === "" && careGiverForm.values.careGiver.firstName === '' && careGiverForm.values.careGiver.lastName === "" && careGiverForm.values.careGiver.phoneNumber === '' && careGiverForm.values.careGiver.emailAddress === '' && careGiverForm.values.careGiver.address.postalCode === '' && careGiverForm.values.careGiver.dateOfBirth === '' &&
    //             careGiverForm.values.careGiver.address.addressLine1 === '' && careGiverForm.values.careGiver.address.city === '' && careGiverForm.values.careGiver.address.state === '')) {
    //             option ? dispatch(setAlert({ title: t('Warning'), subtitle: t('Please fill Carereceiver informations'), type: 'warning', active: true })) : dispatch(setAlert({ title: t('Warning'), subtitle: t('Please fill either Caregiver or Carereceiver informations'), type: 'warning', active: true }))
    //             // setoption(true)

    //         }
    //         if (((careReceiverForm.values.careReceiver.firstName || careReceiverForm.values.careReceiver.lastName || careReceiverForm.values.careReceiver.emailAddress || careReceiverForm.values.careReceiver.dateOfBirth || careReceiverForm.values.careReceiver.address.postalCode || careReceiverForm.values.careReceiver.address.addressLine1 || careReceiverForm.values.careReceiver.address.city || careReceiverForm.values.careReceiver.address.state) && (careGiverForm.values.careGiver.firstName || careGiverForm.values.careGiver.lastName || careGiverForm.values.careGiver.phoneNumber || careGiverForm.values.careGiver.emailAddress || careGiverForm.values.careGiver.dateOfBirth || careGiverForm.values.careGiver.address.postalCode || careGiverForm.values.careGiver.address.addressLine1 || careGiverForm.values.careGiver.address.city || careGiverForm.values.careGiver.address.state)) && (errWarning !== true) && (errWarning1 !== true) && (errWarning2 !== true)) {
    //             setBoth(true)
    //             setButtonDisable(true)
    //             setTimeout(function () {
    //                 profileForm.submitForm()
    //                 if (!profileForm.isValid) {
    //                     setButtonDisable(false)
    //                 }
    //             }, 500)
    //         } else if ((careGiverForm.values.careGiver.firstName || careGiverForm.values.careGiver.lastName || careGiverForm.values.careGiver.phoneNumber || careGiverForm.values.careGiver.emailAddress || careGiverForm.values.careGiver.dateOfBirth || careGiverForm.values.careGiver.address.postalCode || careGiverForm.values.careGiver.address.addressLine1 || careGiverForm.values.careGiver.address.city || careGiverForm.values.careGiver.address.state) && (errWarning !== true) && (errWarning1 !== true) && (errWarning2 !== true)) {
    //             setBoth(false)
    //             careGiverForm.submitForm()
    //             setCrLength(0)
    //         } else if ((careReceiverForm.values.careReceiver.firstName || careReceiverForm.values.careReceiver.lastName || careReceiverForm.values.careReceiver.emailAddress || careReceiverForm.values.careReceiver.dateOfBirth || careReceiverForm.values.careReceiver.address.postalCode || careReceiverForm.values.careReceiver.address.addressLine1 || careReceiverForm.values.careReceiver.address.city || careReceiverForm.values.careReceiver.address.state) && (errWarning !== true) && (errWarning1 !== true) && (errWarning2 !== true)) {
    //             if (careReceiverForm.values.careReceiver.check !== true) {
    //                 dispatch(setAlert({ title: t('Warning'), subtitle: t('Please provide consent for No Caregiver Available'), type: 'warning', active: true }))
    //                 setButtonDisable(false)
    //             } else {
    //                 setBoth(false)
    //                 careReceiverForm.submitForm()
    //                 setCgLength(0)
    //             }

    //         }
    //     }
    // }


    const submitForm = () => {
        let concatStr, details;
        if (profileForm.values.referrerCaseFollowup !== undefined && profileForm.values.referrerCaseFollowup.followupdate) {
            let split = profileForm.values.referrerCaseFollowup.followupdate.split(' ')
            let dateSplit = split[0].split('/')
            let timeSplit = split[1].split(':')
            let date = dateSplit[2]
            let month = dateSplit[1]
            let year = dateSplit[0]
            let hour = timeSplit[0]
            let minute = timeSplit[1]
            concatStr = year + '-' + month + '-' + date + 'T' + hour + ':' + minute + ':00Z'
            //     reasonName = followupReason.map(c => { if (c.value === profileForm.values.referrerCaseFollowup.reason) { return c.text } 
            //     return null;
            // })
            //     reasonName = reasonName.filter(c => c !== undefined).toString()
        }
        if (profileForm.values.careReceiver !== undefined && profileForm.values.careReceiver.dateOfBirth && birthyear1) {
            profileForm.values.careReceiver.dateOfBirth = profileForm.values.careReceiver.dateOfBirth.split('/')
            profileForm.values.careReceiver.dobDay = profileForm.values.careReceiver.dateOfBirth[2]
            profileForm.values.careReceiver.dobMonth = profileForm.values.careReceiver.dateOfBirth[1]
            profileForm.values.careReceiver.dobYear = profileForm.values.careReceiver.dateOfBirth[0]
        }
        if (profileForm.values.careGiver !== undefined && profileForm.values.careGiver.dateOfBirth && birthyear1) {
            profileForm.values.careGiver.dateOfBirth = profileForm.values.careGiver.dateOfBirth.split('/')
            profileForm.values.careGiver.dobDay = profileForm.values.careGiver.dateOfBirth[2]
            profileForm.values.careGiver.dobMonth = profileForm.values.careGiver.dateOfBirth[1]
            profileForm.values.careGiver.dobYear = profileForm.values.careGiver.dateOfBirth[0]
        }
        profileForm.values.careGiver.dobYear = profileForm.values.careGiver.dateOfBirth
        profileForm.values.careReceiver.dobYear = profileForm.values.careReceiver.dateOfBirth
            // let careReceiverPhone = profileForm.values.careReceiver.phone.split('-')
            // let careGiverPhone = profileForm.values.careGiver.phoneNumber.split('-')
            details = {
                "pcspDate": profileForm.values.careGiver.pcspDate ? profileForm.values.careGiver.pcspDate.toString().replace('/', '-').replace('/', '-').slice(0, 10) : null,
                "caseId": 0,
                "organizationId": organization,
                "noCaregiver": profileForm.values.careReceiver.check ? true : false,
                "referrerCaseFollowup": {
                    "reason": profileForm.values.referrerCaseFollowup.reason,
                    "caseId": 0,
                    "notes": profileForm.values.referrerCaseFollowup.notes,
                    "followupDate": concatStr,
                    "referredByName": userDetails.sub,
                    "rcfId": 0
                },
                "caregiver": {
                    "address": {
                        "state": profileForm.values.careGiver.address.state,
                        "addressLine1": profileForm.values.careGiver.address.addressLine1,
                        "addressLine2": "",
                        "city": profileForm.values.careGiver.address.city,
                        "postalCode": profileForm.values.careGiver.address.postalCode,
                        "addressLine3": ""
                    },
                    "language": profileForm.values.careGiver.language,
                    "areaCode": "",
                    relationshipId: 0,
                    "phone": profileForm.values.careGiver.phoneNumber,
                    "gender": '',
                    "pronouns": null,
                    "personId": 0,
                    "firstName": profileForm.values.careGiver.firstName.trim(),
                    "lastName": profileForm.values.careGiver.lastName.trim(),
                    "emailAddress": profileForm.values.careGiver.emailAddress,
                    "maritalStat": "",
                    "programName": "",
                    "subscriberId": "",
                    "dobDay": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careGiver.dobDay) : parseInt(profileForm.values.careGiver.dobDay ? profileForm.values.careGiver.dobDay : 1),
                    "dobMonth": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careGiver.dobMonth) : parseInt(profileForm.values.careGiver.dobMonth ? profileForm.values.careGiver.dobMonth : 1),
                    "dobYear": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careGiver.dobYear) : parseInt(profileForm.values.careGiver.dobYear ? profileForm.values.careGiver.dobYear : 0),
                    "preferredName": "",
                    "hispanic": false,
                    "nativeAmerican": false,
                    "pacificIslander": false,
                    "multiRacial": false,
                    "uninsured": false,
                    "samsId": "",
                    "ssn": "",
                    "homePhone": "",
                    "employmentId": 0,
                    "educationId": 0,
                    "incomeId": 0,
                    "white": false,
                    "black": false,
                    "asian": false,
                    "middleEastern": false,
                    "middleName": "",
                    "phoneType": "",
                    "phoneAuthorized": false,
                    "otherRace": false,
                    "pgName": profileForm.values.careGiver.pgName,
                },
                "careReceiver": {
                    "address": {
                        "state": profileForm.values.careReceiver.address.state,
                        "addressLine1": profileForm.values.careReceiver.address.addressLine1,
                        "addressLine2": "",
                        "city": profileForm.values.careReceiver.address.city,
                        "county": profileForm.values.careReceiver.address.county,
                        "postalCode": profileForm.values.careReceiver.address.postalCode,
                        "addressLine3": ""
                    },
                    "language": profileForm.values.careReceiver.language,
                    "areaCode": "",
                    "phone": profileForm.values.careReceiver.phone,
                    "gender": '',
                    "pronouns": null,
                    "personId": 0,
                    "firstName": profileForm.values.careReceiver.firstName.trim(),
                    "lastName": profileForm.values.careReceiver.lastName.trim(),
                    "emailAddress": profileForm.values.careReceiver.emailAddress,
                    "maritalStat": "",
                    "relationship": "",
                    "relationshipId": 0,
                    "subscriberId": profileForm.values.careReceiver.subscriberId,
                    "dobDay": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobDay) : parseInt(profileForm.values.careReceiver.dobDay ? profileForm.values.careReceiver.dobDay : 1),
                    "dobMonth": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobMonth) : parseInt(profileForm.values.careReceiver.dobMonth ? profileForm.values.careReceiver.dobMonth : 1),
                    "dobYear": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobYear) : parseInt(profileForm.values.careReceiver.dobYear ? profileForm.values.careReceiver.dobYear : 0),
                    "preferredName": "",
                    "hispanic": false,
                    "nativeAmerican": false,
                    "pacificIslander": false,
                    "multiRacial": false,
                    "uninsured": false,
                    "samsId": "",
                    "ssn": "",
                    "homePhone": "",
                    "employmentId": 0,
                    "educationId": 0,
                    "incomeId": 0,
                    "white": false,
                    "black": false,
                    "asian": false,
                    "middleEastern": false,
                    "middleName": "",
                    "phoneType": "",
                    "phoneAuthorized": false,
                    "otherRace": false,
                    "assessType": profileForm.values.careReceiver.assessType,
                    "check": profileForm.values.careReceiver.check ? true : false,
                    "tcareProtocol": profileForm.values.careReceiver.tcareProtocol
                }
            }
        API.post(`referrals`, details).then(res => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('Referral Created Successfully'), type: 'success', active: true }))
                profileForm.resetForm();
                careGiverForm.resetForm();
                careReceiverForm.resetForm();
                history.push('/pendingRefferals')
                setButtonDisable(false)
                // window.location.reload(false);
            }
        }).catch(err => {
            if (err.response.status === 406) {
                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                setButtonDisable(false)
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                setButtonDisable(false)
            }
        })
    }

    return (
        <div className="flex-1 flex justify-center items-center">
            {
                loading ?
                    <DemographicLoader />
                    :
                   
                    <div className="flex flex-col justify-center w-full md:w-11/12">
                            <div className="flex flex-col lg:flex-row justify-between gap-6 flex-wrap items-center">
                            <div className="flex-1 flex flex-col items-center">
                                <div className="">{t('Care Receiver')} {t('Information')}</div>
                                <div className="mt-8 lg:mt-10 grid grid-cols-2 2xl:grid-cols-2 gap-4 w-full">
                                    <FieldInput ariaLabel={'Care Reciever First Name is a required field'} hideData={true} loading={formLoading} label={t('First Name')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.firstName`)} fieldProps={profileForm.getFieldProps(`careReceiver.firstName`)} edit={true} dataLoaded={dataLoaded} />
                                    <FieldInput ariaLabel={'Care Reciever Last Name is a required field'} hideData={true} loading={formLoading} label={t('Last Name')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.lastName`)} fieldProps={profileForm.getFieldProps(`careReceiver.lastName`)} edit={true} dataLoaded={dataLoaded} />
                                    {/* <div className="flex items-center gap-2 relative"> */}
                                        <FieldInput ariaLabel={'Care Reciever Phone Number'} hideData={true} type="phone" loading={formLoading} label={t('Phone Number')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.phone`)} fieldProps={profileForm.getFieldProps(`careReceiver.phone`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.phone`)} edit={true} dataLoaded={dataLoaded} />
                                        {/* <a href={`tel: +1${profileForm.getFieldProps('profileForm.phone').value?.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Call')}>
                                            <FaPhoneAlt size="16px" />
                                        </a> */}
                                    {/* </div> */}
                                    <FieldInput ariaLabel={'Care Reciever Email Address'} hideData={true} loading={formLoading} label={t('Email')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.emailAddress`)} fieldProps={profileForm.getFieldProps(`careReceiver.emailAddress`)} edit={true} dataLoaded={dataLoaded} onChange={(val) => getEmailAddress(val,'pcrr')} err={errWarning} customErrmsg={customsubWarn} />
                                    { birthyear1 ? <FieldInput disabled={crRefused && option? true:option?true:crRefused?true:false} type="date" loading={formLoading} label={t('Date of Birth')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careReceiver.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.dateOfBirth`)} edit={true} dataLoaded={dataLoaded} maxDate={new Date()} />:
                                        <FieldSelect disabled={crRefused ? true:false} loading={formLoading} label={t('Birth Year')} fieldMeta={profileForm.getFieldMeta(`careReceiver.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careReceiver.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.dateOfBirth`)} options={years} edit={true} dataLoaded={dataLoaded} position="bottom" />
 
                                    }
                                    <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative w-96">
                                        {/* <FieldInput type="year" disabled={crRefused ? true:false} loading={formLoading} label={t('Date of Birth')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.dateOfBirth`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.dateOfBirth`)} fieldHelper={careReceiverForm.getFieldHelpers(`careReceiver.dateOfBirth`)} edit={true} dataLoaded={dataLoaded} maxDate={new Date()} /> */}
                                        {crRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cr',false)}>{t('Refused')}</button>}
                                        {!crRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tgray" onClick={()=>setRefused('cr',true)}>{t('Refused')}</button>}
                                            </div>
                                    <FieldInput ariaLabel={'Care Reciever Zip Code is a required field'} loading={formLoading} label={t('Zip Code')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.address.postalCode`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.postalCode`)} edit={true} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'pcrr')} />
                                    {crEnable ?  <FieldInput ariaLabel={'Care Reciever Address'} loading={formLoading} label={t('Address')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.address.addressLine1`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.addressLine1`)} edit={true} dataLoaded={dataLoaded} />:''}
                                    {crEnable ? <FieldInput ariaLabel={'Care Reciever City'} loading={formLoading} label={t('City')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.address.city`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.city`)} edit={true} dataLoaded={dataLoaded} />:''}
                                      {crEnable ? <FieldSelect loading={formLoading} label={t('State')} fieldMeta={profileForm.getFieldMeta(`careReceiver.address.state`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.state`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.address.state`)} options={states} edit={true} dataLoaded={dataLoaded} position="bottom" />:''}
                                      {crEnable ? <FieldInput ariaLabel={'Care Reciever County'} loading={formLoading} label={t('County')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.address.county`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.county`)} edit={true} dataLoaded={dataLoaded} />:''}
                                 
                                    <FieldInput ariaLabel={'Care Reciever Subscriber I D'} loading={formLoading} label={t('Subscriber ID')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.subscriberId`)} fieldProps={profileForm.getFieldProps(`careReceiver.subscriberId`)} edit={true} dataLoaded={dataLoaded} ml={true} length={userDetails.env ==='MOLINA'?'14':'9'} onBlur={(ev) => getSubscriberId(ev.target.value,'pcrr')} onPaste={(ev) => getSubscriberId(ev,'pcrr')} err={errWarning2} customErrmsg={customsubWarn2} />
                                    <FieldSelect loading={formLoading} label={t("Preferred Language")} fieldMeta={profileForm.getFieldMeta('careReceiver.language')} fieldHelper={profileForm.getFieldHelpers('careReceiver.language')} fieldProps={profileForm.getFieldProps('careReceiver.language')} edit={true} options={language} position="top" dataLoaded={dataLoaded} />
                                    {assessmentTypeId1 ? <FieldSelect loading={formLoading} label={t("Assessment Type")} fieldMeta={profileForm.getFieldMeta('careReceiver.assessType')} fieldHelper={profileForm.getFieldHelpers('careReceiver.assessType')} fieldProps={profileForm.getFieldProps('careReceiver.assessType')} edit={true} options={assessmentType} position="top" dataLoaded={dataLoaded} />:''}
                                    {protocolTypeId1 ? <FieldSelect loading={formLoading} label={t("Protocol Type")} fieldMeta={profileForm.getFieldMeta('careReceiver.tcareProtocol')} fieldHelper={profileForm.getFieldHelpers('careReceiver.tcareProtocol')} fieldProps={profileForm.getFieldProps('careReceiver.tcareProtocol')} edit={true} options={protocol} position="top" dataLoaded={dataLoaded} />:''}
                                    {/* <FieldSelect loading={formLoading} label={t('Relationship')} fieldMeta={profileForm.getFieldMeta(`careReceiver.relationshipId`)} fieldProps={profileForm.getFieldProps(`careReceiver.relationshipId`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.relationshipId`)} options={relationShip} edit={true} dataLoaded={dataLoaded} position="bottom" /> */}
                                </div>
                            </div>
                            <div className="flex-1 flex flex-col items-center">
                                <div className="">{t('Caregiver')} {t('Information')}</div>
                                <div className="mt-8 lg:mt-10 grid grid-cols-2 gap-4 w-full lg:w-3/4">
                                    <ReferrerCheckbox loading={formLoading} label={t("No Caregiver Available")}  autoComplete='off' fieldHelper={careReceiverForm.getFieldHelpers('careReceiver.check')} fieldMeta={careReceiverForm.getFieldMeta('careReceiver.check')} fieldProps={careReceiverForm.getFieldProps('careReceiver.check')} dataLoaded={dataLoaded} edit={true} onChange={(ev) => common1(ev)}/>
                                    <ReferrerInput loading={formLoading} label={''}  autoComplete='off' fieldHelper={''} fieldMeta={''} fieldProps={''} dataLoaded={dataLoaded} edit={false} />
                                {/* <FieldCheckbox loading={formLoading} label={t("No Caregiver available")} autoComplete='off' fieldHelper={careGiverForm.getFieldHelpers('careGiver.check')} fieldMeta={careGiverForm.getFieldMeta('careGiver.check')} fieldProps={careGiverForm.getFieldProps('careGiver.check')} dataLoaded={dataLoaded} edit= {true}/><div></div> */}
                                    <ReferrerInput ariaLabel={'Caregiver First Name is a required field'} hideData={true} disabled={option} loading={formLoading} label={t('First Name')} fieldMeta={profileForm.getFieldMeta(`careGiver.firstName`)} fieldProps={profileForm.getFieldProps(`careGiver.firstName`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.firstName`)} edit={true} dataLoaded={dataLoaded} />
                                    <ReferrerInput ariaLabel={'Caregiver Last Name is a required field'} hideData={true} disabled={option} loading={formLoading} label={t('Last Name')} fieldMeta={profileForm.getFieldMeta(`careGiver.lastName`)} fieldProps={profileForm.getFieldProps(`careGiver.lastName`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.lastName`)} edit={true} dataLoaded={dataLoaded} />
                                    <ReferrerInput ariaLabel={'Caregiver Phone Number is a required field'} hideData={true} disabled={option} type="phone" loading={formLoading} label={t('Phone Number')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.phoneNumber`)} fieldProps={profileForm.getFieldProps(`careGiver.phoneNumber`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.phoneNumber`)} edit={true} dataLoaded={dataLoaded} />
                                    <ReferrerInput ariaLabel={'Caregiver Email Address'} hideData={true} disabled={option} loading={formLoading} label={t('Email')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.emailAddress`)} fieldProps={profileForm.getFieldProps(`careGiver.emailAddress`)} edit={true} dataLoaded={dataLoaded} onChange={(val) => getEmailAddress(val,'pcgr')} err={errWarning1} customErrmsg={customsubWarn1} />
                                    { birthyear1 ? <ReferrerInput ariaLabel={'Caregiver Date Of Birth is a required field'} disabled={cgRefused && option? true:option?true:cgRefused?true:false} type="date" loading={formLoading} label={t('Date of Birth')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careGiver.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.dateOfBirth`)} edit={true} dataLoaded={dataLoaded} maxDate={new Date()} />:
                                        <ReferrerSelect ariaLabel={'Caregiver Email Birth Year DropDown- is a required field'} disabled={cgRefused && option? true:option?true:cgRefused?true:false} loading={formLoading} label={t('Birth Year')} fieldMeta={profileForm.getFieldMeta(`careGiver.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careGiver.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.dateOfBirth`)} options={years} edit={true} dataLoaded={dataLoaded} position="bottom" />
 
                                   }
                                    <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative w-72">
                                        {cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cg',false)}>{t('Refused')}</button>}
                                        {!cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tgray" onClick={()=>setRefused('cg',true)}>{t('Refused')}</button>}
                                        </div>
                                    {/* <ReferrerSelect loading={formLoading} label={t('State')} fieldMeta={profileForm.getFieldMeta(`careGiver.state`)} fieldProps={profileForm.getFieldProps(`careGiver.state`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.state`)} options={states} edit={true} dataLoaded={dataLoaded} position="bottom" /> */}
                                        {/* <ReferrerSelect disabled={cgRefused ? true:false} loading={formLoading} label={t('Birth Year')} fieldMeta={profileForm.getFieldMeta(`careGiver.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careGiver.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.dateOfBirth`)} options={years} edit={true} dataLoaded={dataLoaded} position="bottom" />
                                        <div className="mt-3">
                                        {cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cg',false)}>{t('Refused')}</button>}
                                        {!cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tgray" onClick={()=>setRefused('cg',true)}>{t('Refused')}</button>}
                                        </div> */}
                                         {programName1 ? <ReferrerSelect ariaLabel={'Caregiver Program Dropdown is a required field'} disabled={option} loading={formLoading} label={t('Program Name')} fieldMeta={profileForm.getFieldMeta(`careGiver.pgName`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.pgName`)} fieldProps={profileForm.getFieldProps(`careGiver.pgName`)} edit={true} options={programName} position="bottom" dataLoaded={dataLoaded} />:''}
                                         {datePscp ? <ReferrerInput ariaLabel={'Caregiver Date of Associated PCSP is a required field'} disabled={option} loading={formLoading} type="date"  label={t("Date of Associated PCSP")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.pcspDate`)} fieldProps={profileForm.getFieldProps(`careGiver.pcspDate`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.pcspDate`)} edit={true} dataLoaded={dataLoaded} />:''}
                                    <ReferrerInput ariaLabel={'Caregiver Zip Code is a required field'} disabled={option} loading={formLoading} label={t('Zip Code')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.address.postalCode`)} fieldProps={profileForm.getFieldProps(`careGiver.address.postalCode`)} edit={true} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'pcgr')} />
                                    {zipEnable ? <ReferrerInput ariaLabel={'Caregiver Address'} disabled={option} loading={formLoading} label={t('Address')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.address.addressLine1`)} fieldProps={profileForm.getFieldProps(`careGiver.address.addressLine1`)} edit={true} dataLoaded={dataLoaded} />:''}
                                       {zipEnable ? <ReferrerInput ariaLabel={'Caregiver City'} disabled={option} loading={formLoading} label={t('City')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.address.city`)} fieldProps={profileForm.getFieldProps(`careGiver.address.city`)} edit={true} dataLoaded={dataLoaded} />:''}
                                       {zipEnable ? <ReferrerSelect ariaLabel={'Caregiver State Dropdown'} disabled={option} loading={formLoading} label={t('State')} fieldMeta={profileForm.getFieldMeta(`careGiver.address.state`)} fieldProps={profileForm.getFieldProps(`careGiver.address.state`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.address.state`)} options={states} edit={true} dataLoaded={dataLoaded} position="bottom" />:''}
                                 
                                        <ReferrerSelect ariaLabel={'Caregiver Preferred Language Dropdown'} disabled={option} loading={formLoading} label={t("Preferred Language")} fieldMeta={profileForm.getFieldMeta('careGiver.language')} fieldHelper={profileForm.getFieldHelpers('careGiver.language')} fieldProps={profileForm.getFieldProps('careGiver.language')} edit={true} options={language} position="top" dataLoaded={dataLoaded} />
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex mt-10 pt-10 mb-10 justify-center gap-4 border-t border-gray-300 dark:border-gray-600">
                            <div className="flex flex-col gap-3">
                                <FieldInput ariaLabel={'Followup Date'} type="datetime" minDate={new Date()} loading={formLoading} label={t('Scheduled Follow-up')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`referrerCaseFollowup.followupdate`)} fieldProps={profileForm.getFieldProps(`referrerCaseFollowup.followupdate`)} fieldHelper={profileForm.getFieldHelpers(`referrerCaseFollowup.followupdate`)} edit={true} dataLoaded={true} />
                                {/* <FieldSelect loading={formLoading} label={t('Reason')} fieldMeta={profileForm.getFieldMeta(`referrerCaseFollowup.reason`)} fieldProps={profileForm.getFieldProps(`referrerCaseFollowup.reason`)} fieldHelper={profileForm.getFieldHelpers(`referrerCaseFollowup.reason`)} options={followupReason} edit={true} dataLoaded={true} position="bottom" /> */}
                                <FieldInput ariaLabel={'Followup Notes'} type="textarea" loading={formLoading} label={t('Notes')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`referrerCaseFollowup.notes`)} fieldProps={profileForm.getFieldProps(`referrerCaseFollowup.notes`)} fieldHelper={profileForm.getFieldHelpers(`referrerCaseFollowup.notes`)} edit={true} dataLoaded={true} />
                            </div>
                        </div>
                        <div className="text-center">
                        <button disabled={buttonDisable}  className={`relative bg-gradient-to-tr from-tcolor to-blue-300 text-lg text-white px-16 py-2 rounded-full hover:ring-2 hover:ring-offset-2 hover:ring-offset-gray-200 dark:hover:ring-offset-ldark hover:ring-tcolor hover:shadow-xl transition-all duration-300 align-middle outline-none focus:outline-none`}  onClick={() => profileForm.submitForm()()}>{t("Submit")}</button>
                            {/* <Button disabled={disabl2} injectClass="mt-10 py-1 py-2"  onClick={() => validateForm()}>{t("Submit")}</Button> */}
                        </div>
                    </div>
            }
        </div>
    )
}

export default AddCaseDemographic