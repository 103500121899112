import { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { MdCheck } from 'react-icons/md'
import FlowText from "../../components/snippets/FlowText"
import API from '../../axios/API'

import { AnimatePresence, motion } from 'framer-motion'
import ChangeRelationship from "../Dialogs/ChangeRelationship"
import { createPortal } from "react-dom"
import Dialog from "../../components/snippets/Dialog"
import FieldInput from "../../components/snippets/FieldInput"
import { useFormik } from "formik"
import * as Yup from 'yup'
import { useTranslation } from "react-i18next"
import StatusText from "../../components/snippets/StatusText"




const initialFormValues = {
    otherRelationship : ''
}

const RelationshipSelector = ({ injectPopupClass, originX, originY, props, caseId }) => {
    
    const [showSelector, setShowSelector] = useState(false)
    const [relationships, setRelationships] = useState([])
    const [relationship] = useState(props)
    const [openChangeRelation, setOpenChangeRelation] = useState(false)
    const [editData, setEditData] = useState()
    const [openOtherBox, setOpenOtherBox] = useState(false)
    const { t } = useTranslation()
    const hideShowSelector = () => setShowSelector(false);
    let lang = localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))

const otehrCheckBox = relationships.find(data => data.name === relationship);

    Yup.addMethod(Yup.string, 'otherRelation', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^\d]+$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'EmptySpace', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    useEffect(() => {
        if(menuItems && menuItems !== '' && menuItems !== null && menuItems !== undefined){
            if (menuItems.relationship && menuItems.relationship.length > 0 && menuItems.relationship !== undefined) {
                setRelationships(menuItems.relationship)
            } else {
                API.get(`menus/relationships?lang=${lang}`).then((relationshipResp) => {
                    setRelationships(relationshipResp.data.body)
                })
            }
        }else{
            API.get(`menus/relationships?lang=${lang}`).then(res => {
                setRelationships(res.data.body)
            })
        }
    }, [])
    const menuAnim = useMemo(() => ({
        hidden: {
            scale: 0,
            opacity: 0
        },
        visible: {
            scale: 1,
            opacity: 1,
            originX: originX,
            originY: originY
        }
    }), [originX, originY])

    const relation = useMemo(() => {
        let l = relationships.filter(c => c.name === relationship)
        return l.length > 0 ? l[0].name : props
    }, [relationships, relationship, props])

    useLayoutEffect(() => {
        if (showSelector) {
            setTimeout(() => {
                window.addEventListener('click', hideShowSelector)
            }, 100)
        } else {
            window.removeEventListener('click', hideShowSelector)
        }
        return () => window.removeEventListener('click', hideShowSelector)
    }, [showSelector])

   

    const otherValueSchema = Yup.object().shape({
        otherRelationship : Yup.string().EmptySpace().otherRelation().required(t("Other Relationship is required"))
    })

    const otherValueForm = useFormik({
        initialValues : initialFormValues,
        validationSchema:otherValueSchema,
        enableReinitialize: true,
        onSubmit: (values) =>{
            setEditData({...editData,otherRelationship:values.otherRelationship.trim()})
            setOpenChangeRelation(true)
        }
    })

    const editRelationship = (c) => {
        if (c !== undefined)
            if (c.value === 14) {
                setOpenOtherBox(true)
                setEditData({newValue:c,oldValue:relationship,caseId:caseId})
            } else {
                setEditData({ newValue: c, oldValue: relationship, caseId: caseId })
                setOpenChangeRelation(true)
            }
    }
    const cancelModal =()=>{
        otherValueForm.resetForm()
        setOpenOtherBox(false)
    }
    return (
        <div className="relative">
            <div className="cursor-pointer" onClick={() => setShowSelector(true)} title={t("Relationship")} aria-label={relation}>
                {/* <span className="absolute text-[9px] leading-none -top-3 -right-2.5 bg-tcolor text-white px-1 py-1 rounded-full">{relation}</span> */}
                {relation !== null ? <FlowText text={`(${relation})`} />:''}
            </div>
            <AnimatePresence>
                {showSelector &&
                    <motion.div role="listbox" tabIndex={0} variants={menuAnim} initial="hidden" animate="visible" exit="hidden" className={`absolute bg-bluegray-500 dark:bg-ldark py-2 w-48 rounded-lg text-left ${injectPopupClass}`} style={{ zIndex: 10, height: '160px', overflowY: 'auto' }}>
                        <div className="text-xs text-white pl-2">{t("Relationship")}</div>
                        <div className="pt-2">
                            {
                                relationships.map(c => (
                                    <div tabIndex={0} key={c.value}  role="option" aria-selected={relationship === c.name ? 'true' : 'false'} aria-label= {relationship === c.name ? `${c.name} selected` : `${c.name} not selected`} className="py-1 pl-4 pr-2 hover:bg-bluegray-600 text-white text-sm cursor-pointer flex items-center justify-between" onClick={() => editRelationship(c)}>
                                        <span>{c.name}</span>
                                        {(relationship === c.name || (!otehrCheckBox && c.value === 14)) && <MdCheck className="text-green-300" />}
                                        
                                    </div>
                                ))
                            }
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
            {
                createPortal(<Dialog title={t("Relationship change")} showDialog={openChangeRelation} onClose={() => setOpenChangeRelation(false)}>
                    <ChangeRelationship onClose={() => setOpenChangeRelation(false)} props={editData} />
                </Dialog>, document.body)
            }
            {
                createPortal(<Dialog title={t("Type Other")} showDialog={openOtherBox} onClose={() => cancelModal()}>
                    <div className="py-2 w-full">
                        <div className="px-4 mt-2">
                            <FieldInput label={t("Type a value")} autoComplete='off' fieldProps={otherValueForm.getFieldProps('otherRelationship')} fieldMeta={otherValueForm.getFieldMeta('otherRelationship')} fieldHelper={otherValueForm.getFieldHelpers('otherRelationship')} edit={true} dataLoaded={true} />
                        </div>
                        <div className="flex justify-between mt-8 mx-2">
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => cancelModal()}>{t("Cancel")}</button>
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => otherValueForm.handleSubmit()}>{t("Confirm")}</button>
                        </div>
                    </div>
                </Dialog>, document.body)
            }
        </div>
    )
}

export default RelationshipSelector