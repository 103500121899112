import { useEffect, useState, useMemo } from "react"
import { FaBell, FaBellSlash, FaSave } from 'react-icons/fa'

import { FiEdit3 } from "react-icons/fi"
import * as Yup from 'yup'
import API from "../../axios/API"
import FieldInput from '../..//components/snippets/FieldInput'
import FormCard from "../../components/snippets/FormCard"
import Switch from "../../components/snippets/Switch"
import { useFormik } from "formik"
import FlowText from "../../components/snippets/FlowText"
import PageLoader from "../CaseDetails/PageLoader"
import {useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setAlert } from "../../store/theme/actions"





const initialFormValues = {
    // events:[{
    email: ''
    // }]
}


const Notifications = ({ props, org }) => {
    
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(true)
    const [userDetails, setUserDetails] = useState()
    const [edit, setEdit] = useState(false)
    const [editIndex, setEditIndex] = useState()
    const [refresh, setRefresh] = useState(0)
    const [notificationEnabled,setNotificationEnabled] = useState('')
    const [emailValue,setEmailValue] = useState('')
    const [notificationId,setNotificationId] = useState('')
    const [index,setIndex] = useState()
    const { t } = useTranslation()
    const userDetail = useSelector(state =>state.auth.userDetails)
    const organization = useSelector(state => state.auth.organization)
    const dispatch = useDispatch()
    // const [formLoading, setFormLoading] = useState(false)
    // let orgId = localStorage.getItem('orgid')

    Yup.addMethod(Yup.string, 'validateEmail', function () {
        return this.test(t('validEmail'), t("Not valid"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            return r.test(value)
        })
    })
    useEffect(() => {
        setLoading(true)
        if (props && org) {
            API.get(`organizations/${props}/notifications`).then(notificationResp => {
                setEvents(notificationResp.data.body)
                setLoading(false)
            })
        } else if (props && !org) {
            API.get(`users/${props}/notifications`).then(notificationResp => {
                setEvents(notificationResp.data.body)
                setLoading(false)
            })
        } else {
            API.get('users').then(userResp => {
                if (userResp.status === 200) {
                    setUserDetails(userResp.data.body)
                    API.get(`users/${userResp.data.body.pplId}/notifications`).then(notificationResp => {
                        setEvents(notificationResp.data.body)
                        setLoading(false)
                    })
                }
            })
        }
    }, [refresh]) // eslint-disable-line

    const formData = useMemo(() => {
        return { ...initialFormValues, ...events }
    }, [events])

    const notificationFormSchema = Yup.object({
        // events:Yup.array().of(
        //     Yup.object().shape({
        //    email: Yup.string().validateEmail().required(t("Email is required")),

        //     })
        // )
})

    const notificationForm = useFormik({
        initialValues: formData,
        validationSchema: notificationFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            // setFormLoading(true)
            updateForm()
            // setFormLoading(false)
        
        }
    })

    const updateForm = () =>{
            let details = {
            "enabled": notificationEnabled,
            "emailAddress": emailValue,
            "notificationId": notificationId
        }
        var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(" .+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
        if(emailValue === null || emailValue === ''){
            dispatch(setAlert({title: t("Warning"), subtitle: t('Email is required'),type:'warning',active: true}))

        }else if(!regex.test(emailValue)){
            dispatch(setAlert({title: t("Warning"), subtitle: t('Not valid'),type:'warning',active: true}))

        }else{
             API.patch(`organizations/${props}/notifications`, details).then(res => {
            setEdit(false)
            setRefresh(refresh + 1)
            setEditIndex(index)
        })
        }
       
    }

    const setNotifications = (i, value, id, email) => {
        let details = {
            "enabled": value,
            "emailAddress": email,
            "notificationId": id
        }
        if (props && org) {
            if (value === true) {
                setEdit(true)
                setEditIndex(i)
            } else {
                let detail = {
                    "enabled": value,
                    "emailAddress": null,
                    "notificationId": id
                }
                API.patch(`organizations/${props}/notifications`, detail).then(res => {
                    setRefresh(refresh + 1)
                    setEdit(false)
                })
            }
        } else if (props && !org) {
            API.patch(`users/${props}/notifications`, details).then(res => {
                if (res.status === 200) {
                    setRefresh(refresh + 1)
                }
            }).catch(e => {
                console.log(e)
            })
        } else {
            API.patch(`users/${userDetails.pplId}/notifications`, details).then(res => {
                if (res.status === 200) {
                    setRefresh(refresh + 1)
                }
            }).catch(e => {
                console.log(e)
            })
        }
        setEvents(events.map((c, j) => {
            if (i === j && org ){
                c.notificationEnabled = value
            }else if( i !== j && c.email === null && org){
                c.notificationEnabled = false
            }
            return c
        }))
    }
    const editEmail = (i) => {
        setEdit(true)
        setEditIndex(i)
    }
    const saveNotifications = (i, c, email) => {
        setNotificationEnabled(c.notificationEnabled)
        setNotificationId(c.notificationId)
        setEmailValue(email)
        setIndex(i)
        notificationForm.submitForm()
    }

    return (
        <div className={org ? '' : "max-w-4xl"}>
            <FormCard title={t("Notifications")}>
                {
                    loading ? <PageLoader/> : events&& events.length === 0 ? <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There is no notifications to display')}/></div> :!org ?
                        events.map((c, i) => (
                            <div key={i} className="flex p-2 my-2 rounded-lg items-center justify-between hover:bg-bluegray-300 text-gray-700 dark:hover:bg-ldark dark:text-gray-200">
                                <div className="flex items-center">
                                    <div>
                                        {c.notificationEnabled && <FaBell size="20px" />}
                                        {!c.notificationEnabled && <FaBellSlash size="20px" />}
                                    </div>
                                    <div className="ml-2" tabIndex={0}>{c.notificationName}</div>
                                </div>
                                <div tabIndex={0}>
                                    {(organization && userDetail && userDetail.orgId === parseInt(organization))|| (userDetail.role.includes('dynamic_cm')) || (userDetail.role.includes('dynamic_admin')) ? <Switch arialabel={c.notificationEnabled ? `${c.notificationName} Enabled`: `${c.notificationName} Disabled`} value={c.notificationEnabled} setValue={value => setNotifications(i, value, c.notificationId, c.email)} />:''}
                                </div>
                            </div>
                        )) : events.map((c, i) => (
                            <div style={{ margin: 10 }}>
                                <div key={i} className="grid gap-12 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                                    <div className="flex items-center">
                                        <div>
                                            {c.notificationEnabled && <FaBell size="20px" />}
                                            {!c.notificationEnabled && <FaBellSlash size="20px" />}
                                        </div>
                                        <div className="ml-2" tabIndex={0}>{c.notificationName}</div>
                                    </div>
                                    <div tabIndex={0}>
                                        {((organization && userDetail && userDetail.orgId === parseInt(organization)) || (userDetail.role.includes('dynamic_admin'))) ? <Switch arialabel={c.notificationEnabled ? `${c.notificationName} Enabled`: `${c.notificationName} Disabled`} value={c.notificationEnabled} setValue={value => setNotifications(i, value, c.notificationId, c.email)} />:''}
                                    </div>
                                    <div {...c.email !== null ? {'tabIndex':0}:{} } >
                                        {!edit ? <div>{c.email !==null ? c.email : ''}</div> : (i === editIndex) ? <FieldInput ariaLabel={"Email is a required"} fieldHelper={notificationForm.getFieldHelpers(`${i}.email`)} fieldMeta={notificationForm.getFieldMeta(`${i}.email`)} fieldProps={notificationForm.getFieldProps(`${i}.email`)} edit={true} dataLoaded={true} /> : <FlowText text={c.email} />}
                                    </div>
                                    <div>
                                        {(!edit && c.notificationEnabled) ? 
                                            <button className={`h-8 w-8 bg-bluegray-500 dark:bg-bluegray-400 transition-all duration-200 rounded-full ring-4 ring-offset-2 ring-offset-blue-50 dark:ring-offset-mainbg ring-blue-50 dark:ring-mainbg outline-none focus:outline-none`} aria-label="Edit" onClick={() =>editEmail(i)}><FiEdit3 size="16px" className="m-auto text-white" title={t('Edit')} /></button> :
                                            ((i === editIndex) && c.notificationEnabled) ? <button className={`h-8 w-8 bg-bluegray-400 dark:bg-bluegray-500 transition-all duration-200 rounded-full ring-4 ring-offset-2 ring-offset-blue-50 dark:ring-offset-mainbg ring-blue-50 dark:ring-mainbg outline-none focus:outline-none`} aria-label="Save" onClick={() => saveNotifications(i, c, notificationForm.values[i].email)}><FaSave size="16px" className="m-auto text-white dark:text-gray-800" title={t('Save')} /></button> : ''}
                                    </div>
                                </div>
                            </div>
                        ))
                }
            </FormCard>
        </div>
    )
}

export default Notifications