
import { FaExclamationTriangle, FaEye, FaEyeSlash } from 'react-icons/fa'
import { AnimatePresence, motion } from 'framer-motion'
import { TextAnimateX } from '../../commons/anims'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import FlowText from './FlowText'
import NumberFormat from 'react-number-format'
import FieldSupportDatePicker from './FieldSupportDatePicker'
import { createPortal } from 'react-dom'

const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
]

const PcspInput = ({ type = 'text', customErrmsg,fieldProps, fieldMeta, fieldHelper, err = false,label, autoComplete = 'on', placeholder, edit, loading = false, dataLoaded = false, ml, length, onChange, maxDate, minDate,disabled=false,focus,hideData = false, ariaLabel }) => {


  
    const input = useRef(null)
    const wrapRef = useRef(null)
    const [showError, setShowError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [dateFocus, setDateFocus] = useState(false)
    const [pos, setPos] = useState({ width: 0, top: 0, left: 0 })
    const [previousDateFocus, setPreviousDateFocus] = useState(null)
    const [allowFocus,setAllowFocus] = useState(true)
    const calendareRef = useRef(null)

    const handleFocus = (ev) => {
        if(!allowFocus){
            return 
        }
        if (type === 'date' || type === 'datetime' || type === 'year') {
            ev.stopPropagation()
            if(disabled) return
            let e = wrapRef.current.getBoundingClientRect()
            if (type === 'datetime' && e.width < 330) e.width = 330
            let o = { width: `${e.width}px`, left: `${e.x}px` }
            if ((e.top + e.height + 270) > window.innerHeight) {
                o.bottom = `${window.innerHeight - e.top}px`
            } else {
                o.top = `${(e.top + e.height)}px`
            }
            setPos(o)
            setDateFocus(true)
        } else if (input.current !== null && type !== 'phone' && type !== 'price')
            input.current.focus()
    }
    const inpuType = useMemo(() => {
        if (type === 'password') return showPassword ? 'text' : 'password'
        return type
    }, [type, showPassword])
    const dateValue = useMemo(() => {
        let date;
        if (type === 'date') {
            date = new Date(fieldProps.value)
            if (!isNaN(date.getDate()))
                return `${months[date.getMonth()]}-${date.getDate() > 9 ? date.getDate() : ('0' + date.getDate())}-${date.getFullYear()}`
        } else if (type === 'datetime') {
            date = new Date(fieldProps.value)
            if (!isNaN(date.getDate()))
                return `${months[date.getMonth()]}-${date.getDate() > 9 ? date.getDate() : ('0' + date.getDate())}-${date.getFullYear()} ${date.getHours() > 9 ? date.getHours() : ('0' + date.getHours())}:${date.getMinutes() > 9 ? date.getMinutes() : ('0' + date.getMinutes())}`
        }else if(type==='year'){
            date = new Date(fieldProps.value)
            if (!isNaN(date.getDate()))
                return `${date.getFullYear()}`
        }
        return ''
    }, [fieldProps.value, type, edit])// eslint-disable-line

    const inputChange = (e) => {
        if (type === 'file' && typeof onChange === 'function') {
            if (fieldHelper && typeof fieldHelper.setValue === 'function') {
                fieldHelper.setValue(e.target.files)
                fieldHelper.setTouched(true)
            }
            setTimeout(() => {
                onChange(e.target.files[0])
            }, 100)
        }else if(typeof onChange === 'function'){
            onChange(e.target.value)
            // setTimeout(() => {
            // }, 100)
        }
    }
    const onfocus = (e) => {
        if(typeof focus === 'function') focus(e.target.value)
     
    }
    const toggleError = (val) => setShowError(val)
    const hideDate = (ev) => {
        if((ev!== undefined) && (ev.target.classList.contains('daref') || ev.target.closest('.daref'))){

        }else{
            setDateFocus(false); 
            if(typeof focus === 'function') focus()
        }
    }
    const setDate = date => {
        let value = `${date.getFullYear()}/${date.getMonth() > 8 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))}/${date.getDate() > 9 ? date.getDate() : ('0' + date.getDate())}`
        if (typeof fieldHelper.setValue === 'function') fieldHelper.setValue(value)
        setTimeout(() => {
            hideDate()
        }, 200)
    }
    const setDateTime = date => {
        let value = `${date.getFullYear()}/${date.getMonth() > 8 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))}/${date.getDate() > 9 ? date.getDate() : ('0' + date.getDate())} ${date.getHours() > 9 ? date.getHours() : ('0' + date.getHours())}:${date.getMinutes() > 9 ? date.getMinutes() : ('0' + date.getMinutes())}`
        if (typeof fieldHelper.setValue === 'function') fieldHelper.setValue(value)
        // setTimeout(() => {
        //     hideDate()
        // }, 200)
    }


    useLayoutEffect(() => {
        if (dateFocus) {
            setTimeout(() => {
                document.body.addEventListener('click', hideDate)
            }, 200)
        }
        return () => { 
        document.body.removeEventListener('click', hideDate)}
        
    }, [dateFocus]) // eslint-disable-line
    useEffect(() => {
        if (type === 'file' && fieldMeta && fieldMeta.touched === false) {
            input.current.value = null
            fieldHelper.setValue('')
        }
    }, [fieldMeta.touched, fieldProps.value]) // eslint-disable-line

    const disablefield = useMemo(() => {
        let data = {}
        if(hideData) {
            data['data-private'] = 'lipsum'
        }
        return data
    },[hideData])

    useEffect(() => {
        if (calendareRef && dateFocus) {
            setPreviousDateFocus(document.activeElement);
            if (calendareRef !== null && calendareRef.current !== null) {
                calendareRef.current.focus();
            }
        } else {
            previousDateFocus && previousDateFocus.focus()
        }
    }, [calendareRef,dateFocus]);
    
    const closeDate = (ev)=>{
        if(ev === undefined){
            setAllowFocus(false)
            setTimeout(()=>{
                setAllowFocus(true)
            },300)
        }
        if(ev === undefined || !ev.target.closest('.react-datepicker')){
            hideDate()
                if(calendareRef !== null && calendareRef.current !== null){
                    calendareRef.current.removeEventListener('click',closeDate)
                }
        }
    }

    return (
        <div ref={wrapRef} className={`group flex flex-col ${edit? 'ring-1 ring-blue-300 dark:ring-white-600':''} ${type === 'date' ? 'cursor-pointer' : ''} px-2 pt-1 rounded-lg focus-within:ring-tcolor dark:focus-within:ring-tcolor transition-all duration-100 ${loading ? 'animate-pulse' : ''}`} onClick={handleFocus}>
            <span className={`${edit ? 'ml-1' : ''} text-xs text-gray-500 dark:text-gray-400`}><FlowText text={label} direction="x" /></span>
            <div className="relative">
                {edit && dataLoaded &&
                    <>
                        {/* format="(###) #### ####" */}
                        {
                            type === 'phone' ?
                                <NumberFormat {...disablefield} disabled={disabled} className={`pb-1.5 pl-3 bg-transparent pr-10 w-full outline-none`} ref={input} {...fieldProps} displayType={'input'} format="###-###-####" aria-label={ariaLabel} />
                                
                                : type === 'date' ?
                                    <div className="pl-3 pr-10 cursor-pointer">
                                        {dateValue === '' || dateValue === null ? 'MM/DD/YYYY' : dateValue}
                                        <input disabled={disabled}  className="w-0 h-0" onFocus={handleFocus} onBlur={onfocus} aria-label={ariaLabel}/>
                                        {
                                            dateFocus &&
                                            createPortal(<div ref={calendareRef} tabIndex={-1} aria-modal="true" className="fixed left-0 right-0 bottom-0 top-0">
                                                <div className="fixed z-[1000]" style={pos}>
                                                <FieldSupportDatePicker value={fieldProps.value} setValue={date => setDate(date)} maxDate={maxDate} minDate={minDate} currentPicker={() => closeDate()}/>
                                                </div>
                                            </div>, document.body)
                                        }
                                    </div>
                                    : type === 'datetime' ?
                                        <div className="pl-3 pr-10 cursor-pointer">
                                            {dateValue === '' || dateValue === null ? 'MM/DD/YYYY HH:MM' : dateValue}
                                            <input className="w-0 h-0" onFocus={handleFocus} onBlur={onfocus} aria-label={ariaLabel}/>
                                            {
                                                dateFocus &&
                                                createPortal(<div ref={calendareRef} tabIndex={-1} aria-modal="true" className="fixed left-0 right-0 bottom-0 top-0 z-[2]">
                                                <div className="fixed z-[1000]" style={pos}>
                                                    <FieldSupportDatePicker showTime={true} value={fieldProps.value} setValue={date => setDateTime(date)} maxDate={maxDate} minDate={minDate} currentPicker={() => closeDate()}/>
                                                </div>
                                                </div>, document.body)
                                            }
                                        </div>
                                        : type === 'year' ?
                                            <div className="pl-3 pr-10 cursor-pointer">
                                                {dateValue === '' || dateValue === null ? 'yyyy' : dateValue}
                                                <input className="w-0 h-0" onFocus={handleFocus} aria-label={ariaLabel}/>
                                                {
                                                    dateFocus &&
                                                    createPortal(<div ref={calendareRef} tabIndex={-1} aria-modal="true" className="fixed z-[1000]" style={pos}>
                                                        <FieldSupportDatePicker disabled={disabled} showYear={true} value={fieldProps.value} setValue={date => setDate(date)} maxDate={maxDate} minDate={minDate} currentPicker={() => closeDate()}/>
                                                    </div>, document.body)
                                                }
                                            </div>
                                            : type === 'textarea' ?
                                                <textarea className={`pb-1.5 pl-3 bg-transparent pr-10 w-full h-48 outline-none`} ref={input} {...fieldProps} aria-label={ariaLabel}/>
                                                : type === 'file' ?
                                                    <input ref={input} className={`pb-1.5 pl-3 bg-transparent pr-10 w-full outline-none`} type={inpuType} placeholder={placeholder} disabled={loading} maxLength={ml ? `${length}` : ''} onChange={inputChange} aria-label={ariaLabel}/>
                                                    : type === 'price' ?
                                                        <NumberFormat className={`pb-1.5 pl-3 bg-transparent pr-10 w-full outline-none`} ref={input} {...fieldProps} displayType={'input'} decimalScale={2} aria-label={ariaLabel}/>
                                                        :
                                                        <input {...disablefield} ref={input} className={`pb-1.5 pl-3 bg-transparent pr-10 w-full outline-none`} type={inpuType} {...fieldProps} autoComplete={autoComplete} placeholder={placeholder} disabled={loading || disabled} maxLength={ml ? `${length}` : ''} onKeyUp={inputChange} aria-label={ariaLabel}/>

                        }
                        
                    </>
                }
                {
                    !dataLoaded &&
                    <div className="w-3/4 h-2 my-2 rounded-lg animate-pulse bg-gray-300"></div>
                }
                {!edit && dataLoaded &&
                    <div className={`pb-2 h-8 ${fieldProps.value !== null && fieldProps.value !== undefined ? '' : 'text-gray-500 line-through'}`}>{(type === 'date' ? dateValue : (fieldProps.value !== null && fieldProps.value !== undefined ? fieldProps.value.toString() : '')) || ''}</div>
                }
                {edit &&
                    <span className={`absolute ${type === 'password' ? 'right-2' : 'right-4'} -top-1 text-xl flex`}>
                        {fieldMeta.error && fieldMeta.touched && <FaExclamationTriangle className="text-red-500 cursor-pointer" onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} />}
                        {err && <FaExclamationTriangle className="text-red-500 cursor-pointer" onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} />}
                        {type === 'password' && !showPassword && <FaEye className="text-gray-500 cursor-pointer ml-2" onClick={() => setShowPassword(true)} />}
                        {type === 'password' && showPassword && <FaEyeSlash className="text-gray-500 cursor-pointer ml-2" onClick={() => setShowPassword(false)} />}
                    </span>
                }
                <AnimatePresence>
                    {fieldMeta.error && showError  &&
                        <motion.span variants={TextAnimateX} initial="hidden" animate="visible" exit="hidden" className={`absolute -top-1 ${type === 'password' ? 'right-16' : 'right-10'} inline-block bg-gray-300 dark:bg-ldark py-0.5 px-2 rounded text-sm text-red-500`}>
                            {fieldMeta.error}
                        </motion.span>
                    }
                    {err && showError &&
                        <motion.span variants={TextAnimateX} initial="hidden" animate="visible" exit="hidden" className={`absolute -top-1 ${type === 'password' ? 'right-16' : 'right-10'} inline-block bg-gray-300 dark:bg-ldark py-0.5 px-2 rounded text-sm text-red-500`}>
                            {customErrmsg}
                        </motion.span>
                    }
                </AnimatePresence>
            </div>
        </div>
    )
}


export default PcspInput