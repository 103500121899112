import { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { FaRegListAlt } from "react-icons/fa"
import Dialog from "../../components/snippets/Dialog"
import Tab from "../../components/snippets/Tabs/Tab"
import TabButton from "../../components/snippets/Tabs/TabButton"
import TabButtonGroup from "../../components/snippets/Tabs/TabButtonGroup"
import TabGroup from "../../components/snippets/Tabs/TabGroup"
import AddService from "./AddService"
import AdHoc from "./AdHoc"
import FindHelp from "./FindHelp"
import SearchService from "./SearchService"
// import { useSelector } from "react-redux"

const ServiceSelector = ({ active, categories, adhoc, onClose, onAdd,onAddCategoryId,careplanId }) => {
    const [title, setTitle] = useState('')
    const [category, setCategory] = useState(0)
    const [type, setType] = useState(0)
    const [tab, setTab] = useState(0)
    const { t } = useTranslation()
    const focusRef = useRef(null)
    // const userDetails = useSelector(state=>state.auth.userDetails)
    const auntberthInd =JSON.parse(localStorage.getItem('userDetails'))
    const [os,setOs] = useState('')
    const [reRender,setReRender] = useState(-1)
    
    useEffect(() => {
        if(category === 0) setTitle(t('Select Service Category'))
        else if(type === 0) setTitle(t('Select Service Type'))
        else setTitle(t('Search'))
    }, [category, type]) // eslint-disable-line
    useEffect(() => {
        setCategory(0)
        setType(0)
        setTab(0)
    }, [active])

    useEffect(() => {
        if (focusRef.current) {
            focusRef.current.focus();
        }
        setReRender(reRender+1)
    }, [category, type])
    
    useEffect(() => {
        const platform = navigator.platform.toLowerCase();
        const macOSPlatforms = ['macintosh', 'macintel', 'macppc', 'mac68k'];
        if (macOSPlatforms.some(platformType => platform.includes(platformType))) {
          setOs('mac');
        } else {
          setOs('other');
        }
      }, []);
    
    return (
        <Dialog showDialog={active} title={title} onClose={onClose} injectClass="min-w-[70vw]" reRender={reRender}>
            <div className="flex flex-col gap-4 w-full">
                { active && category === 0 ?
                    <div className="flex flex-col gap-2 mx-3 text-sm">
                        {
                            Object.keys(categories).map(c => (
                                <button key={c} className="flex bg-none gap-2 items-center ring-1 ring-gray-400 dark:ring-gray-500 p-2 rounded hover:bg-tcolor hover:text-black cursor-pointer" onClick={() => setCategory(c)}>
                                    <FaRegListAlt />
                                    {categories[c].categoryName}
                                </button>
                            ))
                        }
                    </div>
                : active && type === 0 ?
                    <div className="flex flex-col gap-2 mx-3 text-sm">
                        {
                            Object.keys(categories[category].goalTypeDetails).map(c => (
                                <button key={c} className="flex bg-none gap-2 items-center ring-1 ring-gray-400 dark:ring-gray-500 p-2 rounded hover:bg-tcolor hover:text-black cursor-pointer" onClick={() => setType(c)}>
                                    <FaRegListAlt />
                                    {categories[category].goalTypeDetails[c].serviceTypeName}
                                </button>
                            ))
                        }
                    </div>
                : active ?
                    <div role="document">
                        <div className="flex justify-center">
                            <TabButtonGroup>
                                <TabButton index={0} value={tab} setValue={val => setTab(val)}>
                                    {t('Search')}
                                </TabButton>
                                <TabButton index={1} value={tab} setValue={val => setTab(val)}>
                                    {t('Add Resource')}
                                </TabButton>
                                <TabButton index={2} value={tab} setValue={val => setTab(val)}>
                                    {t('CG Specific')}
                                </TabButton>
                                { auntberthInd.auntBerthaInd === false ? '' : <TabButton index={3} value={tab} setValue={val => setTab(val)}>
                                    {t('findhelp')}
                                </TabButton>
                                   }
                            </TabButtonGroup>
                        </div>
                        <div>
                            <TabGroup value={tab}>
                                <Tab index={0} value={tab}>
                                    <SearchService careplanId={careplanId} categoryId={categories[category].goalTypeDetails[type].categoryId} serviceType={categories[category].goalTypeDetails[type].servieType} serviceId={categories[category].goalTypeDetails[type].serviceId} caseId={categories[category].goalTypeDetails[type].caseId} onAdd={val => onAdd(category, type, val)} onAddCategoryId={val=>onAddCategoryId(val)}/>
                                </Tab>
                                <Tab index={1} value={tab}>
                                    <AddService careplanId={careplanId} categoryId={categories[category].goalTypeDetails[type].categoryId} serviceType={categories[category].goalTypeDetails[type].servieType} serviceId={categories[category].goalTypeDetails[type].serviceId} caseId={categories[category].goalTypeDetails[type].caseId} onAdd={val => onAdd(category, type, val)} onAddCategoryId={val=>onAddCategoryId(val)}/>
                                </Tab>
                                <Tab index={2} value={tab}>
                                    <AdHoc categoryId={categories[category].goalTypeDetails[type].categoryId} serviceType={categories[category].goalTypeDetails[type].servieType} serviceId={categories[category].goalTypeDetails[type].serviceId} caseId={categories[category].goalTypeDetails[type].caseId} onAdd={val => onAdd(category, type, val)} onAddCategoryId={val=>onAddCategoryId(val)} careplanId={careplanId}/>
                                </Tab>
                                <Tab index={3} value={tab}>
                                    <FindHelp careplanId={careplanId} categoryId={categories[category].goalTypeDetails[type].categoryId} serviceType={categories[category].goalTypeDetails[type].servieType} serviceId={categories[category].goalTypeDetails[type].serviceId} caseId={categories[category].goalTypeDetails[type].caseId} onAdd={val => onAdd(category, type, val)} onAddCategoryId={val=>onAddCategoryId(val)}/>
                                </Tab>
                            </TabGroup>
                        </div>
                    </div>
                :   null
                }
                <div className="flex justify-between mt-2">
                    { category === 0 ? 
                        <div></div>
                    : type === 0 ?
                        <button tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded text-gray-600 dark:text-white font-bold hover:opacity-90 hover:ring-1 hover:ring-tcolor" onClick={() => setCategory(0)}>{t('Back')}</button>
                    :
                        <button tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded text-gray-600 dark:text-white font-bold hover:opacity-90 hover:ring-1 hover:ring-tcolor" onClick={() => setType(0)}>{t('Back')}</button>
                        
                    }
                        {(os === 'other' ? <div tabIndex={0} aria-label="Cancel" className="flex items-center relative py-1 px-4 ml-2 rounded text-red-700 dark:text-red-300 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={onClose}>{t('Cancel')}</div>: <button tabIndex={0} aria-label="Cancel" className="flex items-center relative py-1 px-4 ml-2 rounded text-red-700 dark:text-red-300 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={onClose}>{t('Cancel')}</button>)}
                </div>
            </div>
        </Dialog>
    )
}

export default ServiceSelector