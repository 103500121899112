import { useCallback, useMemo, useState ,useEffect, useRef} from 'react'
import DataTable, { createTheme } from 'react-data-table-component'

import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch, useSelector } from 'react-redux'

import { FaUserMinus,FaPhoneAlt,FaEnvelope } from 'react-icons/fa'
import InlineTextField from '../../components/snippets/InlineTextField'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import {setAlert} from '../../store/theme/actions'
import API from '../../axios/API'
import Dialog from '../../components/snippets/Dialog'
import FlowText from '../../components/snippets/FlowText'
import { useTranslation } from 'react-i18next'
import { createPortal } from 'react-dom'

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const RemovedUserlist = () => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [removedAdminList, setRemovedAdminList] = useState([]);
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(false);
    const [reviveuser,setReviveUser] = useState([])
    const dispatch = useDispatch()
    const [pplId,setPplId] = useState()
    const [revivePopup,setRevivePopup] = useState(false)
    const organization = useSelector(state => state.auth.organization)
    const { t } = useTranslation()
    const userDetails = useSelector(state => state.auth.userDetails)
    let orgId = localStorage.getItem('orgid')
    const [perPage, setPerPage] = useState(10);
    const [sizePage] = useState([10]);
    const [totalRows, setTotalRows] = useState(0);
    const [column,setColumn] = useState()
    const [sortDirection,setSortDirection] = useState()
    const [statusFilter, setStatusFilter] = useState()
    const [triggerAll, setTriggerAll] = useState(false)
    const [records,setRecords] = useState(false)
    const reviveRef = useRef(null)
    const [previousActiveFocus, setPreviousActiveFocus] = useState(null);
  
    useEffect(() => {
        setLoading(true);
        let apiPath = `page=${page}&size=${perPage}`
        API.get(`organizations/${organization}/users?status=removed&${apiPath}`).then((res) => {
            if(res.data._embedded && res.status === 200){
                setTotalRows(res.data.page.totalElements)
                setRemovedAdminList(res.data._embedded.immutableUserResponseList)
            }else{
                setRemovedAdminList([]);
            }
        }).catch(e => {
            console.log(e)
        }).finally(()=>setLoading(false))
        previousActiveFocus && previousActiveFocus.focus();
    },[organization,triggerAll])// eslint-disable-line

    const reviveUser = ()=>{
        API.patch(`users/${pplId}:revive?orgId=${orgId}`).then(res=>{
            setReviveUser(res);
            if(res.status === 200 && res.data.status === false){
                dispatch(setAlert({title:t('Warning'),subtitle:res.data.errorMessage,type:'warning',active:true}))
            }
             else if(res.status ===200){
                dispatch(setAlert({title:t('Success'),subtitle:t('User Revived Successfully'),type:'success',active:true}))
                setTriggerAll(!triggerAll);
            }else if(res.status === 400){
                dispatch(setAlert({title:t('Error'),subtitle:t('Invalid Id'),type:'error',active:true}))
            }else if(res.status === 404){
                dispatch(setAlert({title:t('Error'),subtitle:t('User Not Found'),type:'error',active:true}))
            }
        }).catch(e=>{
            console.log(e)
            dispatch(setAlert({ title: t('Error'),subtitle:t("Something went wrong"),active:true,type:'error'}))
        }).finally(()=>setRevivePopup(false))
    }
    const colums = useMemo(() => {
        return [
            { name: t('Name'), selector:'firstName', cell: row =><div aria-label={`Name ${row.firstName} ${row.lastName}`} tabIndex={0}>{row.firstName} {row.lastName}</div>, grow: 1, sortable: true,sortField:'SORT_NAME' },
            // { name: t('User Name'), selector: 'userName', grow:1,sortable: true },
            // { name: t('Phone'), selector: 'phone',grow:1, sortable: true },
            {name:t('Phone'),selector:'phone', cell: row=>{return <div style={{display:'flex',fontSize:'13px',marginTop:'15px'}}>{row.phone === "" || row.phone === null ? '':<a href={`tel: +1${row.phone.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor p-2 rounded-lg text-white" title={t('Call')}>
            <FaPhoneAlt size="10px" style={{margin:'auto'}}/></a>}&nbsp;&nbsp;<span tabIndex={0} aria-label={`phone ${row.phone}`}>{row.phone}</span></div>},grow:1,sortable: true,sortField:'SORT_PHONE'},
            // { name: t('E-Mail'), selector: 'email',grow:1, sortable: true },
            {name:t('E-Mail'),selector:'email', cell:row=>{return <div style={{display:'flex',fontSize:'13px',marginTop:'15px'}}>{row.email === "" || row.email === null ? '' :<a href={`mailto:${row.email} `} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Mail')}>
            <FaEnvelope size="10px" style={{margin:'auto'}} />
        </a>}&nbsp;&nbsp;<span tabIndex={0} aria-label={`Email ${row.email}`}>{row.email}</span> </div>},grow:1,sortable: true,sortField:'SORT_EMAIL'},
            {name: t('Action'), button: true,grow:1, cell: row => <button aria-label='Revive' onClick={()=>((organization && userDetails && userDetails.orgId === parseInt(organization))||( organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin'))) ? openRevivePopup(row.pplId):''}><FaUserMinus size="20px" title={t("Revive")} className="text-red-600 dark:text-red-300" /></button>},
        ]
    }, [reviveuser])// eslint-disable-line
    const SubHeader = useMemo(() => (
        <div>
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} onEnter={() => searchRecords()} onClick={() => searchRecords()} onClose={() => closeFilter()} />
        </div>
    ), [filter]) // eslint-disable-line
    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return removedAdminList.filter(c => filter === '' || filter === null || filterSplit)
    }, [removedAdminList,filter])/// eslint-disable-line
    const ExpandComponent = useCallback(row => {
        return (
            
            <div className="bg-bluegray-300 dark:bg-ldark" key={row.data.caseId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>
     
        )
    }, [])
    const openRevivePopup=(values)=>{
        reviveRef.current?.focus()
        setPplId(values)
        setRevivePopup(true)
    }
    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    })
    useEffect(() => {
        const element = document.querySelectorAll('[id="column-undefined"]');
        if(element!=null){
            if (element) {
                element.forEach((e,i) => {
                    e.setAttribute('id','header_'+i)
                })
            }
        }
    })

    const closeFilter = () => {
        setPage(1)
        setStatusFilter()
        setColumn()
        setSortDirection()
        // changeStatus('All Status')
        setTriggerAll(!triggerAll)
    }

    const closeFilterStatus = () => {
        setRecords(false)
        setPage(1)
        setColumn()
        setSortDirection()
        setFilter('')
        setTriggerAll(!triggerAll)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setRemovedAdminList([])
        setPerPage(sizePage);
        let apiPath = `page=${page}&size=${newPerPage}`
        API.get(`organizations/${organization}/users?status=removed&${apiPath}`).then((res) => {
            if (res.status === 200) {
                setTotalRows(res.data.page.totalElements)
                setPerPage(res.data.page.size)
                setRemovedAdminList(res.data._embedded.immutableCaseList)
            }
        })
        setLoading(false);
        // sizeHandling(newPerPage)
    };

    if ((!loading && (filteredcasedetails.length >= 10 || removedAdminList.length >= 10)) || (!loading && (filteredcasedetails.length >= 10 || removedAdminList.length >= 10))) {
        if (page > 1) {
            // document.getElementById('pagination-next-page')?.removeAttribute('disabled')
            document.getElementById('pagination-first-page')?.removeAttribute('disabled')
            document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
                setPage(1)
            })
        }
        document.getElementById('pagination-next-page')?.addEventListener(('click'), () => {
            setPage(page + 1)
        })
        if (page > 1) {
            document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
            document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
                setPage(page - 1)
            })
        }
    }  else if (!loading && page > 1 && filteredcasedetails.length < 10 && removedAdminList.length < 10) {
        document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
        document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
            setPage(page - 1)
        })
        document.getElementById('pagination-first-page')?.removeAttribute('disabled')
        document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
            setPage(1)
        })
    } else if (filteredcasedetails.length < 10 && removedAdminList.length < 10 && !loading) {
        document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    }else {
        document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    }

    const searchRecords = () => {
        if (filter.length > 0) {
            if (statusFilter !== 'All Status' && statusFilter !== undefined) {
                API.get(`organizations/${organization}/users?status=removed&page=1&size=${perPage}&status=${statusFilter}&search=${filter}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                        setRemovedAdminList([])
                        setRemovedAdminList(res.data._embedded.immutableUserResponseList)
                        setTotalRows(res.data.page.totalElements)
                    }else{
                        setRecords(true)
                        setRemovedAdminList([])
                    }
                })
            } else{
                API.get(`organizations/${organization}/users?status=removed&page=1&size=${perPage}&search=${filter}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        // setPage(1)
                        setRemovedAdminList([])
                        setRemovedAdminList(res.data._embedded.immutableUserResponseList)
                        setTotalRows(res.data.page.totalElements)
                    }else{
                        setRecords(true)
                        setRemovedAdminList([])
                    }
                })
            }
        } 
        else  {
            if(statusFilter && statusFilter !== 'All Status'){
                let Apipath = statusFilter ? `page=1&size=${perPage}&status=${statusFilter}` : `page=${page}&size=${perPage}`
                API.get(`organizations/${organization}/users?status=removed&${Apipath}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                        setRemovedAdminList(res.data._embedded.immutableUserResponseList)
                        setTotalRows(res.data.page.totalElements)
                    }else{
                        setRecords(true)
                        setRemovedAdminList()
                    }
                })
            }
          
        }
        if (res.data._embedded) {
            setRecords(false)
        }
        else {
            setRecords(true)
            setRemovedAdminList([])
        }
    }
 
    const handleSort = async (column, sortDirection) => {
        setColumn(column.sortField)
        setSortDirection(sortDirection.toUpperCase())
        let apiPath = `page=1&size=${perPage}&sort=${column.sortField}`;        
        API.get(`organizations/${organization}/users?status=removed&${apiPath},${sortDirection.toUpperCase()}`).then((res) => {
            if (res.status === 200) {
                setRemovedAdminList(res.data._embedded.immutableUserResponseList)
                setTotalRows(res.data.page.totalElements)
            }
        })
        //   setData(remoteData);
    };

    useEffect(()=>{
        if (revivePopup) {
            setPreviousActiveFocus(document.activeElement);
            reviveRef.current?.focus();
          } else {
            previousActiveFocus && previousActiveFocus.focus();
          }
    },[revivePopup])

    return (
        <div>
            {
                loading ? 
                <DatatableLoader /> :
                <div className=" mt-4 p-2 bg-bluegray-200 dark:bg-ddark rounded-lg">
                <DataTable title="casedetails" columns={colums} data={filteredcasedetails} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination paginationServer={true} noHeader highlightOnHover  selectableRowsHighlight persistTableHead subHeader onSort={handleSort} 
                sortServer paginationRowsPerPageOptions={sizePage} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} noDataComponent={t('There are no records to display')} />:
                {/* <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div> */}
                {records ?<div className='flex justify-center'> <span onClick={() => closeFilterStatus()}><button aria-label="Back to All Cases" className='bg-tcolor flex px-6 py-0.5 mr-3 mt-3 items-center text-sm'>{t("Click here to RemovedUser List")}</button> </span></div> : ''}

                </div>
            }
            {
               revivePopup && createPortal(<div role="dialog"><div ref={reviveRef} role="dialog" tabIndex={-1} aria-modal="true"></div> 
                <Dialog title={t("Revive User")} showDialog={revivePopup} onClose={() => setRevivePopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2" tabIndex={0}>
                        {t("Are you sure you want to revive this user")}
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <span tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setRevivePopup(false)}>{t("No")}</span>
                        <span tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => reviveUser()}>{t("Yes")}</span>
                    </div>
                </div>
            </Dialog></div>,document.body)
            }
        </div>
    )
}

export default RemovedUserlist