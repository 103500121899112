import { FaCheck, FaExclamationTriangle } from 'react-icons/fa'

import React, { useState,useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { TextAnimateX } from '../../commons/anims'

const ReferrerCheckbox = ({ label, fieldProps, fieldMeta, fieldHelper, edit, loading = false, dataLoaded = false, onChange,ariaLabel,ariachecked }) => {

    const input = useRef('input')
    const [showError, setShowError] = useState(false)

    const toggleValue = () => {
        if (!edit || loading) return
        let v = !fieldProps.value
        fieldHelper.setValue(v)
        if(typeof onChange === 'function') onChange(v)
    }
    const toggleError = (val) => setShowError(val)

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (!edit || loading) return
            let v = !fieldProps.value
            fieldHelper.setValue(v)
            if(typeof onChange === 'function') onChange(v)
        }
      };
    return (
        <div role="checkbox" aria-checked={ariachecked} aria-label={ariaLabel} ref={input} tabIndex='0' onKeyDown={handleKeyDown} className={`group relative flex items-center justify-between ${edit ? 'ring-1 ring-gray-300 dark:ring-gray-600 py-3.5' : 'py-1.5'} ${edit && !loading ? 'hover:cursor-pointer hover:bg-opacity-50' : ''} pl-4 pr-2 rounded-lg transition-all duration-100 ${loading ? 'animate-pulse' : ''}`}>
            <div>
                {
                    !dataLoaded ?
                        <span className="w-3 h-3 my-2 rounded-sm animate-pulse bg-gray-300 inline-block align-middle"></span>
                    :
                        <span className={`${fieldProps.value ? 'ring-1' : 'ring-2'} ring-bluegray-500 ${edit ? 'dark:ring-white' : 'dark:ring-gray-400'} w-3 h-3 inline-block align-middle relative`} onClick={toggleValue}>
                            {fieldProps.value ? <FaCheck className="absolute -top-1 text-green-500 text-lg" /> : null}
                        </span>
                }
                <label className="ml-3 text-gray-600 dark:text-gray-400 font-medium text-sm font-bold" htmlFor={fieldProps.name}>{label}</label>
            </div>
            <div>
                {edit &&
                    <span className={`absolute right-6 top-6 text-xl flex`}>
                        {fieldMeta.error && fieldMeta.touched && <FaExclamationTriangle className="text-red-500 cursor-pointer" onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} />}
                    </span>
                }
                <AnimatePresence>
                    {fieldMeta.error && showError &&
                        <motion.span variants={TextAnimateX} initial="hidden" animate="visible" exit="hidden" className={`absolute top-6 right-12 inline-block bg-gray-300 dark:bg-ldark py-0.5 px-2 rounded text-sm text-red-500`}>
                            {fieldMeta.error}
                        </motion.span>
                    }
                </AnimatePresence>
            </div>
        </div>
    )
}

export default ReferrerCheckbox
