import React, { useEffect, useRef } from 'react'
import { MdClose } from 'react-icons/md'
import {FiSearch} from 'react-icons/fi'
import FieldLoader from './FieldLoader'

const InlineTextField = ({ label, injectClass='', loading, value, setValue, onEnter,ml,length ,focus,blur,autoFocus=false,onClose,onClick,ariaLabel='',showSearch=true}) => {
    const inputElem = useRef(null)
    const chgValue = (e) => {
        if(typeof setValue === 'function') setValue(e.target.value)
    }
    const focusInput = () => inputElem.current.focus()

    const keyPress = (e) => {
       if (e.keyCode == 8 && (value === '' || value === null)) {
            closeFunction()
       }
        if(e.key === 'Enter') {
            if(onEnter !== undefined) onEnter()
        }
    }
    const onFocus = (e) => {
        if(typeof focus === 'function') focus(e.target.value)
       
    }
    const onBlur = (e) => {
        if(typeof blur === 'function') blur(e.target.value)
       
    }

    const close = () => {
        if(typeof onClose === 'function') onClose()
    }

    const search =() =>{
        if(onClick !== undefined) onClick()
    }

    const closeFunction=()=>{
        setValue('')
        close()
    }
    const  SearchFunction=()=>{
        search()
    }
    return (
        <div className={`relative ring-1 focus-within:ring-2 ring-gray-600 focus-within:ring-tcolor px-2 py-0.5 rounded-lg ${loading ? 'hover:cursor-wait' : 'hover:cursor-pointer'} flex items-center ${injectClass} ${loading ? 'animate-pulse' : ''}`} onClick={ focusInput }>
            <span className="text-gray-600 dark:text-gray-400 text-sm mr-3 whitespace-nowrap">{ label }</span>
            <div className="w-full">
                <input disabled={loading} autoFocus={autoFocus} ref={ inputElem } className={`outline-none flex-grow text-gray-600 dark:text-white font-medium rounded-lg px-3 py-0.5 focus:text-black bg-transparent w-full ${loading ? 'hover:cursor-wait' : ''}`} type="text" value={ value } onChange={ chgValue } onBlur={onBlur} onFocus={ onFocus } onKeyUp ={ keyPress } maxLength={ml ? `${length}` : ''} aria-label={ariaLabel===''?'Search Field':ariaLabel} />
            </div>
            <span className={`flex w-12 mr-1 ${showSearch ? '' : 'justify-end'}`}>
                {value !== null && value !== '' && showSearch && <button tabIndex={0} aria-label='Search' onClick={()=> SearchFunction()}><FiSearch onClick={()=> SearchFunction()}/></button>}
                { value !== null && value !== '' && <button tabIndex={0} aria-label='Search Close' onClick={() => closeFunction()}><MdClose onClick={() => closeFunction()} /></button> }
            </span>
            { loading && <FieldLoader injectClass="absolute bottom-0 w-[98%] h-[2px] left-1" /> }
        </div>
    )
}

export default InlineTextField
