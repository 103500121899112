import { IoMdSettings } from 'react-icons/io'
import { FaCheck } from 'react-icons/fa'
import { tableSettingsAnim } from '../../commons/anims'
import { AnimatePresence, motion } from 'framer-motion'
import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const TableSettings = ({columns, setColumns}) => {

    const [showTableSettings, setShowTableSettings] = useState(false)
    const { t } = useTranslation()
    const hideTableSettings = () => setShowTableSettings(false)
    const hideTable = useSelector(state => state.theme.hide)
    const toggleColumnActive = (ev, name) => {
        ev.stopPropagation()
        setColumns(columns.map(c => {
            if(c.name === name) c.active = !c.active
            return c
        }))
    }

    useEffect(()=>{
        setShowTableSettings(false)
    },[hideTable])
    useLayoutEffect(() => {
        if(showTableSettings) {
            setTimeout(() => {
                document.body.addEventListener('click', hideTableSettings)
            }, 200)
        } else document.body.removeEventListener('click', hideTableSettings)

        return () => document.body.removeEventListener('click', hideTableSettings)
    }, [showTableSettings])

    const Showsettings=()=>{
        setShowTableSettings(true)
    }
    const accessibilityFixs = (value)=>{
        setColumns(columns.map(c => {
            if(c.name === name) c.active = !c.active
            return c
        }))
    }
    return (
        <div>
            <div><button  tabIndex={0} aria-label="Select column" onClick={() => Showsettings()}><IoMdSettings size="20px" className="ml-4 cursor-pointer" onClick={() => setShowTableSettings(!showTableSettings)} /></button></div>
            <AnimatePresence>
                { 
                    showTableSettings &&
                    <motion.div variants={tableSettingsAnim} initial="hidden" animate="visible" exit="hidden" tabIndex={-1} className="absolute text-gray-700 dark:text-gray-200 bg-bluegray-300 dark:bg-ldark py-2 z-[2] rounded-md w-72 top-full right-0 max-h-[300px] overflow-y-auto shadow-xl">
                        <span className="text-[10px] ml-2 mb-2 text-gray-600 dark:text-gray-300 font-bold">{t("Select Columns")}</span>
                        {
                            columns.map(c => (
                                <div key={c.name} role="checkbox" aria-checked={c.active} aria-label={c.name} tabIndex="0" onKeyDown={(event) => {event.key === 'Enter' && accessibilityFixs(c.name)}} className="group whitespace-nowrap flex items-center pr-4 pl-4 py-2 cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark text-xs" onClick={(ev) => toggleColumnActive(ev, c.name)}>
                                    <span className={`ring-1 ring-bluegray-500 dark:ring-white group-hover:ring-white w-3 h-3 inline-block align-middle relative mr-3`}>
                                        {c.active ? <FaCheck className="absolute -top-1 text-green-500 text-lg" /> : null}
                                    </span>
                                    {c.name}
                                </div>
                            ))
                        }
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}

export default TableSettings