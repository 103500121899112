import { useCallback, useMemo, useState, useEffect, useLayoutEffect, useRef } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom"
import { RiDirectionFill, RiInboxArchiveFill } from 'react-icons/ri'
import InlineTextField from '../../components/snippets/InlineTextField'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import API from '../../axios/API'
import { AnimatePresence, motion } from 'framer-motion'
import { PopInAnim } from '../../commons/anims'
import Dialog from '../../components/snippets/Dialog'
import ClosedCaseDialog from '../Dialogs/ClosedCaseDialog'
import TableSettings from '../../components/snippets/TableSettings'
import TableSelectFilter from '../../components/snippets/TableSelectFilter'
import { FaLayerGroup } from 'react-icons/fa'
import PopoverMenu from '../../components/snippets/PopoverMenu'
import TransferCaseDialog from '../Dialogs/TransferCaseDialog';
import TransferOrg from '../Dialogs/TransferOrg';
import NotesDialog from '../Dialogs/NotesDialog'
import ChildOrgTransferDailog from '../Dialogs/ChildOrgTransferDailog'
// import { useHistory } from 'react-router-dom'
import FlowText from '../../components/snippets/FlowText'
import { setAlert } from '../../store/theme/actions';
import { useTranslation } from 'react-i18next'
import QuickActionMultiRole from '../QuickAction'
import axios from 'axios'
import {refreshTrigger,setReopencasecount } from '../../store/auth/actions'
import 'moment-timezone';
import moment from 'moment';
import ReferrerCaseDialog from '../Dialogs/ReferrerCaseDialog'
import AddCg from '../../components/snippets/AddCg'
import VipButton from "../../components/snippets/VipButton";
import SelectColumn from '../../components/snippets/SelectColumn'
import EnhancedClosedDialog from '../Dialogs/EnhancedClosedDialog'


createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

var caseListSelected = []


const CaseList = ({ history }) => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [records,setRecords] = useState(false);
    const [casedetails, setCaseDetails] = useState([])
    const [selectRows, setSelectedRows] = useState([])
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(true)
    const [openMultiAction, setOpenMultiAction] = useState(true)
    const organization = useSelector(state => state.auth.organization)
    const notesAction = useSelector(state => state.auth.notesAction)
    const [closeCaseModal, setCloseCaseModal] = useState(false)
    const userDetails = useSelector(state => state.auth.userDetails)
    const [activeQuickAction] = useState({ position: 'bottom', active: null })
    const [columnsSelected, setColumnsSelected] = useState([])
    const caseTable = useRef(null)
    const [statusFilter, setStatusFilter] = useState('All Status')
    const [caseId, setCaseId] = useState('')
    const [transferCaseModal, setTransferCaseModal] = useState(false)
    const [transferOrg, setTransferOrg]  = useState(false)
    const [childOrgTransferCaseModal, setChildOrgTransferCaseModal] = useState(false)
    const [reassignreferrerCaseModal, setReassignReferrerCaseModal] = useState(false)
    const closecaseRender = useSelector(state => state.auth.closeCaseRender)
    const transfercaseRender = useSelector(state => state.auth.transferCaseRender)
    const childOrgTransferRender = useSelector(state => state.auth.childOrgTransferRender)
    const [notesDialogModal, setNotesDialogModal] = useState(false)
    const dispatch = useDispatch()
    // const history = useHistory()
    const [downloadAssessmentPopup, setDownloadAssessmentPopup] = useState(false)
    const [downloadSummaryPopup, setDownloadSummaryPopup] = useState(false)
    const [downloadCareplanPopup, setDownloadCareplanPopup] = useState(false)
    const [assessmentId] = useState('')
    const [careplanId] = useState('')
    const [currentCaseId] = useState('')
    const { t } = useTranslation()
    const language = useSelector(state => state.theme.language)
    const reopencount = useSelector(state => state.auth.reopencaseCount)
    const PageRefresh = useSelector(state => state.auth.pageRefresh)
    let orgId = localStorage.getItem('orgid')
    let vipEnable = localStorage.getItem('vipEnable')
    const roles = useSelector(state => state.auth.userDetails?.role)
    const [hideColumn, setHideColumn] = useState(true)
    // const loadAllCase = useSelector(state => state.auth.loadAllCase)
    const subSearch = useSelector(state => state.auth.triggersubSearch)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalPage, setTotalPage] = useState()
    const [statusList, setStatusList] = useState([])
    const [selectSearch, setSelectSearch] = useState([])    
    const [columnSelect, setColumnSelect] = useState(1)
    // const enableAllSearch  = useSelector(state=>state.auth.enableAllSearch)
    const [sizePage] = useState([10])
    const [triggerAll, setTriggerAll] = useState(false)
    const [column,setColumn] = useState()
    const [sortDirection,setSortDirection] = useState()
    const [totalSelected,setTotalSelected] = useState([])
    let lang= localStorage.getItem('language')
    let enhanceClose = localStorage.getItem('enhanceClose')
    const [enhancecloseCaseModal, setEnhanceCloseCaseModal] = useState(false)

    useEffect(() => {
        // setFilter('') //It is handled for clearing the previous search value
        setRecords(false)
       //Page navigation handled using sessionstorage
       var pageView = sessionStorage.getItem('pageView');
       var sort = sessionStorage.getItem('sort');
       var direction = sessionStorage.getItem('direction');
       if(pageView){
        setPage(parseInt(pageView));
        sessionStorage.removeItem('pageView');
       }
       if(sort){        
        setColumn(sort);
        sessionStorage.removeItem('sort');
       }
       if(direction){
        setSortDirection(direction);
        sessionStorage.removeItem('direction');
       }
        
        if ((roles.includes('screener') && parseInt(organization) === userDetails.orgId) || 
        roles.includes('dynamic_scr') && parseInt(organization) !== userDetails.orgId) {
            setHideColumn(false)
        }
        setLoading(true)
        if (history.location.state !== undefined && (history.location.state?.state?.from === 'GeneralSearch' || history.location.state?.from === 'GeneralSearch')) {
            setFilter('')
            setStatusFilter('All Status')
            let details = []
            setCaseDetails([])
            API.get(`status?screenName=allCases&orgId=${orgId}`).then((res) => {
                setStatusList([{ text: 'All Status', value: 'All Status' }, ...res.data.body.map((c) => ({ text: c.statusName, value: c.statusId }))])
            })
            API.get(`fields?screenName=allCases`).then((res) => {
                res.data.body.map(c => {
                    details.push({ id: c.fieldId, name: c.fieldName, active: c.active })
                    return null;
                })
                setColumnsSelected(details)
            })
            history.location.state.state.list.forEach((e, i) => {
                history.location.state.state.list[i].caseLastUpdated = lastdat(history.location.state.state.list[i].caseLastUpdated)
                history.location.state.state.list[i].caseReminderDate = remaindat(history.location.state.state.list[i].caseReminderDate)
                history.location.state.state.list[i].followupDate = follow(history.location.state.state.list[i].followupDate)
                history.location.state.state.list[i].dateClosed = datecon(history.location.state.state.list[i].dateClosed)
                history.location.state.state.list[i].caseCreated = createdat(history.location.state.state.list[i].caseCreated)
            })
            setCaseDetails(history.location.state.state.list)
            setTotalRows(history.location.state.state.list.length)
            setLoading(false)
        } else {
            // let path = statusFiltepage ? `page=${page}&size=${perPage}` : page && perPage ? `page=${page}&size=${perPage}` : statusFilter && perPage ? `size=${perPage}&status=${statusFilter}` : `page=${page}&size=${perPage}`
            let path = column && statusFilter && statusFilter !== 'All Status' && statusFilter !== undefined && filter ? `page=${page}&size=${perPage}&status=${statusFilter}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}` :
            column && statusFilter === undefined && filter ? `page=${page}&size=${perPage}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}`: column && statusFilter && statusFilter !== 'All Status' && statusFilter !== undefined ? `page=${page}&size=${perPage}&status=${statusFilter}&sort=${column},${sortDirection}` :
            statusFilter === undefined && filter ? `page=${page}&size=${perPage}&search=${filter}&searchField=${columnSelect}` : statusFilter && statusFilter !== 'All Status' && statusFilter !== undefined && filter ? `page=${page}&size=${perPage}&status=${statusFilter}&search=${filter}&searchField=${columnSelect}` : statusFilter && statusFilter !== 'All Status' && statusFilter !== undefined ? `page=${page}&size=${perPage}&status=${statusFilter}`:
             column && filter ? `page=${page}&size=${perPage}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}` : column ? `page=${page}&size=${perPage}&sort=${column},${sortDirection}` : pageView && column !== undefined && sortDirection !== undefined ? `page=${page}&size=${perPage}&sort=${column},${sortDirection}` : filter && filter !== undefined ? `page=${page}&size=${perPage}&search=${filter}&searchField=${columnSelect}` :  `page=${page}&size=${perPage}`
            // history.location.state = undefined
            axios.all([API.get(`status?screenName=allCases&orgId=${orgId}`), API.get(`fields?screenName=allCases`), API.get(`organizations/${organization}/cases?queue=active&${path}`),API.get(`menus/case-searchFields?screenName=allCases?lang=${lang}`)]).then(axios.spread(
                (statusResp, allCaseResp, allCaseActive,allColumnSelect) => {
                    let details = []
                    setCaseDetails([])
                    allCaseResp.data.body.map(c => {
                        details.push({ id: c.fieldId, name: c.fieldName, active: c.active })
                        return null;
                    })
                    setColumnsSelected(details)
                    setStatusList([{ text: (t('All Status')), value: (('All Status')) }, ...statusResp.data.body.map((c) => ({ text: c.statusName, value: c.statusId }))])
                    setTotalPage(allCaseActive.data.page.totalPages)
                    setSelectSearch([...allColumnSelect.data.body.map((c) => ({ text: c.name, value: c.value }))])
                    setTotalRows(allCaseActive.data.page.totalElements)
                    if((!allCaseActive.data._embedded && page > 1) || (!allCaseActive.data._embedded && (closecaseRender ||  transfercaseRender || childOrgTransferRender || reopencount))){
                        setRecords(true)
                        setFilter('')
                    }else{
                        setRecords(false)
                    }
                    allCaseActive.data._embedded.immutableCaseList.forEach((e, i) => {
                        allCaseActive.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(allCaseActive.data._embedded.immutableCaseList[i].caseLastUpdated)
                        allCaseActive.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(allCaseActive.data._embedded.immutableCaseList[i].caseReminderDate)
                        allCaseActive.data._embedded.immutableCaseList[i].followupDate = follow(allCaseActive.data._embedded.immutableCaseList[i].followupDate)
                        allCaseActive.data._embedded.immutableCaseList[i].dateClosed = datecon(allCaseActive.data._embedded.immutableCaseList[i].dateClosed)
                        allCaseActive.data._embedded.immutableCaseList[i].caseCreated = createdat(allCaseActive.data._embedded.immutableCaseList[i].caseCreated)
                    })
                    setCaseDetails(allCaseActive.data._embedded.immutableCaseList)
                    setLoading(false)
                    

                }
            )
            ).catch(e => {
                console.log(e)
            }).finally(() => setLoading(false))
        }
    }, [organization,PageRefresh, closecaseRender, transfercaseRender, notesAction, childOrgTransferRender, reopencount, language, triggerAll, page, history !== undefined && history.location !== undefined && history.location.state !== undefined,subSearch]) // eslint-disable-line
    const datecon = (value) => {
        if (value !== null &&  !value.includes('IST')) {
            let timestamp = new Date(value).getTime();
            let tz = moment.tz.guess()
            let tzabbr = moment.tz.zone(tz).abbr(timestamp)
            return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr
            //     let followupdate = new Date(value)
            // return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
        } else {
            return value
        }
    }

    const follow = (value) => {
        if (value !== null  &&  !value.includes('IST')) {
            let timestamp = new Date(value).getTime();
            let tz = moment.tz.guess()
            let tzabbr = moment.tz.zone(tz).abbr(timestamp)
            return moment(value).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr
            //       let followupdate = new Date(value)
            //   return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${value.slice(11,19)}`
        } else {
            return value
        }
    }
    const lastdat = (value) => {
        if (value && !value.includes('IST')) {
            let timestamp = new Date(value).getTime();
            let tz = moment.tz.guess()
            let tzabbr = moment.tz.zone(tz).abbr(timestamp)
            return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr
            //     let followupdate = new Date(value)
            // return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
        } else {
            return value
        }
    }
    const remaindat = (value) => {
        if (value !== null && value) {
            //   let timestamp = new Date(value).getTime();
            //   let tz = moment.tz.guess()
            //   let tzabbr = moment.tz.zone(tz).abbr(timestamp)
            //   return moment(new Date(value)).format('MMM D,YYYY')
            let followupdate = new Date(value)
            return `${followupdate.toUTCString().slice(8, 11)}${followupdate.toUTCString().slice(4, 7)},${followupdate.toUTCString().slice(12,16)}`
        } else {
            return ''
        }
    }

    const createdat = (value) => {
        if (value && !value.includes('IST')) {
            let timestamp = new Date(value).getTime();
            let tz = moment.tz.guess()
            let tzabbr = moment.tz.zone(tz).abbr(timestamp)
            return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr
            //     let followupdate = new Date(value)
            // return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
        } else {
            return value
        }
    }

    const printAssessment = () => {
        API.get(`organizations/${orgId}/assessments/${assessmentId}:print`).then(res => {
            if (res.status === 200) {
                const linkSource = `data:application/pdf;base64,${res.data.body}`;
                const downloadLink = document.createElement("a");
                const fileName = "TCE-Assessment.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                setDownloadAssessmentPopup(false)
            }
        })
    }

    const printSummary = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}assessments/${assessmentId}/summary`, {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Accept': ''
            }
        }).then(res => {
            if (res.status === 200) {
                const linkSource = `data:application/pdf;base64,${res.data.body}`;
                const downloadLink = document.createElement("a");
                const fileName = "TCE-Summary.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                setDownloadSummaryPopup(false)
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
                setDownloadSummaryPopup(false)
            }
        }).catch(err => {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            setDownloadSummaryPopup(false)
        })
        // dispatch(setAlert({ title: 'Warning', subtitle: 'Work in progress', type: 'warning', active: true }))
        // setDownloadSummaryPopup(false)
    }

    const printCareplan = () => {
        // let details = {
        //     caseId: currentCaseId,
        //     cpId: careplanId,
        //     assessId: assessmentId,
        //     step: 2,
        //     lang: 'en',
        //     orgId: organization
        // }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}care-plans/${careplanId}?queue=${2}&assessId=${assessmentId}&caseId=${currentCaseId}`, {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Accept': ''
            }
        }).then(res => {
            if (res.status === 200) {
                const linkSource = `data:application/pdf;base64,${res.data.body}`;
                const downloadLink = document.createElement("a");
                const fileName = "TCE-Care Plan.pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                setDownloadCareplanPopup(false)
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
                setDownloadCareplanPopup(false)
            }
        }).catch(err => {
            dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
            setDownloadCareplanPopup(false)
        })
        // dispatch(setAlert({ title: 'Warning', subtitle: 'Work in progress', type: 'warning', active: true }))
        // setDownloadCareplanPopup(false)
    }

    const caseNavigation = (value) => {
        sessionStorage.setItem('pageView', page.toString());
        sessionStorage.setItem('sort', column);
        sessionStorage.setItem('direction', sortDirection);
    }

    const enableColumns = () => {
        let details = []
        columnsSelected.map(c => {
            details.push(
                {
                    "fieldId": c.id,
                    "fieldName": c.name,
                    "active": c.active
                }
            )
            return null;
        })
        API.post(`fields?screenName=allCases`, details).then((res) => {
        })

    }
    const caseStatusList = useMemo(() => {
        return [(t('All Status')), ...new Set(casedetails.map(c => c.caseStatus))].map(c => ({ text: c, value: c }))
    }, [casedetails, t])
    const colums = useMemo(() => {
        return [
            {
                name:' ',
                selector:'',
                cell: row => { return ((userDetails.orgId === parseInt(organization)) ? <QuickActionMultiRole role={userDetails.role} screen={'allCases'} caseId={row.caseId} cpId={row.latestCpId} assessId={row.latestAssessId} status={row.statusId} careReceiverId={row.careReceiverId} careManagerId={row.careManagerId} caregiverId={row.caregiverId} asstype={row.assessType} refName={row.referredByName} reassess={row.reason} cgPrefLang={row.cgPrefLang} relation={row.relationship} davEnable={row.davMilitaryEnable} /> : ((roles.includes('dynamic_cm') || roles.includes('dynamic_admin') || roles.includes('dynamic_scr')) && userDetails.orgId !== parseInt(organization)) ? <QuickActionMultiRole role={userDetails.role} screen={'allCases'} caseId={row.caseId} cpId={row.latestCpId} assessId={row.latestAssessId} status={row.statusId} careReceiverId={row.careReceiverId} careManagerId={row.careManagerId} caregiverId={row.caregiverId} asstype={row.assessType} refName={row.referredByName} reassess={row.reason} cgPrefLang={row.cgPrefLang} relation={row.relationship} davEnable={row.davMilitaryEnable} /> : '') },
                allowOverflow: true,
                button: true,
                width: '56px', // custom width for icon button
            },
            { name: t('Case #'), selector: 'caseId', cell: row => { return <div aria-label={`Case ${row.caseId}`}>{((row.caregiverId === 0 || row.careReceiverId === 0 || (row.relationship === null &&(row.statusId === 35 || row.statusId === 11))) || ((row.statusId === 35 && ((userDetails.role.includes('caremanager') && row.careManagerId === userDetails.caremanagerId) || ((userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_scr'))||(userDetails.orgId === parseInt(organization) && userDetails.role.includes('screener'))))))) ? <Link  to={`/createCase/${row.caseId}/carereceiver/${row.careReceiverId}/caregiver/${row.caregiverId}/${row.careManagerId}`}>{row.caseId}</Link> : <Link  to={`/casedata/${row.caseId}`} onClick={() => caseNavigation(row)}>{row.caseId}</Link>}</div> }, style: { cursor: "pointer", "& :hover": { textDecoration: "underline" } }, initialActive: true, sortField: 'SORT_CASE_ID', sortable: true },
            { name: t('Program Name'), selector: 'programName',cell:row =><span aria-label={`Program name ${row.programName}`} tabIndex={0}>{row.programName}</span>, sortable: true, sortField: 'SORT_PROGRAM_NAME' },
            { name: t('Protocol'), selector: 'protocol',cell:row =><span aria-label={`protocol ${row.protocol}`} tabIndex={0}>{row.protocol}</span>, sortable: true, sortField: 'SORT_PROTOCOL' },
            {
                name: t('Score'), selector: '',
                cell: row => {
                    const data = row.measureHistory;
                    return (<div style={{ wordWrap: 'break-word', padding: '2px', whiteSpace: 'normal', width: '125%' }}>
                        <div className="flex justify-start" aria-label={`Score`} tabIndex={0}>{(data.length && data[data.length - 1].relationshipHml !== undefined && data[data.length - 1].relationshipHml === 1) ? <div title={t("Relationship")} className="score-1"></div> : (data.length && data[data.length - 1].relationshipHml !== undefined && data[data.length - 1].relationshipHml === 2) ? <div title={t("Relationship")} className="score-2"></div> : (data.length && data[data.length - 1].relationshipHml !== undefined && data[data.length - 1].relationshipHml === 3) ? <div title={t("Relationship")} className="score-3"></div> : ''}&nbsp;
                            {(data.length && data[data.length - 1].stressHml !== undefined && data[data.length - 1].stressHml === 1) ? <div title={t("Stress")} className="score-1"></div> : (data.length && data[data.length - 1].stressHml !== undefined && data[data.length - 1].stressHml === 2) ? <div title={t("Stress")} className="score-2"></div> : (data.length && data[data.length - 1].stressHml !== undefined && data[data.length - 1].stressHml === 3) ? <div title={t("Stress")} className="score-3"></div> : ''}&nbsp;
                            {(data.length && data[data.length - 1].objectiveHml !== undefined && data[data.length - 1].objectiveHml === 1) ? <div title={t("Objective")} className="score-1"></div> : (data.length && data[data.length - 1].objectiveHml !== undefined && data[data.length - 1].objectiveHml === 2) ? <div title={t("Objective")} className="score-2"></div> : (data.length && data[data.length - 1].objectiveHml !== undefined && data[data.length - 1].objectiveHml === 3) ? <div title={t("Objective")} className="score-3"></div> : ''}&nbsp;
                            {(data.length && data[data.length - 1].upliftHml !== undefined && data[data.length - 1].upliftHml === 1) ? <div title={t("Uplifts")} className="score-1"></div> : (data.length && data[data.length - 1].upliftHml !== undefined && data[data.length - 1].upliftHml === 2) ? <div title={t("Uplifts")} className="score-2"></div> : (data.length && data[data.length - 1].upliftHml !== undefined && data[data.length - 1].upliftHml === 3) ? <div title={t("Uplifts")} className="score-3"></div> : ''}&nbsp;
                            {(data.length && data[data.length - 1].depressionHml !== undefined && data[data.length - 1].depressionHml === 1) ? <div title={t("Depression")} className="score-1"></div> : (data.length && data[data.length - 1].depressionHml !== undefined && data[data.length - 1].depressionHml === 2) ? <div title={t("Depression")} className="score-2"></div> : (data.length && data[data.length - 1].depressionHml !== undefined && data[data.length - 1].depressionHml === 3) ? <div title={t("Depression")} className="score-3"></div> : ''}&nbsp;
                            {(data.length && data[data.length - 1].iddHml !== undefined && data[data.length - 1].iddHml === 1) ? <div title={t("IDD")} className="score-1"></div> : (data.length && data[data.length - 1].iddHml !== undefined && data[data.length - 1].iddHml === 2) ? <div title={t("IDD")} className="score-2"></div> : (data.length && data[data.length - 1].iddHml !== undefined && data[data.length - 1].iddHml === 3) ? <div title={t("IDD")} className="score-3"></div> : ''}&nbsp;
                            {(data.length && data[data.length - 1].itpNow !== undefined && data[data.length - 1].itpNow === 0) ? <div title={t("ITP")} className="score-1"></div> : (data.length && data[data.length - 1].itpNow !== undefined && data[data.length - 1].itpNow === 1) ? <div title={t("ITP")} className="score-3"></div> : ''}
                        </div>
                    </div>)
                }
            },
            { name: t('Care Manager Name'), selector:'caremanagerName', cell: row =><span aria-label={`caremanagerName ${row.caremanagerName}`} tabIndex={0}>{row.caremanagerName !== 'null null' ? row.caremanagerName : ''}</span> ,  wrap: true, hide: 'md', initialActive: true, sortField: 'SORT_CAREMANAGER_NAME' ,sortable: true},
            {
                name: t('Referred by'),
                selector:'referredByName',cell: row => <span aria-label={`Referred by ${row.referredByName}`} tabIndex={0}>{row.referredByName !== "null null" ? row.referredByName : ''}</span>,
                sortable: true, wrap: true, sortField: 'SORT_REFERRED_BY'
            },
            { name: t('Caregiver Name'), selector:'caregiverName', cell: row => <div aria-label={`Caregiver Name ${row.caregiverName}`} tabIndex={0} data-private="lipsum">{row.caregiverName !== 'null null' ? row.caregiverName : ''}</div>,  wrap: true, initialActive: true, sortField: 'SORT_CAREGIVER_NAME',sortable: true },
            { name: t('Care Receiver Name'), selector:'careReceiverName', cell: row => 
                        { return <div aria-label={`Care Receiver Name ${row.careReceiverName}`} tabIndex={0} data-private="lipsum" style={{ display: 'flex' }}> 
                            {vipEnable === 'true' && row.vipStatus === true ? <VipButton /> : ''}&nbsp;<span data-private="lipsum" style={{ display: 'flex' }}>{row.careReceiverName !== 'null null' ? row.careReceiverName : ''}</span></div> }, sortable: true, wrap: true, sortField: 'SORT_CARERECIPIENT_NAME' },
            { name: t('Care Receiver SubscriberID'), selector: 'subscriberId',cell:row=><span aria-label={`Care Receiver SubscriberID ${row.subscriberId}`} tabIndex={0}>{row.subscriberId}</span>, sortable: true, wrap: true, sortField: 'SORT_SUBSCRIBER_ID' },
            { name: t('Care Receiver County'), selector: 'county',cell:row =><span aria-label={`Care Receiver County ${row.county}`} tabIndex={0}>{row.county}</span>, sortable: true, wrap: true, sortField: 'SORT_CARE_RECEIVER_COUNTY' },
            {
                name: t('Relationship'),
                selector: 'relationship',cell:row=><span aria-label={`Relationship ${row.relationship}`} tabIndex={0}>{row.relationship}</span>, sortable: true, wrap: true, sortField: 'SORT_RELATIONSHIP'
            },
            { name: t('Reminders'), selector: row => { return <div aria-label={`Reminders ${row.reminderNotes}`} tabIndex={0} style={{ wordWrap: 'break-word', padding: '10px', whiteSpace: 'normal', width: 'auto', maxHeight: '65px' }}>{row.reminderNotes}</div> },sortable: true, sortField: 'SORT_REMINDERS' },
            {
                name: <>{t('Status')}<TableSelectFilter options={statusList} label={t("Status")} defaultValue={t("All Status")} value={statusFilter} setValue={value => changeStatus(value)} injectClass="p-1" /></>,
                selector: 'caseStatus',
                cell:row =><span aria-label={`Status ${row.caseStatus}`} tabIndex={0}>{row.caseStatus}</span>,
                // sortable: true,
                sortField: 'SORT_STATUS',
                wrap: true,
                initialActive: true,
                optionalColumn: false
            },
            {
                name: t('Date Closed'), omit: hideColumn, selector:'dateClosed',cell: row => <span aria-label={`Date Closed ${row.dateClosed}`} tabIndex={0}>{row.dateClosed}</span>, sortable: true, wrap: true, sortField: 'SORT_DATA_CLOSED'
            },
            {
                name: t('Closed Reason'), omit: hideColumn, selector: 'closedReason',cell:row =><span aria-label={`Closed Reason ${row.closedReason}`} tabIndex={0}>{row.closedReason}</span>, sortable: true, wrap: true, sortField: 'SORT_CLOSED_REASON'
            },
            // {
            //     name: t('Closed Reason'), selector: row => {return(<div style={{wordWrap:'break-word',padding:'10px',whiteSpace:'normal',width:'auto'}}> {row.closedReason}</div>)}, sortable: true
            // },
            { name: t('Case Created Date'), selector:'caseCreated' ,cell:row =><span aria-label={`Case Created Date ${row.caseCreated}`} tabIndex={0}>{row.caseCreated !== null ? row.caseCreated : ''}</span>, sortable: true, wrap: true, sortField: 'SORT_CREATE_DATE' },
            { name: t('Reminder Date'), selector:'caseReminderDate',cell: row => <span aria-label={`Reminder Date ${row.caseReminderDate}`} tabIndex={0}>{row.caseReminderDate !== null ? row.caseReminderDate : ''}</span>, sortable: true, wrap: true, sortField: 'SORT_REMINDER_DATE' },
            { name: t('Follow-Up Date'), selector:'followupDate' ,cell:row => <span aria-label={`Follow-Up Date ${row.followupDate}`} tabIndex={0}>{row.followupDate !== null ? row.followupDate : ''}</span>, sortable: true, wrap: true, sortField: 'SORT_FOLLOW_UP_DATE' },
            { name: t('Last Updated'), selector:'caseLastUpdated' ,cell:row =><span aria-label={`Last Updated ${row.caseLastUpdated}`} tabIndex={0}> {row.caseLastUpdated !== null ? row.caseLastUpdated : ''}</span>, sortable: true, wrap: true, sortField: 'SORT_LAST_UPDATE' },
        ].map(c => {
            if (c.name !== undefined && columnsSelected.find(e => e.name === c.name)) c.omit = !columnsSelected.find(e => e.name === c.name).active
            return c
        })
    }, [activeQuickAction, columnsSelected, statusFilter, caseStatusList, casedetails]) // eslint-disable-line

    useLayoutEffect(() => {
        setColumnsSelected(colums.filter(c => c.name !== undefined && c.optionalColumn !== false).map(c => ({ name: c.name, active: c.initialActive || false })))
    }, []) // eslint-disable-line  
    const addCase=()=>{
        history.push({pathname:`/addCase`})
    }
    const SubHeader = useMemo(() => (
        <div className="flex items-center relative">
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} onEnter={() => searchRecords()} ml={true} onClick={() => searchRecords()} onClose={() => closeFilter()} />
            <span aria-label='Search Select'><SelectColumn options={selectSearch} label={t("Select Search")} ariaLab={columnSelect} value={columnSelect} setValue={value => changeSelect(value)} injectClass="p-1" /></span>
            {(parseInt(organization) === userDetails.orgId && roles.includes('screener') || (parseInt(organization) !== userDetails.orgId && roles.includes('dynamic_scr'))) ? <span  className='ml-4' onClick={()=>addCase()} ><AddCg title={t("Enroll Caregiver")} /></span> : ''}
           <TableSettings columns={columnsSelected} setColumns={((columns) => { setColumnsSelected(columns); enableColumns() })} />
        </div>
    ), [filter, columnsSelected,columnSelect,selectSearch,records]) // eslint-disable-line
    
    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return casedetails.filter(c => filter === '' || filter === null || filterSplit)
    }, [casedetails, filter])
    const ExpandComponent = useCallback(row => {
        return (
            <div className="bg-bluegray-300 dark:bg-ldark py-2 px-3" key={row.data.caseId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>
        )
    }, [])

   
    
    const rowsSelected = useCallback(({ selectedRows }) => {
        caseListSelected = totalSelected.map(c => c) 
        let DatacaseIds = casedetails.map(c => c.caseId)
        let tempcase = totalSelected.filter(c => !DatacaseIds.includes(c.caseId))
        selectedRows.forEach(c =>{
            tempcase.push(c)
        })
        // setCaseId(selectedRows.map(c => c.caseId))
        // setSelectedRows(selectedRows)
        setTotalSelected(tempcase)
    }, [totalSelected,casedetails])
   
    useEffect(() => {
        setCaseId(totalSelected.map(c => c.caseId))
        setSelectedRows(totalSelected)
    }, [totalSelected])

    useEffect(()=>{
    setTotalSelected([])
    setSelectedRows([])
    setCaseId()
    },[closecaseRender,transfercaseRender,childOrgTransferRender, reopencount])

    const reopenCase = async(c) => {
        if (c !== undefined) {
           await API.post(`cases:reopen?orgId=${orgId}`, [c]).then(res => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Case Reopened Successfully'), type: 'success', active: true }))
                    dispatch(setReopencasecount(reopencount + 1))
                }
            }).catch(e => {
                console.log(e)
            })
        } else {
            setCaseId([caseId])
        }
       await API.post(`cases:reopen?orgId=${orgId}`, caseId).then(res => {
        let pageRefresh=true;
            if (res.status === 200) {
                let subscriberID = null
                let subList = history?.location?.state?.state?.list;
                if(subList){
                    const filterData = subList.filter(e=> caseId.includes(e.caseId))
                    subscriberID = filterData[0].subscriberId;           
                 API.get(`organizations/${orgId}/cases:search?subId="${subscriberID}"`).then((res)=>{
                    if(res.status === 200){
                        history.push('/case_list',{state:{list:res.data.body,from:"GeneralSearch",subId:subscriberID}}) 
                        dispatch(setAlert({ title: t('Success'), subtitle: t('Case Reopened Successfully'), type: 'success', active: true }))
                        dispatch(setReopencasecount(reopencount+1))
                        dispatch(refreshTrigger(pageRefresh))
                     
                    }
                })
            }
        }
        }).catch(e => {
            console.log(e)
        })
    }
    const openCloseCaseModal = (c) => {
        if (enhanceClose === 'true'){
            setEnhanceCloseCaseModal(true)
        }else{
            setCaseId(caseId)
        setCloseCaseModal(true)
        }
    }

    const openTransferCaseModal = (c) => {
        if (c !== undefined)
            setCaseId([c])
        setTransferCaseModal(true)
    }
    const openTransferOrg = (c) => {
        if (c !== undefined)
        setCaseId([c])
        setTransferOrg(true)
    }
    const openReassignReferrerModal = (c) => {
        if (c !== undefined)
            setCaseId([c])
        setReassignReferrerCaseModal(true)
    }

    const openChildOrgTransferCaseModal = (c) => {

        if (c !== undefined)
            setCaseId([c])
        setChildOrgTransferCaseModal(true)
    }

    const closeModel = () => {
        setCloseCaseModal(false)
        setFilter('');
    }
    const closeReason = () => {
        setEnhanceCloseCaseModal(false)
        setFilter('');
    }

    const closeFilter = () => {
        setPage(1)
        setStatusFilter()
        setColumn()
        setSortDirection()
        setColumnSelect(1)
        // setFilter('')
        // changeStatus('All Status')
        setTriggerAll(!triggerAll)
    }

    const closeFilterStatus = () => {
        setRecords(false)
        setPage(1)
        setStatusFilter()
        setColumn()
        setSortDirection()
        setFilter('')
        changeStatus('All Status')        
        setTriggerAll(!triggerAll)
    }
    // const openCreateNotesModal = (c) => {
    //     if (c !== undefined)
    //         setCaseId(c)
    //     setNotesDialogModal(true)
    // }

    // useEffect(() => {
    //     // if (filteredcasedetails.length === 0 && (statusFilter !== 'All Status' || statusFilter === 'Todo el estado')) {
    //     //     setStatusFilter('All Status')
    //     // }

    // }, [filteredcasedetails, statusFilter])

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setCaseDetails([])
        setPerPage(sizePage);
        let apiPath = statusFilter ? `size=${newPerPage}&status=${statusFilter}` : `page=${page}&size=${newPerPage}`
        API.get(`organizations/${organization}/cases?queue=active&${apiPath}`).then((res) => {
            if (res.status === 200) {
                setTotalRows(res.data.page.totalElements)
                setPerPage(res.data.page.size)
                res.data._embedded.immutableCaseList.forEach((e, i) => {
                    res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                    res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                    res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                    res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                    res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                })
                setCaseDetails(res.data._embedded.immutableCaseList)
            }
        })
        setLoading(false);
        // sizeHandling(newPerPage)
    };

    // const sizeHandling = (values) =>{
    //     let newArray = [values]
    //     sizePage.map((c,i)=>{
    //         if(c === values){
    //             sizePage.splice(i,1)
    //             newArray.push(...sizePage)
    //         }return null;
    //     })
    //     setSizePage(newArray)
    // }

    if ((!loading && history.location.state === undefined && (filteredcasedetails.length >= 10 || casedetails.length >= 10)) || (!loading && (filteredcasedetails.length >= 10 || casedetails.length >= 10))) {
        if (page > 1) {
            // document.getElementById('pagination-next-page')?.removeAttribute('disabled')
            document.getElementById('pagination-first-page')?.removeAttribute('disabled')
            document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
                setPage(1)
            })
        }
        document.getElementById('pagination-next-page')?.addEventListener(('click'), () => {
            setPage(page + 1)
        })
        document.getElementById('pagination-last-page')?.addEventListener(('click'), () => {
            //setPage(totalPage)
        })
        if (page > 1) {
            document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
            document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
                setPage(page - 1)
            })
        }
    }  else if (!loading && page > 1 && filteredcasedetails.length < 10 && casedetails.length < 10) {
        document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
        document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
            setPage(page - 1)
        })
        document.getElementById('pagination-first-page')?.removeAttribute('disabled')
        document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
            setPage(1)
        })
    } else if (filteredcasedetails.length < 10 && casedetails.length < 10 && !loading && history.location.state === undefined) {
        document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    }else {
        document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    }

    const handleSort = async (column, sortDirection) => {
        setTotalSelected(caseListSelected.map(c => c))
        setColumn(column.sortField)
        setSortDirection(sortDirection.toUpperCase())
        let apiPath = statusFilter && statusFilter !== 'All Status' && statusFilter !== undefined && filter ? `size=${perPage}&status=${statusFilter}&search=${filter}&searchField=${columnSelect}&sort=${column.sortField}` :
        statusFilter !== 'All Status' && statusFilter !== undefined  ? `size=${perPage}&status=${statusFilter}&sort=${column.sortField}`:
        filter ? `size=${perPage}&search=${filter}&searchField=${columnSelect}&sort=${column.sortField}` :`page=1&size=${perPage}&sort=${column.sortField}`;
        API.get(`organizations/${organization}/cases?queue=active&${apiPath},${sortDirection.toUpperCase()}`).then((res) => {
            if (res.status === 200 && res.data._embedded) {
                res.data._embedded.immutableCaseList.forEach((e, i) => {
                    res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                    res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                    res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                    res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                    res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                })
                setCaseDetails(res.data._embedded.immutableCaseList)
                setTotalRows(res.data.page.totalElements)
            }
        })
        //   setData(remoteData);
    };


    const changeStatus = (value) => {
        statusList.map((c) => {
            if (c.value === value) {
                setStatusFilter(c.value)
            }
            return c
        })
        if (value !== 'All Status' && column && filter && filter !== undefined) {
            API.get(`organizations/${organization}/cases?queue=active&size=${perPage}&status=${value}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}`).then((res) => {
                if (res.status === 200 && res.data._embedded) {
                    setPage(1)
                    res.data._embedded.immutableCaseList.forEach((e, i) => {
                        res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                        res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                        res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                        res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                        res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                    })
                    setCaseDetails(res.data._embedded.immutableCaseList)
                    setTotalRows(res.data.page.totalElements)
                } else {
                    setRecords(true)
                    setCaseDetails([])
                }
            })
        } else if (value !== 'All Status' && filter && filter !== undefined) {
            API.get(`organizations/${organization}/cases?queue=active&size=${perPage}&status=${value}&search=${filter}&searchField=${columnSelect}`).then((res) => {
                if (res.status === 200 && res.data._embedded) {
                    setPage(1)
                    res.data._embedded.immutableCaseList.forEach((e, i) => {
                        res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                        res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                        res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                        res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                        res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                    })
                    setCaseDetails(res.data._embedded.immutableCaseList)
                    setTotalRows(res.data.page.totalElements)
                }
                else {
                    setRecords(true)
                    setCaseDetails([])
                }
            })
        } else if (value !== 'All Status' && column) {
            API.get(`organizations/${organization}/cases?queue=active&size=${perPage}&status=${value}&sort=${column},${sortDirection}`).then((res) => {
                if (res.status === 200 && res.data._embedded) {
                    setPage(1)
                    res.data._embedded.immutableCaseList.forEach((e, i) => {
                        res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                        res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                        res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                        res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                        res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                    })
                    setCaseDetails(res.data._embedded.immutableCaseList)
                    setTotalRows(res.data.page.totalElements)
                } else {
                    setRecords(true)
                    setCaseDetails([])
                }
            })
        } else if (value !== 'All Status') {
            API.get(`organizations/${organization}/cases?queue=active&size=${perPage}&status=${value}`).then((res) => {
                if (res.status === 200 && res.data._embedded) {
                    setPage(1)
                    res.data._embedded.immutableCaseList.forEach((e, i) => {
                        res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                        res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                        res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                        res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                        res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                    })
                    setCaseDetails(res.data._embedded.immutableCaseList)
                    setTotalRows(res.data.page.totalElements)
                } else {
                    setRecords(true)
                    setCaseDetails([])
                }
            })
        } else {
            API.get(`organizations/${organization}/cases?queue=active&page=${page}&size=${perPage}`).then((res) => {
                if (res.status === 200 && res.data._embedded) {
                    res.data._embedded.immutableCaseList.forEach((e, i) => {
                        res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                        res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                        res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                        res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                        res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                    })
                    setCaseDetails(res.data._embedded.immutableCaseList)
                    setTotalRows(res.data.page.totalElements)
                } else {
                    setRecords(true)
                    setCaseDetails([])
                }
            })
        }
        if (res.data._embedded) {
            setRecords(false)
        }
        else {
            setRecords(true)
            setCaseDetails([])
        }
    }

    const changeSelect = (value) => {
        selectSearch.map((c) => {
            if (c.value === value) {
                setColumnSelect(value)
            }
            return c
        })
    }

    const searchRecords = () => {
        if (filter.length > 0) {
            if (statusFilter !== 'All Status' && statusFilter !== undefined && column && column !== undefined && filter && filter !== undefined) {
                API.get(`organizations/${organization}/cases?queue=active&page=1&size=${perPage}&status=${statusFilter}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                        setCaseDetails([])
                        res.data._embedded.immutableCaseList.forEach((e, i) => {
                            res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                            res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                            res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                            res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                            res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                        })
                        setCaseDetails(res.data._embedded.immutableCaseList)
                        setTotalRows(res.data.page.totalElements)
                    }
                    else {
                        setRecords(true)
                        setCaseDetails([])
                    }
                })
            } else if (statusFilter !== 'All Status' && statusFilter !== undefined && filter && filter !== undefined) {
                API.get(`organizations/${organization}/cases?queue=active&page=1&size=${perPage}&status=${statusFilter}&search=${filter}&searchField=${columnSelect}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                       setCaseDetails([])
                        res.data._embedded.immutableCaseList.forEach((e, i) => {
                            res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                            res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                            res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                            res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                            res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                        })
                        setCaseDetails(res.data._embedded.immutableCaseList)
                        setTotalRows(res.data.page.totalElements)
                    }
                    else {
                        setRecords(true)
                        setCaseDetails([])
                    }
                })
            } else if(column && column !== undefined && filter && filter !== undefined) {
                if(filter){
                    let Apipath = filter ? `page=1&size=${perPage}&search=${filter}&searchField=${columnSelect}&sort=${column},${sortDirection}` : `page=${page}&size=${perPage}`
                    API.get(`organizations/${organization}/cases?queue=active&${Apipath}`).then((res) => {
                        if (res.status === 200 && res.data._embedded) {
                            setPage(1)
                            res.data._embedded.immutableCaseList.forEach((e, i) => {
                                res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                                res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                                res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                                res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                                res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                            })
                            setCaseDetails(res.data._embedded.immutableCaseList)
                            setTotalRows(res.data.page.totalElements)
                        }
                        else {
                            setRecords(true)
                            setCaseDetails([])
                        }
                    })
                }
            }          
            else{
                API.get(`organizations/${organization}/cases?queue=active&page=1&size=${perPage}&search=${filter}&searchField=${columnSelect}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                       setCaseDetails([])
                        res.data._embedded.immutableCaseList.forEach((e, i) => {
                            res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                            res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                            res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                            res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                            res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                        })
                        setCaseDetails(res.data._embedded.immutableCaseList)
                        setTotalRows(res.data.page.totalElements)
                    }
                    else {
                        setRecords(true)
                        setCaseDetails([])
                    }
                })
            }
        }         
        else {
            if(statusFilter && statusFilter !== 'All Status'){
                let Apipath = statusFilter ? `page=1&size=${perPage}&status=${statusFilter}` : `page=${page}&size=${perPage}`
                API.get(`organizations/${organization}/cases?queue=active&${Apipath}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                        res.data._embedded.immutableCaseList.forEach((e, i) => {
                            res.data._embedded.immutableCaseList[i].caseLastUpdated = lastdat(res.data._embedded.immutableCaseList[i].caseLastUpdated)
                            res.data._embedded.immutableCaseList[i].caseReminderDate = remaindat(res.data._embedded.immutableCaseList[i].caseReminderDate)
                            res.data._embedded.immutableCaseList[i].followupDate = follow(res.data._embedded.immutableCaseList[i].followupDate)
                            res.data._embedded.immutableCaseList[i].dateClosed = datecon(res.data._embedded.immutableCaseList[i].dateClosed)
                            res.data._embedded.immutableCaseList[i].caseCreated = createdat(res.data._embedded.immutableCaseList[i].caseCreated)
                        })
                        setCaseDetails(res.data._embedded.immutableCaseList)
                        setTotalRows(res.data.page.totalElements)
                        setTotalPage(res.data.page.totalPages)
                    }
                    else {
                        setRecords(true)
                        setCaseDetails([])
                    }
                })
            }
          
        }
        if (res.data._embedded) {
            setRecords(false)
        }
        else {
            setRecords(true)
            setCaseDetails([])
        }
    }
 
    useEffect(() => {
        caseListSelected = []
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    },[])
    const rowSelectCritera = (row) => {
      return totalSelected.map(c => c.caseId).includes(row.caseId)
    }
    
    return (
        <div className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg relative" ref={caseTable}>
            {
                loading ?
                    <DatatableLoader /> : 
                    <DataTable title="casedetails" role='grid' columns={colums} data={filteredcasedetails} 
                    theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination paginationServer={true} noHeader highlightOnHover 
                    // selectableRows={(userDetails.role[0] !== 'referrer' && userDetails.role[0] !== 'screener' && organization && userDetails && userDetails.orgId === parseInt(organization)) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_cm')) || (userDetails.orgId !== parseInt(organization) && userDetails.role.length === 3 && userDetails.role.includes('referrer') && userDetails.role.includes('dynamic_admin') && userDetails.role.includes('dynamic_ref')) || ((userDetails.orgId !== parseInt(organization) && userDetails.role.length === 1 && userDetails.role.includes('dynamic_cm')) || ((userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin')))) ? true : false}
                    selectableRows={(userDetails.orgId === parseInt(organization) && (userDetails.role.includes('caremanager') || userDetails.role.includes('admin'))) || (userDetails.orgId !== parseInt(organization) && (userDetails.role.includes('dynamic_cm') || userDetails.role.includes('dynamic_admin'))) ? true : false}
                        selectableRowsComponentProps={{"role":"columnheader"}}
                        selectableRowSelected={rowSelectCritera}
                    selectableRowsHighlight persistTableHead subHeader onSort={handleSort}
                        sortServer paginationRowsPerPageOptions={sizePage} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} subHeaderComponent={SubHeader} onChangePage={val => setPage(val)}  paginationDefaultPage={page}  expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} onSelectedRowsChange={rowsSelected} selectableRowsNoSelectAll={false} paginationComponentOptions={{ rowsPerPageText: 'Items per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }} noDataComponent={t('There are no records to display')} tabIndex='0' />
                        // :<div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>

            }
           {records ?<div className='flex justify-center'> <span tabIndex={0} onEnter={() => closeFilterStatus()} onClick={() => closeFilterStatus()}><button aria-label="Back to All Cases" className='bg-tcolor flex px-6 py-0.5 mr-3 mt-3 items-center text-sm'>{t("Click here to All Cases")}</button> </span></div> : ''}

            <AnimatePresence>
                {

                    selectRows.length > 0 &&
                    <motion.button  variants={PopInAnim} initial="hidden" animate="visible" exit="hidden" className="flex items-center absolute bottom-6 ml-4 rounded text-white text-xs hover:bg-opacity-80" onClick={() => setOpenMultiAction(true)}>
                        <FaLayerGroup size="18px" className="mr-1 text-black dark:text-white" />
                        {selectRows.length > 0 &&
                            <span className="absolute -top-3 left-3/4 bg-tcolor rounded-lg py-0.5 px-1.5 text-[9px]">{selectRows.length}</span>
                        }
                        <PopoverMenu title={`${t('Action')} (${selectRows.length})`} origin="topright" active={openMultiAction} onClose={() => setOpenMultiAction(false)}>
                            {selectRows.every(i => i.statusId !== 11) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openCloseCaseModal()}>
                                <RiInboxArchiveFill size="16px" className="mr-2" />
                                {t("Close Case")}
                            </div> : ''
                            }
                            {/* {selectRows[0].caseStatus !== 'PENDING_REFERRER' ?: ''} */}
                            {
                                ((userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && selectRows[0].statusId !== 11 && selectRows[0].statusId !== 35) || (userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && selectRows[0].statusId === 11) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin'))) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openTransferCaseModal()}>
                                    <RiDirectionFill size="16px" className="mr-2" />
                                    {t("Transfer Case")}
                                </div> : ''
                            }
                            {
                               (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin')) && <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openTransferOrg()}>
                                    <RiDirectionFill size="16px" className="mr-2" />
                                    {t("Associated Child-org Transfer")}
                                </div> 
                            }
                             {
                                ((userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && selectRows.every(i => i.referredByName !== null)) ||(userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && selectRows.every(i => i.referredByName !== null))) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openReassignReferrerModal()}>
                                    <RiDirectionFill size="16px" className="mr-2" />
                                    {t("Reassign Referrer")}
                                </div> : ''
                            }
                            {selectRows.filter((c) => c.caseStatus === 'Closed').length === 0 ? ((userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && selectRows[0].statusId !== 11 && selectRows[0].statusId !== 35) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && selectRows[0].statusId !== 11 && selectRows[0].statusId !== 35 && selectRows[0].statusId !== 1 && selectRows[0].statusId !== 2 && selectRows[0].statusId !== 3 && selectRows[0].statusId !== 4 && selectRows[0].statusId !== 5 && selectRows[0].statusId !== 7 && selectRows[0].statusId !== 9)) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openChildOrgTransferCaseModal()}>
                                <RiDirectionFill size="16px" className="mr-2" />
                                {t("Child-org Transfer")}
                            </div> : '' : ''}
                            {selectRows.every(i => i.statusId === 11) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => reopenCase()}>
                                <RiDirectionFill size="16px" className="mr-2" />
                                {t("Reopen Case")}
                            </div> : ''}
                        </PopoverMenu>
                    </motion.button>
                }
            </AnimatePresence>
            <Dialog title={t("Close Case")} showDialog={closeCaseModal} onClose={() => closeModel()}>
                <ClosedCaseDialog onClose={() => closeModel()} caseIds={caseId} />
            </Dialog>
            <Dialog title={t("Transfer Case")} showDialog={transferCaseModal} onClose={() => setTransferCaseModal(false)}>
                <TransferCaseDialog onClose={() => setTransferCaseModal(false)} caseIds={caseId} />
            </Dialog>
            <Dialog title={t("Associated Child-org Transfer")} showDialog={transferOrg} onClose={() => setTransferOrg(false)}>
                <TransferOrg onClose={() => setTransferOrg(false)} caseIds={caseId} />
            </Dialog>
            <Dialog title={t("Reassign Referrer")} showDialog={reassignreferrerCaseModal} onClose={() => setReassignReferrerCaseModal(false)}>
                <ReferrerCaseDialog onClose={() => setReassignReferrerCaseModal(false)} caseIds={caseId} />
            </Dialog>
            <Dialog title={t("Child-org Transfer")} showDialog={childOrgTransferCaseModal} onClose={() => setChildOrgTransferCaseModal(false)}>
                <ChildOrgTransferDailog onClose={() => setChildOrgTransferCaseModal(false)} caseIds={caseId} />
                {/* <h1>Working in progress</h1> */}
            </Dialog>
            <Dialog title={t("Create Notes")} showDialog={notesDialogModal} onClose={() => setNotesDialogModal(false)}>
                <NotesDialog onClose={() => setNotesDialogModal(false)} props={caseId} type={'create'} />
            </Dialog>
            <Dialog title={t("Download File")} showDialog={downloadAssessmentPopup} onClose={() => setDownloadAssessmentPopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Are you sure you want to download this file?")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setDownloadAssessmentPopup(false)}>No</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => printAssessment()}>Yes</button>
                    </div>
                </div>
            </Dialog>
            <Dialog title={t("Download File")} showDialog={downloadSummaryPopup} onClose={() => setDownloadSummaryPopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Are you sure you want to download this file?")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setDownloadSummaryPopup(false)}>No</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => printSummary()}>Yes</button>
                    </div>
                </div>
            </Dialog>
            <Dialog title={t("Download File")} showDialog={downloadCareplanPopup} onClose={() => setDownloadCareplanPopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Are you sure you want to download this file?")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setDownloadCareplanPopup(false)}>No</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => printCareplan()}>Yes</button>
                    </div>
                </div>
            </Dialog>
            <Dialog title={t("Enhanced Close Case Reasons")} showDialog={enhancecloseCaseModal} onClose={() => closeReason()}>
                <EnhancedClosedDialog onClose={() => closeReason()} caseIds={caseId} />
            </Dialog>
        </div>
    )
}

export default CaseList
