import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { TextAnimateX, TextAnimateY } from '../../commons/anims'

const FlowText = ({ text, childrenStagger= 0.08, direction= 'y', force=false,arialabel }) => {

    const animate = useSelector(state => state.theme.animate)
    
    const wrapAnim = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                when: 'beforeChildren',
                staggerChildren: childrenStagger
            }
        }
    }
    return (
        <motion.span aria-hidden="true" variants={ animate || force ? wrapAnim : '' } initial="hidden" animate="visible"> 
            {
                text?.split('').map((c, i) => (
                    <motion.span key={i} className="inline-block" variants={ !animate && !force ? '' : direction === 'x' ? TextAnimateX : TextAnimateY }>{c === ' ' ? '\u00A0' : c}</motion.span>
                ))
            }
        </motion.span>

    )
}

export default FlowText