import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState,useRef } from "react"
import { useTranslation } from "react-i18next"
import { HiViewGridAdd } from "react-icons/hi"
import { IoMdTrash } from "react-icons/io"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import API from "../../axios/API"
import { PopInAnim } from "../../commons/anims"
import InlineTextField from "../../components/snippets/InlineTextField"
import { setAlert, setPageTitle } from "../../store/theme/actions"
import CareplanLoader from "./CareplanLoader"
import ServiceSelector from "./ServiceSelector"
import { useFormik } from "formik"
import FieldInput from "../../components/snippets/FieldInput"
import Dialog from "../../components/snippets/Dialog"
import ConfirmFinalize from "./ConfirmFinalize"
// import { FiDownload } from "react-icons/fi"
// import { SiMinutemailer } from "react-icons/si"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import { createPortal } from "react-dom"
import FlowText from "../../components/snippets/FlowText"
import axios from "axios"
// import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import FormCard from "../../components/snippets/FormCard"
import Clock from "../../components/snippets/Clock"
import { FiDownload } from "react-icons/fi"
import { SiMinutemailer } from 'react-icons/si'
import CareplanDialog from "../Dialogs/CareplanDialog"
import AdditionalServiceSelector from "./AdditionalServiceSelector"
// import { downloadCareplan, emailCareplan } from "../Case/CaseAction"

const initialValues = {
    cgResponsibility: '',
    cmResponsibility: '',
    followup: '',
    remind1: '',
    remind2: '',
    remind3: ''
}


const Careplan = ({ match }) => {
    const [loading, setLoading] = useState(true)
    const [chkAuthorized, setChkAuthorized] = useState(false)
    const [careplanData, setCareplanData] = useState({})
    const [showServiceSelector, setShowServiceSelector] = useState(false)
    const [selectorDetails, setSelectorDetails] = useState({})
    const [formData, setFormData] = useState(initialValues)
    const [edit, setEdit] = useState(false)
    const [rerun, setRerun] = useState(false)
    const [showFinalize, setShowFinalize] = useState(false)
    const [caseId, setCaseId] = useState('')
    const [demographic, setDemographic] = useState([])
    // const [race, setRace] = useState([])
    const history = useHistory()
    const [goalId, setGoalId] = useState('')
    // const [categoryId, setCategoryId] = useState('')
    // const [enableFinalize, setEnableFinalize] = useState(false)
    const [enableDownload, setEnableDownload] = useState(false)
    const [enableMail, setEnableMail] = useState(false)
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const [finalizePopup, setFinalizePopup] = useState(false)
    const [downloadPopup, setDownloadPopup] = useState(false)
    // const organization = useSelector(state => state.auth.organization)
    // const [blocksave, setBlocksave] =  useState(false)
    let orgId = localStorage.getItem('orgid')
    const [disable, setDisable] = useState(false)
    const [timeZone, setTimeZone] = useState('')
    const organization = useSelector(state=>state.auth.organization)
    const userDetails = useSelector(state=>state.auth.userDetails)
    const [serviceLen,setServiceLen] = useState(0)
    const [previousServiceFocus,setPreviousServiceFocus] = useState(null)
    const [previousDownloadFocus, setPreviousDownloadFocus] = useState(null);
    const [showAdditionalSelector, setShowAdditionalSelector] = useState(false)
    const [supportServices, setSupportServices] = useState([])
    const [additionalDesiredOutcome,setAdditionalDesiredOutcome] = useState('')


    const form = useFormik({
        initialValues: formData,
        enableReinitialize: true
    })

    useEffect(() => {
        setCaseId(match.params.caseId)
        setLoading(true)
        API.get(`organizations/${orgId}/cases/${match.params.caseId}/demographic`)
            .then(res => {
                if (res.status === 200) {
                    setDemographic(res.data.body)
                    if (res.data.body.caregiver.race === 1) {
                        res.data.body.caregiver.race = 'white'
                    } else if (res.data.body.caregiver.race === 2) {
                        res.data.body.caregiver.race = 'black or african american'
                    } else if (res.data.body.caregiver.race === 3) {
                        res.data.body.caregiver.race = 'hispanic or latin american'
                    } else if (res.data.body.caregiver.race === 4) {
                        res.data.body.caregiver.race = 'asian'
                    } else if (res.data.body.caregiver.race === 5) {
                        res.data.body.caregiver.race = 'american indian or alaska native'
                    } else if (res.data.body.caregiver.race === 6) {
                        res.data.body.caregiver.race = 'native hawaiian or pacific islander'
                    } else if (res.data.body.caregiver.race === 7) {
                        res.data.body.caregiver.race = 'middle eastern or north african'
                    } else if (res.data.body.caregiver.race === 8) {
                        res.data.body.caregiver.race = 'some other race/ethnicity'
                    } else if (res.data.body.caregiver.race === 9) {
                        res.data.body.caregiver.race = 'refused'
                    } else {
                        res.data.body.caregiver.race = "Other"
                    }
                    // setRace(res.data.body.caregiver.race)
                } else {
                    dispatch(setAlert({ title: t('unauthorized'), subtitle: t('unauthorized_to_view'), active: true, type: 'warning' }))
                    setDisable(false)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, []) // eslint-disable-line

    useEffect(() => {
        dispatch(setPageTitle(t('Care Plan')))
    }, [i18n.language]) // eslint-disable-line
    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/care-plans/${match.params.careplanId}/queue/${match.params.queue}`, {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Accept': 'application/json'
            }
        }).then(() => setChkAuthorized(true))
            .catch(err => {
                if (err) {
                    dispatch(setAlert({ title: t('unauthorized'), subtitle: t('unauthorized_to_view'), active: true, type: 'warning' }))
                    // history.push('/')
                }
            })
    }, []) // eslint-disable-line
    useEffect(() => {
        if (!chkAuthorized) return
        axios.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/care-plans/${match.params.careplanId}/queue/${match.params.queue}`, {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Accept': 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                let foldate = res.data.body.followupDate
                let remind1 = (res.data.body && res.data.body.reminder1 && res.data.body.reminder1 !== null && res.data.body.reminder1.split('-')[0] + "/" + res.data.body.reminder1.split('-')[1] + "/" + res.data.body.reminder1.split('-')[2]) || ''
                let remind2 = (res.data.body && res.data.body.reminder2 && res.data.body.reminder2 !== null && res.data.body.reminder2.split('-')[0] + "/" + res.data.body.reminder2.split('-')[1] + "/" + res.data.body.reminder2.split('-')[2]) || ''
                let remind3 = (res.data.body && res.data.body.reminder3 && res.data.body.reminder3 !== null && res.data.body.reminder3.split('-')[0] + "/" + res.data.body.reminder3.split('-')[1] + "/" + res.data.body.reminder3.split('-')[2]) || ''
                // formData.followup = res.data.body.followupDate
                setCareplanData(res.data.body)
                setAdditionalDesiredOutcome(res.data.body.supportServices.length > 0 ? res.data.body.supportServices[0].desiredOutcome : '')
                setSupportServices(res.data.body.supportServices.length > 0 ? res.data.body.supportServices :[])
                setEdit(true)
                setEnableDownload(true)
                setEnableMail(true)
                findServiceLength(res.data.body)
                let utc = moment.utc(foldate).local().format('YYYY-MM-DD HH:mm');
                setFormData({ remind1: remind1, remind2: remind2, remind3: remind3, followup: foldate ? utc : '' || '' })
                // if(res.data.data.status === 'pending') setEdit(true)
                setLoading(false)
            } else {
                dispatch(setAlert({ title: t('unauthorized'), subtitle: t('unauthorized_to_view'), active: true, type: 'warning' }))
                setDisable(false)
                // history.push('/')
            }
        })
            .catch(err => {
                console.log(err)
                if (err) {
                    dispatch(setAlert({ title: t('unauthorized'), subtitle: t('unauthorized_to_view'), active: true, type: 'warning' }))
                    setDisable(false)
                    // history.push('/')
                }
            })
    }, [chkAuthorized, rerun]) // eslint-disable-line


    const selectService = (goalType, strategy, strategyId) => {
        if (!edit) return
        setGoalId(goalType.goalId)
        setSelectorDetails({ goalType, strategy, strategyId: strategyId, categories: goalType.goalDetails[strategy].strategyDetails })
        setShowServiceSelector(true)
    }
    const serviceAdded = (category, type, val) => {
        if (!edit) return
        let data = JSON.parse(JSON.stringify(careplanData))
        data.caseGoals.filter(c => {
            if (c.goalId === goalId) {
                let count = 0;
                c.goalDetails.map(e => {
                    count = count + 1
                    if (e.strategyId === selectorDetails.strategyId) {
                        e.strategyDetails.map(f => {
                            f.goalTypeDetails.map(h => {
                                val.map(a => {
                                    if (a.serviceId === h.serviceId) {
                                        h.services.push(a)
                                    } return null;
                                })
                                return null;
                            })
                            return null;
                        })
                    } return null;
                })
                setCareplanData(data)
                setShowServiceSelector(false)

            }
            return null;
        })

    }

    // useLayoutEffect(() => {
    //     if(careplanData.caseGoals === undefined || blocksave) return
    //   saveCareplan()

    // }, [careplanData, blocksave]);// eslint-disable-line  

    const focusEvent = () => {
        // setBlocksave(true)
    }
    const blurEvent = () => {
        // setBlocksave(false)
        // saveCareplan1()
    }
    const OnmouseEnter = () => {
        var date = moment(new Date())
        var zone = careplanData.timeZoneOffset
        // var a = date.tz(zone).format('HH:SS z')
        setTimeZone(date.clone().tz(zone).format('MMM DD,YYYY hh:mm:ss A z'))
    }
    const setComment = (value, serviceId, i) => {
        let id = i
        if (!edit) return
        let data = JSON.parse(JSON.stringify(careplanData))
        data.caseGoals.map(a => {
            a.goalDetails.map(c => {
                c.strategyDetails.map(d => {
                    d.goalTypeDetails.map(e => {
                        e.services.map((f, i) => {
                            if (f.serviceId === serviceId.serviceId) {
                                if (f.rdbId && f.rdbId !== 0 && f.rdbId === serviceId.rdbId && id === i) {
                                    f.comment = value;
                                    return f
                                } else if (f.selectedServiceId !== null && f.selectedServiceId && f.selectedServiceId === serviceId.selectedServiceId && id === i) {
                                    f.comment = value
                                    return f
                                } else if (f.serviceId === serviceId.serviceId && f.findHelpId === serviceId.findHelpId && id === i) {
                                    f.comment = value
                                    return f
                                }
                            }
                            return null;
                        })
                        return null;
                    })
                    return null;
                })
                return null
            })
            return null;
        })
        setCareplanData(data)
    }
    const setOutcome = (goalType, strategy, value) => {
        if (!edit) return
        let data = JSON.parse(JSON.stringify(careplanData))
        data.caseGoals.map((c, i) => {
            if (c.goalId === goalType.goalId) {
                c.goalDetails.map((d, j) => {
                    if (goalType.goalDetails[parseInt(strategy)].strategyId === d.strategyId) {
                        d.desiredOutcome = value
                    }
                    return d
                })
            }
            return c
        })
        setCareplanData(data)
    }

    const showOutcome = (goalType, strategy) => {
        for (let category in goalType.goalDetails[strategy].strategyDetails) {
            for (let type in goalType.goalDetails[strategy].strategyDetails[category].goalTypeDetails) {
                if (goalType.goalDetails[strategy].strategyDetails[category].goalTypeDetails[type].services.length > 0) return true
            }
        }
        return false
    }
    const removeItem = (item, id) => {
        if (!edit) return
        let data = JSON.parse(JSON.stringify(careplanData))
        data.caseGoals.map(a => {
            a.goalDetails.map(c => {
                c.strategyDetails.map(d => {
                    d.goalTypeDetails.map(e => {
                        e.services.map((f, i) => {
                            if (f.serviceId === item.serviceId) {
                                if (i === id) {
                                    if ((item.rdbId !== 0) && (f.rdbId === item.rdbId)) {
                                        f.deleted = true
                                    } else if ((item.rdbId === 0) && (f.selectedServiceId === item.selectedServiceId)) {
                                        f.deleted = true
                                    }
                                }
                            }
                            return null;
                        })
                        return null
                    })
                    return null;
                })
                return null;
            })
            return null;
        })
        setCareplanData(data)

    }
    const setCgRespone = val => {
        if (!edit) return
        setCareplanData({ ...careplanData, cgResponsibility: val })
    }
    const setCmRespone = val => {
        if (!edit) return
        setCareplanData({ ...careplanData, cmResponsibility: val })
    }
    const saveCareplan = (action = 'save') => {
        let serviceLength = 0
        let count = 0
        careplanData.caseGoals.map(element => {
            element.goalDetails.map(e => {
                e.strategyDetails.map(f => {
                    f.goalTypeDetails.map((h, i) => {
                        if (h.services.length > 0) {
                            serviceLength = serviceLength + 1
                        }
                        h.services.forEach((j, k) => {
                            if (k <= h.services.length) {
                                if (j.deleted === true) {
                                    count = count + 1
                                }

                            }
                            if (count === h.services.length) {
                                e.desiredOutcome = ""
                            }
                            return null;
                        })

                        return null;
                    })
                    return null;
                })
                return null;
            })
            return null;
        })
        if (!edit) return
        // if (form.values.followup === '') {
        //     dispatch(setAlert({ title: t('Followup field is required'), subtitle: t('Please fill followup'), active: true, type: 'warning' }))
        // } 
        else {
            setEdit(false)
            careplanData.supportServices = supportServices.length > 0 ? supportServices : []
            API.post(`organizations/${orgId}/care-plans/${match.params.careplanId}/services`, careplanData)
                .then(res => {
                    if (res.status === 200) {
                        dispatch(setAlert({ title: t('Care Plan Saved Successfully'), subtitle: '', active: true, type: 'success' }))
                        setRerun(!rerun)
                        setEnableDownload(true)
                        setEnableMail(true)
                    } else {
                        dispatch(setAlert({ title: t('Unable to Save Care Plan'), subtitle: res.data.errors.join(', '), active: true, type: 'error' }))
                        setEdit(true)
                    }
                })
                .catch(err => {
                    if (err.response.status === 406) {
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        history.push(`/casedata/${caseId}`)

                    }
                })
        }
    }

    // const saveCareplan1 = (action = 'save') => {
    //     let serviceLength = 0
    //     careplanData.caseGoals.map(element =>{
    //         element.goalDetails.map(e=>{
    //             e.strategyDetails.map(f=>{
    //                 f.goalTypeDetails.map(h=>{
    //                     if(h.services.length >0){
    //                         serviceLength = serviceLength + 1
    //                     }
    //                     return null;
    //                 })
    //                 return null;
    //             })
    //             return null;
    //         })
    //         return null;
    //     })
    //     if (!edit) return
    //     // if (form.values.followup === '') {
    //     //     dispatch(setAlert({ title: t('Followup field is required'), subtitle: t('Please fill followup'), active: true, type: 'warning' }))
    //     // } 
    //     else {
    //         setEdit(false)
    //         API.post(`organizations/${orgId}/care-plans/${match.params.careplanId}/services`, careplanData)
    //         .then(res => {
    //             if (res.status === 200) {
    //                 // dispatch(setAlert({ title: t('Care Plan Saved Successfully'), subtitle: '', active: true, type: 'success' }))
    //                 setRerun(!rerun)
    //                 // setEnableDownload(true)
    //                 // setEnableMail(true)
    //             } else {
    //                 dispatch(setAlert({ title: t('Unable to Save Care Plan'), subtitle: res.data.errors.join(', '), active: true, type: 'error' }))
    //                 setEdit(true)
    //             }
    //         })
    //         .catch(err => {
    //             if (err.response.status === 406) {
    //                 dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
    //                 history.push(`/casedata/${caseId}`)

    //             }
    //         })
    //     }
    // }

    const finalize = () => {
        if (form.values.followup === '') {
            setDisable(false)
            dispatch(setAlert({ title: t('Followup field is required'), subtitle: t('Please fill followup'), active: true, type: 'warning' }))
        } else {
            setShowFinalize(true)
        }
    }

    const validateString = (values) => {
        let r = /^[^-\s]/g
        return r.test(values)
    }


    const finalizeCarePlan = () => {
        setDisable(true)
        let serviceLength = 0
        let deleted = 0
        careplanData.caseGoals.map(element => {
            element.goalDetails.map(e => {
                e.strategyDetails.map(f => {
                    f.goalTypeDetails.map(h => {
                        h.services.forEach((element, index) => {
                            if (element.deleted === false) {
                                deleted = deleted + 1
                            }

                        });
                        if (h.services.length === 0) {
                            //length is 0
                        }
                        if (h.services.length > 0 && deleted > 0 && h.goalId !== 0) {
                            serviceLength = serviceLength + 1
                        }
                        return null;
                    })
                    return null;
                })
                return null;
            })
            return null;
        })
        if (careplanData.cmResponsibility && careplanData.cgResponsibility && form.values.followup) {
            let cmValidation = validateString(careplanData.cmResponsibility)
            let cgValidation = validateString(careplanData.cgResponsibility)
            let time = form.values.followup.toString().slice(11, 16)
            let sigdate = moment().format('YYYY-MM-DD HH:mm:ss')
            let sDate = sigdate.slice(0, 10)
            let sTime = sigdate.slice(11, 16)
            let finalSDate = sDate + `T${sTime}:00z`
            // let time = form.values.followup.toString().split(' ')[1] ? form.values.followup.toString().split(' ')[1] : '00:00'
            if (cmValidation && cgValidation) {
                // if(serviceLength>0){
                //     API.post(`organizations/${orgId}/care-plans/${match.params.careplanId}/services`, careplanData)
                //         .then(res => {
                //             if (res.status === 200) {
                //                 // dispatch(setAlert({ title: t('Careplan Saved Successfully'), subtitle: '', active: true, type: 'success' }))
                //                 setRerun(!rerun)
                //                 let details = {
                //                     "caseId": parseInt(caseId),
                //                     "carePlanId": parseInt(match.params.careplanId),
                //                     "sigDate": new Date(),
                //                     "followupTriggerDate": form.values.followup.toString().replace('/','-').replace('/','-').slice(0,10) +`T${time}:00.000Z`,
                //                     "cmResponsibility": careplanData.cmResponsibility,
                //                     "cgResponsibility": careplanData.cgResponsibility
                //                 }
                //                 API.post(`/organizations/${orgId}/care-plans/${match.params.careplanId}:finalize`, details).then(res => {
                //                     setShowFinalize(false)
                //                     if (res.status === 200) {
                //                         history.push(`/casedata/${caseId}`);
                //                     }
                //                 })
                //             } else {
                //                 dispatch(setAlert({ title: t('Unable to Save Care Plan'), subtitle: res.data.errors.join(', '), active: true, type: 'error' }))
                //                 setEdit(true)
                //             }
                //         })
                //         .catch(err => {
                //             if (err.response.status === 406) {
                //                 dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                //                 history.push(`/casedata/${caseId}`)

                //             }else {
                //             console.log(err)
                //             dispatch(setAlert({ title: t('Unable to Save Care Plan'), subtitle: '', active: true, type: 'error' }))
                //             setEdit(true)}
                //         })
                // }
                if (serviceLength > 0) {

                    let details = {
                        "caseGoals": careplanData.caseGoals,
                        "caseId": parseInt(caseId),
                        "supportServices" : supportServices.length > 0 ? supportServices : [],
                        // "carePlanId": parseInt(match.params.careplanId),
                        "sigDate": finalSDate,
                        "followupDate": form.values.followup.toString().replace('/', '-').replace('/', '-').slice(0, 10) + `T${time}:00Z`,
                        "cmResponsibility": careplanData.cmResponsibility,
                        "cgResponsibility": careplanData.cgResponsibility
                    }
                    API.post(`/organizations/${orgId}/care-plans/${match.params.careplanId}:finalize`, details).then(res => {
                        setShowFinalize(false)
                        if (res.status === 200) {
                            history.push(`/casedata/${caseId}`);
                        }
                    })
                        .catch(err => {
                            if (err.response.status === 406) {
                                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                                history.push(`/casedata/${caseId}`)

                            } else {
                                console.log(err)
                                dispatch(setAlert({ title: t('Unable to Save Care Plan'), subtitle: '', active: true, type: 'error' }))
                                setEdit(true)
                            }
                            setDisable(false)
                        })
                }

                else if (serviceLength === 0) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: t('Add atleast one services to Finalize Care Plan'), active: true, type: 'warning' }))
                    setShowFinalize(false)
                    setDisable(false)
                }
            } else if (!cmValidation) {
                dispatch(setAlert({ title: t('Warning'), subtitle: t('Empty space at the beginning or empty values are not accepted at Caremanager responsibility field'), type: 'warning', active: true }))
                setDisable(false)
                setShowFinalize(false)
                setDisable(false)
            } else if (!cgValidation) {
                dispatch(setAlert({ title: t('Warning'), subtitle: t('Empty space at the beginning or empty values are not accepted at Caregiver responsibility field'), type: 'warning', active: true }))
                setShowFinalize(false)
                setDisable(false)
            }
        } else if (!careplanData.cgResponsibility && !careplanData.cmResponsibility) {
            dispatch(setAlert({ title: t('Warning'), subtitle: t('Caregiver and Caremanager Responsibility is a required field'), type: 'warning', active: true }))
            setShowFinalize(false)
            setDisable(false)
        } else if (!careplanData.cgResponsibility) {
            dispatch(setAlert({ title: t('Warning'), subtitle: t('Caregiver Responsibility is a required field'), type: 'warning', active: true }))
            setShowFinalize(false)
            setDisable(false)
        } else if (!careplanData.cmResponsibility) {
            dispatch(setAlert({ title: t('Warning'), subtitle: t('Caremanager Responsibility is a required field'), type: 'warning', active: true }))
            setShowFinalize(false)
            setDisable(false)
        }
    }


    const addCategoryId = (val) => {
        // setCategoryId(val)
    }

    const proceedFinalize = () => {
        // setEnableFinalize(true)
        setFinalizePopup(false)
    }
    const setDate = (val) => {
        let time = val.value.toString().slice(11, 16)
        careplanData.followupDate = val.value.toString().replace('/', '-').replace('/', '-').slice(0, 10) + `T${time}:00Z`

    }
    const setRemind1 = (val) => {
        careplanData.reminder1 = (val.value && val.value !== null && val.value.toString().replace('/', '-').replace('/', '-').slice(0, 10)) || null
    }
    const setRemind2 = (val) => {
        careplanData.reminder2 = (val.value && val.value !== null && val.value.toString().replace('/', '-').replace('/', '-').slice(0, 10)) || null
    }
    const setRemind3 = (val) => {
        careplanData.reminder3 = (val.value && val.value !== null && val.value.toString().replace('/', '-').replace('/', '-').slice(0, 10)) || null
    }
    // const shareCareplan = () => {
    //     let details = {
    //         caseId: match.params.caseId,
    //         cpId: match.params.careplanId,
    //         assessId: match.params.assessmentId,
    //         step: 2,
    //         lang: 'en',
    //         orgId: organization,
    //         draft :1
    //     }
    //     API.post(`care-plans/${match.params.careplanId}:share`,details).then(res=>{
    //         if(res.status === 200){
    //             dispatch(setAlert({title:t('Success'),subtitle:t('Care Plan shared successfully'),type:'success',active:true}))
    //         }
    //     })
    // }
    const printCareplan = () => {
        // let details = {
        //     caseId: match.params.caseId,
        //     cpId: match.params.careplanId,
        //     assessId: match.params.assessmentId,
        //     step: 2,
        //     lang: 'en',
        //     orgId: organization
        // }
        axios.get(`organizations/${orgId}/care-plans/${match.params.careplanId}/queue/${match.params.queue}`, {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Accept': 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                const linkSource = `data:application/pdf;base64,${res.data.body}`;
                const downloadLink = document.createElement("a");
                const fileName = "TCE-Care Plan.pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                setDownloadPopup(false)
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
                setDownloadPopup(false)
            }
        }).catch(err => {
            dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
            setDownloadPopup(false)
        })
    }

    const shareCareplan = () => {
        let timestamp = new Date().getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        let details = {
            caseId: match.params.caseId,
            cpId: match.params.careplanId,
            assessId: match.params.assessmentId,
            step: 2,
            lang: 'en',
            orgId: organization,
            draft: 1,
            timeZone: tzabbr
        }
        API.post(`care-plans/${match.params.careplanId}:share`, details).then(res => {
            if (res.status === 200 && res.data.body === true) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('Care Plan shared successfully'), type: 'success', active: true }))
            } else if (res.status === 200 && res.data.status === false) {
                dispatch(setAlert({ title: t('Warning'), subtitle: res.data.errorMessage, active: true, type: 'warning' }))
            }
        }).catch(err => {
            dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
        })
    }

    const findServiceLength = (value) => {
        let serviceLength = 0
        let count = 0
        value.caseGoals.map(element => {
            element.goalDetails.map(e => {
                e.strategyDetails.map(f => {
                    f.goalTypeDetails.map((h, i) => {
                        if (h.services.length > 0) {
                            serviceLength = serviceLength + 1
                            setServiceLen(serviceLength)
                        } else if (h.services.length === 0) {
                            setServiceLen(serviceLength)
                        }
                        h.services.forEach((j, k) => {
                            if (k <= h.services.length) {
                                if (j.deleted === true) {
                                    count = count + 1
                                }

                            }
                            if (count === h.services.length) {
                                e.desiredOutcome = ""
                            }
                            return null;
                        })

                        return null;
                    })
                    return null;
                })
                return null;
            })
            return null;
        })
    }

    const dialogRef = useRef(null);
    const downloadRef = useRef(null)
useEffect(() => {
  if (showServiceSelector) { 
    setPreviousServiceFocus(document.activeElement);
    dialogRef.current?.focus();
  }else{
    previousServiceFocus && previousServiceFocus.focus();
  }
}, [showServiceSelector]);
    
  useEffect(() => {
    if (downloadPopup) {
      setPreviousDownloadFocus(document.activeElement);
      downloadRef.current?.focus();
    } else {
      previousDownloadFocus && previousDownloadFocus.focus();
    }
  }, [downloadPopup]);
    const addSupportServices = (values) => {
        values[0].serviceId = 0
        setSupportServices((prevData)=>[...prevData,...values])
        setShowAdditionalSelector(false)
    }

    const setAdditionalComment = (value,index)=>{
        setSupportServices(prevData=>{
            const newData = [...prevData]
            newData[index] = {...newData[index],comment : value}
            return newData
        })
    }

    const AddDesiredoutcome =(value,index) =>{
        setSupportServices(prevData=>{
            const newData = [...prevData]
            newData[index] = {...newData[index],desiredOutcome : value}
            return newData
        })
    }
    
    const removeAdditionalServices = (value,index)=>{
        setSupportServices(prevData=>{
            const newData = [...prevData]
            newData[index] = {...newData[index],deleted: true}
            return newData
        })
    }

    return (
        <div className="mb-8" aria-hidden={showServiceSelector}>
            {loading ? <CareplanLoader />
                :
                <div>
                    <div className="flex justify-between text-lg font-bold">
                        <div>
                            <div tabIndex={0}>
                                <span className="text-zinc-600 dark:text-gray-400">{t("Care Plan for")} </span><span className="text-gray-600 dark:text-white" data-private="lipsum">{demographic.caregiver.firstName} {demographic.caregiver.lastName}</span>
                            </div>
                            <div tabIndex={0}>
                                <span className="text-zinc-600 dark:text-gray-400">{t("Caring for")} </span><span className="text-gray-600 dark:text-white" data-private="lipsum">{demographic.careReceiver.firstName} ({demographic.careReceiver.relationship})</span>
                            </div>
                            <div>
                                <span tabIndex={0} className="text-zinc-600 dark:text-gray-400">{t("Case Id")} &nbsp;&nbsp;</span><span className="text-gray-600 dark:text-white underline"><Link to={`/casedata/${caseId}`}>{caseId}</Link></span>
                            </div>
                        </div>
                        <div className="flex gap-4 items-center">
                            {edit && ((userDetails.orgId === parseInt(organization) && userDetails.parentRole.includes('caremanager')) || (userDetails.orgId !== parseInt(organization) && userDetails.childRole.includes('dynamic_cm'))) &&
                                serviceLen > 0 &&
                                <>
                                    {/* downloadCareplan(match.params.careplan_id, careplanData.caregiver) */}
                                    {enableDownload ? <button className="flex gap-1 font-bold items-center py-0.5 px-4 ml-2 text-sm rounded bg-tcolor hover:bg-opacity-80 text-white cursor-pointer" tabIndex={0} onClick={() => setDownloadPopup(true)}>
                                        <FiDownload /> {t('Download')}
                                    </button>:''}
                                    
                                    {/* emailCareplan(match.params.careplan_id, dispatch, t) */}
                                    {enableMail ?  <button className="flex gap-1 font-bold items-center py-0.5 px-4 ml-2 text-sm rounded bg-tcolor hover:bg-opacity-80 text-white cursor-pointer" tabIndex={0} onClick={() => shareCareplan()}>
                                        <SiMinutemailer /> {`${t('Send to')} ${t('caregiver')}`}
                                    </button>:''}
                                   
                                </>
                            }
                        </div>

                    </div>
                    <div className="flex flex-col gap-4 mt-6">
                        {
                            careplanData.caseGoals.map((goalType,l) => (
                                goalType.goalName !== undefined && goalType.goalId !== 0 &&
                                <motion.section  key={l} className="form-wrap py-4 m-0">
                                    <div tabIndex={0} className="text-lg font-bold text-gray-700 dark:text-white">
                                        {goalType.goalName}
                                    </div>
                                    <div className="flex flex-col gap-3 mt-3 ml-3">
                                        {
                                            Object.keys(goalType.goalDetails).map((strategy,i) => (
                                                <div key={i}>
                                                    <div className="flex flex-col gap-2 text-gray-600 dark:text-gray-200">
                                                        <div className="flex items-center gap-2 font-semibold">
                                                            <div>{goalType.goalDetails[strategy].strategyName}</div>
                                                            {edit &&
                                                                <button aria-label={goalType.goalDetails[strategy].strategyName + " Add"} className="flex gap-1 items-center bg-gradient-to-tr from-tcolor to-blue-400 px-3 py-0.5 rounded text-[10px] cursor-pointer hover:ring-2 hover:ring-offset-2 hover:ring-tcolor hover:ring-offset-gray-200 dark:hover:ring-offset-ldark text-white" onClick={() => selectService(goalType, strategy, goalType.goalDetails[strategy].strategyId)}>
                                                                    <HiViewGridAdd size="12px" /><span>{t("Add")}</span>
                                                                </button>
                                                            }
                                                        </div>
                                                        {(goalType.goalDetails[strategy].desiredOutcome || showOutcome(goalType, strategy)) &&
                                                            <div>
                                                                <InlineTextField ariaLabel="Desired Outcomes" label={t("Desired Outcomes")} value={goalType.goalDetails[strategy].desiredOutcome} setValue={val => setOutcome(goalType, strategy, val)} focus={focusEvent} blur={blurEvent} showSearch={false} />
                                                            </div>
                                                        }
                                                        <div>
                                                            <div>
                                                                {
                                                                    Object.keys(goalType.goalDetails[strategy].strategyDetails).map((category,j) => (
                                                                        <div key={j}>
                                                                            {
                                                                                Object.keys(goalType.goalDetails[strategy].strategyDetails[category].goalTypeDetails).map((type,k) => (
                                                                                    <div key={k}>
                                                                                        {goalType.goalDetails[strategy].strategyDetails[category].goalTypeDetails[type].services.length > 0 &&
                                                                                            <div className="flex flex-col gap-4 pl-3" key={k}>
                                                                                                {/* <div className="text-sm font-bold mt-1 -mb-2 text-gray-500 dark:text-gray-300">{t('These support service are to help you adjust or change your personal rules')}</div> */}
                                                                                                <AnimatePresence key={k}>
                                                                                                    {
                                                                                                        goalType.goalDetails[strategy].strategyDetails[category].goalTypeDetails[type].services.map((item, i) => (
                                                                                                            <>
                                                                                                                {
                                                                                                                    !item.deleted && <motion.div variants={PopInAnim} initial="hidden" animate="visible" exit="hidden" key={i} className="relative ring-1 ring-gray-400 dark:ring-gray-600 rounded-lg pl-3 py-2">
                                                                                                                        {item.info && item.info !== '' && <div tabIndex={0} className="font-bold text-gray-600 dark:text-tcolor">{item.info}</div>}
                                                                                                                        {/* {item.value.content && item.value.content !== '' && <div className="font-bold text-tcolor">{item.value.content}</div>} */}
                                                                                                                        <div className="text-sm mt-1">
                                                                                                                            {item.address && item.address !== '' &&
                                                                                                                                <>
                                                                                                                                    <div>{item.address}</div>
                                                                                                                                    <div>{`${item.city}, ${item.state} ${item.zipCode}`}</div>
                                                                                                                                </>
                                                                                                                            }
                                                                                                                            {item.phone && item.phone !== '' && <div>{`${item.phone !== '-' ? item.phone : ''}`}</div>}
                                                                                                                            {item.webLink && item.webLink !== '' && <div><a className="underline" target="_blank" rel="noopener noreferrer" href={(item.webLink.indexOf("http://") === 0 || item.webLink.indexOf("https://") === 0 || item.webLink.indexOf("HTTP://") === 0 || item.webLink.indexOf("HTTPS://") === 0) ? `${item.webLink}` : `https://${item.webLink}`}>{`${item.webLink !== '-' ? item.webLink : ''}`}</a></div>}
                                                                                                                            <div className="mr-3 mt-3">
                                                                                                                                <textarea placeholder={t('Comment')} className={`pb-1.5 pl-3 pt-2 text-sm bg-gray-300 dark:bg-ldark text-black dark:text-white pr-10 w-full rounded h-[90%] outline-none`} value={item.comment || ''} onChange={ev => setComment(ev.target.value, item, i)} onBlur={blurEvent} onFocus={focusEvent} />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        {edit &&
                                                                                                                            <div tabIndex={0} className="absolute top-2 right-3 p-0.5 rounded-full bg-red-500 text-white cursor-pointer" title={t('Remove')} onClick={() => removeItem(item, i)}>
                                                                                                                                <IoMdTrash size="20px" />
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </motion.div>
                                                                                                                }
                                                                                                            </>
                                                                                                        ))
                                                                                                    }
                                                                                                </AnimatePresence>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </motion.section>
                            ))
                        }
                        <motion.section aria-label={'Additional Resources and Services'} className="form-wrap py-4 m-0">
                            <div className="text-lg font-bold text-gray-700 dark:text-white">
                                {'Additional Resources and Services'}
                            </div>
                            <div className="flex flex-col gap-3 mt-3 ml-3">
                                <div className="flex flex-col gap-2 text-gray-600 dark:text-gray-200">
                                    <div className="flex items-center gap-2 font-semibold">
                                        <div>{'Add Support Resources and Services'}</div>
                                        {edit &&
                                            <button aria-label={'Add Support Resources and Services' + " Add"} onClick={() => setShowAdditionalSelector(true)} className="flex gap-1 items-center bg-gradient-to-tr from-tcolor to-blue-400 px-3 py-0.5 rounded text-[10px] cursor-pointer hover:ring-2 hover:ring-offset-2 hover:ring-tcolor hover:ring-offset-gray-200 dark:hover:ring-offset-ldark text-white">
                                                <HiViewGridAdd size="12px" /><span>{t("Add")}</span>
                                            </button>
                                        }
                                    </div>
                                  
                                    {supportServices !== undefined && supportServices.length > 0 && <div className="flex flex-col gap-4 pl-3">
                                                    {/* <div className="text-sm font-bold mt-1 -mb-2 text-gray-500 dark:text-gray-300">{t('These support service are to help you adjust or change your personal rules')}</div> */}
                                                    <AnimatePresence>
                                                        {
                                                           supportServices.map((item, i) => (
                                                                <>
                                                                    {
                                                                        !item.deleted && <motion.div variants={PopInAnim} initial="hidden" animate="visible" exit="hidden" key={i} className="relative ring-1 ring-gray-400 dark:ring-gray-600 rounded-lg pl-3 py-2">
                                                                        <div className="mb-2 px-1"><InlineTextField label={t("Desired Outcomes")} value={item.desiredOutcome} setValue={val => AddDesiredoutcome(val,i)} focus={focusEvent} blur={blurEvent} showSearch={false} /> </div>
                                                                            {item.info && item.info !== '' && <div className="font-bold text-gray-600 dark:text-tcolor">{item.info}</div>}
                                                                            {/* {item.value.content && item.value.content !== '' && <div className="font-bold text-tcolor">{item.value.content}</div>} */}
                                                                            <div className="text-sm mt-1">
                                                                                {item.address && item.address !== '' &&
                                                                                    <>
                                                                                        <div>{item.address}</div>
                                                                                        <div>{`${item.city}, ${item.state} ${item.zipCode}`}</div>
                                                                                    </>
                                                                                }
                                                                                {item.phone && item.phone !== '' && <div>{`${item.phone !== '-' ? item.phone : ''}`}</div>}
                                                                                {item.webLink && item.webLink !== '' && <div><a className="underline" target="_blank" rel="noopener noreferrer" href={(item.webLink.indexOf("http://") === 0 || item.webLink.indexOf("https://") === 0 || item.webLink.indexOf("HTTP://") === 0 || item.webLink.indexOf("HTTPS://") === 0) ? `${item.webLink}` : `https://${item.webLink}`}>{`${item.webLink !== '-' ? item.webLink : ''}`}</a></div>}
                                                                                <div className="mr-3 mt-3">
                                                                                    <textarea placeholder={t('Comment')} className={`pb-1.5 pl-3 pt-2 text-sm bg-gray-300 dark:bg-ldark text-black dark:text-white pr-10 w-full rounded h-[90%] outline-none`} value={item.comment || ''} onChange={ev => setAdditionalComment(ev.target.value, i)} onBlur={blurEvent} onFocus={focusEvent} />
                                                                                </div>
                                                                            </div>
                                                                            {edit &&
                                                                                <div className="absolute top-2 right-3 p-0.5 rounded-full bg-red-500 text-white cursor-pointer" title={t('Remove')} onClick={() => removeAdditionalServices(item, i)}>
                                                                                    <IoMdTrash size="20px" />
                                                                                </div>
                                                                            }
                                                                        </motion.div>
                                                                    }
                                                                </>
                                                            ))
                                                        }
                                                    </AnimatePresence>
                                                </div>}
                                </div>
                            </div>
                        </motion.section>
                        <div className="form-wrap py-4 m-0">
                            <div className="text-lg font-bold text-gray-700 dark:text-white mb-2" tabIndex={0}>
                                {t("Caregiver's Responsibilities")}
                            </div>
                            <div>
                                <textarea aria-label={"Caregiver's Responsibilities"} placeholder={t('Type Here')} className={`pb-1.5 pl-3 pt-2 text-sm bg-gray-300 dark:bg-ldark text-black dark:text-white pr-10 w-full rounded h-[90%] outline-none`} value={careplanData.cgResponsibility || ''} onChange={ev => setCgRespone(ev.target.value)} onBlur={blurEvent} onFocus={focusEvent} />
                            </div>
                        </div>
                        <div className="form-wrap py-4 m-0">
                            <div className="text-lg font-bold text-gray-700 dark:text-white mb-2" tabIndex={0}>
                                {t('Care Manager Responsibilities')}
                            </div>
                            <div>
                                <textarea aria-label={"Care Manager Responsibilities"} placeholder={t('Type Here')} className={`pb-1.5 pl-3 pt-2 text-sm bg-gray-300 dark:bg-ldark text-black dark:text-white pr-10 w-full rounded h-[90%] outline-none`} value={careplanData.cmResponsibility || ''} onChange={ev => setCmRespone(ev.target.value)} onBlur={blurEvent} onFocus={focusEvent} />
                            </div>
                        </div>

                     
                       { careplanData.automaticReminder	? <FormCard>
                        <div className="text-lg font-bold text-gray-700 dark:text-white mb-1" tabIndex={0}>
                                {t('Automated Reminder')}
                            </div>
                       <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                      <FieldInput ariaLabel={t("First Reminder")} type="date" minDate={new Date()}  loading={false} label={t("First Reminder")} autoComplete='off' fieldMeta={form.getFieldMeta('remind1')} fieldProps={form.getFieldProps('remind1')} fieldHelper={form.getFieldHelpers('remind1')}  edit={edit}  dataLoaded={true} onChange={setRemind1(form.getFieldProps('remind1'))}/>
                      <FieldInput ariaLabel={t("Second Reminder")} type="date" minDate={new Date(form.values.remind1)}  loading={false} label={t("Second Reminder")} autoComplete='off' fieldMeta={form.getFieldMeta('remind2')} fieldProps={form.getFieldProps('remind2')} fieldHelper={form.getFieldHelpers('remind2')}  edit={edit}  dataLoaded={true} onChange={setRemind2(form.getFieldProps('remind2'))}/>
                      <FieldInput ariaLabel={t("Third Reminder")} type="date" minDate={new Date(form.values.remind2)}  loading={false}  label={t("Third Reminder")} autoComplete='off' fieldMeta={form.getFieldMeta('remind3')} fieldProps={form.getFieldProps('remind3')} fieldHelper={form.getFieldHelpers('remind3')}  edit={edit}  dataLoaded={true} onChange={setRemind3(form.getFieldProps('remind3'))}/>
                      </div>
                           </FormCard> :''}
                     
                        <div className="form-wrap py-4 m-0">
                            <div className="flex text-lg font-bold text-gray-700 dark:text-white mb-2"  tabIndex={0} onMouseEnter={() => OnmouseEnter()} >
                                {t('Followup Date')}
                                <Clock title={timeZone} />
                            </div>
                            <div>
                                <FieldInput ariaLabel={"Followup Date"} type="datetime" minDate={new Date()} loading={false} label={t('Followup Date')} autoComplete='off' fieldMeta={form.getFieldMeta('followup')} fieldProps={form.getFieldProps('followup')} fieldHelper={form.getFieldHelpers('followup')} edit={edit} dataLoaded={true} onChange={setDate(form.getFieldProps('followup'))}/>
                            </div>
                        </div>
                        {edit &&
                            <div className="flex gap-4 justify-center mr-4">
                                <button disabled={loading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-tcolor hover:bg-opacity-80 text-black" onClick={() => saveCareplan()}>{t('save')}</button>
                                <button disabled={loading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => finalize()}>{t('Finalize Care Plan')}</button>
                            </div>
                        }
                    </div>
                </div>
            }
            {
                showServiceSelector && createPortal(<div role="dialog">
                <div ref={dialogRef} tabIndex={-1} aria-modal="true" role="dialog" aria-labelledby="dialogTitle"></div>
                <ServiceSelector active={showServiceSelector} categories={selectorDetails.categories} adhoc={careplanData.caseGoals} zip={careplanData.zip} onClose={() => setShowServiceSelector(false)} onAdd={(category, type, val) => serviceAdded(category, type, val)} onAddCategoryId={(val) => addCategoryId(val)} careplanId={match.params.careplanId} />
                </div>,document.body)
            }
            <div>
            </div>
            <AdditionalServiceSelector active={showAdditionalSelector} zip={careplanData.zip} careplanId={match.params.careplanId} onClose={() => setShowAdditionalSelector(false)} onAdd={(ev) => addSupportServices(ev)} />
            <Dialog showDialog={showFinalize} title={t('Finalize Care Plan')} onClose={() => {setShowFinalize(false)}} >
                <ConfirmFinalize disabled={disable} onClose={() => setShowFinalize(false)} onFinalize={finalizeCarePlan} />
            </Dialog>
            {
                createPortal(<Dialog title="Confirmation" ref={dialogRef} tabIndex={-1} aria-modal="true" showDialog={finalizePopup} onClose={() => setFinalizePopup(false)}>
                    <div className="py-2 w-full">
                        <div className="px-4 mt-2" tabIndex={0}>
                            <FlowText text={t(`Are you sure you want to proceed with finalize`)} />
                        </div>
                        <div className="flex justify-between mt-8 mx-2">
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setFinalizePopup(false)}>{t("No")}</button>
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => proceedFinalize()}>{t("Yes")}</button>
                        </div>
                    </div>
                </Dialog>, document.body)
            }
            {
                downloadPopup && 
                createPortal(<div role="dialog">
                    <div ref={downloadRef} role="dialog" tabIndex={-1} aria-modal="true"></div>
                <CareplanDialog prefLanguage={careplanData.prefLanguage} active={downloadPopup} onClose={() => setDownloadPopup(false)} assessmentId={match.params.assessmentId} caseId={match.params.caseId} careplanId={match.params.careplanId} type={'create'} /> 
                    {/* <div ref={downloadRef} role="dialog" tabIndex={-1} aria-modal="true">
                   <div className="py-2 w-full">
                        <div className="px-4 mt-2">
                            
                        </div>
                    </div> */}
                    </div>, document.body)
            }
        </div>
    )
}

export default withRouter(Careplan)