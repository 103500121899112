import { useCallback, useMemo, useState, useEffect } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import { useSelector } from 'react-redux'
import InlineTextField from '../../components/snippets/InlineTextField'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import FormCard from '../../components/snippets/FormCard'
import API from '../../axios/API'
import { useTranslation } from 'react-i18next'
import FlowText from '../../components/snippets/FlowText'

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const Associatedorgs = () => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [childOrganizationList, setChildOrganizationList] = useState([]);
    const [parentOrganizationList, setParentOrganizationList] = useState([]);
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation()
    const organization = useSelector(state => state.auth.organization)
    useEffect(() => {
        setLoading(true);
        API.get(`organizations/${organization}/associated`).then((res) => {
            setChildOrganizationList(res.data.child)
            setParentOrganizationList(res.data.parent)
        }).catch(e => {
            // console.log(e)
        }).finally(() => setLoading(false))
    }, [organization])// eslint-disable-line


    const colums = useMemo(() => {
        return [
            { name: t('Organization Name'), selector: 'organizationName',cell:row =><span aria-label={`Organization Name ${row.organizationName}`} tabIndex={0}>{row.organizationName}</span>, grow: 1, sortable: true },
            { name: t('Address'), selector:row =><span tabIndex={0} aria-label={`Address ${row.organizationAddress.addressLine1 ? row.organizationAddress.addressLine1 : ''} ${row.organizationAddress.addressLine2 ? row.organizationAddress.addressLine2 : ''} ${row.organizationAddress.addressLine3 ? row.organizationAddress.addressLine3 : ''} ${row.organizationAddress.addressLine1 && row.organizationAddress.addressLine2 && row.organizationAddress.addressLine3 ? "," : ''} ${row.organizationAddress.city ? row.organizationAddress.city : ''},${row.organizationAddress.country ? row.organizationAddress.country : ''}, ${row.organizationAddress.state ? row.organizationAddress.state : ''},${row.organizationAddress.postalCode ? row.organizationAddress.postalCode : ''}`}> {row.organizationAddress.addressLine1 ? row.organizationAddress.addressLine1 : ''} {row.organizationAddress.addressLine2 ? row.organizationAddress.addressLine2 : ''} {row.organizationAddress.addressLine3 ? row.organizationAddress.addressLine3 : ''} {row.organizationAddress.addressLine1 && row.organizationAddress.addressLine2 && row.organizationAddress.addressLine3 ? "," : ''} {row.organizationAddress.city ? row.organizationAddress.city : ''},{row.organizationAddress.country ? row.organizationAddress.country : ''}, {row.organizationAddress.state ? row.organizationAddress.state : ''},{row.organizationAddress.postalCode ? row.organizationAddress.postalCode : ''}</span>, grow: 1, sortable: true },
            { name: t('Phone'), selector: 'phone', cell:row =><span aria-label={`Phone ${row.phone}`} tabIndex={0}>{row.phone}</span>, grow: 1, sortable: true },
            { name: t('License Count'), selector: 'licenses', cell:row =><span aria-label={`License Count ${row.licenses}`} tabIndex={0}>{row.licenses}</span>, grow: 1, sortable: true },
            // { name: 'License Used', selector: 'email', grow: 1, sortable: true }
        ]
    }, [])// eslint-disable-line

    const SubHeader = useMemo(() => (
        <div>
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line
    
    const childFilteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return childOrganizationList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [childOrganizationList, filter])// eslint-disable-line

    const parentFilteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return parentOrganizationList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [parentOrganizationList])// eslint-disable-line
    const ExpandComponent = useCallback(row => {
        return (

            <div className="bg-bluegray-300 dark:bg-ldark" key={row.data.caseId}>
                {
                    Object.keys(row.data.child).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>

        )
    }, []) // eslint-disable-line
    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    })    

    return (
        <div class="pb-8">
            {
                loading ?
                    <DatatableLoader /> :
                    <div>
                        <div className=" mt-4 p-2 ">
                            <FormCard title={t("Parent Organizations")} className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg">
                                {parentFilteredcasedetails.length>0?
                                    <DataTable columns={colums} data={parentFilteredcasedetails} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} noHeader expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} noDataComponent={t('There are no records to display')}/>:
                                    <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>
                                }
                            </FormCard>
                        </div>
                        <div className=" mt-4 p-2 ">
                            <FormCard title={t("Child Organizations")} className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg">
                                <DataTable title="casedetails" columns={colums} data={childFilteredcasedetails} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} noDataComponent={t('There are no records to display')} />
                                {/* :<div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div> */}
                            
                                </FormCard>
                        </div>
                    </div>
            }
        </div>

    )
}

export default Associatedorgs