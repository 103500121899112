
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import API from '../../axios/API'
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/theme/actions';
import { useTranslation } from "react-i18next"
import CareplanSelect from '../../components/snippets/CareplanSelect';
// import axios from "axios"
import moment from 'moment';
import Dialog from '../../components/snippets/Dialog';
const initialFormValues = {
    language: ''

}


const CareplanDialog = ({ onClose, caseId, assessmentId, careplanId, match, type,prefLanguage,active }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    let orgId = localStorage.getItem('orgid')
    const [language, setLanguage] = useState([])
    let lang = localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))
    // const organization = useSelector(state => state.auth.organization)



    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s][a-zA-Z_0-9\s-]+$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })

    useEffect(() => {
        if (menuItems !== undefined && menuItems !== null && menuItems !== '') {
            if (menuItems.language && menuItems.language.length > 0 && menuItems.language !== undefined) {
                setLanguage(menuItems.language.map(c => ({ text: c.name, value: c.key, selected: true })))
            } else {
                API.get(`menus/languages?lang=${lang}`).then((languageResp) => {
                    setLanguage(languageResp.data.body.map(c => ({ text: c.name, value: c.key, selected: true })))
                })
            }
            setDataLoaded(true)
        } else {
            API.get(`menus/languages?lang=${lang}`).then(((languageResp) => {
                setLanguage(languageResp.data.body.map(c => ({ text: c.name, value: c.key, selected: true })))
                setDataLoaded(true)
            }))
        }
    }, []) // eslint-disable-line

    const reasonFormSchema = Yup.object().shape({
        language: Yup.string().required(t("Language is a required field")),
    })

    const reasonForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: reasonFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            submitForm()
        }
    })

    // const shareCareplan = () => {
    //     let timestamp = new Date().getTime();
    //     let tz = moment.tz.guess()
    //     let tzabbr = moment.tz.zone(tz).abbr(timestamp)
    //     let details = {
    //         caseId: caseId,
    //         cpId: careplanId,
    //         assessId: assessmentId,
    //         step: 2,
    //         lang: reasonForm.values.language,
    //         orgId: organization,
    //         draft :1, 
    //         timeZone:tzabbr
    //     }
    //     API.post(`care-plans/${careplanId}:share`,details).then(res=>{
    //         if(res.status === 200 && res.data.body === true){
    //             dispatch(setAlert({title:t('Success'),subtitle:t('Care Plan shared successfully'),type:'success',active:true}))
    //         }else if(res.status ===200 && res.data.status === false) {
    //             dispatch(setAlert({ title: t('Warning'), subtitle: res.data.errorMessage,active: true, type: 'warning' }))
    //             onClose();
    //         }
    //     }).catch(err => {
    //         dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
    //         onClose();
    //     })
    // }

    const submitForm = () => {

        let timestamp = new Date().getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        let langu = []
        language.map(c => {
            if (c.selected) {
                langu.push(c.key)
            } return null;

        })
        console.log("Assess Id ", assessmentId)
        if (careplanId === 0) {
            API.get(`assessments/${assessmentId}:printForm?lang=${reasonForm.values.language.toLowerCase()}`).then(res => {
                if (res.status === 200) {
                    const linkSource = `data:application/pdf;base64,${res.data.body}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "TCE-Blank-Assessment.pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Assessment downloaded successfully'), type: 'success', active: true }))
                    onClose()
                } else {
                    dispatch(setAlert({ title: ('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
                    onClose();
                }
            }).catch(err => {
                dispatch(setAlert({ title: ('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
                onClose();
            })
        } else {
            API.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/care-plans/${careplanId}/queue/${2}?Timezone=${tzabbr}&lang=${reasonForm.values.language}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Accept': ''
                }
            }).then(res => {
                if (res.status === 200) {
                    const linkSource = `data:application/pdf;base64,${res.data.body}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "TCE-Care Plan.pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Care Plan download successfully'), type: 'success', active: true }))
                    onClose()

                } else {
                    dispatch(setAlert({ title: ('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
                    onClose();
                }
            }).catch(err => {
                dispatch(setAlert({ title: ('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
                onClose();
            })
        }
    }

    return (
        <Dialog title={t("Download File")} showDialog={active} onClose={() => onClose()}>
            <div className="py-2 w-full">
            <div className="px-4 mt-2">
                {/* <span>{t("CG Preferred Language")}</span> : {} <br/>&nbsp; */}
                <div tabIndex={0} className="flex items-center justify-center"><span class="text-tcolor dark:text-white font-bold ">{t("CG Preferred Language")}</span> : {prefLanguage} </div><br/>
                <CareplanSelect label={t("Language")} fieldMeta={reasonForm.getFieldMeta('language')} fieldHelper={reasonForm.getFieldHelpers('language')} fieldProps={reasonForm.getFieldProps('language')} options={language} edit={true} dataLoaded={dataLoaded} />


                {/* <FieldSelect label={t("Reason")} fieldMeta={reasonForm.getFieldMeta('reason')} fieldHelper={reasonForm.getFieldHelpers('reason')} fieldProps={reasonForm.getFieldProps('reason')} options={closeReason} edit={true} position="bottom" dataLoaded={dataLoaded} onChange={reasonSelect} /> */}
            </div>
            <div className="flex justify-between mt-8 mx-2" >
                <div tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={onClose}>{t("No")}</div>
                <div tabIndex={0} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => reasonForm.submitForm()}>{t("Yes")}</div>
            </div>
        </div>
        </Dialog>
    )

}

export default CareplanDialog