import { useEffect, useState,useLayoutEffect } from 'react'
import { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch, useSelector } from 'react-redux'
import FieldInput from "../../components/snippets/FieldInput"
import { useFormik } from "formik"
import * as Yup from 'yup';
import API from '../../axios/API'
import { setAlert } from '../../store/theme/actions'
import { setDemographic } from '../../store/auth/actions'
import axios from 'axios'
import { useTranslation } from "react-i18next"
import RaceMultiSelect from '../../components/snippets/RaceMultiSelect'
import CloseSelect from '../../components/snippets/CloseSelect'
import FieldDate from '../../components/snippets/FieldDate'
import DemoRace from '../../components/snippets/DemoRace'




createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const Editcaregiver = ({ details, onClose }) => {

    // const [loading, setLoading] = useState(true)
    const [formLoading, setFormLoading] = useState(false)
    const [editForm] = useState(true)
    const [dataLoaded] = useState(true)
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({race:[]})
    const demographiccount = useSelector(state => state.auth.demographiccount)
    const [raceEthnicity, setRaceEthnicity] = useState([])
    const {t} = useTranslation()
    const [cgRefused,setCgRefused] = useState(false)
    const [years,setYears] = useState([])
    let orgId = localStorage.getItem('orgid')
    let birthyear = localStorage.getItem('birthyear')
    const [birthyear1, setBirthYear1] = useState(false)
    const [errWarning,setErrWarning] = useState(false)
    const [customsubWarn,setCustomSubWarn] = useState()
    const [nextButtonDisable,setNextButtonDisable] = useState(false)
    const [subAsian,setSubAsians] = useState([])
    const [subNative,setSubNative] = useState([])
    const [otherAsianString,setOtherAsianString] = useState(false)
    const [otherNativeString,setOtherNativeString] = useState(false)
    const [otherAsianValue,setOtherAsianValue] = useState([])
    const [otherNativeValue,setOtherNativeValue] = useState([])
    const OTHER_ASIAN = 20;
    const OTHER_NATIVE = 26;
    let lang = localStorage.getItem('language')
    let orgEthnicityEnable = localStorage.getItem('orgEthnicity')
    const [orgRaceEnable,setOrgRaceEnabl] = useState(false)
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))

    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^\d]+$/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'validateEmail', function () {
        return this.test(t('validEmail'), t("Not valid"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'validateName', function () {
        return this.test(t('onlyAlphabet and numbers are allowed'), t('Only Alphabets and numbers allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test(t('validPhone'), t("Not a Valid Phone"), (value) => {
            if (value === undefined || value === '') return true
            return (value.replace(/ /g, '').length === 13 || value.replace(/ /g, '').length === 12)
        })
    })
    Yup.addMethod(Yup.string, 'EmptySpace', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    useEffect(() => {
        for (let i = 1900; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
    }, []) //eslint-disable-line

    
    useEffect(()=>{
        if(birthyear === 'true'){
            setBirthYear1(true)
        } 
        if(orgEthnicityEnable === 'true'){
            setOrgRaceEnabl(true)
        }
        if (menuItems !== undefined && menuItems !== null && menuItems !== '') {
            if (menuItems.races && menuItems.races.length > 0 && menuItems.races !== undefined) {
                setRaceEthnicity(menuItems.races.map(c => ({text: c.name, value: c.value })))
            } else {
                API.get(`menus/races?lang=${lang}`).then((raceResp) => {
                    setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.subRaces && menuItems.subRaces !== null && menuItems.subRaces !== '' && menuItems.subRaces !== undefined) {
                setSubAsians(menuItems.subRaces.subAsianRace.map((c) => ({ text: c.name, value: c.key })))
                setSubNative(menuItems.subRaces.subHawaiianRace.map((c) => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/subraces?lang=${lang}`).then((subRaces) => {
                    setSubAsians(subRaces.data.body.subAsianRace.map(c=>({ text:c.name, value: c.value})))
                    setSubNative(subRaces.data.body.subHawaiianRace.map(c=>({ text:c.name, value: c.value})))
                })
            }
        }else{
            axios.all([API.get(`menus/races?lang=${lang}`),API.get(`menus/subraces?lang=${lang}`)]).then(axios.spread((raceResp,subRaces) => {
                if (userResp.status === 200) {
                    setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
                    setSubAsians(subRaces.data.body.subAsianRace.map(c=>({ text:c.name, value: c.value})))
                    setSubNative(subRaces.data.body.subHawaiianRace.map(c=>({ text:c.name, value: c.value})))
                }
            }))
        }
    },[]) //eslint-disable-line

    useEffect(() => {
        // setLoading(true)
        axios.all([API.get(`caregivers/${details.cgId}`)]).then(axios.spread((userResp) => {
            if (userResp.status === 200) {
                userResp.data.race = []
                if (userResp.data.white === true) {
                    userResp.data.race.push(1)
                } if (userResp.data.black === true) {
                    userResp.data.race.push(2)
                } if (userResp.data.hispanic === true) {
                    userResp.data.race.push(3)
                }if (userResp.data.asian === true && orgEthnicityEnable !== 'true') {
                    userResp.data.race.push(4)
                }if (userResp.data.nativeAmerican === true) {
                    userResp.data.race.push(5)
                }if (userResp.data.pacificIslander === true && orgEthnicityEnable !== 'true') {
                    userResp.data.race.push(6)
                }if (userResp.data.middleEastern === true) {
                    userResp.data.race.push(7)
                } if (userResp.data.otherRace === true) {
                    userResp.data.race.push(8)
                } if (userResp.data.refused === true) {
                    userResp.data.race.push(9)
                }
                if(userResp.data.ethnicity !== undefined && orgEthnicityEnable === 'true'){
                    userResp.data.ethnicity.map((c,i)=>{
                        userResp.data.race.push(c)
                        if(c >= 10 && c<= 29){
                            setOtherAsianValue((prevValue)=>[...prevValue,c])
                        }
                        if(c >= 30 && c<= 35){
                            setOtherNativeValue((prevValue)=>[...prevValue,c])
                        }
                    })
                }
               
                if(birthyear === 'true' && userResp.data.dobYear !== 0){

                    userResp.data.dateOfBirth = userResp.data.dobYear + "/" + userResp.data.dobMonth + "/" + userResp.data.dobDay
                }else if(userResp.data.dobYear !== 0){
                    userResp.data.dateOfBirth = userResp.data.dobYear
                }else{
                    userResp.data.dateOfBirth = ''
                }
                if(userResp.data.dobYear === 0){
                    setCgRefused(true)
                }
                if(userResp.data.otherAsianRace.otherSubRaceId === OTHER_ASIAN){
                    setOtherAsianString(true)
                }else{setOtherAsianString(false)}
                if(userResp.data.otherNativeRace.otherSubRaceId === OTHER_NATIVE){
                    setOtherNativeString(true)
                }else{ setOtherNativeString(false) }
                setFormData(userResp.data)
                setYears(years.map(c=>({text:c,value:c})))
              
                // setLoading(false)
                setFormLoading(false)
            }
        }))
    }, [])// eslint-disable-line
    // const formData = useMemo(() => {
    //     details.race = []
    //     console.log(details)
    //     if (details.white === true) { 
    //         details.race.push(1)
    //     }if (details.black === true) {
    //         details.race.push(2)
    //     }if (details.hispanic === true) {
    //         details.race.push(3)
    //     }if (details.asian === true) {
    //         details.race.push(4)
    //     }if (details.nativeAmerican === true) {
    //         details.race.push(5)
    //     }if (details.pacificIslander === true) {
    //         details.race.push(6)
    //     }if (details.middleEastern === true) {
    //         details.race.push(7)
    //     }if (details.otherRace === true) {
    //         details.race.push(8)
    //     }if (details.refused === true) {
    //         details.race.push(9)
    //     } 
    //     details.date = details.dobMonth + '/' + details.dobDay + '/' + details.dobYear
    //     return { ...details }
    // },[])


    const profileFormSchema = cgRefused ? Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required(t("FirstName is a required field")),
        lastName: Yup.string().nullable().max(50).validateName().required(t("LastName is a required field")),
        // race: Yup.array(),
        dateOfBirth: Yup.date(),
        emailAddress: Yup.string().validateEmail().nullable(),
        phone: Yup.string().validatePhoneLength().required(t("Phone is a required field")),
        dobDay: Yup.number(),
        dobMonth: Yup.number(),
        dobYear: Yup.number(),
        otherAsianRace:Yup.object({
            otherSubRaceId: Yup.string().nullable(),
            otherSubRaceValue:otherAsianString ? Yup.string().EmptySpace().onlyAlphabet().nullable().required(t('Required')) : Yup.string().nullable()
        }),
        otherNativeRace:Yup.object({
            otherSubRaceId:  Yup.string().nullable(),
            otherSubRaceValue:otherNativeString ?  Yup.string().EmptySpace().onlyAlphabet().nullable().required(t('Required')) : Yup.string().nullable()
        })
    }):Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required(t("FirstName is a required field")),
        lastName: Yup.string().nullable().max(50).validateName().required(t("LastName is a required field")),
        // race: Yup.array(),
        dateOfBirth: Yup.date().required(t("Please provide the required details")).nullable(),
        emailAddress: Yup.string().validateEmail().nullable(),
        phone: Yup.string().validatePhoneLength().required(t("Phone is a required field")),
        dobDay: Yup.number(),
        dobMonth: Yup.number(),
        dobYear: Yup.number(),
        otherAsianRace:Yup.object({
            otherSubRaceId: Yup.string().nullable(),
            otherSubRaceValue:otherAsianString ? Yup.string().EmptySpace().onlyAlphabet().nullable().required('Required') : Yup.string().nullable()
        }),
        otherNativeRace:Yup.object({
            otherSubRaceId: Yup.string().nullable(),
            otherSubRaceValue:otherNativeString ?  Yup.string().EmptySpace().onlyAlphabet().nullable().required('Required') : Yup.string().nullable()
        })
    })

    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
                setFormLoading(true)
                updateForm()
                setFormLoading(false)
        }
    })

    const setRefused = (side,value) =>{
        if(side === 'cg'){
            setCgRefused(value)
        }
    }

    const getEmailAddress = (value) =>{
        var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            if(regex.test(value)){
            API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${details.cgId}/emailValidate?email=${value}`).then((res) => {
                if (res.status === 200) {
                    setErrWarning(false)
                    setNextButtonDisable(false)
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true })) 
                    setErrWarning(true)
                    setNextButtonDisable(true)
                    setCustomSubWarn(err.response.data.errorMessage)
                    // profileForm.setValues({ ...profileForm.values,...profileForm.values,emailAddress:''})   
                }
            })
            }else{
                setErrWarning(false)
                setNextButtonDisable(false)
            }
        }
   
      
        const Saveclient =()=>{
            // setNextButtonDisable(true)
            profileForm.submitForm()
        }

    const updateForm = () => {
        if (profileForm.values.dateOfBirth && birthyear1) {
            let date = profileForm.values.dateOfBirth;
            date = date?.split('/')
            profileForm.values.dobDay = date[2]
            profileForm.values.dobMonth = date[1]
            profileForm.values.dobYear = date[0]
        }
        let details = {
            cgId: profileForm.values.personId,
            firstName: profileForm.values.firstName.trim(),
            lastName: profileForm.values.lastName.trim(),
            dobYear:cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.dobYear): parseInt(profileForm.values.dateOfBirth ? profileForm.values.dateOfBirth:0),
            dobDay:cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.dobDay) : parseInt(profileForm.values.dobDay ? profileForm.values.dobDay:1),
            dobMonth:cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.dobMonth) : parseInt(profileForm.values.dobMonth ? profileForm.values.dobMonth:1),
            email: profileForm.values.emailAddress,
            white: profileForm.values.race.includes(1),
            black: profileForm.values.race.includes(2),
            asian: otherAsianValue.length > 0 ? true : profileForm.values.race.includes(4),
            middleEastern: profileForm.values.race.includes(7),
            otherRace: profileForm.values.race.includes(8),
            refused: profileForm.values.race.includes(9),
            nativeAmerican: profileForm.values.race.includes(5),
            pacificIslander: otherNativeValue.length > 0 ? true : profileForm.values.race.includes(6),
            hispanic: profileForm.values.race.includes(3),
            phone: profileForm.values.phone,
            ethnicityId: profileForm.values.race.includes(9) ? [] :[...otherAsianValue,...otherNativeValue],
            otherAsianRace:{
                otherSubRaceId:profileForm.values.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.otherAsianRace.otherSubRaceValue !== null ? 29 :'',
                otherSubRaceValue:profileForm.values.otherAsianRace.otherSubRaceValue,
                raceId: profileForm.values.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.otherAsianRace.otherSubRaceValue !== null ? 4 : ''
            },
            otherNativeRace:{
                otherSubRaceId:profileForm.values.otherNativeRace.otherSubRaceValue !== ''&& profileForm.values.otherNativeRace.otherSubRaceValue !== null ? 35 : '',
                otherSubRaceValue:profileForm.values.otherNativeRace.otherSubRaceValue,
                raceId: profileForm.values.otherNativeRace.otherSubRaceValue !== ''&& profileForm.values.otherNativeRace.otherSubRaceValue !== null ? 6 : ''  
            }
        }
        API.patch(`caregivers/${details.cgId}?orgId=${orgId}`, details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('Caregiver updated successfully'), active: true, type: 'success' }))
                onClose()
                dispatch(setDemographic(demographiccount + 1))
            }
        }).catch(err => {
            if (err.response.status === 406) {
                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
               
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            }
        }).finally(() => onClose)
    }

    const setOtherAsianSelect = (value) =>{
        if(otherAsianValue.includes(value)){
            let values = otherAsianValue.filter((c)=>c!==value)
            setOtherAsianValue(values)
        }else{
            setOtherAsianValue((prevValue)=>[...prevValue,value])
        }
    }

    // console.log(profileForm.values.race)
    const setNativeSelect = (value) =>{
        if(otherNativeValue.includes(value)){
            let values = otherNativeValue.filter((c)=>c!==value)
            setOtherNativeValue(values)
        }else{
            setOtherNativeValue((prevValue)=>[...prevValue,value])
        }
    }
   
    useLayoutEffect(()=>{
        if(profileForm.values.race.includes(29)){
            setOtherAsianString(true)
          }else{
            setOtherAsianString(false)
            profileForm.setValues({ ...profileForm.values, ...profileForm.values, otherAsianRace: { ...profileForm.values.otherAsianRace, otherSubRaceValue: "" } })
          }
    },[profileForm.values.race.includes(29)])
    useLayoutEffect(()=>{
        if(profileForm.values.race.includes(35)){
            setOtherNativeString(true)
          }else{
            setOtherNativeString(false)
            profileForm.setValues({ ...profileForm.values, ...profileForm.values, otherNativeRace: { ...profileForm.values.otherNativeRace, otherSubRaceValue: "" } })
          }
    },[profileForm.values.race.includes(35)])

    return (
        <div className="py-2 w-full">
            <div className="px-4 mt-2">
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4">
                    <FieldInput ariaLabel={t("First Name is a required field")} hideData={true} loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('firstName')} fieldProps={profileForm.getFieldProps('firstName')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={t("Last Name is a required field")} hideData={true} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('lastName')} fieldProps={profileForm.getFieldProps('lastName')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={t("Phone Number is a required field")} hideData={true} loading={formLoading} type="phone" label={t("Phone Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('phone')} fieldProps={profileForm.getFieldProps('phone')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'1'} />
                    <FieldInput ariaLabel={t("Email")} hideData={true} loading={formLoading} label={t("Email")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('emailAddress')} fieldProps={profileForm.getFieldProps('emailAddress')} edit={editForm} dataLoaded={dataLoaded} onChange={(val) => getEmailAddress(val)} err={errWarning} customErrmsg={customsubWarn} />
                  { birthyear1 ? <FieldDate ariaLabel={t("Date of Birth is a required field")} disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`dateOfBirth`)} fieldProps={profileForm.getFieldProps(`dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`dateOfBirth`)} edit={editForm} dataLoaded={dataLoaded}  />:
                              <CloseSelect ariaLabel={t('Birth Year is a required field')} disabled={cgRefused ? true:false} loading={formLoading} label={t('Birth Year')} fieldMeta={profileForm.getFieldMeta(`dateOfBirth`)} fieldProps={profileForm.getFieldProps(`dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`dateOfBirth`)} options={years} edit={editForm} dataLoaded={dataLoaded} position="bottom" />

                    }
                    <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative w-80">
                               {/* <FieldInput  disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={profileForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} /> */}
                               {cgRefused && <button style={{height:'fit-content',width:'fit-content',color:'white'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cg',false)}>{t('Refused')}</button>}
                               {!cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tgray dark:text-white" onClick={()=>setRefused('cg',true)}>{t('Refused')}</button>}
                            </div>
                   { orgRaceEnable ? <RaceMultiSelect ariaLabel={t("Race/Ethnicity")} loading={formLoading} label={t("Race/Ethinicity")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('race')} fieldProps={profileForm.getFieldProps('race')} fieldHelper={profileForm.getFieldHelpers('race')} edit={editForm} dataLoaded={dataLoaded} options={raceEthnicity} asians={subAsian} native={subNative} setAsian={(ev)=>setOtherAsianSelect(ev)} setNative={(ev)=>setNativeSelect(ev)}/>:
                    <DemoRace ariaLabel={t("Race/Ethnicity")} loading={formLoading} label={t("Race/Ethinicity")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('race')} fieldProps={profileForm.getFieldProps('race')} fieldHelper={profileForm.getFieldHelpers('race')} edit={editForm} dataLoaded={dataLoaded} options={raceEthnicity} />}
                    {otherAsianString && <FieldInput ariaLabel={t("Other Asian")} hideData={true} loading={formLoading} label={t("Other Asian")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('otherAsianRace.otherSubRaceValue')} fieldProps={profileForm.getFieldProps('otherAsianRace.otherSubRaceValue')} edit={editForm} dataLoaded={dataLoaded} />}
                    {otherNativeString && <FieldInput ariaLabel={t("Other Native Hawaiian")} hideData={true} loading={formLoading} label={t("Other Native Hawaiian")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('otherNativeRace.otherSubRaceValue')} fieldProps={profileForm.getFieldProps('otherNativeRace.otherSubRaceValue')} edit={editForm} dataLoaded={dataLoaded} />}
                </div>
            </div>
            {editForm &&
                <div className="flex justify-between mt-7 mx-5">
                    <button className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700 hover:bg-opacity-80 text-white" onClick={onClose}>{t("Cancel")}</button>
                    <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" disabled={nextButtonDisable} onClick={() => Saveclient()}>{t("Save")}</button>
                </div>
            }
        </div>
    )
}

export default Editcaregiver