import { useFormik } from "formik"
import { useEffect, useState } from "react"
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import FormCard from "../../components/snippets/FormCard"
import PageLoader from "./PageLoader"
import DataTable, { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { setAlert } from "../../store/theme/actions"
import API from '../../axios/API'
import { Link } from "react-router-dom";
import { FaEdit, FaFile, FaListAlt,FaPhoneAlt,FaEnvelope,FaStickyNote} from 'react-icons/fa'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import FlowText from "../../components/snippets/FlowText"
import AssessmentFavFormButton from "../../components/snippets/AssessmentFavFormButton"
import { useHistory } from 'react-router-dom'
import FavFormButton from '../../components/snippets/FavFormButton' 
import RelationshipSelector from '../CaseDetails/RelationshipSelector'
import ReassessDialog from "../../components/snippets/ReassesDialog";
import Reassessments from "../Dialogs/Reassessment";
import VipCase from "../../components/snippets/VipCase";
import VipCard from "../../components/snippets/VipCard";
import EmailText from "../../components/snippets/EmailText";
import { createPortal } from "react-dom";
import Dialog from "../../components/snippets/Dialog";
import { L } from "caniuse-lite/data/agents";
import { pointer } from "caniuse-lite/data/features";
import DavServiceDialog from "../Dialogs/DavServiceDialog";
import DAVDialog from "../../components/snippets/DAVDialog";
import StatusText from "../../components/snippets/StatusText";
import ProtocolSelector from "./ProtocolSelector";
createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const NewCaseDetails = ({ initialData }) => {

    const [editForm, setEditForm] = useState(false)
    const [formLoading, setFormLoading] = useState(true)
    const [data, setData] = useState()
    const [formData, setFormData] = useState()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [relationship, setRelationship] = useState([])
    const [stress, setStress] = useState([])
    const [objective, setObjective] = useState([])
    const [uplift, setUplift] = useState([])
    const [depression, setDepression] = useState([])
    const [idd, setIdd] = useState([])
    const [itpNow,setItpNow] = useState([])
    // const [language, setLanguage] = useState([])
    // const [insurance, setInsurance] = useState([])
    const organization = useSelector(state => state.auth.organization)
    const userDetails = useSelector(state => state.auth.userDetails)
    const closecaseRender = useSelector(state => state.auth.closeCaseRender)
    let setCountNumber = useState()
    const dispatch = useDispatch()
    let history = useHistory()
    const { t } = useTranslation()
    let orgId = localStorage.getItem('orgid')
    let reason = localStorage.getItem('reassess')
    let birthyear = localStorage.getItem('birthyear')
    const [birthyear1, setBirthYear1] = useState(false)
    const [reassessDialogModal, setReassessDialogModal] = useState(false)
    const [reassessDialogModal1, setReassessDialogModal1] = useState(false)
    const [assessmentTypeId,setAssesmentTypeId] = useState()
    let prgName = localStorage.getItem('prgname')
    let assessType1 = localStorage.getItem('assessType')
    let pcsp = localStorage.getItem('pcsp')
    const [programName1, setProgramName1]=useState(false)
    const [datePscp, setDatePscp]=useState(false)
    const [assessmentTypeId1,setAssessmentTypeId1] = useState(false)
    const [assessColumn, setAssessColumn] = useState(false)
    const [disable,setDisable] =useState(false)
    const [vip,setVip] = useState(false)
    // let Create = localStorage.getItem('createScreen')
    let Rescreens = localStorage.getItem('Rescreen')
    let vipEnable = localStorage.getItem('vipEnable')
    const [closeDialogs, setCloseDialogs] = useState(false)
    let noCaregiver = localStorage.getItem('noCaregiver')
    const [clonePopup,setClonePopup] = useState(false)
    const [assessId,setAssessId] = useState()
    const [cpId,setCpId] = useState()
    let protocolType = localStorage.getItem('tcareProtocolEnabled')
    const [grgProtocol, setGrgProtocol]=useState(false)
    let lang= localStorage.getItem('language')
    let orgEthnicityEnable = localStorage.getItem('orgEthnicity')
    const [orgRaceEnable,setOrgRaceEnabl] = useState(false)
    let jobFamily = localStorage.getItem('jobFamily')
    let employeeFamily = localStorage.getItem('empType')
    let davService = localStorage.getItem('military')
    const [davServiceEnable,setDavServiceEnable] = useState(false)
    const [caregiverId,setCaregiverId] = useState()
    const [carereceiverId,setCareReceiverId] = useState()
    useEffect(() => {
        if(birthyear === 'true'){
            setBirthYear1(true)
           }
           if(prgName === 'true'){
            setProgramName1(true)
        }
        if(protocolType === 'true'){
            setGrgProtocol(true)
        }
        if(pcsp === 'true'){
            setDatePscp(true)
        }
        if(assessType1 === 'true'){
            setAssessmentTypeId1(true)
        }
        if(reason !== "true"){
            setAssessColumn(true)
        }
        if(orgEthnicityEnable === 'true'){
            setOrgRaceEnabl(true)
        }
    
        axios.all([API.get(`organizations/${organization}/cases/${initialData}`), API.get(`menus/languages?lang=${lang}`), API.get(`menus/insurance-options?lang=${lang}`)]).then(axios.spread((caseResp, languageResp, insuranceResp) => {
            // setInsurance(insuranceResp.data.body.map(c => ({ text: c.name, value: c.value })))
            // setLanguage(languageResp.data.body.map(c => ({ text: c.name, value: c.key })))
            if (caseResp.data) {
                     caseResp.data.cgInfo.race = []
                     if(orgEthnicityEnable === 'true' && caseResp.data.cgInfo.ethnicity !== undefined && caseResp.data.cgInfo.ethnicity !== null &&caseResp.data.cgInfo.ethnicity.toString().length > 0){
                        caseResp.data.cgInfo.race.push(caseResp.data.cgInfo.ethnicity)
                    }
                    if(caseResp.data.cgInfo.white === true){
                        caseResp.data.cgInfo.race.push(t('White'))
                    }if(caseResp.data.cgInfo.black === true){
                        caseResp.data.cgInfo.race.push(t('Black or African American'))
                    }if(caseResp.data.cgInfo.hispanic === true){
                        caseResp.data.cgInfo.race.push(t('Hispanic or Latino'))
                    }if(caseResp.data.cgInfo.asian === true && orgEthnicityEnable !== 'true'){
                        caseResp.data.cgInfo.race.push(t('Asian'))
                    } if(caseResp.data.cgInfo.nativeAmerican === true){
                        caseResp.data.cgInfo.race.push(t('American Indian or Alaska Native'))
                    }if(caseResp.data.cgInfo.pacificIslander === true && orgEthnicityEnable !== 'true'){
                        caseResp.data.cgInfo.race.push(t('Native Hawaiian or Pacific Islander'))
                    }if(caseResp.data.cgInfo.middleEastern === true){
                        caseResp.data.cgInfo.race.push(t('Middle Eastern or North African'))
                    }if(caseResp.data.cgInfo.otherRace === true){
                        caseResp.data.cgInfo.race.push(t('Some other race/ethnicity'))
                    }if(caseResp.data.cgInfo.refused === true){
                        caseResp.data.cgInfo.race.push(t('Refused'))
                    }
                   
                    caseResp.data.crInfo.race = []
                    if(orgEthnicityEnable === 'true' && caseResp.data.crInfo.ethnicity !== undefined && caseResp.data.crInfo.ethnicity !== null &&caseResp.data.crInfo.ethnicity.toString().length > 0){
                        caseResp.data.crInfo.race.push(caseResp.data.crInfo.ethnicity)
                    }
                    if(caseResp.data.crInfo.white === true){
                        caseResp.data.crInfo.race.push(t('White'))
                    }if(caseResp.data.crInfo.black === true){
                        caseResp.data.crInfo.race.push(t('Black or African American'))
                    }if(caseResp.data.crInfo.hispanic === true){
                        caseResp.data.crInfo.race.push(t('Hispanic or Latino'))
                    }if(caseResp.data.crInfo.asian === true && orgEthnicityEnable !== 'true'){
                        caseResp.data.crInfo.race.push(t('Asian'))
                    }if(caseResp.data.crInfo.nativeAmerican === true){
                        caseResp.data.crInfo.race.push(t('American Indian or Alaska Native'))
                    }if(caseResp.data.crInfo.pacificIslander === true && orgEthnicityEnable !== 'true'){
                        caseResp.data.crInfo.race.push(t('Native Hawaiian or Pacific Islander'))
                    } if(caseResp.data.crInfo.middleEastern === true){
                        caseResp.data.crInfo.race.push(t('Middle Eastern or North African'))
                    }if(caseResp.data.crInfo.otherRace === true){
                        caseResp.data.crInfo.race.push(t('Some other race/ethnicity'))
                    }if(caseResp.data.crInfo.refused === true){
                        caseResp.data.crInfo.race.push(t('Refused'))
                    }
                    if(caseResp.data.cgInfo.gender === 'M'){
                        caseResp.data.cgInfo.gender = t('Male')
                    }
                    else if(caseResp.data.cgInfo.gender === 'F'){
                        caseResp.data.cgInfo.gender = t('Female')
                    }
                    else if(caseResp.data.cgInfo.gender === 'O'){
                        caseResp.data.cgInfo.gender = t('Other')
                    } else if(caseResp.data.cgInfo.gender === 'R'){
                        caseResp.data.cgInfo.gender = t('Refused')
                    }
                    if(caseResp.data.cgInfo.MaritalStatus === 'S'){
                        caseResp.data.cgInfo.MaritalStatus = t('Single')
                    }
                    else if(caseResp.data.cgInfo.MaritalStatus === 'M'){
                        caseResp.data.cgInfo.MaritalStatus = t('Married or Domestic Partner')
                    }
                    else if(caseResp.data.cgInfo.MaritalStatus === 'W'){
                        caseResp.data.cgInfo.MaritalStatus = t('Widowed')
                    } 
                    else if(caseResp.data.cgInfo.MaritalStatus === 'O'){
                        caseResp.data.cgInfo.MaritalStatus = t('Other')
                    }else if(caseResp.data.cgInfo.MaritalStatus === 'R'){
                        caseResp.data.cgInfo.MaritalStatus = t('Refused')
                    }
                    if(caseResp.data.cgInfo.pronouns === 168){
                        caseResp.data.cgInfo.pronouns = t('She/Her/Hers')
                    }
                    else if(caseResp.data.cgInfo.pronouns === 169){
                        caseResp.data.cgInfo.pronouns = t('He/Him/His')
                    }
                    else if(caseResp.data.cgInfo.pronouns === 170){
                        caseResp.data.cgInfo.pronouns = t('They/Them/Theirs')
                    }
                    if(caseResp.data.cgInfo.disability === '1'){
                        caseResp.data.cgInfo.disability = t('Yes')
                    }
                    else if(caseResp.data.cgInfo.disability === '2'){
                        caseResp.data.cgInfo.disability = t('No')
                    }
                    else if(caseResp.data.cgInfo.disability === '3'){
                        caseResp.data.cgInfo.disability = t('Refused')
                    }
                    if(caseResp.data.cgInfo.miltary === '0'){
                        caseResp.data.cgInfo.miltary = t('No')
                    }else if(caseResp.data.cgInfo.miltary === '1'){
                        caseResp.data.cgInfo.miltary = t('Yes')
                    }
                    if(caseResp.data.cgInfo.alcohol === '0'){
                        caseResp.data.cgInfo.alcohol = t('No')
                    }else if(caseResp.data.cgInfo.alcohol === '1'){
                        caseResp.data.cgInfo.alcohol = t('Yes')
                    }else if(caseResp.data.cgInfo.alcohol === '6'){
                        caseResp.data.cgInfo.alcohol = t('Refused')
                    }
                    if(caseResp.data.crInfo.gender === 'M'){
                        caseResp.data.crInfo.gender = t('Male')
                    }
                    else if(caseResp.data.crInfo.gender === 'F'){
                        caseResp.data.crInfo.gender = t('Female')
                    }
                    else if(caseResp.data.crInfo.gender === 'O'){
                        caseResp.data.crInfo.gender = t('Other')
                    }else if(caseResp.data.crInfo.gender === 'R'){
                        caseResp.data.crInfo.gender = t('Refused')
                    }
                    if(caseResp.data.crInfo.MaritalStatus === 'S'){
                        caseResp.data.crInfo.MaritalStatus = t('Single')
                    }
                    else if(caseResp.data.crInfo.MaritalStatus === 'M'){
                        caseResp.data.crInfo.MaritalStatus = t('Married or Domestic Partner')
                    }
                    else if(caseResp.data.crInfo.MaritalStatus === 'W'){
                        caseResp.data.crInfo.MaritalStatus = t('Widowed')
                    } 
                    else if(caseResp.data.crInfo.MaritalStatus === 'O'){
                        caseResp.data.crInfo.MaritalStatus = t('Other')
                    } else if(caseResp.data.crInfo.MaritalStatus === 'R'){
                        caseResp.data.crInfo.MaritalStatus = t('Refused')
                    } 
                     if(caseResp.data.crInfo.pronouns === 168){
                        caseResp.data.crInfo.pronouns = t('She/Her/Hers')
                    }
                    else if(caseResp.data.crInfo.pronouns === 169){
                        caseResp.data.crInfo.pronouns = t('He/Him/His')
                    }
                    else if(caseResp.data.crInfo.pronouns === 170){
                        caseResp.data.crInfo.pronouns = t('They/Them/Theirs')
                    }if(caseResp.data.crInfo.disability === '1'){
                        caseResp.data.crInfo.disability = t('Yes')
                    }
                    else if(caseResp.data.crInfo.disability === '2'){
                        caseResp.data.crInfo.disability = t('No')
                    }
                    else if(caseResp.data.crInfo.disability === '3'){
                        caseResp.data.crInfo.disability = t('Refused')
                    }
                    if(caseResp.data.crInfo.miltary === '0'){
                        caseResp.data.crInfo.miltary = t('No')
                    }else if(caseResp.data.crInfo.miltary === '1'){
                        caseResp.data.crInfo.miltary = t('Yes')
                    }
                    if(caseResp.data.crInfo.alcohol === '0'){
                        caseResp.data.crInfo.alcohol = t('No')
                    }else if(caseResp.data.crInfo.alcohol === '1'){
                        caseResp.data.crInfo.alcohol = t('Yes')
                    }else if(caseResp.data.crInfo.alcohol === '6'){
                        caseResp.data.crInfo.alcohol = t('Refused')
                    }
                    if(caseResp.data.crInfo.vipStatus === true){
                        setVip(true)
                    }
                    
                    setFormData(caseResp.data)
                
                setData(caseResp.data.assessmentHistory)
                if (caseResp.data.measureHistory) {
                    let output = []
                    let relationshipScore = []
                    let stressScore = []
                    let objectiveScore = []
                    let upliftScore = []
                    let depressionScore = []
                    let iddScores = []
                    let itpScores = []
                    caseResp.data.measureHistory.forEach((element, id) => {
                        relationshipScore.push({ score: element.relationshipScore, hml: element.relationshipHml })
                        stressScore.push({ score: element.stressScore, hml: element.stressHml })
                        objectiveScore.push({ score: element.objectiveScore, hml: element.objectiveHml })
                        upliftScore.push({ score: element.upliftScore, hml: element.upliftHml })
                        depressionScore.push({ score: element.depressionScore, hml: element.depressionHml })
                        iddScores.push({ score: element.iddScore, hml: element.iddHml })
                        itpScores.push({ score: element.itpNow, hml: element.itpNow})
                        output.push([element.relationshipScore, element.stressScore, element.objectiveScore, element.upliftScore, element.depressionScore, element.iddScore,element.itpScores])
                    })
                    setRelationship(relationshipScore)
                    setStress(stressScore)
                    setObjective(objectiveScore)
                    setUplift(upliftScore)
                    setDepression(depressionScore)
                    setIdd(iddScores)
                    setItpNow(itpScores)
                }
                setCaregiverId(caseResp.data.cgInfo.id)
                setCareReceiverId(caseResp.data.crInfo.id)
                setFormLoading(false)
                setDataLoaded(true)
            }
        }))
    }, [closecaseRender,closeDialogs])// eslint-disable-line
   
    // const datacon =(value)=>
    // {
    //    let formData = new Date(value)
    //    return `${formData.toUTCString().slice(8,11)}${formData.toUTCString().slice(4,7)}, ${formData.toUTCString().slice(11,16)}`
    // }
    
    // const caredate =(value)=>
    // {
    //    let careplandate = new Date(value)
    //    return `${careplandate.toUTCString().slice(8,11)}${careplandate.toUTCString().slice(4,7)}, ${careplandate.toUTCString().slice(11,16)}`
    // }
    // const createdate =(value)=>
    // {
    //    let createdondate = new Date(value)
    // return `${createdondate.toUTCString().slice(8,11)}${createdondate.toUTCString().slice(4,7)}, ${createdondate.toUTCString().slice(11,16)}`
    // }
    
    const followdate =(value) =>{
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
       return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss')+ ' ' +tzabbr
       
    }
    // const followdate =(value)=>
    // {
       
    //     let followupdate = new Date(value)
    //     console.log( new Date(value).toLocaleString())
    //     return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${value.slice(11,19)}`
    //  }

    const toggleFormEdit = () => {
        history.push({pathname:`/client/${formData.cgInfo.id}`,state:{caseId: formData.caseId,service:true}})
    }
    const toggleEdit =() => {
        history.push({pathname:`/client/${formData.crInfo.id}`,state:{caseId: formData.caseId,service:true}})
    }

    const sequence = (i)=>{
        if(i > 0){
          if(formData.assessmentHistory[i].assessmentId !== formData.assessmentHistory[i-1].assessmentId){
            setCountNumber = setCountNumber +1
            return setCountNumber 
            // return (setCountNumber).toFixed();
          }else{
            return ''
          }}else{
            setCountNumber = 1
            return setCountNumber
          }
    }
    const profileForm = useFormik({
        initialValues: formData,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            setFormLoading(true)
            addNotes()
            setFormLoading(false)
        }
    })

    const openClonePopup = (assId,cpId)=>{
        setAssessId(assId);
        setCpId(cpId);
        setClonePopup(true)
    }
    const clonecareplan =(assId,cpId)=>{
        setDisable(true)
        let clonedetails = {
                "caseId": formData.caseId,
                "screenerAssess": true,
                "assessmentId": assId,
                "carePlanId": cpId
        }
          API.post(`organizations/${orgId}/cloneCareplan`,clonedetails).then((res) => {
            if (res.status === 200) {
                history.push(`/case/${res.data.body.caseId}/assessment/${res.data.body.assessmentId}/careplan/${res.data.body.carePlanId}/${0}`)
                dispatch(setAlert({ title: t('Success'), subtitle: t('Care Plan Created Successfully'), type: 'success', active: true }))
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const readOnlyAssess =(caseId,assessmentId,caseType)=>{
        history.push({pathname:`/case/${caseId}/readonly-assessment/${assessmentId}`,state:{caseType:caseType? 'SCR' : ''}})
    }
    const columns = [
        {
            name: ' ',
            selector:' ',
            cell:(row, index) =><><div tabIndex={0}>{sequence(index)}</div></>,
            grow :0
        },
        {
            name: t('Assessment Type'),
            selector: 'assessmentType',
            cell:row =><div tabIndex={0} aria-label={`Assessment Type ${row.assessmentType}`}>{row.assessmentType}</div>,
            wrap:true
        },
        {
            name:t('Assessment Reason'), 
            selector: 'reason', 
            cell:row =><div tabIndex={0} aria-label={`Assessment Reason ${row.reason}`}>{row.reason}</div>,
            wrap:true,
            omit:assessColumn
        },
        {
            name: t('Status'),
            selector: 'assessmentStatus',
            cell: rows => <>
                <div>
               {
                   (formData.cgInfo.careManagerId === 0 || (userDetails.role.includes('admin')) || (userDetails.caremanagerId !== formData.cgInfo.careManagerId) || (userDetails.caremanagerId === formData.cgInfo.careManagerId)) ? (rows.assessStatId === 4) ? <StatusText arialabel={`Status ${rows.assessmentStatus}`} text={rows.assessmentStatus} /> : (rows.assessStatId === 19) ? <StatusText arialabel={`Status ${rows.assessmentStatus}`}  text={rows.assessmentStatus} /> : 
                   ((rows.assessStatId === 20) && (rows.screenercase === true) && (rows.caseStatId === 0)) ? <StatusText arialabel={`Status ${rows.assessmentStatus}`} text={rows.assessmentStatus} /> :
                   (rows.assessStatId === 20) &&  (rows.screenercase === false) && (rows.careplanStatId === 0) ? <StatusText arialabel={`Status Pending Care Plan`} text={t('Pending Care Plan')} /> :(rows.assessStatId === 20) &&  (rows.screenercase === false) && (rows.careplanStatId === 11) ? <StatusText arialabel={`Status ${rows.careplanStatus}`} text={rows.careplanStatus} /> : (rows.assessStatId === 20) && (rows.careplanStatId === 21) ? <StatusText arialabel={`Care Plan In Progress`} text={t('Care Plan In Progress')} /> : (rows.assessStatId === 9) ? <StatusText arialabel={`Followup Assessment in Progress`} text={t('Followup Assessment in Progress')} /> : (rows.assessStatId === 29) ? <StatusText  arialabel={`Status ${rows.assessmentStatus}`} text={rows.assessmentStatus} /> : (rows.assessStatId === 31) ? <StatusText arialabel={`Status ${rows.assessmentStatus}`} text={rows.assessmentStatus} /> : (rows.assessStatId === 20) && (rows.careplanStatId === 22) ? <StatusText arialabel={`Status COMPLETE`} text={t('COMPLETE')} /> :
                   (rows.assessStatId === 9) ? <StatusText arialabel={`Status Followup Assessment In Progress`} text={t('Followup Assessment In Progress')} /> : (rows.assessStatId === 29) ? <StatusText arialabel={`Status Followup Care Plan In Progress`} text={t('Followup Care Plan In Progress')} /> : (rows.assessStatId === 31) ? <StatusText arialabel={`Status Followup Summary In Progress`} text={t('Followup Summary In Progress')} /> : ((rows.assessStatId === 32) && (rows.careplanStatId === 30)) ? <StatusText arialabel={`Status COMPLETE`} text={t('COMPLETE')} /> :
                   (rows.assessStatId === 32) && (rows.careplanStatId === 11)  ?  <StatusText arialabel={`Status ${rows.careplanStatus}`} text={rows.careplanStatus} /> :
                   (rows.assessStatId === 32) && (rows.careplanStatId !== 29) && (rows.careplanStatId !== 30) ?  <StatusText arialabel={`Status Pending Followup Care Plan`} text={t('Pending Followup Care Plan')} /> : (rows.assessStatId === 32) && ((rows.careplanStatId === 21) || (rows.careplanStatId === 29)) ? <StatusText arialabel={`Status Followup Care Plan In Progress`} text={t('Followup Care Plan In Progress')}/>:
                   <StatusText arialabel={`Status ${rows.assessmentStatus}`} text={rows.assessmentStatus} /> : (userDetails.role.includes('screener') && rows.assessStatId === 4) ? <StatusText  arialabel={`Status Assessment In Progress`} text={t('Assessment In Progress')} /> : (rows.assessStatId === 4) ? <StatusText arialabel={`Status Assessment In Progress`} text={t('Assessment In Progress')} /> : (rows.assessStatId === 19) ? <StatusText arialabel={`Status Summary In Progress`} text={t('Summary In Progress')} /> : ((rows.assessStatId === 20) && (rows.caseStatId === 5) && (rows.careplanStatId === 0)) ? <StatusText arialabel={`Status Pending Care Plan`} text={t('Pending Care Plan')} /> : (rows.assessStatId === 20) && (rows.careplanStatId === 21) ? <StatusText arialabel={`Status Care Plan In Progress`} text={t('Care Plan In Progress')} /> : ((rows.assessStatId === 20) && (rows.caseStatId === 5) && (rows.careplanStatId === 0)) ? <StatusText arialabel={`Status Pending Care Plan`} text={'Pending Care Plan'} /> : 
                    (rows.assessStatId === 9) ? <StatusText arialabel={`Status Followup Assessment In Progress`} text={t('Followup Assessment In Progress')} /> : (rows.assessStatId === 29) ? <StatusText arialabel={`Status Followup Care Plan In Progress`} text={t('Followup Care Plan In Progress')} /> : (rows.assessStatId === 31) ? <StatusText arialabel={`Status Followup Summary In Progress`} text={t('Followup Summary In Progress')} /> : ((rows.assessStatId === 32) && (rows.careplanStatId === 30)) ? <StatusText arialabel={`Status COMPLETE`} text={t('COMPLETE')} /> 
                   :(rows.assessStatId === 20) && (rows.careplanStatId === 22) ? <StatusText arialabel={`Status COMPLETE`} text={t('COMPLETE')}/> : (rows.assessStatId === 32) && (rows.careplanStatId === 11)  ?  <StatusText arialabel={`Status ${rows.careplanStatus}`} text={rows.careplanStatus} /> : (rows.assessStatId === 32) && (rows.careplanStatId !== 29) && (rows.careplanStatId !== 30) ?  <StatusText arialabel={`Status Pending Followup Care Plan`} text={t('Pending Followup Care Plan')} /> : (rows.assessStatId === 32) && ((rows.careplanStatId === 21) || (rows.careplanStatId === 29)) ? <StatusText arialabel={`Status Followup Care Plan In Progress`} text={t('Followup Care Plan In Progress')}/>: (rows.assessStatId === 2) ? <StatusText arialabel={`Status ${rows.assessmentStatus}`} text={rows.assessmentStatus} /> : ''}
               </div>
            </>,
        },
        {
            name: t('Assessment Submitted By'),
            selector: 'submittedBy',
            cell: rows =>
            <>
            <div tabIndex={0} aria-label={`Assessment Submitted By ${rows.submittedBy}`}>
                {rows.submittedBy !== null && rows.submittedBy !== ""?rows.submittedBy:''}
            </div>
            </>
        },
       
        {
            name: t('Assessment Date'),
            selector: 'assessmentCompletedDate',
            cell: rows => 
            <>
                <div>{rows.assessmentCompletedDate !== null && rows.assessmentCompletedDate !== "" ? <div tabIndex={0} aria-label={`Assessment Date ${rows.assessmentCompletedDate}`}><Moment format="MMM D,YYYY">{rows.assessmentCompletedDate}</Moment></div> : <div tabIndex={0} aria-label={`Assessment Date ${rows.assessmentCompletedDate}`}><h1>-</h1></div>}</div>
            </>,
        },
        {
            name: t('Care plan Date'),
            selector: 'careplanCompletedDate',
            cell: rows => 
            <>
                <div>{rows.careplanCompletedDate != null ? <div tabIndex={0} aria-label={`Care plan Date ${rows.careplanCompletedDate}`}><Moment format="MMM D, YYYY">{rows.careplanCompletedDate}</Moment></div> : <div tabIndex={0} aria-label={`Care plan Date ${rows.careplanCompletedDate}`}><h1>-</h1></div>}</div>
            </>,
            grow:1
        },
        {
            name: t('Follow-Up Date'),
            selector: ' followUpDate',
            cell: rows => <>
            <div>{rows.followUpDate !== null && rows.followUpDate !== "" ? <div tabIndex={0} aria-label={`Follow-Up Date ${rows.followUpDate}`}><h1>{followdate(rows.followUpDate)}</h1></div> : <div tabIndex={0} aria-label={`Follow-Up Date ${rows.followUpDate}`}><h1>-</h1></div>}</div>
        </>,
        },
        {
            name: t('Documents'),
            button: true,
            cell: columns => <>
                <div aria-label={`Documents`} style={{ margin: "10px" }}>{columns.assessmentCompleted === true? (organization && userDetails) ? <Link><FaEdit size="20px" color="#0399D8" title={t('click to view Assessment')} onClick={()=>readOnlyAssess(formData.caseId,columns.assessmentId,columns.screenercase)} /></Link> : <FaEdit color="grey" size="20px" title={t('Assessment Not Completed Yet')}/> : <FaEdit color="grey" size="20px" title={t('Assessment Not Completed Yet')} />}</div>
                <div aria-label={`Documents`} style={{ margin: "10px" }}>{(columns.screenercase === true && columns.summaryCompleted === true) ? <Link to={{ pathname: `/case/${formData.caseId}/readonly-score/${columns.assessmentId}` }}><FaListAlt size="20px" color="#0399D8" title={t('click to view Score')} /></Link> :(columns.screenercase === false && columns.summaryCompleted === true)?<Link to={{ pathname: `/case/${formData.caseId}/readonly-summary/${columns.assessmentId}` }}><FaListAlt size="20px" color="#0399D8" title={t('click to view Summary')} /></Link>:(columns.screenercase === false && columns.summaryCompleted === false)? <Link ><FaListAlt color="grey" size="20px" title={t('Summary Not Completed Yet')}/></Link>:<Link ><FaListAlt color="grey" size="20px" title={t('Summary Not Completed Yet')} /></Link>}</div>
                <div aria-label={`Documents`} style={{ margin: "10px" }}>{columns.careplanCompleted === true ? (organization && userDetails) ? <Link to={{ pathname: `/case/${formData.caseId}/assessment/${columns.assessmentId}/readonly-careplan/${columns.careplanId}/${1}` }}><FaFile size="20px" color="#0399D8" title={t('click to view Care Plan')} /></Link> : <Link ><FaFile color="grey" size="20px" title={t('Care Plan Not Completed Yet')} /></Link>:<Link ><FaFile color="grey" size="20px" title={t('Care Plan Not Completed Yet')} /></Link>}</div>
            </>,
        },
      
          {
            name: ' ',
            button:  true, grow: 1, cell: columns => <>
               <div aria-label={`Documents`} style={{ margin: "10px",cursor:"pointer" }}>{(userDetails.caremanagerId === formData.cgInfo.careManagerId) && columns.cloneEnabled === true && formData.status === "PENDING_FLLOWUP_ASSESSMENT" ? <FaStickyNote tabIndex={0} size="20px" color="#65a30d" title={t("Clone Care Plan")} onClick={() => (!disable) ? openClonePopup(columns.assessmentId,columns.careplanId) : ''}/> : ''}</div>
              </>
          },
      
    ];

    const { themeMode } = useSelector(state => state.theme)

    const addNotes = () => {
        let details =
        {
            "caseId": profileForm.values.caseId,
            "notes": [{
                "description": profileForm.values.notes
            }
            ]
        }
        API.post('notes', details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t("Notes saved successfully"), type: "success", active: true }))
            }
        }).catch(e => {
            console.log(e)
        })
    }


    const createNewAssessment = () => {
        setDisable(true)
        let orgId = localStorage.getItem('orgid')
        let details ={
            "screener":0
        }
        API.post(`organizations/${orgId}/cases/${formData.caseId}/assessments`,details).then(res => {
            if (res.status === 200) {
                // if(reason === "true" &&  Create === 'true'){
                //     setAssesmentTypeId(res.data.body.assessmentId)
                //     setReassessDialogModal1(true)
                // }else{
                    history.push(`/case/${res.data.body.caseId}/assessment/${res.data.body.assessmentId}`)
                // }
                dispatch(setAlert({ title: t('Success'), subtitle: t('Assessment created successfully'), type: 'success', active: true }))
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const createRescreen = () => {
        setDisable(true)
        let orgId = localStorage.getItem('orgid')
        let details ={
            "screener":1
        }
        API.post(`organizations/${orgId}/cases/${formData.caseId}/assessments`,details).then(res => {
            if (res.status === 200) {
                if(reason === "true" && Rescreens === 'true'){
                    setAssesmentTypeId(res.data.body.assessmentId)
                    setReassessDialogModal(true)
                }else{
                    history.push(`/case/${res.data.body.caseId}/assessment/${res.data.body.assessmentId}`)
                }
                dispatch(setAlert({ title: t('Success'), subtitle: t('Assessment created successfully'), type: 'success', active: true }))
            }
        }).catch(err => {
            console.log(err)
        })
    }
    const assessment_inprogress = (value,status) => {  
        // if(reason === "true" && formData.reason === false && Create === 'true' && status === 9) {
        //     setAssesmentTypeId(value)
        //     setReassessDialogModal(true)
        // }else{
            history.push(`/case/${formData.caseId}/assessment/${value}`)

    }
    const continueScreener = (value,count) => {  
        // if(reason === "true" && formData.reason === false  && Create === 'true'){
        //     setAssesmentTypeId(value)
        //     setReassessDialogModal(true)
        // }else 
        if(reason === "true" && formData.reason === false  && Rescreens === 'true' && count > 1){
            setAssesmentTypeId(value)
            setReassessDialogModal(true)
        }else{
            history.push(`/case/${formData.caseId}/assessment/${value}`)
        }

    }
    
    const summary_inprogress = (value) => {  
        history.push(`/case/${formData.caseId}/summary/${value}`)

}
    const Score_inprogress =(value) =>{
        history.push(`/case/${formData.caseId}/score/${value}`)
    }

const careplan_inprogress = (asmtId,cpId) => {  
    history.push(`/case/${formData.caseId}/assessment/${asmtId}/careplan/${cpId}/${1}`)

}

    const createCareplan = (value) => {
        setDisable(true)
        let orgId = localStorage.getItem('orgid')
        API.post(`organizations/${orgId}/assessments/${value}/care-plans`).then((res) => {
            if (res.status === 200) {
                history.push(`/case/${formData.caseId}/assessment/${value}/careplan/${res.data.cpId}/${0}`)
                dispatch(setAlert({ title: t('Success'), subtitle: t('Careplan Created Successfully'), type: 'success', active: true }))
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const createNewFollowupAssessment = () => {
        if(davService === 'true' && formData.davMilitaryEnable){
            setDavServiceEnable(true)
        }else{
        history.push(`/case/${formData.caseId}/resourceUtlization`)
        }
    }

    const closeDialog = () => {
        setCloseDialogs(true)
        setReassessDialogModal(false)
    }
    return (
        <>
            {
                formLoading ? <PageLoader /> : <div class="pb-8">
                    <div class="flex grid gap-5 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
                        <div>
                            <FormCard title={t("Caregiver")}>
                               {(userDetails.caremanagerId === formData.cgInfo.careManagerId) || (userDetails.role.includes('admin')) || (noCaregiver === 'true' && userDetails.role.includes('caremanager'))|| (userDetails.orgId === parseInt(organization) && userDetails.role.includes('referrer')) ||(userDetails.role.includes('screener'))|| ((userDetails.orgId !== parseInt(organization) && (userDetails.role.includes('dynamic_ref') || userDetails.role.includes('dynamic_scr'))))  ? <FavFormButton arialabel={'Care Giver'} injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleFormEdit} handleCancel={() => setEditForm(false)}  />:''}

                                {/* <div className="grid gap-1 grid-cols-3 sm:grid-cols-3 xl:grid-cols-3 2xl:grid-col-1"> */}
                                <div className="flex flex-grow justify-around">
                                    <div>
                                        <div className="flex-shrink w-64"><span tabIndex={0} aria-label={`${formData.cgInfo.name !== 'null null' ? formData.cgInfo.name :''}caring for ${formData.cgInfo.relationship}`} className="flex-shrink w-64" data-private="lipsum"><span>{(formData.cgInfo.name !== 'null null' ? formData.cgInfo.name :'')}</span>&nbsp; 
                                        <span className="font-bold" >{t("caring")} for &nbsp;</span>{((organization && userDetails && userDetails.orgId === parseInt(organization)) || userDetails.caremanagerId === formData.cgInfo.careManagerId || (userDetails.role.length === 2 && userDetails.role.includes('caremanager') && userDetails.role.includes('dynamic_cm'))||(userDetails.role.includes('screener'))||(userDetails.orgId !== parseInt(organization) && (userDetails.role.includes('dynamic_ref')||userDetails.role.includes('dynamic_scr')) || userDetails.role.includes('admin'))) ?  <span><RelationshipSelector className="px-4" props={formData.cgInfo.relationship} caseId={formData.caseId}  /></span> :formData.cgInfo.relationship }
                                        </span>&nbsp;
                                        <div tabIndex={0} ><span class="font-bold ...">{t("Preferred Name")}</span>: {formData.cgInfo.preferredName}</div> &nbsp;   
                                        <div tabIndex={0}><span class="font-bold ...">{t("Address")}</span>: {formData.cgInfo.address.addressLine1} {formData.cgInfo.address.addressLine2} <br/>
                                        {formData.cgInfo.address.city ?  `${formData.cgInfo.address.city},`:''}{formData.cgInfo.address.state} {formData.cgInfo.address.postalCode}</div>
                                       
                                       <div className="flex flex-grow justify-start" tabIndex={0}><span class="font-bold ...">{t('Time zone')}</span>: {formData.cgInfo.timeZone ? `${formData.cgInfo.timeZone}`:''} </div>&nbsp;
                                       {/* &nbsp;<span className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={timeZone}><FaClock size="16px" /></span> */}
                                        {formData.cgPhone && <div className="flex flex-grow justify-start" data-private="lipsum" tabIndex={0}>
                                        <span class="font-bold ...">{t("Cell")}: </span>&nbsp;{formData.cgPhone} &nbsp;
                                            <a href={`tel: +1${formData.cgPhone.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Call')}>
                                                <FaPhoneAlt size="16px" />
                                            </a>
                                        </div>}&nbsp;
                                        { formData.cgHomePhone && <div className="flex flex-grow justify-start" tabIndex={0}>
                                        <div><span class="font-bold ...">{t("Home")}:</span>&nbsp;{formData.cgHomePhone} &nbsp;&nbsp;</div>
                                            <a href={`tel: +1${formData.cgHomePhone.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Call')}>
                                                <FaPhoneAlt size="16px" />
                                            </a>
                                        </div>}&nbsp;
                                        {formData.cgInfo.email&&<div className="flex" data-private="lipsum" tabIndex={0}>
                                <div className="font-bold"  >{t("Email")} :</div>
                            <div className="flex">
                                {                                    
                                    <span className={`flex items-center flex-shrink w-48 px-2`}>
                                        <EmailText force={true} text={formData.cgInfo.email}>
                                   </EmailText>&nbsp;&nbsp;
                                   
                                   <a href={`mailto: ${formData.cgInfo.email} `} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Mail')}>
                                     <FaEnvelope size="16px" />
                                 </a>
                                    </span>}
                                    </div>
                               </div>}&nbsp;
                                      
                                        <div><div tabIndex={0}><span class="font-bold ...">{t("Preferred Language")}</span>: {formData.cgInfo.language}</div>&nbsp;
                                        <div tabIndex={0}><span class="font-bold ...">{t("Military/Veteran")}</span>: {formData.cgInfo.miltary}</div> &nbsp;
                                        { programName1 ? <div className="flex flex-grow justify-start" tabIndex={0}><span class="font-bold ...">{t('Program Name')}</span>:  {formData.cgInfo.pgName ? `${formData.cgInfo.pgName}`:''}</div>:''}
                                         {/* { datePscp ? <div className="flex-shrink w-64"><span class="font-bold ...">{t('PCSP')}</span>: {formData.cgInfo.pcspDate ? <Moment format="MMM DD,YYYY">{formData.cgInfo.pcspDate}</Moment>:''}</div> :''} */}
                                        </div>
                                        </div>
                                    </div>
                                    <div style={{ height: 'auto', width: '3px'}} className="bg-tcolor text-white dark:bg-white"></div>
                                    <div>
                                        <div><div tabIndex={0}>{birthyear1 ?<span class="font-bold ...">{t("Date Of Birth")}</span>:<span class="font-bold ...">{t("Birth Year")}</span>}: {birthyear1 && formData.cgInfo.dobYear !== 0 ? formData.cgInfo.dobMonth+"-"+formData.cgInfo.dobDay+"-"+formData.cgInfo.dobYear : formData.cgInfo.dobYear !== 0 ? formData.cgInfo.dobYear : t('Refused')} </div>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Marital Status")}</span>: {formData.cgInfo.MaritalStatus}</div><br/>
                                        { employeeFamily === 'true' ? <div tabIndex={0} className="flex flex-grow justify-start"><span class="font-bold ...">{t('Employee Type')}</span>:  {formData.cgInfo.employeeType ? `${formData.cgInfo.employeeType}`:''}</div>:''}
                                        { jobFamily === 'true' ? <div tabIndex={0} className="flex flex-grow justify-start"><span class="font-bold ...">{t('Job Family Group')}</span>:  {formData.cgInfo.jobFamilyGroup ? `${formData.cgInfo.jobFamilyGroup}`:''}</div>:''}
                                        </div><br/>
                                        <div className="flex-shrink w-64"><div tabIndex={0}><span class="font-bold ...">{t("Education")}</span>: {formData.cgInfo.education}</div>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Employment")}</span>: {formData.cgInfo.employment}</div>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Income")}</span>: {formData.cgInfo.income}</div>
                                        </div> 
                                        <br/>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Insurance")}</span>: {formData.cgInfo.insurance.split(',').map((c,i)=>{return `${t(c)}${i+1 === formData.cgInfo.insurance.split(',').length ? '' : ','}`})}</div>&nbsp;
                                        <div className="flex-shrink w-64"><div tabIndex={0}><span class="font-bold ...">{t("Race/Ethnicity")}</span>:
                                            {
                                                formData.cgInfo.race.map((c ,i) =>
                                                <span>  {`${c} ${(formData.cgInfo.race.length === i+1) ? '':','}`}</span>
                                                )}</div>
                                         {/* {formData.cgInfo.asianSubRace && <div><span class="font-bold ...">{t("Asian SubRace")}</span>: {formData.cgInfo.asianSubRace}</div>} */}
                                         {formData.cgInfo.otherAsianText && orgRaceEnable && <div tabIndex={0}><span class="font-bold ...">{t("Other Asian")}</span>: {formData.cgInfo.otherAsianText}</div>}
                                         {/* {formData.cgInfo.nativeSubRace &&<div><span class="font-bold ...">{t("Native SubRace")}</span>: {formData.cgInfo.nativeSubRace}</div>} */}
                                         {formData.cgInfo.otherNativeText && orgRaceEnable && <div tabIndex={0}><span class="font-bold ...">{t("Other Native Hawaiian")}</span>: {formData.cgInfo.otherNativeText}</div>}
                                        <div tabIndex={0}><span class="font-bold ...">{t("Gender")}</span>: {formData.cgInfo.gender}</div>
                                        <div tabIndex={0}><span class="font-bold ...">{t("Pronouns")}</span>: {formData.cgInfo.pronouns} </div>
                                        <div tabIndex={0}><span class="font-bold ...">{t("Disability")}</span>: {formData.cgInfo.disability} </div>
                                        { davService === 'true' ? <div tabIndex={0} className="flex-shrink w-72"><span class="font-bold ...">{t('Service Branch')}</span>:  {formData.cgInfo.militaryServiceBranch ? `${formData.cgInfo.militaryServiceBranch}`:''}</div>:''}
                                        { davService === 'true' ? <div tabIndex={0} className="flex flex-grow justify-start"><span class="font-bold ...">{t('Rank')}</span>:  {formData.cgInfo.militaryServiceRank ? `${formData.cgInfo.militaryServiceRank}`:''}</div>:''}
                                        { davService === 'true' ? <div tabIndex={0} className="flex flex-grow justify-start"><span class="font-bold ...">{t('Date of Enlistment')}</span>: {formData.cgInfo.militaryEnlistmentDate ? <Moment format="MM/DD/YYYY">{formData.cgInfo.militaryEnlistmentDate}</Moment>:''}</div>:''}
                                        { davService === 'true' ? <div tabIndex={0} className="flex flex-grow justify-start"><span class="font-bold ...">{t('Date of Discharge')}</span>: {formData.cgInfo.militaryDischargeDate ?<Moment format="MM/DD/YYYY">{formData.cgInfo.militaryDischargeDate}</Moment>:''}</div>:''}
                                        </div>
                                       <br/>
                                        <div tabIndex={0}><span class="font-bold ...">{t("Health")}</span>: {formData.cgInfo.health}</div>
                                        <br/>
                                        <div tabIndex={0}><span class="font-bold ...">{t("Alcohol Use")}</span>: {formData.cgInfo.alcohol}</div>
                                    </div>
                                </div>
                            </FormCard>
                        </div>
                        <div>
                        {vipEnable === "true" && vip?<VipCard className='ml-14' title={t("Care Receiver")}>
                        {vipEnable === "true"  && vip?<VipCase injectClass=" absolute -top-2 left-2 "/>:''}
                            {(userDetails.caremanagerId === formData.cgInfo.careManagerId) || (userDetails.role.includes('admin')) || (noCaregiver === 'true' && userDetails.role.includes('caremanager'))|| ((userDetails.orgId === parseInt(organization)) && userDetails.role.includes('referrer')) || (userDetails.role.includes('screener')) || (userDetails.orgId !== parseInt(organization) && (userDetails.role.includes('dynamic_ref') || userDetails.role.includes('dynamic_scr')))   ? <FavFormButton arialabel={'Care Receiver'} injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleEdit} handleCancel={() => setEditForm(false)} />:''}
                                <div className="flex flex-grow justify-around">
                                    <div>
                                        <div data-private="lipsum"><div tabIndex={0}>{(formData.crInfo.name !== 'null null' ? formData.crInfo.name :'')} </div>&nbsp;
                                        <div tabIndex={0}><span class="font-bold ...">{t("Preferred Name")}</span>: {formData.crInfo.preferredName}</div> &nbsp;     
                                        <div tabIndex={0}><span class="font-bold ...">{t("Address")}</span>: {formData.crInfo.address.addressLine1} {formData.crInfo.address.addressLine2} <br/>
                                        {formData.crInfo.address.city ?  `${formData.crInfo.address.city},`:''}{formData.crInfo.address.state} {formData.crInfo.address.county} {formData.crInfo.address.postalCode}</div>
                                        </div>
                                        <br/>
                                        <div tabIndex={0} className="flex-shrink w-56"><span class="font-bold ...">{t("Diagnosis")}</span>: {formData.crDiagnosis}</div>
                                        <br/>
                                        <div tabIndex={0} className="flex-shrink w-56"><span class="font-bold ...">{t("Memory Issues")}</span>: {formData.crMemory}</div>
                                        <br/>
                                        <div tabIndex={0}><span class="font-bold ...">{t("Military/Veteran")}</span>: {formData.crInfo.miltary}</div>
                                        <br/>
                                        { grgProtocol ? <div tabIndex={0}><span class="font-bold ...">{t("Protocol")}</span>: {<ProtocolSelector className="px-4" props={formData.crInfo.protocolType} caseId={formData.caseId}  />}</div> : '' }
                                        { grgProtocol ? <br/> : '' }
                                        <div tabIndex={0}><span class="font-bold ...">{t("Subscriber ID")}</span>: {formData.crInfo.subscriberId}</div>
                                        <br/>
                                        { assessmentTypeId1 ? <div tabIndex={0} className="flex-shrink w-56"><span class="font-bold ...">{t("Assessment Type")}</span>: {formData.crInfo.assessType}</div> :''}
                                        <br/><br/>
                                        <br/>
                                    </div>
                                    <div style={{ height: 'auto', width: '3px'}} className="bg-tcolor text-white dark:bg-white"></div>
                                    <div>
                                    <div><div tabIndex={0}>{birthyear1 ? <span class="font-bold ...">{t("Date Of Birth")}</span> : <span class="font-bold ...">{t("Birth Year")}</span>}: {birthyear1 && formData.crInfo.dobYear !==0 ? formData.crInfo.dobMonth+"-"+formData.crInfo.dobDay+"-"+formData.crInfo.dobYear : formData.crInfo.dobYear !==0 ? formData.crInfo.dobYear : ''} </div>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Marital Status")}</span>: {formData.crInfo.MaritalStatus}</div>
                                        </div>
                                        <br/>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Education")}</span>: {formData.crInfo.education}<br/>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Employment")}</span>: {formData.crInfo.employment}</div>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Income")}</span>: {formData.crInfo.income}</div>
                                        </div> 
                                        <br/>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Insurance")}</span>: {formData.crInfo.insurance.split(',').map((c,i)=>{return `${t(c)}${i+1 === formData.crInfo.insurance.split(',').length ? '' : ','}`})}</div>&nbsp;
                                        <div className="flex-shrink w-64"><div tabIndex={0}><span class="font-bold ...">{t("Race/Ethnicity")}</span>: {
                                                formData.crInfo.race.map((c ,i) =>
                                                <span> {`${c} ${(formData.crInfo.race.length === i+1) ? '':','}`}</span>
                                                )}</div>
                                         {/* {formData.crInfo.asianSubRace &&<div><span class="font-bold ...">{t("Asian SubRace")}</span>: {formData.crInfo.asianSubRace}</div>} */}
                                        {formData.crInfo.otherAsianText && orgRaceEnable && <div tabIndex={0}><span class="font-bold ...">{t("Other Asian")}</span>: {formData.crInfo.otherAsianText}</div>}
                                        {/* { formData.crInfo.nativeSubRace &&<div><span class="font-bold ...">{t("Native SubRace")}</span>: {formData.crInfo.nativeSubRace}</div>} */}
                                         {formData.crInfo.otherNativeText && orgRaceEnable && <div tabIndex={0}><span class="font-bold ...">{t("Other Native Hawaiian")}</span>: {formData.crInfo.otherNativeText}</div>}
                                         <div tabIndex={0}><span class="font-bold ...">{t("Gender")}</span>: {formData.crInfo.gender}</div>
                                        <div tabIndex={0}><span class="font-bold ...">{t("Pronouns")}</span>: {formData.crInfo.pronouns} </div>
                                        <div tabIndex={0}><span class="font-bold ...">{t("Disability")}</span>: {formData.crInfo.disability} </div>
                                        { davService === 'true' ? <div tabIndex={0} className="flex-shrink w-72"><span class="font-bold ...">{t('Service Branch')}</span>:  {formData.crInfo.militaryServiceBranch ? `${formData.crInfo.militaryServiceBranch}`:''}</div>:''}
                                        { davService === 'true' ? <div tabIndex={0} className="flex flex-grow justify-start"><span class="font-bold ...">{t('Rank')}</span>:  {formData.crInfo.militaryServiceRank ? `${formData.crInfo.militaryServiceRank}`:''}</div>:''}
                                        { davService === 'true' ? <div tabIndex={0} className="flex flex-grow justify-start"><span class="font-bold ...">{t('Date of Enlistment')}</span>: <Moment format="MM/DD/YYYY">{formData.crInfo.militaryEnlistmentDate}</Moment></div>:''}
                                        { davService === 'true' ? <div tabIndex={0} className="flex flex-grow justify-start"><span class="font-bold ...">{t('Date of Discharge')}</span>: <Moment format="MM/DD/YYYY">{formData.crInfo.militaryDischargeDate}</Moment></div>:''}
                                        </div>
                                        <br/><br/>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </VipCard>:<FormCard className='ml-14' title={t("Care Receiver")}>
                        { vipEnable === "true" && vip ?<VipCase injectClass=" absolute -top-2 left-2 "/>:''}
                            {(userDetails.caremanagerId === formData.cgInfo.careManagerId) || (userDetails.role.includes('admin')) || (noCaregiver === 'true' && userDetails.role.includes('caremanager')) || ((userDetails.orgId === parseInt(organization)) && userDetails.role.includes('referrer')) || (userDetails.role.includes('screener')) || (userDetails.orgId !== parseInt(organization) && (userDetails.role.includes('dynamic_ref')||userDetails.role.includes('dynamic_scr')))   ? <FavFormButton arialabel={'Care Receiver'} injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleEdit} handleCancel={() => setEditForm(false)} />:''}
                                <div className="flex flex-grow justify-around">
                                    <div>
                                        <div data-private="lipsum"><div tabIndex={0}>{(formData.crInfo.name !== 'null null' ? formData.crInfo.name :'')} </div>&nbsp;
                                        <div tabIndex={0}><span class="font-bold ...">{t("Preferred Name")}</span>: {formData.crInfo.preferredName}</div> &nbsp;     
                                        <div tabIndex={0}><span class="font-bold ...">{t("Address")}</span>: {formData.crInfo.address.addressLine1} {formData.crInfo.address.addressLine2} <br/>
                                        {formData.crInfo.address.city ?  `${formData.crInfo.address.city},`:''}{formData.crInfo.address.state} {formData.crInfo.address.county} {formData.crInfo.address.postalCode}</div>
                                        </div>
                                        <br/>
                                        <div tabIndex={0} className="flex-shrink w-56"><span class="font-bold ...">{t("Diagnosis")}</span>: {formData.crDiagnosis}</div>
                                        <br/>
                                        <div tabIndex={0} className="flex-shrink w-56"><span class="font-bold ...">{t("Memory Issues")}</span>: {formData.crMemory}</div>
                                        <br/>
                                        <div tabIndex={0}><span class="font-bold ...">{t("Military/Veteran")}</span>: {formData.crInfo.miltary}</div>
                                        <br/>
                                        { grgProtocol ? <div tabIndex={0}><span class="font-bold ...">{t("Protocol")}</span>: {<ProtocolSelector className="px-4" props={formData.crInfo.protocolType} caseId={formData.caseId}  />}</div> : '' }
                                        { grgProtocol ? <br/> : '' }
                                        <div tabIndex={0}><span class="font-bold ...">{t("Subscriber ID")}</span>: {formData.crInfo.subscriberId}</div>
                                        <br/>
                                        { assessmentTypeId1 ? <div tabIndex={0} className="flex-shrink w-56"><span class="font-bold ...">{t("Assessment Type")}</span>: {formData.crInfo.assessType}</div> :''}
                                        <br/><br/>
                                        <br/>
                                    </div>
                                    <div style={{ height: 'auto', width: '3px'}} className="bg-tcolor text-white dark:bg-white"></div>
                                    <div>
                                    <div><div tabIndex={0}>{birthyear1 ? <span class="font-bold ...">{t("Date Of Birth")}</span> : <span class="font-bold ...">{t("Birth Year")}</span>}: {birthyear1 && formData.crInfo.dobYear !==0 ? formData.crInfo.dobMonth+"-"+formData.crInfo.dobDay+"-"+formData.crInfo.dobYear : formData.crInfo.dobYear !==0 ? formData.crInfo.dobYear : t('Refused')} </div>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Marital Status")}</span>: {formData.crInfo.MaritalStatus}</div>
                                        </div>
                                        <br/>
                                        <div className="flex-shrink w-64"><div tabIndex={0}><span class="font-bold ...">{t("Education")}</span>: {formData.crInfo.education}</div>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Employment")}</span>: {formData.crInfo.employment}</div>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Income")}</span>: {formData.crInfo.income}</div>
                                        </div> 
                                        <br/>
                                        <div tabIndex={0} className="flex-shrink w-64"><span class="font-bold ...">{t("Insurance")}</span>: {formData.crInfo.insurance.split(',').map((c,i)=>{return `${t(c)}${i+1 === formData.crInfo.insurance.split(',').length ? '' : ','}`})}</div>&nbsp;
                                        <div className="flex-shrink w-64"><div tabIndex={0}><span class="font-bold ...">{t("Race/Ethnicity")}</span>: {
                                                formData.crInfo.race.map((c ,i) =>
                                                <span> {`${c} ${(formData.crInfo.race.length === i+1) ? '':','}`}</span>
                                                )}</div>
                                        {/* {formData.crInfo.asianSubRace &&<div><span class="font-bold ...">{t("Asian SubRace")}</span>: {formData.crInfo.asianSubRace}</div>} */}
                                        {formData.crInfo.otherAsianText && orgRaceEnable && <div tabIndex={0}><span class="font-bold ...">{t("Other Asian")}</span>: {formData.crInfo.otherAsianText}</div>}
                                        {/* { formData.crInfo.nativeSubRace &&<div><span class="font-bold ...">{t("Native SubRace")}</span>: {formData.crInfo.nativeSubRace}</div>} */}
                                         {formData.crInfo.otherNativeText && orgRaceEnable &&<div tabIndex={0}><span class="font-bold ...">{t("Other Native Hawaiian")}</span>: {formData.crInfo.otherNativeText}</div>}
                                        <div tabIndex={0}><span class="font-bold ...">{t("Gender")}</span>: {formData.crInfo.gender}</div>
                                        <div tabIndex={0}><span class="font-bold ...">{t("Pronouns")}</span>: {formData.crInfo.pronouns} </div>
                                        <div tabIndex={0}><span class="font-bold ...">{t("Disability")}</span>: {formData.crInfo.disability} </div>
                                        { davService === 'true' ? <div tabIndex={0} className="flex-shrink w-72"><span class="font-bold ...">{t('Service Branch')}</span>:  {formData.crInfo.militaryServiceBranch ? `${formData.crInfo.militaryServiceBranch}`:''}</div>:''}
                                        { davService === 'true' ? <div tabIndex={0} className="flex flex-grow justify-start"><span class="font-bold ...">{t('Rank')}</span>:  {formData.crInfo.militaryServiceRank ? `${formData.crInfo.militaryServiceRank}`:''}</div>:''}
                                        { davService === 'true' ? <div tabIndex={0} className="flex flex-grow justify-start"><span class="font-bold ...">{t('Date of Enlistment')}</span>: {formData.crInfo.militaryEnlistmentDate ?<Moment format="MM/DD/YYYY">{formData.crInfo.militaryEnlistmentDate}</Moment>:''}</div>:''}
                                        { davService === 'true' ? <div tabIndex={0} className="flex flex-grow justify-start"><span class="font-bold ...">{t('Date of Discharge')}</span>:  {formData.crInfo.militaryDischargeDate ?<Moment format="MM/DD/YYYY">{formData.crInfo.militaryDischargeDate}</Moment>:''}</div>:''}
                                        </div>
                                        <br/><br/>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </FormCard>}
                        </div>

                    </div>
                   {formData.referredBy ?<FormCard title={t("Referred By")}>
                           <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                         
                                    <div tabIndex={0}><span class="font-bold ...">{t("Referred by")}:</span><span className="flex flex-grow justify-start">{formData.referredBy}</span> </div>
                                    <div tabIndex={0} ><span class="font-bold ...">{t("Phone")}:</span>
                                  {formData.referredByPhone &&  <span className="flex flex-grow justify-start">{formData.referredByPhone} &nbsp;&nbsp;
                                            <a href={`tel: +1${formData.referredByPhone.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Call')}>
                                                <FaPhoneAlt size="16px" />
                                            </a></span>}
                                  </div>
                                            <div tabIndex={0}><span class="font-bold ...">{t("Email")}:</span>
                                            {formData.referredByEmail && <span className="flex flex-grow justify-start">{formData.referredByEmail}&nbsp;&nbsp;
                                            <a href={`mailto:${formData.cgInfo.email}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Mail')}>
                                                <FaEnvelope size="16px" />
                                            </a> </span>}
                                               </div>
                                 </div>
                                      
                              

                       </FormCard>:''}
                    <FormCard title={t("History" )}className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg">
                        {
                            !dataLoaded ?
                                <DatatableLoader /> :
                                <>
                                <div tabIndex={0}>{t("Case Created on")} :   <Moment format="MMM DD,YYYY">{formData.createdOn}</Moment> </div>
                                { datePscp && formData.pcspDate !== null ? <div tabIndex={0}>{t("Date of Associated PCSP")} :  <Moment format="MMM DD,YYYY">{formData.pcspDate}</Moment> </div> : datePscp ? <div>{t("Date of Associated PCSP")} : </div>:''}
                                <DataTable
                                    columns={columns}
                                    data={data} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} noHeader
                                /><br/>
                             {
                                (organization && userDetails && userDetails.orgId === parseInt(organization)) ? (dataLoaded) ? (formData.cgInfo.careManagerId === 0 || (formData.status === "CLOSED") ||(userDetails.role.length === 1 && userDetails.role.includes('admin')) || (userDetails.caremanagerId !== formData.cgInfo.careManagerId)) || ((userDetails.role.length === 1 && userDetails.role.includes('admin')) ||  userDetails.role.includes('screener')) ? '' : 

                                (dataLoaded) ? ((formData.status === "PENDING_ASSESSMENT") && userDetails.role[0] !== "referrer") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Create New Assessment')} onClick={() => createNewAssessment()} /></div> : 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true) && (formData.accessCase === true)  ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Continue Screener')} onClick={() => continueScreener(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].screenerCount)} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== false) && (formData.accessCase === true)  ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Assessment In Progress')} onClick={() => assessment_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /></div> :
                                (formData.status === "PENDING_FLLOWUP_ASSESSMENT" && ((userDetails.orgId === parseInt(organization) &&(userDetails.parentRole.includes('caremanager')) || (userDetails.orgId !== parseInt(organization))))) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton disabled={disable} onClick={() => createNewFollowupAssessment()} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Initiate Follow-Up Assessment')} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 19) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true) && (formData.accessCase === true) ?  <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} title={t("Summary in progress")} onClick={() =>Score_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /> </div>:
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 19) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== false) && (formData.accessCase === true) ?  <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} title={t("Summary in progress")} onClick={() =>summary_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /> </div>:
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 5) && (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 0) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Pending Care Plan')} onClick={() => createCareplan(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /></div>: 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 5) && (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 11) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Pending Care Plan')} onClick={() => createCareplan(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /></div>: 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20) && (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 21) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 21) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Care Plan in Progress')} onClick={() => careplan_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].careplanId)} /></div>:
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 9) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Followup Assessment in Progress')} onClick={() => assessment_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId)} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 31) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Followup Summary in Progress')} onClick={() =>summary_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)}/></div> : 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 29) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t("Followup Care Plan in Progress")} onClick={() => careplan_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].careplanId)}/> </div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 32) && ((formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 29) || (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 21)) && (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId !== 22) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Followup Care Plan in Progress')} onClick={() => careplan_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].careplanId)} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 32) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Pending Followup Care Plan")} onClick={() => createCareplan(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /></div> :'' : '' : '' : ''
                            }
                             {formData.cgInfo.careManagerId === 0  && formData.crInfo.careManagerId === 0  && userDetails.orgId === parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('caremanager') && !(userDetails.role.includes('screener'))) && formData.caseType === 'UnAssignedCases' && formData.rescreenActivated === true)  && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:
                             formData.cgInfo.careManagerId === 0  && formData.crInfo.careManagerId === 0  && userDetails.orgId === parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('caremanager') && !(userDetails.role.includes('screener'))) && formData.caseType === 'UnAssignedCases' && formData.rescreenActivated === true)  && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 11)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:
                             formData.cgInfo.careManagerId === 0  && formData.crInfo.careManagerId === 0  && userDetails.orgId === parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('caremanager') && !(userDetails.role.includes('screener'))) && formData.caseType === 'UnAssignedCases' && formData.rescreenActivated === true)  && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 2)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:
                             formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId === parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('caremanager') && !(userDetails.role.includes('screener'))) && formData.caseType === 'UnAssignedCases' && formData.rescreenActivated === true) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Continue Screener')} onClick={() => continueScreener(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].screenerCount)} /></div>:
                             formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId === parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('caremanager') && !(userDetails.role.includes('screener'))) && formData.caseType === 'UnAssignedCases'  && formData.rescreenActivated === true) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 19) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true)) ?  <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} title={t("Summary in progress")} onClick={() =>Score_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /></div>:''
                             }
                            {formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId === parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('caremanager') && !(userDetails.role.includes('screener'))) && formData.caseType === 'Watchlist' && formData.rescreenActivated === false) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:
                            formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId === parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('caremanager') && !(userDetails.role.includes('screener'))) && formData.caseType === 'Watchlist' && formData.rescreenActivated === false) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 11)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:
                            formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId === parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('caremanager') && !(userDetails.role.includes('screener'))) && formData.caseType === 'Watchlist' && formData.rescreenActivated === false) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 2)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:
                             formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId === parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('caremanager') && !(userDetails.role.includes('screener'))) && formData.caseType === 'Watchlist' && formData.rescreenActivated === false) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Continue Screener')} onClick={() => continueScreener(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].screenerCount)} /></div>:
                             formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId === parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('caremanager') && !(userDetails.role.includes('screener'))) && formData.caseType === 'Watchlist' && formData.rescreenActivated === false) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 19) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true)) ?  <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} title={t("Summary in progress")} onClick={() =>Score_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /></div>:''
                             }
                            {formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm') && !(userDetails.role.includes('dynamic_scr'))) && formData.caseType === 'UnAssignedCases' && formData.rescreenActivated === true)  && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:
                            formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm') && !(userDetails.role.includes('dynamic_scr'))) && formData.caseType === 'UnAssignedCases' && formData.rescreenActivated === true)  && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 11)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:
                            formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm') && !(userDetails.role.includes('dynamic_scr'))) && formData.caseType === 'UnAssignedCases' && formData.rescreenActivated === true)  && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 0)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Create New Assessment')} onClick={() => createNewAssessment()} /></div>:
                             formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm') && !(userDetails.role.includes('dynamic_scr')) ) && formData.caseType === 'UnAssignedCases' && formData.rescreenActivated === true) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4)&&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Continue Screener')} onClick={() => continueScreener(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].screenerCount)} /></div>:
                             formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm') && !(userDetails.role.includes('dynamic_scr')) ) && formData.caseType === 'UnAssignedCases'  && formData.rescreenActivated === true) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 19) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true)) ?  <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} title={t("Summary in progress")} onClick={() =>Score_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /></div>:
                             formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm') && !(userDetails.role.includes('dynamic_scr'))) && formData.caseType === 'UnAssignedCases' && formData.rescreenActivated === true)  && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 2)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:
                             formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm') && !(userDetails.role.includes('dynamic_scr'))) && formData.caseType === 'UnAssignedCases' && formData.rescreenActivated === true)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:''
                             }
                            {formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm')) && formData.caseType === 'Watchlist' && formData.rescreenActivated === false) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:
                            formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm')) && formData.caseType === 'Watchlist' && formData.rescreenActivated === false) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 11)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:
                             formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm')) && formData.caseType === 'Watchlist' && formData.rescreenActivated === false) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Continue Screener')} onClick={() => continueScreener(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].screenerCount)} /></div>:
                             formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm')) && formData.caseType === 'Watchlist' && formData.rescreenActivated === false) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 19) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true)) ?  <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} title={t("Summary in progress")} onClick={() =>Score_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /></div>:
                             formData.cgInfo.careManagerId === 0 && formData.crInfo.careManagerId === 0  && userDetails.orgId !== parseInt(organization) && formData.status !== "CLOSED" &&(((userDetails.role.includes('dynamic_cm') && !(userDetails.role.includes('screener'))) && formData.caseType === 'Watchlist' && formData.rescreenActivated === false) && (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 2)) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div>:''
                             }
                              {((organization && userDetails && userDetails.orgId === parseInt(organization)  &&(userDetails.role.includes('screener') && !userDetails.role.includes('caremanager')) || organization && userDetails && userDetails.orgId !== parseInt(organization)  &&(userDetails.role.includes('dynamic_scr') && !userDetails.role.includes('dynamic_cm')))) ?
                             (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 0) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 3) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Create New Assessment')} onClick={() => createNewAssessment()} /></div> : 
                             (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 0) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 11) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Create New Assessment')} onClick={() => createNewAssessment()} /></div> : 
                             (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 1) && (formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Continue Screener')} onClick={() => continueScreener(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].screenerCount)} /></div> :
                             (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 11) && (formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Continue Screener')} onClick={() => continueScreener(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].screenerCount)} /></div> :
                             <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div> :''
                             }
                              {
                                ((organization && userDetails && userDetails.orgId === parseInt(organization)  && (userDetails.role.includes('caremanager') && userDetails.role.includes('screener'))) || (organization && userDetails && userDetails.orgId !== parseInt(organization)  && (userDetails.role.includes('dynamic_cm') && userDetails.role.includes('dynamic_scr')))) ? 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 0) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 3) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Create New Assessment')} onClick={() => createNewAssessment()} /></div> : 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 0) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 11) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Create New Assessment')} onClick={() => createNewAssessment()} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 2) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 3) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Create New Assessment')} onClick={() => createNewAssessment()} />
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div> : 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true) && (formData.accessCase === true)  ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Continue Screener')} onClick={() => continueScreener(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].screenerCount)} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 38) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true) && (formData.accessCase === true)  ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Continue Screener')} onClick={() => continueScreener(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].screenerCount)} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== false) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Assessment In Progress')} onClick={() => assessment_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} />
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div> :
                                (formData.status === "PENDING_FLLOWUP_ASSESSMENT" && ((userDetails.orgId === parseInt(organization) &&(userDetails.parentRole.includes('caremanager')) || (userDetails.orgId !== parseInt(organization) &&(userDetails.childRole.includes('dynamic_cm')))))) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED")  && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton disabled={disable} onClick={() => createNewFollowupAssessment()} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Initiate Follow-Up Assessment')} />
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 19) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ?  <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} title={t("Summary in progress")} onClick={() =>Score_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /> 
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div>:
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 19) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== false) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ?  <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} title={t("Summary in progress")} onClick={() =>summary_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} />
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /> </div>:
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 5) && (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 0) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Pending Care Plan')} onClick={() => createCareplan(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} />
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div>: 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 5) && (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 11) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Pending Care Plan')} onClick={() => createCareplan(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} />
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div>: 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20) && (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 21) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 21) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Care Plan in Progress')} onClick={() => careplan_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].careplanId)} />
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div>:
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 9) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Followup Assessment in Progress')} onClick={() => assessment_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId)} />
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 31) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true)  && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Followup Summary in Progress')} onClick={() =>Score_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)}/>
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div> : 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 31) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Followup Summary in Progress')} onClick={() =>summary_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)}/>
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div> : 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 29) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t("Followup Care Plan in Progress")} onClick={() => careplan_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].careplanId)}/> 
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 32) && ((formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 29) || (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 21)) && (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId !== 22) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Followup Care Plan in Progress')} onClick={() => careplan_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].careplanId)} />
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 32) && (userDetails.caremanagerId === formData.cgInfo.careManagerId) && (formData.status !== "CLOSED") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Pending Followup Care Plan")} onClick={() => createCareplan(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} />
                                <AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Re-Screen")} onClick={() => createRescreen()} /></div> :<div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Re-Screen')} onClick={() => createRescreen()} /></div> : '' 
                            }
                              {
                                 (organization && userDetails && userDetails.orgId !== parseInt(organization)) ? (dataLoaded) ? (formData.cgInfo.careManagerId === 0 || (formData.status === "CLOSED") ||(userDetails.childRole.length === 1 && userDetails.childRole.includes('dynamic_admin')) || (userDetails.caremanagerId !== formData.cgInfo.careManagerId)) || ((userDetails.childRole.length === 1 && userDetails.childRole.includes('dynamic_admin')) ||  userDetails.childRole.includes('dynamic_scr')) ? '' : 
                                (dataLoaded) ? (formData.status === "PENDING_ASSESSMENT") && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Create New Assessment')} onClick={() => createNewAssessment()} /></div> : 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true) && (formData.accessCase === true)  ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Continue Screener')} onClick={() => continueScreener(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].screenerCount)} /></div> :                                
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 4) && (formData.accessCase === true) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Assessment In Progress')} onClick={() => assessment_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /></div> :
                                (formData.status === "PENDING_FLLOWUP_ASSESSMENT" && ((userDetails.orgId === parseInt(organization) &&(userDetails.parentRole.includes('caremanager')) || (userDetails.orgId !== parseInt(organization) &&(userDetails.childRole.includes('dynamic_cm')))))) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton onClick={() => createNewFollowupAssessment()} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Initiate Follow-Up Assessment')} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 19) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== true) && (formData.accessCase === true) ?  <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} title={t("Summary in progress")} onClick={() =>Score_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /> </div>:
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 19) &&(formData.assessmentHistory[formData.assessmentHistory.length -1].screenercase	=== false) && (formData.accessCase === true) ?  <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} title={t("Summary in progress")} onClick={() =>summary_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /> </div>:
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 5) && (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 0) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t('Pending Care Plan')} onClick={() => createCareplan(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /></div>: 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 20) && (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 21) && (formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId === 21) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Care Plan in Progress')} onClick={() => careplan_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].careplanId)} /></div>:
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 9) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Followup Assessment in Progress')} onClick={() => assessment_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].caseStatId)} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 31) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Followup Summary in Progress')} onClick={() =>summary_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)}/></div> : 
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 29) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> : <AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t("Followup Care Plan in Progress")} onClick={() => careplan_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].careplanId)}/> </div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 32) && ((formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 29) || (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId === 21)) && (formData.assessmentHistory[formData.assessmentHistory.length -1].careplanStatId !== 22) && (formData.accessCase === true) ? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton injectClass={'mr-2 ml-2'} edit={editForm} title={t('Followup Care Plan in Progress')} onClick={() => careplan_inprogress(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId,formData.assessmentHistory[formData.assessmentHistory.length -1].careplanId)} /></div> :
                                (formData.assessmentHistory[formData.assessmentHistory.length -1].assessStatId === 32) && (formData.accessCase === true)? <div><span tabIndex={0}>{t("Next Action")}</span> :<AssessmentFavFormButton disabled={disable} injectClass={'mr-2 ml-2'} edit={editForm} title={t("Pending Followup Care Plan")} onClick={() => createCareplan(formData.assessmentHistory[formData.assessmentHistory.length -1].assessmentId)} /></div> :'' : '' : '' : ''
                            }
                                    </>
                            }
                    </FormCard>
                    <FormCard title={t("Measures")}>
                        {
                            !dataLoaded ?
                                <DatatableLoader /> :
                               <div className="overflow-auto">
                                 <table class="table-auto">
                                    <thead>
                                        <th></th>
                                        {
                                            formData.measureHistory.map((c, i) => {
                                                if (c.assessId !== 0) {
                                                    return <th><div className={'mr-5 ml-5 pr-5 pl-5 rounded-t-lg ...'} title={formData.measureHistory[i].assessmentTransLabel} style={{ backgroundColor: '#0399D8' }}> {formData.measureHistory[i].screenerAssessment ? 'IA' : i+1}
                                                    
                                                    </div></th>
                                                }
                                                return null;
                                            })
                                        }
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{t("Relationship")}</td>
                                            {
                                                relationship.map(c => {
                                                    return <td>{(c.hml === 1) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#117e5a',color:'white' }}>{c.score}</div> : (c.hml === 2) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#FFC56D',color:'black' }}>{c.score}</div> : (c.hml === 3) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#ce0000',color:'white' }}>{c.score}</div> : ''}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{t("Stress")}</td>
                                            {
                                                stress.map(c => {
                                                    return <td>{(c.hml === 1) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#117e5a',color:'white' }}>{c.score}</div> : (c.hml === 2) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#FFC56D',color:'black' }}>{c.score}</div> : (c.hml === 3) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#ce0000',color:'white' }}>{c.score}</div> : ''}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{t("Objective")}</td>
                                            {
                                                objective.map(c => {
                                                    return <td>{(c.hml === 1) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#117e5a',color:'white' }}>{c.score}</div> : (c.hml === 2) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#FFC56D',color:'black' }}>{c.score}</div> : (c.hml === 3) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#ce0000',color:'white' }}>{c.score}</div> : ''}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{t("Uplifts")}</td>
                                            {
                                                uplift.map(c => {
                                                    return <td>{(c.hml === 1) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#117e5a',color:'white' }}>{c.score}</div> : (c.hml === 2) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#FFC56D',color:'black' }}>{c.score}</div> : (c.hml === 3) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#ce0000',color:'white' }}>{c.score}</div> : ''}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{t("Depression")}</td>
                                            {
                                                depression.map(c => {
                                                    return <td>{(c.hml === 1) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#117e5a',color:'white' }}>{c.score}</div> : (c.hml === 2) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#FFC56D',color:'black' }}>{c.score}</div> : (c.hml === 3) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#ce0000',color:'white' }}>{c.score}</div> : ''}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{t("Id Discrep")}</td>
                                            {
                                                idd.map(c => {
                                                    return <td>{(c.hml === 1) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#117e5a',color:'white' }}>{c.score}</div> : (c.hml === 2) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#FFC56D',color:'black' }}>{c.score}</div> : (c.hml === 3) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#ce0000',color:'white' }}>{c.score}</div> : ''}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{t("ITP")}</td>
                                            {
                                               itpNow.map(c => {
                                                    return <td>{(c.hml === 0) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#117e5a',color:'white' }}>{t("No")}</div>  : (c.hml === 1) ? <div className={'mr-5 ml-5 pr-5 pl-5'} style={{ backgroundColor: '#ce0000',color:'white' }}>{t("Yes")}</div> : ''}</td>
                                                })
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                               </div>
                        }
                    </FormCard>
                    {
                        createPortal(<Dialog title={t("Clone Care Plan")} showDialog={clonePopup} onClose={() => setClonePopup(false)}>
                            <div className="py-2 w-full">
                                <div className="px-4 mt-2">
                                    <FlowText text={t("You are about to duplicate your last care plan, would you like to proceed?")} />
                                </div>
                                <div className="flex justify-between mt-8 mx-2">
                                    <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-700 dark:text-red-300 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setClonePopup(false)} >{t("No")}</button>
                                    <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => clonecareplan(assessId,cpId)} >{t("Yes")}</button>
                                </div>
                            </div>
                        </Dialog>, document.body)
                    }
                </div>
            }

            <ReassessDialog title={t("Assessment Reason")} showDialog={reassessDialogModal1} onClose={() => setReassessDialogModal1(false)}>
                <Reassessments onClose={() => setReassessDialogModal1(false)} props={initialData} assess ={assessmentTypeId} />
            </ReassessDialog>
            <ReassessDialog title={t("Reassessment Reason")} showDialog={reassessDialogModal} onClose={() => closeDialog()}>
                <Reassessments onClose={() => setReassessDialogModal(false)} props={initialData} assess ={assessmentTypeId}  />
            </ReassessDialog>
            <DAVDialog title={t("DAV Military Service")} showDialog={davServiceEnable} onClose={() => setDavServiceEnable(false)}>
                <DavServiceDialog onClose={() => setDavServiceEnable(false)} props={initialData} cgId={caregiverId} crId={carereceiverId} resource={true} />
            </DAVDialog>
            
        </>
    )
}


export default NewCaseDetails