import { AnimatePresence, motion } from "framer-motion"
import { wrapAnim, wrapPopAnim } from "../../commons/anims"
import { IoMdClose } from "react-icons/io"
import { useEffect, useRef } from "react"

const Dialog = ({ children, showDialog, title, onClose, injectClass='',zIndex=true,reRender=0 }) => {
    const changeRef = useRef(null)
    const close = () => {
        if(typeof onClose === 'function') onClose()
    }

    useEffect(()=>{
        if(reRender > 0){
            document.getElementById('title')?.focus()
        }
    },[title])

    return (
        <AnimatePresence>
            {showDialog &&
                <motion.div variants={wrapAnim} initial="hidden" animate="visible" exit="hidden" className={`flex items-center justify-center fixed top-0 bottom-0 left-0 right-0 bg-black text-gray-700 dark:text-white bg-opacity-60 ${zIndex ? 'z-[900]' : ''}`}>
                    <motion.div variants={wrapPopAnim} className={`bg-bluegray-200 dark:bg-ldark px-4 pt-2 pb-4 min-w-[500px] rounded-lg shadow-xl flex flex-col max-h-[90vh] ${injectClass}`}>
                        <div ref={changeRef} className="flex justify-between items-center">
                            <span id="title" tabIndex={0} className="text-lg font-medium text-gray-600 dark:text-white">
                                {title}
                            </span>
                            <button className="bg-none" tabIndex={0} onClick={close}><IoMdClose aria-label="Close" size="20px" className="cursor-pointer" onClick={close} /></button>
                        </div>
                        <div className="flex mt-4 w-full">
                            {children}
                        </div>
                    </motion.div>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default Dialog